/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  CheckIfEmailExist,
  CheckIfPhoneExist,
  FetchStatesByCountryCode,
} from "../../redux/actions/authaction";
import { connect } from "react-redux";
import ActionTypes from "../../redux/actiontype/ActionTypes";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CreateAction } from "../../redux/actions/createaction";
import SelectSearch, { fuzzySearch } from "react-select-search";

const EditUserModal = (props) => {
  const {
    edituser,
    setNotify,
    show,
    setShow,
    item,
    handleShowEdit,
    allcountriesstate,
    allstatesstate,
    fetchallcountrystates,
    isPhoneValid,
    isEmailValid,
    checkifemailexist,
    checkifphoneexist,
    editUserLoading,
    setEditUserLoading,
  } = props;
  const [userdetails, setUserdetails] = useState({});
  const [emailValid, setEmailValid] = useState(false);
  const countryarray = [];
  const statearray = [];

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditUserLoading(true);
    edituser(
      userdetails,
      setNotify,
      ActionTypes.EDIT_USER_SUCCESS,
      ActionTypes.EDIT_USER_FAIL,
      setShow,
      setEditUserLoading
    );
  };

  useEffect(async () => {
    if (Object.keys(item).length !== 0) {
      const countryData = allcountriesstate.find(
        (k) => k.country === item.countryOfResidence
      );
      const _statecode = countryData?.alpha3Code;
      fetchallcountrystates(setNotify, _statecode);

      let id = item.id;
      let email = item.email;
      let firstName = item.firstName;
      let lastName = item.lastName;
      let countryOfResidence = item.countryOfResidence;
      let countryOfNationality = item.countryOfNationality;
      let state = item.state;
      let city = item.city;
      let postalCode = item.postalCode;
      let address = item.address;
      let phoneNumber = item.phoneNumber;
      let isKYCCompliancePassed = item.isKYCCompliancePassed;
      let dateOfBirth = item.dateOfBirth;
      let isActive = item.isActive;
      let userRole = item.userRole;
      let accountType = returnAccountType(item.accountType);
      let gender = item.gender;
      let businessName = item.businessName;
      let businessRegNumber = item.businessRegNumber;

      setUserdetails({
        ...userdetails,
        ...{
          id,
          email,
          firstName,
          lastName,
          countryOfResidence,
          countryOfNationality,
          state,
          city,
          postalCode,
          address,
          phoneNumber,
          isKYCCompliancePassed,
          dateOfBirth,
          isActive,
          userRole,
          accountType,
          gender,
          businessName,
          businessRegNumber,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, allcountriesstate]);

  const handlereturnvalueForResidence = (cout) => {
    const countryData = countryarray.find((k) => k.value === cout);
    const _statecode = countryData.alpha3Code;

    fetchallcountrystates(setNotify, _statecode);

    const countryOfResidence = cout;

    setUserdetails({ ...userdetails, ...{ countryOfResidence } });
  };

  const handlereturnvalueForNationality = (cout) => {
    const countryOfNationality = cout;
    setUserdetails({ ...userdetails, ...{ countryOfNationality } });
  };

  const handlereturnvalueforstates = (state) => {
    setUserdetails({ ...userdetails, ...{ state } });
  };

  const checkphone = (phone) => {
    checkifphoneexist(setNotify, phone);
  };

  const checkemail = (email) => {
    let reg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (reg.test(email)) {
      // this is a valid email address
      setEmailValid(true);
      checkifemailexist(setNotify, email);
    } else {
      // invalid email, maybe show an error to the user.
      setEmailValid(false);
    }
  };

  const returnAccountType = (type) => (type === "Individual-Account" ? 0 : 1);

  useEffect(() => {}, [show]);

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.country;
      countryarray.push(newitem);
    }
  }

  if (allstatesstate) {
    for (var l = 0; l < allstatesstate.length; l++) {
      let item = allstatesstate[l];
      let newitem = {};
      newitem.name = item;
      newitem.value = item;
      statearray.push(newitem);
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleShowEdit}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="firstName">First Name</label>
                <input
                  name="firstName"
                  id="firstName"
                  placeholder={item.firstName}
                  defaultValue={item.firstName}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const firstName = event.target.value;
                    setUserdetails({ ...userdetails, ...{ firstName } });
                  }}
                />
              </div>

              <div className="col-md-6">
                <label htmlfor="lastName">Last Name</label>
                <div className="position-relative form-group">
                  <input
                    name="date"
                    id="lastName"
                    placeholder={item.lastName}
                    defaultValue={item.lastName}
                    type="text"
                    className="form-control"
                    onChange={(event) => {
                      const lastName = event.target.value;
                      setUserdetails({ ...userdetails, ...{ lastName } });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlfor="examplePhone">Phone Number</label>
                <div className="position-relative form-group">
                  <input
                    name="phone"
                    id="examplePhone"
                    placeholder="Phone Number"
                    defaultValue={item.phoneNumber}
                    maxLength={11}
                    type="tel"
                    className="form-control col-md-12"
                    style={{
                      backgroundColor: isPhoneValid ? "" : "red",
                    }}
                    onChange={(event) => {
                      const phoneNumber = event.target.value;
                      setUserdetails({ ...userdetails, ...{ phoneNumber } });
                      checkphone(phoneNumber);
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="position-relative form-group">
                  <label htmlFor="exampleEmail">Email</label>
                  <input
                    name="email"
                    id="exampleEmail"
                    className="form-control"
                    defaultValue={item.email}
                    placeholder="Email"
                    type="email"
                    required
                    style={{
                      color: isEmailValid && emailValid ? "" : "red",
                    }}
                    onChange={(event) => {
                      const email = event.target.value;
                      setUserdetails({ ...userdetails, ...{ email } });
                      checkemail(email);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="exampleGender">Sex</label>
                <select
                  type="select"
                  id="rateType"
                  name="rateType"
                  className="mb-2 form-control"
                  defaultValue={item.gender}
                  onChange={(event) => {
                    const gender = event.target.value;
                    setUserdetails({ ...userdetails, ...{ gender } });
                  }}
                >
                  <option> Select Gender </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>

              <div className="col-md-6">
                <label htmlFor="exampleGender">
                  Activate/Deactivate Account
                </label>
                <select
                  type="select"
                  id="rateType"
                  name="rateType"
                  className="mb-2 form-control"
                  defaultValue={item.isActive}
                  onChange={(event) => {
                    const isActive = event.target.value;
                    setUserdetails({ ...userdetails, ...{ isActive } });
                  }}
                >
                  <option> Select Active State </option>
                  {/* <option selected="selected" value={item.isActive}>{returnActive(item.isActive)}</option> */}
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="exampleGender">KYC Compliance Passed</label>
                <select
                  type="select"
                  id="rateType"
                  name="rateType"
                  className="mb-2 form-control"
                  defaultValue={item.isKYCCompliancePassed}
                  onChange={(event) => {
                    const isKYCCompliancePassed = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ isKYCCompliancePassed },
                    });
                  }}
                >
                  <option> Select Compliance </option>
                  {/* <option selected="selected" value={item.isKYCCompliancePassed}>{returnActive(item.isKYCCompliancePassed)}</option> */}
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <div className="col-md-6">
                <label htmlFor="exampleGender">Account Type</label>
                <select
                  type="select"
                  id="rateType"
                  name="rateType"
                  className="mb-2 form-control"
                  defaultValue={returnAccountType(item.accountType)}
                  onChange={(event) => {
                    const accountType = event.target.value;
                    setUserdetails({ ...userdetails, ...{ accountType } });
                  }}
                >
                  <option> Select Account Type </option>
                  {/* <option selected="selected" value={returnAccountType(item.accountType)}>{item.accountType}</option> */}
                  <option value={0}>Individual Account</option>
                  <option value={1}>Business Account</option>
                </select>
              </div>
            </div>
          </div>

          <div
            hidden={userdetails.accountType > 0 ? false : true}
            className="col-md-12 position-relative form-group"
          >
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="businessName">Business Name</label>
                <input
                  name="businessName"
                  id="businessName"
                  placeholder={item.businessName}
                  defaultValue={item.businessName}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const businessName = event.target.value;
                    setUserdetails({ ...userdetails, ...{ businessName } });
                  }}
                />
              </div>

              <div className="col-md-6">
                <label htmlfor="businessRegNumber">Business Reg. Number</label>
                <div className="position-relative form-group">
                  <input
                    name="businessRegNumber"
                    id="businessRegNumber"
                    placeholder={item.businessRegNumber}
                    defaultValue={item.businessRegNumber}
                    type="text"
                    className="form-control"
                    onChange={(event) => {
                      const businessRegNumber = event.target.value;
                      setUserdetails({
                        ...userdetails,
                        ...{ businessRegNumber },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="country">Country (Residence)</label>
                <SelectSearch
                  options={countryarray}
                  search={true}
                  value={item.countryOfResidence}
                  onChange={handlereturnvalueForResidence}
                  filterOptions={fuzzySearch}
                  placeholder="Choose country"
                />
              </div>

              <div className="col-md-6">
                <div className="position-relative form-group">
                  <label htmlFor="exampleState">
                    <span className="text-danger"></span>State (Residence)
                  </label>

                  {allstatesstate?.length ? (
                    <SelectSearch
                      options={statearray}
                      search={true}
                      value={item.state}
                      onChange={handlereturnvalueforstates}
                      filterOptions={fuzzySearch}
                      placeholder="Choose state"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="exampleAddress">Address (Residence)</label>
                <div className="position-relative form-group">
                  <input
                    name="address"
                    id="exampleAddress"
                    defaultValue={item.address}
                    placeholder="1234 Main St"
                    type="text"
                    className="form-control"
                    onChange={(event) => {
                      const address = event.target.value;
                      setUserdetails({ ...userdetails, ...{ address } });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="country">Country of Nationality</label>
                <SelectSearch
                  options={countryarray}
                  search={true}
                  value={item.countryOfNationality}
                  onChange={handlereturnvalueForNationality}
                  filterOptions={fuzzySearch}
                  placeholder="Choose country"
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <div className="position-relative form-group">
                  <label htmlFor="exampleCity">
                    <span className="text-danger"></span>City (Residence)
                  </label>
                  <input
                    name="text"
                    id="exampleCity"
                    defaultValue={item.city}
                    placeholder="City"
                    type="text"
                    className="form-control"
                    onChange={(event) => {
                      const city = event.target.value;
                      setUserdetails({ ...userdetails, ...{ city } });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="position-relative form-group">
                  <label htmlFor="exampleCountry">
                    <span className="text-danger"></span>Postal Code (Residence)
                  </label>
                  <input
                    name="text"
                    id="exampleCode"
                    defaultValue={item.postalCode}
                    placeholder="Postal Code"
                    type="text"
                    className="form-control"
                    onChange={(event) => {
                      const postalCode = event.target.value;
                      setUserdetails({ ...userdetails, ...{ postalCode } });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShowEdit}>
          Close
        </Button>
        <Button
          variant="danger"
          disabled={editUserLoading}
          onClick={handleSubmit}
        >
          Update User
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    allcountriesstate: state.allcountriesstate.allcountriesstate,
    allstatesstate: state.allstatesstate.allstatesstate,
    isPhoneValid: state.phonecheckstate.isValid,
    isEmailValid: state.emailcheckstate.isValid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    edituser: (
      userdetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setEditUserLoading
    ) => {
      dispatch(
        CreateAction(
          userdetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setEditUserLoading
        )
      );
    },
    fetchallcountrystates: (setError, statecode) => {
      dispatch(FetchStatesByCountryCode(setError, statecode));
    },
    checkifemailexist: (setError, email) => {
      dispatch(CheckIfEmailExist(setError, email));
    },
    checkifphoneexist: (setError, phone) => {
      dispatch(CheckIfPhoneExist(setError, phone));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal);
