import React, { useState } from "react";

const StepFourReg = (props, ref) => {
  let { setUserdetails, userdetails, accountype, isLoading, handleSubmit } =
    props;

  const [uiError, setUiError] = useState(false);

  const processBusiness = (prop) => {
    let tree = prop;
    if (tree === "1") {
      return "Business Account";
    } else if (tree === "0") {
      return "Individual Account";
    }
  };

  const checkDate = (props) => {
    var years = new Date(new Date() - new Date(props)).getFullYear() - 1970;
    if (years >= 18) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div id="step-3">
      <div id="accordion" className="accordion-wrapper mb-3">
        <div className="card">
          <div id="headingOne" className="card-header">
            <button
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              className="text-left m-0 p-0 btn btn-link btn-block"
            >
              <span className="form-heading text-danger">
                Final Information<p>Enter your business details below</p>
              </span>
            </button>
          </div>
          <div
            data-parent="#accordion"
            id="collapseOne"
            aria-labelledby="headingOne"
            className="collapse show"
          >
            <div className="card-body">
              <div className="form-row">
                <div className="col-md-12">
                  <div className="position-relative form-group">
                    <label htmlFor="exampleUserType">Account Type</label>
                    <select
                      id="exampleUserType"
                      className="mb-2 form-control"
                      defaultValue={userdetails.accountType}
                      onClick={(event) => {
                        const accountType = event.target.value;
                        const userRole = 0; //event.target.value;
                        setUserdetails({
                          ...userdetails,
                          ...{ userRole, accountType },
                        });
                      }}
                      onChange={(event) => {
                        const accountType = event.target.value;
                        const userRole = 0; //event.target.value;
                        setUserdetails({
                          ...userdetails,
                          ...{ userRole, accountType },
                        });
                      }}
                    >
                      <option>-- Select Type --</option>
                      <option selected value={userdetails.accountType}>
                        {processBusiness(userdetails.accountType)}
                      </option>
                      <option value="1">Business Account</option>
                      <option value="0">Individual Account</option>
                    </select>
                    {uiError && (
                      <div
                        className="bg-danger"
                        style={{
                          marginTop: "1rem",
                          display: "table",
                          color: "white",
                        }}
                      >
                        You need to select account type before proceeding
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="form-row"
                hidden={userdetails.accountType === "0" ? false : true}
              >
                <div className="col-md-12">
                  <label htmlFor="exampleGender">Gender</label>
                  <div className="position-relative form-group">
                    <select
                      type="select"
                      id="gender"
                      name="gender"
                      className="mb-2 form-control"
                      onChange={(event) => {
                        const gender = event.target.value;
                        setUserdetails({ ...userdetails, ...{ gender } });
                      }}
                    >
                      <option value=""> -- Select Gender -- </option>
                      <option selected value={userdetails.gender}>
                        {userdetails.gender}
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <label htmlfor="exampleDate">Date of Birth</label>
                  <div className="position-relative form-group">
                    <input
                      name="date"
                      max="2003-01-01"
                      id="exampleDate"
                      onKeyDown={false}
                      placeholder="date placeholder"
                      type="date"
                      defaultValue={userdetails.dateOfBirth}
                      className="form-control"
                      onChange={(event) => {
                        var result = checkDate(event.target.value);
                        if (result) {
                          const dateOfBirth = event.target.value;
                          setUserdetails({
                            ...userdetails,
                            ...{ dateOfBirth },
                          });
                        } else {
                          alert("you are too young/date is invalid");
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="form-row"
                hidden={userdetails.accountType === "0" ? true : false}
              >
                <div className="col-md-12">
                  <div className="position-relative form-group">
                    <label htmlFor="businessName">
                      Business Name (Optional)
                    </label>
                    <input
                      name="text"
                      id="businessName"
                      placeholder="Business Name"
                      type="text"
                      className="form-control"
                      defaultValue={userdetails.businessName}
                      onChange={(event) => {
                        const businessName = event.target.value;
                        setUserdetails({
                          ...userdetails,
                          ...{ businessName },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="position-relative form-group">
                    <label htmlFor="accessBRN">
                      Business Registration Number (Optional)
                    </label>
                    <input
                      disabled={accountype}
                      name="text"
                      id="accessBRN"
                      placeholder="Business Registration Number"
                      type="text"
                      className="form-control"
                      defaultValue={userdetails.businessRegNumber}
                      onChange={(event) => {
                        const businessRegNumber = event.target.value;
                        setUserdetails({
                          ...userdetails,
                          ...{ businessRegNumber },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="swal2-icon swal2-success swal2-animate-success-icon">
                <div
                  className="swal2-success-circular-line-left"
                  style={{ backgroundColor: "rgb(255, 255, 255)" }}
                />
                <span className="swal2-success-line-tip" />
                <span className="swal2-success-line-long" />
                <div className="swal2-success-ring" />
                <div
                  className="swal2-success-fix"
                  style={{ backgroundColor: "rgb(255, 255, 255)" }}
                />
                <div
                  className="swal2-success-circular-line-right"
                  style={{ backgroundColor: "rgb(255, 255, 255)" }}
                />
              </div>

              <div className="mt-3 mb-3" />
              <div className="text-center">
                {/* <div className="results-title">You just arrived at the last step!</div> */}

                {(() => {
                  if (isLoading) {
                    return (
                      <button
                        type="submit"
                        className="form-controls form-control-height btn btn-primary rounded submit px-3"
                      >
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </button>
                    );
                  } else {
                    return (
                      <button
                        hidden={!userdetails.accountType ? true : false}
                        className="btn-shadow btn-wide btn btn-danger btn-lg"
                        type="submit"
                        // disabled={isLoading}
                        onClick={handleSubmit}
                      >
                        Sign Up Now
                      </button>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFourReg;
