import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import authreducer from "./reducers/authreducer";
import authErrorReducer from "./reducers/autherrorreducer";
import loadingreducer from "./reducers/loadingreducer";
import {
  fetchcountryreducer,
  countrycodereducer,
  fetchallcountryreducer,
} from "./reducers/fetchcountryreducer";
import fetchstatesreducer from "./reducers/fetchstatesreducer";
import emailcheckreducer from "./reducers/emailcheckreducer";
import phonecheckreducer from "./reducers/phonecheckreducer";
import {
  signupreducer,
  userprofilereducer,
  allfundsreducer,
} from "./reducers/signupreducer";
import {
  fetchuserrecieversreducer,
  fetchchargesreducer,
  downloadreceiptreducer,
  uploadconfrimationreducer,
  addpaymentreducer,
  fetchjciaccountreducer,
} from "./reducers/fetchchargesreducer";
import {
  fetchpromosreducer,
  fetchreferralsreducer,
  fetchpurposesreducer,
  fetchallusersreducer,
  fetchaustracreportreducer,
  fetchcountryusersreducer,
  fetchusersmonthlyreducer,
  fetchcountrytransactionsreducer,
  fetchsingleuserreducer,
  fetchpendingtransactionssreducer,
  fetchappsettingsreducer,
  fetchwalletreducer,
  fetchcustomerreferralreducer,
  fetchadminreferralsreducer,
} from "./reducers/fetchpromosreducer";
import fetchratesreducer from "./reducers/fetchratesreducer";
import {
  fetchbanksreducer,
  fetchbusinessbanksreducer,
  fetchavailablebanksreducer,
} from "./reducers/fetchbanksreducer";
import {
  transactionreducer,
  conversionreducer,
  allreceivingcurrencyreducer,
  allsendingcurrencyreducer,
  customertransactionsreducer,
  alltransactionreducer,
  allcountiresreducer,
  reftransactionreducer,
  typetransactionreducer,
  advertconversionstatereducer,
  receivingconversionstatereducer,
  allreportreducer,
  exporttransactionreducer,
  alltsendingreducer,
  alltreceivingreducer,
  allpromotransreducer,
} from "./reducers/transactionreducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  loadingstate: loadingreducer,
  allcountriesstate: fetchcountryreducer,
  allcountriescodestate: countrycodereducer,
  authstate: authreducer,
  autherror: authErrorReducer,
  emailcheckstate: emailcheckreducer,
  phonecheckstate: phonecheckreducer,
  allstatesstate: fetchstatesreducer,
  userregstate: signupreducer,
  allcharges: fetchchargesreducer,
  alluserreceivers: fetchuserrecieversreducer,
  allpromos: fetchpromosreducer,
  allreferrals: fetchreferralsreducer,
  allpurposestate: fetchpurposesreducer,
  allrates: fetchratesreducer,
  allbanks: fetchbanksreducer,
  allavailablebanks: fetchavailablebanksreducer,
  allbusinessbanks: fetchbusinessbanksreducer,
  conversionstate: conversionreducer,
  transactionstate: transactionreducer,
  allreceivingcurrencystate: allreceivingcurrencyreducer,
  allsendingcurrencystate: allsendingcurrencyreducer,
  downloadreceiptstatus: downloadreceiptreducer,
  uploadconfirmation: uploadconfrimationreducer,
  allcustomertransactions: customertransactionsreducer,
  redirecturl: addpaymentreducer,
  userprofilestate: userprofilereducer,
  alltransactions: alltransactionreducer,
  allusers: fetchallusersreducer,
  austracreport: fetchaustracreportreducer,
  countryusers: fetchcountryusersreducer,
  monthlyusers: fetchusersmonthlyreducer,
  ijcbankaccount: fetchjciaccountreducer,
  allcountries: allcountiresreducer,
  countrytransactions: fetchcountrytransactionsreducer,
  singleuser: fetchsingleuserreducer,
  pendingtransactions: fetchpendingtransactionssreducer,
  appsettings: fetchappsettingsreducer,
  reftransactions: reftransactionreducer,
  typetransactions: typetransactionreducer,
  allfundsstate: allfundsreducer,
  advertconversionstate: advertconversionstatereducer,
  reporttransactions: allreportreducer,
  exporttransactions: exporttransactionreducer,
  receivingconversionstate: receivingconversionstatereducer,
  walletbalance: fetchwalletreducer,
  referraltransactions: fetchcustomerreferralreducer,
  adminreferraltransactions: fetchadminreferralsreducer,
  alltransactionsending: alltsendingreducer,
  alltransactionreceiving: alltreceivingreducer,
  allcountries: fetchallcountryreducer,
  promotransactions: allpromotransreducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
