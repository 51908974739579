import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetAction } from "../redux/actions/getaction";
import { GetSingleObjectAction } from "../redux/actions/getsoaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import Chart from "react-apexcharts";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { CreateAction } from "../redux/actions/createaction";
import { mainAxios } from "../utils/axios";

const Dashboardarea = (props) => {
  const {
    setNotify,
    show,
    setShow,
    fetchalltransactions,
    fetchallusers,
    allusers,
    editsettings,
    fetchappsettingsdetails,
    appsettings,
    alltransactions,
    allreferrals,
    fetchreferrals,
    fetchusersbycountry,
    allcountriesstate,
    countryusers,
    pendingtransactions,
    fetchPENDINGTRANSACTIONS,
    countrytransactions,
    fetchmonthlyusers,
    monthlyusers,
  } = props;
  let heightValue = [];
  const countryarray = [];
  const [checkCountryLoading, setCheckCountryLoading] = useState(false);
  const [updateSettingsLoading, setUpdateSettingsLoading] = useState(false);
  const [checkCountryTransactionLoading, setCheckCountryTransactionLoading] =
    useState(false);
  const [settingsobj, setSettingsobj] = useState({});
  const [referrals, setReferrals] = useState({});
  const [koro, setKoro] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      fill: {
        colors: ["#f5363c", "#f5363c", "#f5363c"],
      },
      stroke: {
        colors: ["#f5363c"],
      },
    },
    series: [
      {
        name: "Total Users:",
        data: [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4],
      },
    ],
    colors: ["#e7322f", "#e7322f", "#e7322f"],
    responsive: [
      {
        breakpoint: 1000,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const [users, setUsers] = useState({});
  const [transactions, setTransactions] = useState(0);

  useEffect(() => {
    if (allreferrals !== undefined && allreferrals[0] !== null) {
      setReferrals(allreferrals[0][0]);
    }
  }, [allreferrals]);

  const getAllTransactions = async () => {
    try {
      const res = await mainAxios.get(
        `/Transactions/getTotalNumberOfTransactions`
      );
      if (res?.data?.data) {
        setTransactions(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const getAllTransactions = async () => {
  //   try {
  //     const res = await mainAxios.get(`/Transactions/getAllTransactions`);

  //     if (res?.data?.data.length) {
  //       setTransactions(res.data);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const getAllUsers = async () => {
    try {
      const res = await mainAxios.get(`/Users/getAllCustomers`);

      if (res?.data?.data.length) {
        setUsers(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllTransactions();
    getAllUsers();
    fetchreferrals(
      show,
      setNotify,
      ActionTypes.GET_REFERRAL_SUCCESS,
      ActionTypes.GET_REFERRAL_FAIL,
      setShow
    );
    fetchmonthlyusers(
      show,
      setNotify,
      ActionTypes.GET_MONTHLY_USERS_SUCCESS,
      ActionTypes.GET_MONTHLY_USERS_FAIL,
      setShow
    );
    fetchPENDINGTRANSACTIONS(
      "nothing",
      setNotify,
      ActionTypes.FETCH_PENDING_TRANSACTIONS_SUCCESS,
      ActionTypes.FETCH_PENDING_TRANSACTIONS_FAIL,
      setShow
    );
    fetchappsettingsdetails(
      "nothing",
      setNotify,
      ActionTypes.GET_APP_SETTINGS_SUCCESS,
      ActionTypes.GET_APP_SETTINGS_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (monthlyusers !== undefined) {
      monthlyusers.forEach((element) => {
        heightValue.push(element.totalUsers);
      });

      setKoro({
        options: {
          chart: {
            id: "basic-bar",
          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
        },
        series: [
          {
            name: "Total Users:",
            data: heightValue,
          },
        ],
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyusers]);

  const handlereturnvalue = (cout) => {
    const _statecode = cout;
    setCheckCountryLoading(true);
    fetchusersbycountry(
      _statecode,
      setNotify,
      ActionTypes.FETCH_USERS_BY_COUNTRY_SUCCESS,
      ActionTypes.FETCH_USERS_BY_COUNTRY_FAIL,
      setCheckCountryLoading
    );
  };

  const handletransactionvalue = (cout) => {
    const _countrycode = cout;
    setCheckCountryTransactionLoading(true);
    fetchusersbycountry(
      _countrycode,
      setNotify,
      ActionTypes.FETCH_TRANSACTIONS_BY_COUNTRY_SUCCESS,
      ActionTypes.FETCH_TRANSACTIONS_BY_COUNTRY_FAIL,
      setCheckCountryTransactionLoading
    );
  };

  useEffect(() => {
    if (appsettings !== undefined) {
      setSettingsobj(appsettings[0]);
    }
  }, [appsettings]);

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.alpha3Code;
      newitem.currency = item.currency;
      countryarray.push(newitem);
    }
  }

  const updateSettings = (e) => {
    e.preventDefault();
    setUpdateSettingsLoading(true);
    editsettings(
      settingsobj,
      setNotify,
      ActionTypes.EDIT_TRANSACTION_LIMIT_SUCCESS,
      ActionTypes.EDIT_TRANSACTION_LIMIT_FAIL,
      setShow,
      setUpdateSettingsLoading
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-left card">
            <div className="widget-content p-0 w-100">
              <div className="mixed-chart">
                <Chart
                  options={koro.options}
                  series={koro.series}
                  type="bar"
                  // width="1400"
                  height="350"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mbg-3 h-auto pl-0 pr-0 bg-transparent no-border card-header">
        <div className="card-header-title fsize-2 text-capitalize font-weight-normal">
          Summary Section
        </div>
        <div className="btn-actions-pane-right text-capitalize actions-icon-btn"></div>
      </div>

      <div className="row">
        <div className="col-md-6 col-lg-4">
          <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-left card">
            <div className="widget-content p-0 w-100">
              <div className="widget-content-outer">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content">
                    {/* <h6 className="widget-subheading">Income</h6> */}
                    <div className="widget-chart-flex">
                      <div className="widget-numbers mb-0 w-100">
                        <div
                          className="widget-chart-flex"
                          style={{ marginBottom: 40 }}
                        >
                          <div className="fsize-4 text-danger">
                            {/* <small className="opacity-5">$</small> */}
                            {users?.totalNoItems}
                          </div>
                          <div className="ml-auto">
                            <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                              <span className="text-success pl-2">
                                {/* +14% */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget-content-left fsize-1">
                  <div className="text-muted opacity-6">
                    Total Registered Users
                  </div>
                  <br></br>
                </div>
                <div
                  className="widget-content-wrapper"
                  style={{ marginBottom: 10 }}
                >
                  <div className="widget-content-right w-100">
                    <div className="progress-bar-xs progress">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        aria-valuenow={71}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-4">
          <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-left card">
            <div className="widget-content p-0 w-100">
              <div className="widget-content-outer">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content">
                    {/* <h6 className="widget-subheading">Income</h6> */}
                    <div className="widget-chart-flex">
                      <div className="widget-numbers mb-0 w-100">
                        <div
                          className="widget-chart-flex"
                          style={{ marginBottom: 40 }}
                        >
                          <div className="fsize-4 text-danger">
                            {/* <small className="opacity-5">$</small> */}
                            {transactions}
                          </div>
                          <div className="ml-auto">
                            <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                              <span className="text-success pl-2">
                                {/* +14% */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget-content-left fsize-1">
                  <div className="text-muted opacity-6">
                    Total Number of Transactions
                  </div>
                  <br></br>
                </div>
                <div
                  className="widget-content-wrapper"
                  style={{ marginBottom: 10 }}
                >
                  <div className="widget-content-right w-100">
                    <div className="progress-bar-xs progress">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        aria-valuenow={71}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-4">
          <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-left card">
            <div className="widget-content p-0 w-100">
              <div className="widget-content-outer">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content">
                    {/* <h6 className="widget-subheading">Income</h6> */}
                    <div className="widget-chart-flex">
                      <div className="widget-numbers mb-0 w-100">
                        <div
                          className="widget-chart-flex"
                          style={{ marginBottom: 40 }}
                        >
                          <div className="fsize-4 text-danger">
                            {/* <small className="opacity-5">$</small> */}
                            {pendingtransactions}
                          </div>
                          <div className="ml-auto">
                            <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                              <span className="text-success pl-2">
                                {/* +14% */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget-content-left fsize-1">
                  <div className="text-muted opacity-6">
                    Total Pending Transactions
                  </div>
                  <br></br>
                </div>
                <div
                  className="widget-content-wrapper"
                  style={{ marginBottom: 10 }}
                >
                  <div className="widget-content-right w-100">
                    <div className="progress-bar-xs progress">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        aria-valuenow={71}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-card mb-3 card">
        <div className="card-body">
          <h5 className="card-title">Total Users & Transactions record</h5>
          <div className="row">
            <div className="col-md-4">
              <div className="position-relative form-group">
                <label htmlFor="exampleCustomSelect">
                  View Total Users In a Country
                </label>
                <SelectSearch
                  options={countryarray}
                  search={true}
                  onChange={handlereturnvalue}
                  filterOptions={fuzzySearch}
                  placeholder="Choose your country"
                />
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="exampleCustomSelect"></label>
              <div className="fsize-2">
                {(() => {
                  if (checkCountryLoading) {
                    return <span className="text-danger">Loading...</span>;
                  } else {
                    return <span className="text-danger">{countryusers}</span>;
                  }
                })()}
              </div>
            </div>
            <div className="col-md-4">
              <div className="position-relative form-group">
                <label htmlFor="exampleCustomSelect">
                  View Total Transactions in a Country
                </label>
                <SelectSearch
                  options={countryarray}
                  search={true}
                  onChange={handletransactionvalue}
                  filterOptions={fuzzySearch}
                  placeholder="Choose your country"
                />
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="exampleCustomSelect"></label>
              <div className="fsize-2">
                {/* <small className="opacity-5">$</small> */}

                {(() => {
                  if (checkCountryTransactionLoading) {
                    return <span className="text-danger">Loading...</span>;
                  } else {
                    return (
                      <span className="text-danger">{countrytransactions}</span>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-xl-4">
          <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
            <div className="widget-chat-wrapper-outer">
              <div className="widget-chart-content pt-3 pl-3 pb-1">
                <div className="widget-chart-flex">
                  <div className="widget-numbers">
                    <div className="widget-chart-flex">
                      <div className="fsize-4">
                        <span className="card-title">
                          Transaction Min Limit
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <h6
                  className="widget-subheading mb-0 opacity-5"
                  style={{ fontSize: 12 }}
                >
                  You can set transaction minimum limit here...
                </h6>
                <label htmlFor="exampleCustomSelect" style={{ marginTop: 30 }}>
                  Enter Amount
                </label>
                <div className="input-group">
                  <div className="input-group-prepend"></div>
                  <input
                    placeholder="Amount"
                    step={1}
                    type="number"
                    defaultValue={settingsobj?.transactionMinLimit}
                    className="form-control"
                    onChange={(event) => {
                      const transactionMinLimit = parseInt(event.target.value);
                      setSettingsobj({
                        ...settingsobj,
                        ...{ transactionMinLimit },
                      });
                    }}
                  />
                  {/*  */}
                </div>
                <div className="ml-auto">
                  <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                    <span className="text-success pl-2">
                      <button
                        className="btn-wide btn btn-danger"
                        disabled={updateSettingsLoading}
                        style={{ marginTop: 15 }}
                        onClick={updateSettings}
                      >
                        Update
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-8">
          <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
            <div className="widget-chat-wrapper-outer">
              <div className="widget-chart-content pt-3 pl-3 pb-1">
                <div className="widget-chart-flex">
                  <div className="widget-numbers">
                    <div className="widget-chart-flex">
                      <div className="fsize-4">
                        <span className="card-title">
                          Referral Minimum Amount & Bonus
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <h6
                  className="widget-subheading mb-0 opacity-5"
                  style={{ fontSize: 12 }}
                >
                  You can set transaction minimum limit here...
                </h6>
                <div className="form-row">
                  <div className="col-md-6">
                    <label
                      htmlFor="exampleCustomSelect"
                      style={{ marginTop: 30 }}
                    >
                      Min Required Amount
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        {/* <span className="input-group-text">$</span> */}
                      </div>
                      <input
                        placeholder="Amount"
                        step={1}
                        type="number"
                        defaultValue={referrals.minAmount}
                        className="form-control"
                        onChange={(event) => {
                          const referral_Trans_Min_AmountForReward = parseInt(
                            event.target.value
                          );
                          setSettingsobj({
                            ...settingsobj,
                            ...{ referral_Trans_Min_AmountForReward },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="exampleCustomSelect"
                      style={{ marginTop: 30 }}
                    >
                      Bonus Value
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        {/* <span className="input-group-text">$</span> */}
                      </div>
                      <input
                        placeholder="Amount"
                        step={1}
                        type="number"
                        // defaultValue={settingsobj?.bonusValue}
                        defaultValue={referrals.bonusValue}
                        className="form-control"
                        onChange={(event) => {
                          const bonusValue = parseInt(event.target.value);
                          setSettingsobj({ ...settingsobj, ...{ bonusValue } });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="ml-auto">
                  <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                    <span className="text-success pl-2">
                      <button
                        className="btn-wide btn btn-danger"
                        disabled={updateSettingsLoading}
                        style={{ marginTop: 15 }}
                        onClick={updateSettings}
                      >
                        Update
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-xl-4">
          <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
            <div className="widget-chat-wrapper-outer">
              <div className="widget-chart-content pt-3 pl-3 pb-1">
                <div className="widget-chart-flex">
                  <div className="widget-numbers">
                    <div className="widget-chart-flex">
                      <div className="fsize-4">
                        <span className="card-title">
                          Transaction Daily Limit
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <h6
                  className="widget-subheading mb-0 opacity-5"
                  style={{ fontSize: 12 }}
                >
                  You can set daily transaction minimum limit here...
                </h6>
                <label htmlFor="exampleCustomSelect" style={{ marginTop: 30 }}>
                  Enter Amount
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {/*<span className="input-group-text">$</span>*/}
                  </div>
                  <input
                    placeholder="Amount"
                    step={1}
                    type="number"
                    defaultValue={settingsobj?.transactionMaxDailyLimit}
                    className="form-control"
                    onChange={(event) => {
                      const transactionDailyLimit = parseInt(
                        event.target.value
                      );
                      setSettingsobj({
                        ...settingsobj,
                        ...{ transactionDailyLimit },
                      });
                    }}
                  />
                </div>
                <div className="ml-auto">
                  <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                    <span className="text-success pl-2">
                      <button
                        className="btn-wide btn btn-danger"
                        disabled={updateSettingsLoading}
                        style={{ marginTop: 15 }}
                        onClick={updateSettings}
                      >
                        Update
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-8">
          <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
            <div className="widget-chat-wrapper-outer">
              <div className="widget-chart-content pt-3 pl-3 pb-1">
                <div className="widget-chart-flex">
                  <div className="widget-numbers">
                    <div className="widget-chart-flex">
                      <div className="fsize-4">
                        <span className="card-title">
                          transaction Hold & Monthly Limit
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <h6
                  className="widget-subheading mb-0 opacity-5"
                  style={{ fontSize: 12 }}
                >
                  You can set transaction hold and monthly limits here...
                </h6>
                <div className="form-row">
                  <div className="col-md-6">
                    <label
                      htmlFor="exampleCustomSelect"
                      style={{ marginTop: 30 }}
                    >
                      Hold Limit Amount
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        {/* <span className="input-group-text">$</span> */}
                      </div>
                      <input
                        placeholder="Amount"
                        step={1}
                        type="number"
                        defaultValue={settingsobj?.transactionHoldLimit}
                        className="form-control"
                        onChange={(event) => {
                          const transactionHoldLimit = parseInt(
                            event.target.value
                          );
                          setSettingsobj({
                            ...settingsobj,
                            ...{ transactionHoldLimit },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="exampleCustomSelect"
                      style={{ marginTop: 30 }}
                    >
                      Transaction Monthly Limit
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        {/* <span className="input-group-text">$</span> */}
                      </div>
                      <input
                        placeholder="Amount"
                        step={1}
                        type="number"
                        defaultValue={settingsobj?.transactionMaxMonthlyLimit}
                        className="form-control"
                        onChange={(event) => {
                          const transactionMonthlyLimit = parseInt(
                            event.target.value
                          );
                          setSettingsobj({
                            ...settingsobj,
                            ...{ transactionMonthlyLimit },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="ml-auto">
                  <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                    <span className="text-success pl-2">
                      <button
                        className="btn-wide btn btn-danger"
                        disabled={updateSettingsLoading}
                        style={{ marginTop: 15 }}
                        onClick={updateSettings}
                      >
                        Update
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    alltransactions: state.alltransactions.alltransactions,
    allusers: state.allusers.allusers,
    allcountriesstate: state.allcountriesstate.allcountriesstate,
    countryusers: state.countryusers.countryusers,
    countrytransactions: state.countrytransactions.countrytransactions,
    monthlyusers: state.monthlyusers.monthlyusers,
    pendingtransactions: state.pendingtransactions.pendingtransactions,
    appsettings: state.appsettings.appsettings,
    allreferrals: state.allreferrals.allreferrals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchreferrals: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      // dispatch(ShowLoading(setNotify));
      dispatch(
        GetSingleObjectAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchalltransactions: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchallusers: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchmonthlyusers: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchappsettingsdetails: (
      customerId,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetSingleObjectAction(
          customerId,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    editsettings: (
      settingsdetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setChargesLoading
    ) => {
      dispatch(
        CreateAction(
          settingsdetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setChargesLoading
        )
      );
    },
    fetchusersbycountry: (
      countryCode,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetSingleObjectAction(
          countryCode,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchPENDINGTRANSACTIONS: (
      nothing,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetSingleObjectAction(
          nothing,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboardarea);
