import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { ShowLoading } from "../redux/actions/authaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import $ from "jquery";
// import DataTable from "datatables.net";
import { GetAction } from "../redux/actions/getaction";
import EditCustomerReferralModal from "./modals/EditCustomerReferralModal";

const Customerreferralarea = (props) => {
  const {
    setNotify,
    show,
    handleShow,
    setShow,
    fetchreferrals,
    adminreferraltransactions,
    addReferralLoading,
    setAddReferralLoading,
  } = props;
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    fetchreferrals(
      "null",
      setNotify,
      ActionTypes.GET_REFERRAL_TRANSACTION_BY_ADMIN_SUCCESS,
      ActionTypes.GET_REFERRAL_TRANSACTION_BY_ADMIN_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   $(document).ready(function () {
  //     $("#examtable").DataTable({ responsive: !0, destroy: true, order: [[8, "desc"]] });
  //   });
  // }, []);

  //this is to check for changes in addreferralloading variable
  useEffect(() => {
    if (!addReferralLoading) {
      fetchreferrals(
        show,
        setNotify,
        ActionTypes.GET_REFERRAL_TRANSACTION_BY_ADMIN_SUCCESS,
        ActionTypes.GET_REFERRAL_TRANSACTION_BY_ADMIN_FAIL,
        setShow
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addReferralLoading]);

  useEffect(() => {
    if (adminreferraltransactions !== undefined) {
      setReferrals(adminreferraltransactions);
      $("#examtable").DataTable({
        responsive: !0,
        destroy: true,
      });
    }
  }, [adminreferraltransactions]);

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [details, setDetails] = useState({});

  const handleShowEdit = () => {
    setShowEdit(!showEdit);
  };
  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  };

  const [item, setItem] = useState({});

  function handleEdit(item) {
    setShowEdit(true);
    setItem(item);
  }

  useEffect(() => {
    if (details.status !== null) {
      fetchreferrals(
        details.status,
        setNotify,
        ActionTypes.GET_REFERRAL_TRANSACTION_BY_ADMIN_SUCCESS,
        ActionTypes.GET_REFERRAL_TRANSACTION_BY_ADMIN_FAIL,
        setShow
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  function handleDelete(item) {
    setShowDelete(true);
    setItem(item);
  }

  const renderrow = (items) => {
    return items.map((item, i) => {
      return (
        <>
          <tr>
            <td>{`${item.firstName} ${item.lastname || ""}`}</td>
            <td>{item.email}</td>
            <td>{item.payout_Balance}</td>
            <td>{item.total_No_Transaction}</td>
            <td>{item.status}</td>
            <td>
              <button
                className="mb-2 mr-2 btn btn-dark"
                onClick={() => handleEdit(item)}
              >
                Edit
              </button>
              {/* <button
                  className="mb-2 mr-2 btn btn-danger"
                  onClick={() => handleDelete(item)}
                >
                  Delete
                </button> */}
            </td>
          </tr>
        </>
      );
    });
  };

  return (
    <>
      <div className="app-page-title app-page-title-simple">
        <div className="page-title-wrapper">
          <div className="page-title-heading" style={{ marginLeft: 16 }}>
            <div>
              <div className="page-title-head center-elem">
                <span className="d-inline-block">Referral Management</span>
              </div>
              <div className="page-title-subheading opacity-10">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <span>Here you can manage all your referral</span>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-xl-12">
        <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
          <div className="widget-chat-wrapper-outer">
            <div className="widget-chart-content pt-3 pl-3 pb-1">
              <div className="form-row">
                <div>
                  <h4>Total:</h4>
                  <h4>
                    <b>
                      {referrals.length ?? 0} referral
                      {referrals.length > 1 ? "s" : ""}
                    </b>
                  </h4>
                </div>
                <div className="ml-auto" style={{ marginRight: 15 }}>
                  <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                    <span className="text-success pl-2">
                      {/* <select
                        type="select"
                        id="rateType"
                        name="rateType"
                        className="mb-2 form-control"
                        onChange={(event) => {
                          const status = event.target.value;
                          setDetails({
                            ...details,
                            ...{ status },
                          });
                        }}
                      >
                        <option> Select Status </option>
                        <option value="PAID">Paid</option>
                        <option value="UNPAID">Unpaid</option>
                      </select> */}

                      <div className="position-relative form-group col-md-12">
                        <span>
                          <input
                            type="file"
                            name="fileToUpload"
                            className="mb-2 mr-2 btn btn-danger btn-lg btn-block"
                            onChange={(event) => {
                              const fileToUpload = event.target.files[0];
                              setDetails({
                                ...details,
                                ...{ fileToUpload },
                              });
                            }}
                          />
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-card mb-3 card">
        <div className="card-body">
          {referrals?.length ? (
            <table
              style={{ width: "100%" }}
              id="examtable"
              className="table table-hover table-striped table-bordered"
            >
              <thead style={{ textAlign: "center" }}>
                <tr>
                  <th>
                    <b>Customer Name</b>
                  </th>
                  <th>
                    <b>Email</b>
                  </th>
                  <th>
                    <b>Payout Balance</b>
                  </th>
                  <th>
                    <b>Total No Transaction</b>
                  </th>
                  <th>
                    <b>Status</b>
                  </th>

                  <th>
                    <b>Action</b>
                  </th>
                </tr>
              </thead>

              <tbody style={{ textAlign: "center" }}>
                {renderrow(referrals)}
              </tbody>
            </table>
          ) : null}
        </div>
      </div>

      <EditCustomerReferralModal
        item={item}
        setNotify={setNotify}
        show={showEdit}
        handleEdit={handleEdit}
        setShow={setShowEdit}
        handleShowEdit={handleShowEdit}
        addReferralLoading={addReferralLoading}
        setAddReferralLoading={setAddReferralLoading}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    adminreferraltransactions:
      state.adminreferraltransactions.adminreferraltransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchreferrals: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(ShowLoading(setNotify));
      if (show !== undefined) {
        dispatch(
          GetAction(
            show,
            setNotify,
            successactiontype,
            failureactiontype,
            setShow
          )
        );
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customerreferralarea);
