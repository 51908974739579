/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  UserRegisterAuthAction,
  ShowLoading,
  FetchAllCountry,
  FetchStatesByCountryCode,
  CheckIfEmailExist,
  CheckIfPhoneExist,
  FetchAllCountryCodes,
  FetchAllNations,
} from "../../redux/actions/authaction";
import { usePasswordValidation } from "../../utils/passwordValidation";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { GetsingleArrayAction } from "../../redux/actions/getpatchaction";
import ActionTypes from "../../redux/actiontype/ActionTypes";

const NewRegistration = (props) => {
  const location = useLocation();
  const type = location.state.type;
  let extension = "";

  const {
    registeruser,
    isLoading,
    fetchallcountry,
    fetchcodes,
    fetchallcountrycodes,
    fetchallcountrystates,
    checkifemailexist,
    checkifphoneexist,
    allcountriesstate,
    allcountries,
    allcountriescodestate,
    allstatesstate,
    isEmailValid,
    isPhoneValid,
    setNotify,
    allfundsstate,
    fetchfunds,
  } = props;
  const [userdetails, setUserdetails] = useState({});
  const [phoneerrortext, setPhoneErrorText] = useState();
  const [agreed, setAgreed] = useState(false);
  const history = useHistory();

  const checkemail = (email) => {
    let reg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (reg.test(email)) {
      // this is a valid email address
      setEmailValid(true);
      checkifemailexist(setError, email);
    } else {
      // invalid email, maybe show an error to the user.
      setEmailValid(false);
    }
  };

  useEffect(() => {
    let userRole = "";
    let accountType = "";
    if (type === "personal") {
      userRole = 0;
      accountType = 0;
      setUserdetails({
        ...userdetails,
        ...{ userRole, accountType },
      });
    } else {
      userRole = 0;
      accountType = 1;
      setUserdetails({
        ...userdetails,
        ...{ userRole, accountType },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fundsarray = [];
  if (allfundsstate) {
    for (var k = 0; k < allfundsstate.length; k++) {
      let item = allfundsstate[k];
      let newitem = {};
      newitem.name = item.fund;
      newitem.value = item.id;
      fundsarray.push(newitem);
    }
  }

  const allcountriesarray = [];
  if (allcountriesstate) {
    for (var m = 0; m < allcountriesstate.length; m++) {
      let item = allcountriesstate[m];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.country + " " + item.alpha3Code;
      newitem.currency = item.currency;
      allcountriesarray.push(newitem);
    }
  }

  const allcountrycodesarray = [];
  if (allcountries) {
    for (var d = 0; d < allcountries.length; d++) {
      let item = allcountries[d];
      let newitem = {};
      newitem.name = item.callingCode;
      newitem.value = item.callingCode;
      allcountrycodesarray.push(newitem);
    }
  }

  const allnations = [];
  if (allcountriescodestate) {
    for (var w = 0; w < allcountriescodestate.length; w++) {
      let item = allcountriescodestate[w];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.country;
      allnations.push(newitem);
    }
  }

  const statearray = [];
  if (allstatesstate) {
    for (var l = 0; l < allstatesstate.length; l++) {
      let item = allstatesstate[l];
      let newitem = {};
      newitem.name = item;
      newitem.value = item;
      statearray.push(newitem);
    }
  }

  useEffect(() => {
    if (!isPhoneValid) {
      setPhoneErrorText("Phone number already exist!");
    } else {
      setPhoneErrorText("");
    }
  }, [isPhoneValid]);

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  useEffect(() => {
    fetchallcountry(setError);
    fetchcodes(setError);
    fetchallcountrycodes(setError);
    fetchfunds(
      ActionTypes.FETCH_FUNDS_SUCCCESS,
      ActionTypes.FETCH_FUNDS_FAIL,
      setNotify
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loginloading, setLoginLoading] = useState(false);

  useEffect(() => {}, [isLoading]);

  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const [hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: password.firstPassword,
      secondPassword: password.secondPassword,
    });

  const [error, setError] = useState({
    hasError: false,
    message: "",
  });

  const checkphone = (phone) => {
    checkifphoneexist(setError, phone);
  };

  const handlereturnvalue2 = (cout) => {
    const countryOfNationality = cout; //.substring(0, cout.length - 4);
    setUserdetails({ ...userdetails, ...{ countryOfNationality } });
  };

  const handlereturnvalue3 = (cout) => {
    let extension = cout;
    setUserdetails({ ...userdetails, ...{ extension } });
  };

  const handlereturnvalue = (cout) => {
    const _statecode = cout.substring(cout.length - 3, cout.length);
    fetchallcountrystates(setError, _statecode);
    const countryofResidence = _statecode; //cout.substring(0, cout.length - 4);
    setUserdetails({ ...userdetails, ...{ countryofResidence } });
  };

  const handlereturnvalueforstates = (state) => {
    setUserdetails({ ...userdetails, ...{ state } });
  };

  const handlereturnvalueforsourceOfFunds = (sourceOfFunds) => {
    setUserdetails({ ...userdetails, ...{ sourceOfFunds } });
  };

  const checkDate = (props) => {
    var years = new Date(new Date() - new Date(props)).getFullYear() - 1970;
    if (years >= 18) {
      return true;
    } else {
      return false;
    }
  };

  //to chaeck the login state of the user on loads
  useEffect(() => {}, []);
  const [emailValid, setEmailValid] = useState(false);
  const [emailerrortext, setEmailErrortext] = useState();
  const [showerr, setShowErr] = useState(false);
  const [errmsg, setErrmsg] = useState();
  const [err, setErr] = useState();

  const [uiError2, setUiError2] = useState(false);
  const [uiError, setUiError] = useState(false);
  const [uiError3, setUiError3] = useState(false);
  const [uiError4, setUiError4] = useState(false);
  const [uiError5, setUiError5] = useState(false);
  const [uiError6, setUiError6] = useState(false);
  const [uiError7, setUiError7] = useState(false);
  const [uiError8, setUiError8] = useState(false);
  const [uiError9, setUiError9] = useState(false);
  const [uiError10, setUiError10] = useState(false);
  // const [uiError11, setUiError11] = useState(false);
  const [uiError12, setUiError12] = useState(false);
  const [uiError13, setUiError13] = useState(false);
  const [uiError14, setUiError14] = useState(false);
  const [uiError15, setUiError15] = useState(false);
  const [uiErrorDOB, setUiErrorDOB] = useState(false);

  const pwderrmsg1 =
    "Your password must match, has a special character, number, Upper & lower case letters & not less than 8 characters";
  const pwderrmsg2 =
    "Your password must has a special character, a number, Upper & lower case letters and not less than 8 characters";

  const checkoutput = () => {
    setUiError15(false);
    setUiError14(false);
    setUiError13(false);
    setUiError12(false);
    setUiError10(false);
    setUiError9(false);
    setUiError8(false);
    setUiError7(false);
    setUiError6(false);
    setUiError5(false);
    setUiError4(false);
    setUiError3(false);
    setUiError2(false);
    setUiError(false);

    if (!userdetails.firstName) {
      setUiError2(true);
      return false;
    } else if (!userdetails.lastName) {
      setUiError(true);
      return false;
    } else if (!userdetails.countryOfNationality) {
      setUiError4(true);
      return false;
    } else if (!userdetails.countryofResidence) {
      setUiError5(true);
      return false;
    } else if (!userdetails.state) {
      setUiError6(true);
      return false;
    } else if (!userdetails.extension && userdetails.extension !== "") {
      setUiError13(true);
      return false;
    } else if (!userdetails.phoneNumber) {
      setUiError3(true);
      return false;
    } else if (!userdetails.address) {
      setUiError14(true);
      return false;
    } else if (!userdetails.city) {
      setUiError7(true);
      return false;
    } else if (!userdetails.email) {
      setUiError15(true);
      return false;
    } else if (!userdetails.sourceOfFunds) {
      setUiError10(true);
      return false;
    } else if (!userdetails.pin) {
      setUiError9(true);
      return false;
    } else if (!userdetails.postalCode) {
      setUiError8(true);
      return false;
    } else if (userdetails.pin.length < 6) {
      setUiError12(true);
      return false;
    } else if (!userdetails.dateOfBirth) {
      setUiErrorDOB(true);
      return false;
    } else {
      setUiError15(false);
      setUiError14(false);
      setUiError13(false);
      setUiError12(false);
      setUiError10(false);
      setUiError9(false);
      setUiError8(false);
      setUiError7(false);
      setUiError6(false);
      setUiError5(false);
      setUiError4(false);
      setUiError3(false);
      setUiError2(false);
      setUiError(false);
      setUiErrorDOB(false);
      return true;
    }
  };

  useEffect(() => {
    if (!isEmailValid) {
      setEmailErrortext("Email already exist!");
    } else {
      setEmailErrortext("");
    }
  }, [isEmailValid]);

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
    setShowErr(true);
    if (hasNumber && upperCase && lowerCase && specialChar) {
      if (match) {
        setErrmsg("");
        setErr(false);
      }
    } else {
      setErrmsg(pwderrmsg2);
      setErr(true);
    }
  };

  const setSecond = (event) => {
    setShowErr(true);
    setPassword({ ...password, secondPassword: event.target.value });
    if (hasNumber && upperCase && lowerCase && specialChar && match) {
      setErrmsg("");
      setErr(false);
      const password = event.target.value;
      setUserdetails({ ...userdetails, ...{ password } });
    } else {
      setErrmsg(pwderrmsg1);
      setErr(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let yesres = checkoutput();
    if (yesres) {
      if (validateEmail(userdetails.email)) {
        setLoginLoading(true);
        registeruser(userdetails, history, setNotify, setLoginLoading);
      } else {
        alert("email not vaild");
      }
    } else {
      // alert("invalid information");
    }
  };

  return (
    <div className="rows justify-content-centers">
      <div className="col-md-8 col-lg-12">
        <div
          className="wrap d-md-flex"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="login-wrap p-4 col-md-12">
            <div className="row">
              <div className="col-md-5">
                <form className="signin-form">
                  <div className="dropdown-menu-header-inner bg-danger">
                    <div
                      className="menu-header-image"
                      style={{
                        backgroundImage: "url(../login/images/bg-1.jpeg)",
                      }}
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="lastName">
                        First Name
                      </label>
                      <input
                        name="text"
                        id="firstName"
                        defaultValue={userdetails.firstName}
                        placeholder="First Name"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        onChange={(event) => {
                          const firstName = event.target.value;
                          setUserdetails({ ...userdetails, ...{ firstName } });
                        }}
                      />
                      {uiError2 && (
                        <div
                          className="bg-danger"
                          style={{
                            marginTop: "1rem",
                            display: "table",
                            color: "white",
                          }}
                        >
                          You need to enter first name
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="name">
                        Last Name
                      </label>
                      <input
                        name="text"
                        id="lastName"
                        placeholder="Last Name"
                        defaultValue={userdetails.lastName}
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        onChange={(event) => {
                          const lastName = event.target.value;
                          setUserdetails({ ...userdetails, ...{ lastName } });
                        }}
                      />
                      {uiError && (
                        <div
                          className="bg-danger"
                          style={{
                            marginTop: "1rem",
                            display: "table",
                            color: "white",
                          }}
                        >
                          You need to enter last name
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="form-group col-md-4">
                      <label className="label" htmlFor="lastName">
                        Country Code
                      </label>
                      <div className="position-relative form-group">
                        <SelectSearch
                          options={allcountrycodesarray}
                          // defaultValue={userdetails.countryofResidence}
                          search={true}
                          onChange={handlereturnvalue3}
                          filterOptions={fuzzySearch}
                          placeholder="Select"
                        />
                        {uiError13 && (
                          <div
                            className="bg-danger"
                            style={{
                              marginTop: "1rem",
                              display: "table",
                              color: "white",
                            }}
                          >
                            You need to select phone number extension
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group col-md-8">
                      <label className="label" htmlFor="lastName">
                        Phone Number
                      </label>
                      <div className="position-relative form-group">
                        <input
                          name="phone"
                          id="examplePhone"
                          maxLength={11}
                          defaultValue={userdetails.phoneNumber}
                          placeholder="Phone Number"
                          type="number"
                          className="form-control col-md-12"
                          style={{
                            backgroundColor: isPhoneValid ? "" : "red",
                          }}
                          onChange={(event) => {
                            let phoneNumber = event.target.value;
                            phoneNumber = extension + "" + phoneNumber;
                            setUserdetails({
                              ...userdetails,
                              ...{ phoneNumber },
                            });

                            if (event.target.value.length > 5) {
                              checkphone(phoneNumber);
                            }
                          }}
                        />
                        {uiError3 && (
                          <div
                            className="bg-danger"
                            style={{
                              marginTop: "1rem",
                              display: "table",
                              color: "white",
                            }}
                          >
                            You need to enter your phone number
                          </div>
                        )}
                        <span className="text-danger">{phoneerrortext}</span>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label className="label" htmlFor="name">
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="exampleEmail"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Email"
                        // defaultValue={userdetails.email}
                        required
                        // style={{
                        //   // backgroundColor: isEmailValid && emailValid ? "" : "red",
                        //   fontSize: "11px",
                        // }}
                        onChange={(event) => {
                          const email = event.target.value;
                          setUserdetails({ ...userdetails, ...{ email } });
                          checkemail(email);
                        }}
                      />
                      {uiError15 && (
                        <div
                          className="bg-danger"
                          style={{
                            marginTop: "1rem",
                            display: "table",
                            color: "white",
                          }}
                        >
                          You need to enter a valid email address
                        </div>
                      )}
                      <span className="text-danger">{emailerrortext}</span>
                    </div>
                    <div
                      className="form-group col-md-12"
                      style={{ marginTop: 15 }}
                    >
                      <label className="label" htmlFor="lastName">
                        Source of Funds
                      </label>
                      <div className="position-relative form-group">
                        <SelectSearch
                          options={fundsarray}
                          search={true}
                          defaultValue={userdetails.sourceOfFunds}
                          onChange={handlereturnvalueforsourceOfFunds}
                          filterOptions={fuzzySearch}
                          placeholder="Select"
                        />
                        {uiError10 && (
                          <div
                            className="bg-danger"
                            style={{
                              marginTop: "1rem",
                              display: "table",
                              color: "white",
                            }}
                          >
                            You need to select source of funds
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="lastName">
                        Password
                      </label>
                      <div className="position-relative form-group">
                        <input
                          name="password"
                          id="examplePassword"
                          placeholder="Password"
                          type="password"
                          //   autoComplete="off"
                          autoSave="off"
                          autoComplete="off"
                          className="form-control"
                          autoComplete="off"
                          defaultValue={userdetails.password}
                          onChange={(event) => {
                            setFirst(event);
                          }}
                          onKeyUp={(event) => {
                            setFirst(event);
                          }}
                        />

                        {/* <span className="text-danger">{phoneerrortext}</span> */}
                      </div>
                    </div>

                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="name">
                        Confirm Password
                      </label>
                      <input
                        name="passwordrep"
                        id="examplePasswordRep"
                        placeholder="Confirm Password"
                        type="password"
                        autoComplete="off"
                        autoSave="off"
                        autoComplete="off"
                        className="form-control"
                        autoComplete="off"
                        defaultValue={userdetails.password}
                        onKeyUp={(event) => {
                          setSecond(event);
                        }}
                        onChange={(event) => {
                          setSecond(event);
                        }}
                      />
                      <p>
                        {" "}
                        {showerr ? (
                          <span
                            style={{
                              fontSize: "11px",
                              color: "red",
                              width: 20,
                            }}
                          >
                            {errmsg}
                          </span>
                        ) : (
                          <span />
                        )}
                      </p>
                      {/* <span className="text-danger">{emailerrortext}</span> */}
                    </div>
                  </div>
                </form>
              </div>
              <div className=" col-md-7">
                <form className="signin-form" autoComplete="off">
                  <div className="dropdown-menu-header-inner bg-danger">
                    <div
                      className="menu-header-image"
                      style={{
                        backgroundImage: "url(../login/images/bg-1.jpeg)",
                      }}
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="lastName">
                        Country of Nationality
                      </label>
                      <div className="position-relative form-group">
                        <SelectSearch
                          options={allnations}
                          defaultValue={userdetails.countryOfNationality}
                          search={true}
                          onChange={handlereturnvalue2}
                          filterOptions={fuzzySearch}
                          placeholder="Select"
                        />
                        {uiError4 && (
                          <div
                            className="bg-danger"
                            style={{
                              marginTop: "1rem",
                              display: "table",
                              color: "white",
                            }}
                          >
                            You need to select country of Nationality
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="lastName">
                        Country of Residence
                      </label>
                      <div className="position-relative form-group">
                        <SelectSearch
                          options={allcountriesarray}
                          defaultValue={userdetails.countryofResidence}
                          search={true}
                          onChange={handlereturnvalue}
                          filterOptions={fuzzySearch}
                          placeholder="Select"
                        />
                        {uiError5 && (
                          <div
                            className="bg-danger"
                            style={{
                              marginTop: "1rem",
                              display: "table",
                              color: "white",
                            }}
                          >
                            You need to select country of Residence
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="name">
                        State of Residence
                      </label>
                      <div className="position-relative form-group">
                        <SelectSearch
                          options={statearray}
                          search={true}
                          defaultValue={userdetails.state}
                          onChange={handlereturnvalueforstates}
                          filterOptions={fuzzySearch}
                          placeholder="Select"
                        />
                        {/* <span style={{ fontSize: 12 }}>(select country first)</span> */}
                        {uiError6 && (
                          <div
                            className="bg-danger"
                            style={{
                              marginTop: "1rem",
                              display: "table",
                              color: "white",
                            }}
                          >
                            You need to select state
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group -mt-2 col-md-6">
                      <label className="label" htmlFor="name">
                        Street Address
                      </label>
                      <input
                        name="address"
                        id="exampleAddress"
                        placeholder="1234 Main St"
                        type="text"
                        defaultValue={userdetails.address}
                        className="form-control"
                        autoComplete="off"
                        onChange={(event) => {
                          const address = event.target.value;
                          setUserdetails({ ...userdetails, ...{ address } });
                        }}
                      />
                      {uiError14 && (
                        <div
                          className="bg-danger"
                          style={{
                            marginTop: "1rem",
                            display: "table",
                            color: "white",
                          }}
                        >
                          You need to enter your address
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="lastName">
                        City
                      </label>
                      <input
                        name="text"
                        id="exampleCity"
                        placeholder="City"
                        type="text"
                        defaultValue={userdetails.city}
                        className="form-control"
                        autoComplete="off"
                        onChange={(event) => {
                          const city = event.target.value;
                          setUserdetails({ ...userdetails, ...{ city } });
                        }}
                      />
                      {uiError7 && (
                        <div
                          className="bg-danger"
                          style={{
                            marginTop: "1rem",
                            display: "table",
                            color: "white",
                          }}
                        >
                          You need to select city
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="name">
                        Postal Code
                      </label>
                      <input
                        name="number"
                        id="exampleCode"
                        placeholder="Postal Code"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        defaultValue={userdetails.postalCode}
                        onChange={(event) => {
                          const postalCode = event.target.value;
                          setUserdetails({ ...userdetails, ...{ postalCode } });
                        }}
                      />
                      {uiError8 && (
                        <div
                          className="bg-danger"
                          style={{
                            marginTop: "1rem",
                            display: "table",
                            color: "white",
                          }}
                        >
                          You need to enter postal code
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row mt-3">
                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="name">
                        Access PIN
                      </label>
                      <input
                        name="accessPin"
                        id="accessPin"
                        placeholder="Pin (Must be 6 Digits)"
                        minLength="6"
                        maxLength="6"
                        type="text"
                        defaultValue={userdetails.pin}
                        className="form-control"
                        autoComplete="off"
                        onChange={(event) => {
                          const pin = event.target.value;
                          setUserdetails({ ...userdetails, ...{ pin } });
                        }}
                      />
                      {uiError9 && (
                        <div
                          className="bg-danger"
                          style={{
                            marginTop: "1rem",
                            display: "table",
                            color: "white",
                          }}
                        >
                          You need to enter an access pin of 6 digits
                        </div>
                      )}
                      {uiError12 && (
                        <div
                          className="bg-danger"
                          style={{
                            marginTop: "1rem",
                            display: "table",
                            color: "white",
                          }}
                        >
                          Pin (must be 6 digits)
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="name">
                        Referral Code (Optional)
                      </label>
                      <input
                        name="number"
                        id="referralCode"
                        placeholder="Referral Code"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        defaultValue={userdetails.referralCode}
                        onChange={(event) => {
                          const referralCode = event.target.value;
                          setUserdetails({
                            ...userdetails,
                            ...{ referralCode },
                          });
                        }}
                      />
                      {/* {uiError8 && (
                    <div
                      className="bg-danger"
                      style={{
                        marginTop: "1rem",
                        display: "table",
                        color: "white",
                      }}
                    >
                      You need to enter poster code
                    </div>
                  )} */}
                    </div>
                  </div>

                  <div hidden={type === "personal" ? false : true}>
                    <div className="form-row mt-3">
                      <div className="form-group col-md-6">
                        <label className="label" htmlFor="name">
                          Gender
                        </label>
                        <div className="position-relative form-group">
                          <select
                            type="select"
                            id="gender"
                            name="gender"
                            className="mb-2 form-control"
                            onChange={(event) => {
                              const gender = event.target.value;
                              setUserdetails({ ...userdetails, ...{ gender } });
                            }}
                          >
                            <option value=""> -- Select -- </option>
                            {/* <option selected value={userdetails.gender}>
                          {userdetails.gender}
                        </option> */}
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group -mt-2 col-md-6">
                        <label className="label" htmlFor="name">
                          Date of Birth
                        </label>
                        <div className="position-relative form-group">
                          <input
                            name="date"
                            max="2003-01-01"
                            id="exampleDate"
                            onKeyDown={() => false}
                            placeholder="date placeholder"
                            type="date"
                            defaultValue={userdetails.dateOfBirth}
                            className="form-control"
                            autoComplete="off"
                            onChange={(event) => {
                              var result = checkDate(event.target.value);
                              if (result) {
                                const dateOfBirth = event.target.value;
                                setUserdetails({
                                  ...userdetails,
                                  ...{ dateOfBirth },
                                });
                              } else {
                                alert("you are too young/date is invalid");
                              }
                            }}
                          />
                          {uiErrorDOB && (
                            <div
                              className="bg-danger"
                              style={{
                                marginTop: "1rem",
                                display: "table",
                                color: "white",
                              }}
                            >
                              Select date of birth
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div hidden={type === "business" ? false : true}>
                    <div className="form-row">
                      <div className="form-group -mt-2 col-md-6">
                        <label className="label" htmlFor="name">
                          Business Name
                        </label>
                        <input
                          name="text"
                          id="businessName"
                          placeholder="Business Name"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          defaultValue={userdetails.businessName}
                          onChange={(event) => {
                            const businessName = event.target.value;
                            setUserdetails({
                              ...userdetails,
                              ...{ businessName },
                            });
                          }}
                        />
                      </div>

                      <div className="form-group -mt-2 col-md-6">
                        <label className="label" htmlFor="name">
                          Business Reg. No
                        </label>
                        <input
                          //   disabled={userdetails.accountType}
                          name="text"
                          id="accessBRN"
                          placeholder="Business Registration Number"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          defaultValue={userdetails.businessRegNumber}
                          onChange={(event) => {
                            const businessRegNumber = event.target.value;
                            setUserdetails({
                              ...userdetails,
                              ...{ businessRegNumber },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="d-flex justify-content-center text-center mt-4">
              <div className="text-left">
                <input
                  id="agreeCheckbox"
                  type="checkbox"
                  onChange={(e) => setAgreed(e.target.checked)}
                />
                <label htmlFor="agreeCheckbox" className="ml-2">
                  {" "}
                  I agree with{" "}
                </label>
                <Link to="/user-agreement">
                  {" "}
                  <b>the user agreement</b>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group mt-3 text-center">
          <div className="w-100 d-flex justify-content-center signup-wrapper">
            <Link
              to="/introreg"
              className="w-25 btn rounded px-3 mr-2"
              style={{ border: "1px solid #ff0008", color: "#ff0008" }}
            >
              Back
            </Link>
            {loginloading ? (
              <button
                type="submit"
                className="form-controls form-control-height btn btn-primary rounded submit px-3"
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className="form-controls w-25 form-control-height btn btn-primary rounded submit px-3"
                disabled={loginloading || !agreed}
                onClick={handleSubmit}
              >
                Sign Up
              </button>
            )}
          </div>
          {/* {(() => {
            if (loginloading) {
              return (
                <button
                  type="submit"
                  className="form-controls form-control-height btn btn-primary rounded submit px-3"
                >
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </button>
              );
            } else {
              return (
                <button
                  type="submit"
                  className="form-controls w-25 form-control-height btn btn-primary rounded submit px-3"
                  disabled={loginloading}
                  onClick={handleSubmit}
                >
                  Sign Up
                </button>
              );
            }
          })()} */}
        </div>
        <p className="text-center">
          Already have an Account ?{" "}
          <Link to="/login" className="checkbox-primary checkbox-wrap2">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loadingstate.isLoading,
    authstate: state.authstate.authstate,
    user: state,
    isEmailValid: state.emailcheckstate.isValid,
    isPhoneValid: state.phonecheckstate.isValid,
    allcountriesstate: state.allcountriesstate.allcountriesstate,
    allcountriescodestate: state.allcountriescodestate.allcountriescodestate,
    allstatesstate: state.allstatesstate.allstatesstate,
    allfundsstate: state.allfundsstate.allfundsstate,
    allcountries: state.allcountries.allcountries,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registeruser: (userdetails, history, setNotify, setLoginLoading) => {
      dispatch(ShowLoading(userdetails));
      dispatch(
        UserRegisterAuthAction(
          userdetails,
          history,
          //   setError,
          setNotify,
          setLoginLoading
        )
      );
    },
    fetchallcountry: (setError) => {
      dispatch(FetchAllCountry(setError));
    },
    fetchcodes: (setError) => {
      dispatch(FetchAllNations(setError));
    },
    fetchallcountrycodes: (setError) => {
      dispatch(FetchAllCountryCodes(setError));
    },
    fetchallcountrystates: (setError, statecode) => {
      dispatch(FetchStatesByCountryCode(setError, statecode));
    },
    checkifemailexist: (setError, email) => {
      dispatch(CheckIfEmailExist(setError, email));
    },
    checkifphoneexist: (setError, phone) => {
      dispatch(CheckIfPhoneExist(setError, phone));
    },
    fetchfunds: (successactiontype, failureactiontype, setNotify) => {
      dispatch(
        GetsingleArrayAction(successactiontype, failureactiontype, setNotify)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewRegistration);
