import React, { useState, forwardRef, useImperativeHandle } from "react";
import SelectSearch, { fuzzySearch } from "react-select-search";

  const StepTwoReg = forwardRef((props, ref) => {
  const allcountriesarray = [];
  let {
    setUserdetails,
    userdetails,
    allstatesstate,
    handlereturnvalueforstates,
    allcountriesstate,
    handlereturnvalue,
  } = props;

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.country + " " + item.alpha3Code;
      newitem.currency = item.currency;
      allcountriesarray.push(newitem);
    }
  }

  const statearray = [];
  if (allstatesstate) {
    for (var l = 0; l < allstatesstate.length; l++) {
      let item = allstatesstate[l];
      let newitem = {};
      newitem.name = item;
      newitem.value = item;
      statearray.push(newitem);
    }
  }

  const [uiError2, setUiError2] = useState(false);
  const [uiError, setUiError] = useState(false);
  const [uiError3, setUiError3] = useState(false);
  const [uiError4, setUiError4] = useState(false);
    const [uiError5, setUiError5] = useState(false);

   useImperativeHandle(ref, () => ({
    isValidated() {
      setUiError4(false);
      setUiError3(false);
      setUiError2(false);
      setUiError(false);
      setUiError5(false);
      if (!userdetails.countryofResidence) {
        // setUiError(false);
        setUiError2(true);
        return false;
      } else if (!userdetails.state) {
        setUiError(true);
        return false;
      } else if (!userdetails.city) {
        setUiError3(true);
        return false;
      } else if (!userdetails.postalCode) {
        setUiError4(true);
        return false;
      } else if (!userdetails.address) {
        setUiError5(true);
        return false;
      } else {
        setUiError4(false);
        setUiError3(false);
        setUiError2(false);
        setUiError(false);
        setUiError5(false);
        return true;
      }
    },
  }));

  return (
    <div id="step-2">
      <div id="accordion" className="accordion-wrapper mb-3">
        <div className="card">
          <div id="headingOne" className="card-header">
            <button
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              className="text-left m-0 p-0 btn btn-link btn-block"
            >
              <span className="form-heading text-danger">
                Address Information<p>Enter your address details</p>
              </span>
            </button>
          </div>
          <div
            data-parent="#accordion"
            id="collapseOne"
            aria-labelledby="headingOne"
            className="collapse show"
          >
            <div className="card-body">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="exampleCountry">
                    <span className="text-danger"></span> Select Country of
                    Nationality
                  </label>
                  <div className="position-relative form-group">
                    <SelectSearch
                      options={allcountriesarray}
                      defaultValue={userdetails.countryofResidence}
                      search={true}
                      onChange={handlereturnvalue}
                      filterOptions={fuzzySearch}
                      placeholder="Choose your country"
                    />
                    {uiError2 && (
                      <div
                        className="bg-danger"
                        style={{
                          marginTop: "1rem",
                          display: "table",
                          color: "white",
                        }}
                      >
                        You need to select country of Nationality
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="position-relative form-group">
                    <label htmlFor="accessPin"> Select State</label>
                    <SelectSearch
                      options={statearray}
                      search={true}
                      defaultValue={userdetails.state}
                      onChange={handlereturnvalueforstates}
                      filterOptions={fuzzySearch}
                      placeholder="Choose your state"
                    />
                    {uiError && (
                      <div
                        className="bg-danger"
                        style={{
                          marginTop: "1rem",
                          display: "table",
                          color: "white",
                        }}
                      >
                        You need to select state
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  {(() => {
                    if (userdetails.countryofResidence) {
                      return (
                        <>
                          <span>
                            Selected:
                            <span
                              className="form-heading text-danger"
                              style={{ fontSize: 12, color: "green" }}
                            >
                              {"   "}
                              {userdetails.countryofResidence}
                            </span>
                          </span>
                        </>
                      );
                    } else {
                      return <></>;
                    }
                  })()}
                </div>
                <div className="col-md-6">
                  {(() => {
                    if (userdetails.state) {
                      return (
                        <>
                          <span>
                            Selected:
                            <span
                              className="form-heading text-danger"
                              style={{ fontSize: 12, color: "green" }}
                            >
                              {"   "}
                              {userdetails.state}
                            </span>
                          </span>
                        </>
                      );
                    } else {
                      return <></>;
                    }
                  })()}
                </div>
                <div className="col-md-6">
                  <div className="position-relative form-group">
                    <label htmlFor="accessPin">City</label>

                    <input
                      name="text"
                      id="exampleCity"
                      placeholder="City"
                      type="text"
                      defaultValue={userdetails.city}
                      className="form-control"
                      onChange={(event) => {
                        const city = event.target.value;
                        setUserdetails({ ...userdetails, ...{ city } });
                      }}
                    />
                    {uiError3 && (
                      <div
                        className="bg-danger"
                        style={{
                          marginTop: "1rem",
                          display: "table",
                          color: "white",
                        }}
                      >
                        You need to select city
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="position-relative form-group">
                    <label htmlFor="exampleCountry">
                      <span className="text-danger"></span>Postal Code
                    </label>
                    <input
                      name="text"
                      id="exampleCode"
                      placeholder="Postal Code"
                      type="text"
                      className="form-control"
                      defaultValue={userdetails.postalCode}
                      onChange={(event) => {
                        const postalCode = event.target.value;
                        setUserdetails({ ...userdetails, ...{ postalCode } });
                      }}
                    />
                    {uiError4 && (
                      <div
                        className="bg-danger"
                        style={{
                          marginTop: "1rem",
                          display: "table",
                          color: "white",
                        }}
                      >
                        You need to enter poster code
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <label htmlFor="exampleAddress">Address</label>
                  <div className="position-relative form-group">
                    <input
                      name="address"
                      id="exampleAddress"
                      placeholder="1234 Main St"
                      type="text"
                      defaultValue={userdetails.address}
                      className="form-control"
                      onChange={(event) => {
                        const address = event.target.value;
                        setUserdetails({ ...userdetails, ...{ address } });
                      }}
                    />
                    {uiError5 && (
                      <div
                        className="bg-danger"
                        style={{
                          marginTop: "1rem",
                          display: "table",
                          color: "white",
                        }}
                      >
                        You need to enter address
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default StepTwoReg;
