import React, { useEffect } from "react";
// import { ShowLoading } from '../../redux/actions/authaction';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import { EditAction } from '../../redux/actions/editaction';

const ViewUsersModal = (props) => {
  const { show, item, handleShowView } = props;
  // const [ratedetails, setRatedetails] = useState({});

  useEffect(() => {}, [show]);

  const Processme = (type) => {
    if (type) {
      return "YES";
    } else {
      return "NO";
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleShowView}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <div className="card-shadow-primary profile-responsive card-border mb-7 card">
            <div className="dropdown-menu-header">
              <div className="dropdown-menu-header-inner bg-danger">
                <div
                  className="menu-header-image"
                  // style={{backgroundImage: 'url("assets/images/dropdown-header/abstract1.jpg")'}}
                />
                <div className="menu-header-content btn-pane-right">
                  <div className="avatar-icon-wrapper mr-2 avatar-icon-xl">
                    <div className="avatar-icon">
                      <img src="assets/images/user.jpg" alt="Avatar 5" />
                    </div>
                  </div>
                  <div>
                    <h5 className="menu-header-title">
                      {item.firstName} {item.lastName}
                    </h5>
                    <div className="widget-subheading">
                      {item.phoneNumber} {item.email}
                    </div>
                    <h6 className="menu-header-subtitle">{item.accountType}</h6>
                  </div>
                </div>
              </div>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <div className="widget-content p-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Gender</div>
                          <div className="widget-subheading">{item.gender}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Age</div>
                          <div className="widget-subheading">{item.age}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="widget-content p-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Postal Code</div>
                          <div className="widget-subheading">
                            {item.postalCode}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Pin</div>
                          <div className="widget-subheading">{item.pin}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="widget-content p-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Address</div>
                          <div className="widget-subheading">
                            {item.address}, {item.city}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">State, Country</div>
                          <div className="widget-subheading">
                            {item.state}, {item.country}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="widget-content p-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Business Name</div>
                          <div className="widget-subheading">
                            {item.businessName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            Business Reg. Number
                          </div>
                          <div className="widget-subheading">
                            {item.businessRegNumber}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="widget-content p-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Reset Key</div>
                          <div className="widget-subheading">
                            {item.resetKey}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            Referral Discount Code
                          </div>
                          <div className="widget-subheading">
                            {item.referralDiscountCode}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="widget-content p-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            AML Compliance Passed
                          </div>
                          <div className="widget-subheading">
                            {Processme(item.isAMLCompliancePassed)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            KYC Compliance Passed
                          </div>
                          <div className="widget-subheading">
                            {Processme(item.isKYCCompliancePassed)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="widget-content p-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Scanned For AML</div>
                          <div className="widget-subheading">
                            {Processme(item.isScannedForAML)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Active</div>
                          <div className="widget-subheading">
                            {Processme(item.isActive)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="widget-content p-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Date Of Birth</div>
                          <div className="widget-subheading">
                            {item.dateOfBirth?.substring(0, 10)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Date Created</div>
                          <div className="widget-subheading">
                            {item.createdDate?.substring(0, 10)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleShowView}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewUsersModal);
