import React, { useState, useEffect } from "react";
import { CreateAction } from "../../redux/actions/createaction";
import { connect } from "react-redux";
import ActionTypes from "../../redux/actiontype/ActionTypes";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SelectSearch, { fuzzySearch } from "react-select-search";

const AddRatesModal = (props) => {
  
  const {
    createrate,
    setNotify,
    show,
    handleShow,
    setShow,
    ratesLoading,
    setRatesLoading,
    allcountriesstate,
  } = props;


  const [ratedetails, setRatedetails] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setRatesLoading(true);
    createrate(
      ratedetails,
      setNotify,
      ActionTypes.ADD_RATE_SUCCESS,
      ActionTypes.ADD_RATE_FAIL,
      setShow,
      setRatesLoading
    );
  };

  const allcountriesarray = [];

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.alpha3Code;
      newitem.currency = item.currency;
      allcountriesarray.push(newitem);
    }
  }

  const handlesendingfrom = (cout) => {
    const sendCountryCode = cout;
    setRatedetails({ ...ratedetails, ...{ sendCountryCode } });
  };

  const handlesendingfrom2 = (cout) => {
    const receiveCountryCode = cout;
    setRatedetails({ ...ratedetails, ...{ receiveCountryCode } });
  };

  useEffect(() => {}, [show]);

  return (
    <Modal show={show} onHide={handleShow} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Rate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="exampleGender">Rate Type</label>
                <select
                  type="select"
                  id="rateType"
                  name="rateType"
                  className="mb-2 form-control"
                  onChange={(event) => {
                    const rateType = event.target.value;
                    setRatedetails({ ...ratedetails, ...{ rateType } });
                  }}
                >
                  <option> Select Rate Type </option>
                  <option value="LiveRate">Live Rate</option>
                  <option value="TransactionRate">Transaction Rate</option>
                </select>
              </div>

              <div className="col-md-6">
                <label htmlFor="countryDesc">Country Description</label>
                <input
                  name="countryDesc"
                  id="countryDesc"
                  placeholder="Country Description"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const countryDesc = event.target.value;
                    setRatedetails({ ...ratedetails, ...{ countryDesc } });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="sendCountryCode">Send Country Code</label>

                <SelectSearch
                  style={{ display: "none" }}
                  autoComplete="off"
                  options={allcountriesarray}
                  search={true}
                  onChange={handlesendingfrom}
                  filterOptions={fuzzySearch}
                  placeholder="Choose your country"
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="receiveCountryCode">Receive Country Code</label>

                <SelectSearch
                  style={{ display: "none" }}
                  autoComplete={"off"}
                  options={allcountriesarray}
                  search={true}
                  onChange={handlesendingfrom2}
                  filterOptions={fuzzySearch}
                  placeholder="Choose your country"
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              {/* <div className="col-md-4">
                <label htmlFor="amount">Amount</label>
                <input
                  name="amount"
                  id="amount"
                  placeholder="Amount"
                  type="number"
                  className="form-control"
                  onChange={(event) => {
                    const amount = event.target.value;
                    setRatedetails({ ...ratedetails, ...{ amount } });
                  }}
                />
              </div> */}

              <div className="col-md-6">
                <label htmlFor="amount_Local">Amount (Local)</label>
                <input
                  name="amount_Local"
                  id="amount_Local"
                  placeholder="Amount (Local)"
                  type="number"
                  className="form-control"
                  onChange={(event) => {
                    const amount_Local = event.target.value;
                    setRatedetails({ ...ratedetails, ...{ amount_Local } });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="amount_USD">Amount (USD)</label>
                <input
                  name="amount_USD"
                  id="amount_USD"
                  placeholder="Amount (USD)"
                  type="number"
                  className="form-control"
                  onChange={(event) => {
                    const amount_USD = event.target.value;
                    setRatedetails({ ...ratedetails, ...{ amount_USD } });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="exampleGender">Active Status</label>
                <select
                  type="select"
                  id="isActive"
                  name="isActive"
                  className="mb-2 form-control"
                  onChange={(event) => {
                    const isActive = event.target.value;
                    setRatedetails({ ...ratedetails, ...{ isActive } });
                  }}
                >
                  <option> -- Select Status -- </option>
                  <option value="true">Active</option>
                  <option value="false">In-Active</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShow}>
          Close
        </Button>
        <Button variant="danger" onClick={handleSubmit} disabled={ratesLoading}>
          Add Rate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    allcountriesstate: state.allcountriesstate.allcountriesstate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createrate: (
      ratedetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setRatesLoading
    ) => {
      dispatch(
        CreateAction(
          ratedetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setRatesLoading
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRatesModal);
