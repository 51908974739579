import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import DataTable from "datatables.net";
import { useTable, usePagination } from "react-table";

import { GetAction } from "../redux/actions/getaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import ViewUsersModal from "./modals/ViewUsersModal";
import EditUserModal from "./modals/EditUserModal";
import { GetSingleObjectAction } from "../redux/actions/getsoaction";
import { mainAxios } from "../utils/axios";
import { formatNaira, numSequence } from "../utils/extras";

const processStatus = (type) => {
  if (type === true) return "Active";
  else if (type === false) return "In-Active";
  else return "Others";
};

const COLUMNS = [
  {
    Header: "User Type",
    accessor: "userRole",
  },
  {
    Header: "Email Address",
    accessor: "email",
  },
  {
    Header: "Full Name",
    accessor: (d) => `${d.firstName} ${d.lastName}`,
  },
  {
    Header: "Verification Status",
    accessor: (d) => processStatus(d.isActive),
  },
  {
    Header: "Date Created",
    accessor: (d) => d.createdDate.substring(0, 10),
  },
];

const Usersarea = (props) => {
  const {
    setNotify,
    show,
    setShow,
    fetchallusers,
    allusers,
    fetchuserbyemail,
    singleuser,
  } = props;
  const [users, setUsers] = useState([]);
  const [dothat, setDothat] = useState("");
  const [item, setItem] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [editUserLoading, setEditUserLoading] = useState(false);
  const [suser, setSuser] = useState(false);
  const [count, setCount] = useState(0);
  const [controlledPageCount, setPageCount] = useState(0);

  const {
    state: { pageIndex, pageSize },
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      data: users,
      columns: COLUMNS,
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  const getUsers = useCallback(async () => {
    try {
      const res = await mainAxios.get(
        `/Users/getAllCustomers?pageNumber=${
          pageIndex + 1
        }&pageSize=${pageSize}`
      );

      if (res?.data?.data.length) {
        setUsers(res.data.data);
        setCount(res.data.totalNoItems);
        setPageCount(res.data.totalPages);
      }
    } catch (e) {
      console.log(e);
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    getUsers();
  }, [getUsers, pageIndex, pageSize, editUserLoading]);

  // useEffect(() => {
  //   $(document).ready(function () {
  //     $("#examtable").DataTable({
  //       responsive: !0,
  //       destroy: true,
  //       order: [[5, "desc"]],
  //     });
  //   });
  // }, []);

  const [fetchloading, setFetchLoading] = useState(false);

  useEffect(() => {
    fetchallusers(
      show,
      setNotify,
      ActionTypes.FETCH_ALL_USERS_SUCCESS,
      ActionTypes.FETCH_ALL_USERS_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allusers !== undefined) {
      setUsers(allusers);
    }
  }, [allusers]);

  useEffect(() => {
    if (!editUserLoading) {
      fetchallusers(
        show,
        setNotify,
        ActionTypes.FETCH_ALL_USERS_SUCCESS,
        ActionTypes.FETCH_ALL_USERS_FAIL,
        setShow
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUserLoading]);

  const searchtable = () => {
    setFetchLoading(true);
    setSuser(true);
    fetchuserbyemail(
      dothat,
      setNotify,
      ActionTypes.FETCH_SINGLE_USER_SUCCESS,
      ActionTypes.FETCH_SINGLE_USER_FAIL,
      setFetchLoading
    );
  };

  useEffect(() => {
    if (singleuser !== undefined && suser) {
      viewUser(singleuser[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleuser]);

  const handleShowEdit = () => {
    setShowEdit(!showEdit);
  };

  const handleShowView = () => {
    setShowView(!showView);
  };

  function viewUser(item) {
    setShowView(true);
    setItem(item);
  }

  function editUser(item) {
    setShowEdit(true);
    setItem(item);
  }

  const renderrow = (items) => {
    if (Object.keys(items).length !== 0) {
      return items.map((item, i) => {
        return (
          <>
            <tr style={{ textAlign: "center" }}>
              <td>{item.userRole}</td>
              <td>{item.email}</td>
              <td>
                {item.firstName} {item.lastName}
              </td>
              <td>{processStatus(item.isActive)}</td>
              <td>{item.createdDate.substring(0, 10)}</td>
              <td className="d-flex flex-wra p-2" style={{ gap: "8px" }}>
                <button
                  className
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-danger"
                  onClick={() => {
                    viewUser(item);
                  }}
                >
                  View
                </button>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-dark"
                  onClick={() => {
                    editUser(item);
                  }}
                >
                  Edit
                </button>
              </td>
              {/* <td>
                {" "}
                <button
                  style={{ maxWidth: "100%" }}
                  className="btn-wide btn btn-danger"
                  onClick={() => {
                    viewUser(item);
                  }}
                >
                  View
                </button>
                <button
                style={{ maxWidth: "100%" }}
                  className="btn-wide btn btn-dark"
                  onClick={() => {
                    editUser(item);
                  }}
                >
                  Edit
                </button>{" "}
              </td> */}
            </tr>
          </>
        );
      });
    }
  };

  return (
    <>
      <div className="app-page-title app-page-title-simple">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div>
              <div className="page-title-head center-elem">
                <span className="d-inline-block">
                  User Management & Verification
                </span>
              </div>
              <div className="page-title-subheading opacity-10">
                <nav className aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <span>
                        Here you can manage users and their verifications
                      </span>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-xl-12">
        <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
          <div className="widget-chat-wrapper-outer">
            <div className="widget-chart-content pt-3 pl-3 pb-1">
              <div className="widget-chart-flex">
                <div className="widget-numbers">
                  <div className="widget-chart-flex">
                    <div className="fsize-4">
                      <span>User Search</span>
                    </div>
                  </div>
                </div>
              </div>
              <h6
                className="widget-subheading mb-0 opacity-5 text-danger"
                style={{ fontSize: 12 }}
              >
                You can search for any user here...
              </h6>

              <div className="form-row">
                <div className="col-md-12">
                  <div className="position-relative form-group">
                    <label htmlFor="exampleEmail" style={{ marginTop: 30 }}>
                      Search User by Email
                    </label>
                    <input
                      name="email"
                      id="exampleEmail"
                      placeholder="Enter Email"
                      type="email"
                      className="form-control"
                      onChange={(event) => {
                        const email = event.target.value;
                        setDothat(email);
                      }}
                    />
                  </div>
                  <button
                    className="btn-wide btn btn-danger float-right"
                    disabled={fetchloading}
                    onClick={searchtable}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-card mb-3 card">
        <div className="card-body">
          <table
            style={{ width: "100%" }}
            id="examtable"
            className="table table-hover table-striped table-bordered"
          >
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>User Type</th>
                <th>Email Address</th>
                <th>Full Name</th>
                <th>Verification Status</th>
                <th>Date Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{renderrow(users)}</tbody>
          </table>
          {!!page.length && (
            <div className="pagination-wrapper my-4">
              <div className="colItem">
                <div className="colItem pageSize item">
                  <span className="sup">Items per page: </span>
                  <div className="colItem selectGroup">
                    <select
                      id="pageSize"
                      name="pageSize"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                      defaultValue={pageSize}
                    >
                      <option value="" hidden>
                        {pageSize}
                      </option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                      <option value="60">60</option>
                      <option value="70">70</option>
                      <option value="80">80</option>
                      <option value="90">90</option>
                      <option value="100">100</option>
                    </select>
                    <i data-feather="chevron-down" className="dropdownIcon"></i>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="colItem item">
                  <span className="sup">
                    page <b>{pageIndex + 1}</b> of <b>{count}</b> entries
                  </span>
                </div>
                <div className="divider"></div>
              </div>
              <div className="colItem">
                <div className="divider"></div>
                <div className="colItem item">
                  <div className="colItem selectGroup">
                    <select
                      id="pageSize"
                      name="pageSize"
                      onChange={(e) => {
                        const pageNumber = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(pageNumber);
                      }}
                      value={pageIndex + 1}
                    >
                      <option value="" hidden>
                        {pageIndex + 2}
                      </option>
                      {numSequence(pageCount).map((item) => (
                        <option key={`page_${item}`} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {/* <FeatherIcon
                        iconName="chevron-down"
                        className="dropdownIcon icon"
                        color="var(--bs-gray-200)"
                      /> */}
                  </div>
                  <div className="colItem">
                    <span className="sup">
                      &nbsp; of {pageCount} page{pageCount !== 1 ? "s" : ""}
                    </span>
                  </div>
                </div>
                <div className="divider"></div>
                <button
                  className="colItem item"
                  disabled={!canPreviousPage}
                  onClick={() => previousPage()}
                >
                  Previous
                </button>
                <div className="divider"></div>
                <button
                  className="colItem item"
                  disabled={!canNextPage}
                  onClick={() => nextPage()}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <ViewUsersModal
        item={item}
        show={showView}
        setShow={setShowView}
        handleShowView={handleShowView}
      />
      <EditUserModal
        item={item}
        show={showEdit}
        setShow={setShowEdit}
        handleShowEdit={handleShowEdit}
        setNotify={setNotify}
        editUserLoading={editUserLoading}
        setEditUserLoading={setEditUserLoading}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allusers: state.allusers.allusers,
    singleuser: state.singleuser.singleuser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchallusers: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchuserbyemail: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetSingleObjectAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Usersarea);
