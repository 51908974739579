import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetAction } from "../redux/actions/getaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import $ from "jquery";
import DataTable from "datatables.net";
import EditBeneficiaryModal from "./modals/EditBeneficiaryModal";
import backgroundImage from "../assets/images/water.png";
import DeleteBeneficiaryModal from "./modals/DeleteBeneficiaryModal";

const Beneficiaryarea = (props) => {
  const {
    setNotify,
    show,
    setShow,
    fetchbeneficiary,
    alluserreceivers,
    beneficiaryLoading,
    setBeneficiaryLoading,
  } = props;

  useEffect(() => {
    fetchbeneficiary(
      show,
      setNotify,
      ActionTypes.GET_USER_RECEIVERS_SUCCESS,
      ActionTypes.GET_USER_RECEIVERS_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!beneficiaryLoading) {
      fetchbeneficiary(
        show,
        setNotify,
        ActionTypes.GET_USER_RECEIVERS_SUCCESS,
        ActionTypes.GET_USER_RECEIVERS_FAIL,
        setShow
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaryLoading]);

  useEffect(() => {
    $(document).ready(function () {
      $("#examtable").DataTable();
    });
    // $(document).ready(function () {
    //   $("#examtable").DataTable({ responsive: 10 });
    // });
  }, []);

  const [item, setItem] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  };

  const handleShowEdit = () => {
    setShowEdit(!showEdit);
  };

  function handleEdit(item) {
    setShowEdit(true);
    setItem(item);
  }

  function handleDelete(item) {
    setShowDelete(true);
    setItem(item);
  }

  const renderrow = (items) => {
    if (Object.keys(items).length !== 0) {
      return items.map((item, i) => {
        return (
          <>
            <tr>
              <td>{item.accountName}</td>
              <td>{item.accountNumber}</td>
              <td>{item.bankName}</td>
              <td>{item.accountSWiftCode}</td>
              <td>{item.accountCurrency}</td>
              <td>{item.bankIdentifier}</td>
              <td className="d-flex flex-wrap p-2" style={{ gap: "8px" }}>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-dark"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </button>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-danger"
                  onClick={() => handleDelete(item)}
                >
                  Delete
                </button>
              </td>
              {/* <td>
                <button
                  className="mb-2 mr-2 btn btn-danger"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </button>
                <button
                  className="mb-2 mr-2 btn btn-danger"
                  onClick={() => handleDelete(item)}
                >
                  Delete
                </button>
              </td> */}
            </tr>
          </>
        );
      });
    }
  };

  return (
    <>
      <section
        style={{
          backgroundImage: `url("${backgroundImage}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPositionY: "center",
          backgroundPositionX: "calc(15% + 310px)",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="app-page-title app-page-title-simple">
          <div className="page-title-wrapper">
            <div className="page-title-heading" style={{ marginLeft: 16 }}>
              <div>
                <div className="page-title-head center-elem">
                  <span className="d-inline-block">
                    <h2> List of my Beneficiaries</h2>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-md-12 col-xl-12"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
            <div className="widget-chat-wrapper-outer">
              <div className="widget-chart-content pt-3 pl-3 pb-1">
                <div className="form-row">
                  <div>
                    <h4>Total:</h4>
                    <h4>
                      <b>
                        {alluserreceivers.length ?? 0} beneficiar
                        {alluserreceivers.length > 1 ? "ies" : "y"}
                      </b>
                    </h4>
                  </div>

                  <div className="ml-auto" style={{ marginRight: 15 }}>
                    <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                      <span className="text-success pl-2">
                        <button
                          className="btn-wide mb-2 mr-2 btn btn-shadow btn-danger btn-lg"
                          onClick={setShow}
                        >
                          Add New Beneficiary
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-card mb-3">
          <div className="card-body">
            <table
              style={{ width: "100%" }}
              id="examtable"
              className="table table-hover table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th>Account Name</th>
                  <th>Account Number</th>
                  <th>Bank Name</th>
                  <th>Account SWiftCode</th>
                  <th>Account Currency</th>
                  <th>Bank Identifier</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{renderrow(alluserreceivers)}</tbody>
            </table>
          </div>
        </div>

        <EditBeneficiaryModal
          item={item}
          setNotify={setNotify}
          show={showEdit}
          handleEdit={handleEdit}
          setShow={setShowEdit}
          handleShowEdit={handleShowEdit}
          beneficiaryLoading={beneficiaryLoading}
          setBeneficiaryLoading={setBeneficiaryLoading}
        />
        <DeleteBeneficiaryModal
          item={item}
          setNotify={setNotify}
          show={showDelete}
          handleDelete={handleDelete}
          setShow={setShowDelete}
          handleShowDelete={handleShowDelete}
          addaccountLoading={beneficiaryLoading}
          setAddAccountLoading={setBeneficiaryLoading}
        />
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    alluserreceivers: state.alluserreceivers.alluserreceivers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchbeneficiary: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Beneficiaryarea);
