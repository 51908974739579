import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CreateNewAction } from "../redux/actions/createaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import { useHistory } from "react-router";
import AppHeader from "../components/AppHeader";
import AppSidebar from "../components/AppSidebar";
import Footer from "../components/Footer";
import Waitarea from "../components/Waitarea";

const CheckTransaction = (props) => {
  const { redirecturl, checktranstatus } = props;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

   const [notify, setNotify] = useState({
     isOpen: false,
     message: "",
     type: "",
   });

  useEffect(() => {
    if (redirecturl) {
      let postObj = {
        token: redirecturl.paymentToken,
        ref: redirecturl.paymentReferenceNo,
      };
      checktranstatus(
        postObj,
        history,
        ActionTypes.GET_PAYMENT_STATUS_SUCCESS,
        ActionTypes.GET_PAYMENT_STATUS_FAIL,
        setIsLoading
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirecturl]);

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
      <AppHeader notify={notify} setNotify={setNotify} />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Waitarea notify={notify} setNotify={setNotify} />
            <Footer />
          </div>
        </div>
      </div>
      <div className="app-drawer-overlay d-none animated fadeIn" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirecturl: state.redirecturl.redirecturl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checktranstatus: (
      details,
      history,
      successactiontype,
      failureactiontype,
      setIsLoading
    ) => {
      dispatch(
        CreateNewAction(
          details,
          history,
          successactiontype,
          failureactiontype,
          setIsLoading
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckTransaction);
