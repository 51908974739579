/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { LoginAuthAction } from "../redux/actions/authaction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import img1 from "../assets/images/JCI.png";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

const SignInarea = (props) => {
  const { loginuser, isLoading, setNotify } = props;
  const [userdetails, setUserdetails] = useState({});
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(userdetails.username)) {
      if (
        userdetails.username !== undefined &&
        userdetails.password !== undefined &&
        userdetails.password !== ""
      ) {
        setLoginLoading(true);
        loginuser(userdetails, history, setNotify, setLoginLoading);
      } else {
        setNotify({
          isOpen: true,
          message: "kindly fill all empty spaces",
          type: "error",
        });
      }
    } else {
      setNotify({
        isOpen: true,
        message: "email not valid",
        type: "error",
      });
    }
  };

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const handlePasswordChange = (prop) => (event) => {
    //setValues({ ...values, [prop]: event.target.value });
    const password = event.target.value;
    setUserdetails({ ...userdetails, ...{ password } });
  };

  const [loginloading, setLoginLoading] = useState(false);

  useEffect(() => {}, [isLoading]);

  //to chaeck the login state of the user on loads
  useEffect(() => {}, []);

  return (
    <div className="rows justify-content-centers">
      <div className="col-md-8 col-lg-6">
        <div
          className="wrap d-md-flex"
          style={{ backgroundColor: "transparent", marginTop: -50 }}
        >
          <div className="login-wrap p-4 col-md-12">
            <div className="d-flex">
              <div className="w-100">
                {/* <h3 className="mb-4" style={{ color: "black", fontSize: 30 }}>
                  Sign In
                </h3> */}
              </div>
              {/* <div className="w-100"> style={{}}
                <p className="social-media d-flex justify-content-end">
                  <a
                    href="https://www.apple.com"
                    target="_blank"
                    className="social-icon d-flex align-items-center justify-content-centers"
                  >
                    <span className="fa fa-apple" />
                  </a>
                  <a
                    href="https://www.google.com"
                    target="_blank"
                    className="social-icon d-flex align-items-center justify-content-centers"
                  >
                    <span className="fa fa-android" />
                  </a>
                </p>
              </div> */}
            </div>
            <form action="#" className="signin-form">
              <div className="dropdown-menu-header-inner bg-danger">
                <div
                  className="menu-header-image"
                  style={{ backgroundImage: "url(../login/images/bg-1.jpeg)" }}
                />
              </div>
              <div className="form-group mb-3 mt-2">
                <label className="label" htmlFor="name">
                  Email *
                </label>
                <Input
                  type="email"
                  className="form-controls form-control-height"
                  placeholder="Email Address"
                  required
                  onChange={(event) => {
                    const username = event.target.value;
                    setUserdetails({ ...userdetails, ...{ username } });
                  }}
                />
              </div>
              <div className="form-group mb-3 mt-2">
                <label className="label mt-2" htmlFor="password">
                  Password *
                </label>
                <Input
                  type={values.showPassword ? "text" : "password"}
                  onChange={handlePasswordChange("password")}
                  className="form-controls form-control-height"
                  //value={values.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="form-group d-md-flex">
                <div className="w-50 text-left">
                  {/* <label className="checkbox-wrap checkbox-primary mb-0">
                    Remember Me
                    <input type="checkbox" defaultChecked />
                    <span className="checkmark" />
                  </label> */}
                </div>
                <Link to="/resetpassword" className="w-50 text-md-right">
                  <a>Forgot Password?</a>
                </Link>
              </div>
              <div className="form-group mt-3">
                {(() => {
                  if (loginloading) {
                    return (
                      <button
                        type="submit"
                        className="form-controls form-control-height btn btn-primary rounded submit px-3"
                      >
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </button>
                    );
                  } else {
                    return (
                      <button
                        type="submit"
                        className="form-controls form-control-height btn btn-primary rounded submit px-3"
                        disabled={loginloading}
                        onClick={handleSubmit}
                      >
                        Login
                      </button>
                    );
                  }
                })()}
              </div>
            </form>
            <p className="text-center">
              Don't have an Account ?{" "}
              <Link to="/introreg" className="checkbox-primary checkbox-wrap2">
                Sign Up
              </Link>
              {/* <Link
                to="/"
                className="checkbox-success checkbox-wrap2"
                style={{ marginLeft: 32 }}
              >
                Go Home
              </Link> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loadingstate.isLoading,
    authstate: state.authstate.authstate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginuser: (userdetails, history, setNotify, setLoginLoading) => {
      dispatch(
        LoginAuthAction(userdetails, history, setNotify, setLoginLoading)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInarea);
