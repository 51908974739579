/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./assets/css/style.css";
import "./assets/css/animate.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/magnific-popup.min.css";
import "./assets/css/nice-select.css";
import "./assets/css/slick.min.css";
import "./assets/css/odometer.min.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/meanmenu.css";
import "./assets/css/responsive.css";
import { Link } from "react-router-dom";
import jcilogo from "../../src/assets/images/logo_png.png";
import logo from "./assets/img/logo.png";
import email from "../../src/assets/images/email.png";
import whatsapp from "./assets/img/whatsapp.png";
import login from "../../src/assets/images/user.png";
import map from "./assets/img/map.png";
import { connect } from "react-redux";
import Notification from "../components/Notification";
// eslint-disable-next-line no-lone-blocks

const Policy = (props) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <div>
      <Notification notify={notify} setNotify={setNotify} />
      {/* is-sticky */}
      <div className="navbar-area">
        <div className="luvion-responsive-nav">
          <div className="container">
            <div className="luvion-responsive-menu">
              <div className="logo">
                <Link to="/home">
                  <img src={logo} height={100} alt="logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="luvion-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/home">
                <img src={logo} height={130} alt="logo" />
              </Link>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
                style={{ display: "block" }}
              >
                <ul className="navbar-nav">
                  {/* <li className="nav-item">
                    <Link to="/home" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about" className="nav-link">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="#services" className="nav-link">
                      Services
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    {/*<Link to="/login" src={login} className="nav-link">*/}
                    {/*  <img src={login} alt="logo" style={{ marginRight: 8 }} />*/}
                    {/*  Login*/}
                    {/*</Link>*/}
                  </li>
                </ul>
                <div className="others-options">
                  {/*<Link*/}
                  {/*  to="/introreg"*/}
                  {/*  className="btn btn-primary"*/}
                  {/*  style={{ marginLeft: 20 }}*/}
                  {/*>*/}
                  {/*  Register*/}
                  {/*</Link>*/}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div
        className="page-title-area item-bg1 jarallax"
        data-jarallax='{"speed": 0.3}'
      >
        <div className="container">
          <div className="page-title-content">
            <h2>Privacy Policy</h2>
            <p>Our privacy policy</p>
          </div>
        </div>
      </div>

      <section className="blog-details-area ptb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="blog-details">
                <div className="article-content">
                  <h3>Privacy Policy</h3>
                  <p>
                    This privacy policy (‘the Privacy Policy’) applies to{" "}
                    <strong>
                      <b>JCI Remit Pty Ltd</b>
                    </strong>
                    (CAN 645 908 907) registered in the State of Queensland,
                    Australia and their affiliates and related companies,
                    (together, ‘ JCI Remit ’, ‘we’, ‘us’ or ‘our’), and details
                    how we collect, manage and use Personal Information,
                    including but not limited to Personal Information collected
                    on our mobile applications, websites, via email, cloud-based
                    services, or other communication platforms with a link to
                    this Privacy Policy (“Platforms”).
                  </p>
                  <p>
                    <strong>
                      <b>Personal Information:</b>
                    </strong>{" "}
                    we mean information that is associated with a specific
                    person and can be used to reasonably identify that person.
                    Personal information does not include information that has
                    been made anonymous and cannot reasonably identify a
                    specific person. It covers how we collect, use, and share
                    that Personal Information as of the date that this Privacy
                    Policy is posted
                  </p>
                  <p>
                    JCI Remit is committed to respecting the privacy of all
                    Personal Information. We appreciate that individuals are
                    concerned about the security of their Personal Informwation,
                    and we are committed to protecting any Personal Information
                    in our custody or control. JCI Remit is subject to the
                    Privacy Act 1988 (Cth) and the Australian Privacy Principles
                    (APPs).
                  </p>
                  <p>
                    JCI Platforms may also contain links to third party
                    Platforms. These linked sites are not under our control, and
                    we are not responsible for content on these sites, nor are
                    these sites subject to this Privacy Policy. Before
                    disclosing your personal information on any third-party
                    website, we highly recommend that you examine the terms and
                    conditions and privacy policy of that site.
                  </p>
                  <p>
                    This Privacy Policy applies when you sign up for, access, or
                    use our services, features, technologies, functions or
                    purchase goods and services offered on our Platforms
                    (collectively ‘JCI Remit Services’) and in relation to
                    Personal Information we may otherwise collect during the
                    course of our business as set out in this Privacy Policy.
                  </p>
                  <p>
                    JCI Remit may amend the Privacy Policy at any time. The
                    updated version will be available by following the ‘Privacy’
                    link on the Platforms link. The revised version will be
                    effective from the time we post it on the Platforms. JCI
                    Remit may highlight any material changes to the Privacy
                    Policy that may impact you by notifying you by email or
                    otherwise.
                  </p>
                  <h3>Collecting Personal Information</h3>
                  <p>
                    We at JCI Remit work hard to be fair and open about the way
                    we collect information about you and what we intend to do
                    with the information.{" "}
                  </p>
                  <p>
                    We receive and store certain types of information from you
                    when you interact with our Platforms to deliver the products
                    you request from us, and help improve your overall online
                    experience. For example, like many other Platforms, we store
                    “cookies” and other web-based files on user devices to get
                    certain types of information when your web browser, mobile
                    phone, or other device accesses our Platform. The
                    information sent to us may include data on the pages you
                    access, your computer IP address, device identifiers, the
                    type of operating system you are using, your location,
                    mobile network information, standard web log data and other
                    information. Web log data includes the browser type you are
                    using and traffic to and from our site.{" "}
                  </p>
                  <p>
                    When you visit our Platforms or use JCI Remit Services, we
                    may also collect information about your transactions and
                    your activities. Details on our collection of Personal
                    Information through the use of cookies and analytical
                    services is set out below. In addition, if you open an JCI
                    Remit account with us or use JCI Remit Services, we may
                    collect the following types of information:
                  </p>
                  <p>
                    <ul className="article-features-list">
                      <li>
                        Contact information, such as your name, address
                        (including your geolocation), phone number and email.
                      </li>
                      <li>
                        Financial information, such as the full bank account
                        numbers and/or credit or debit card numbers that you
                        link to your JCI Remit account or give us when you use
                        JCI Remit Services.
                      </li>
                      <li>
                        Detailed Personal Information such as your date of
                        birth, driver’s license number, or other identification
                        information or documents. We may also obtain information
                        about you from third parties including our Partners and
                        related companies, as well as credit reporting bodies
                        and identity verification services, and publicly or
                        commercially available sources for the purposes of
                        complying with relevant legislation (eg. anti-money
                        laundering laws).
                      </li>
                    </ul>
                  </p>
                  <p>
                    You may choose to provide us with access to certain Personal
                    Information stored by third parties such as social media
                    sites (e.g., Facebook and Twitter). The information we may
                    receive varies by site and is controlled by that site. By
                    associating an account managed by a third party with your
                    JCI Remit account and authorising JCI Remit to have access
                    to this information, you agree that JCI Remit may collect,
                    store and use this information in accordance with this
                    Privacy Policy.
                  </p>
                  <p>
                    We may also collect your Personal Information if you enter a
                    competition or promotion we run (either directly or through
                    our marketing agents or our Partners).
                  </p>
                  <p>
                    We may also collect and handle sensitive personal
                    information about you, such as your health information, to
                    deliver our products or services. We will only collect this
                    information where it is reasonably necessary and we have
                    your consent, or where it is required by law.
                  </p>
                  <p>
                    In order to help protect you from fraud and misuse of your
                    Personal Information, we may collect information about your
                    use and interaction with our Platforms or JCI Remit
                    Services. For example, we may evaluate your computer, mobile
                    phone or other access device to identify any malicious
                    software or activity.
                  </p>
                  <p>
                    We may also collect additional information from or about you
                    in other ways, such as through contact with our customer
                    support team, suppliers or service providers (whether via
                    mail, email or through telephone enquiries), your responses
                    to a market survey, and from interactions with JCI Remit’s
                    Partners or as otherwise notified to you at the time.
                  </p>
                  <p>
                    We may also collect the Personal Information of visitors to
                    our offices. This information may include your name, contact
                    details and position. We will collect this information
                    directly from you. We may also collect and handle sensitive
                    personal information about you, such as your health
                    information, to comply with workplace health and safety
                    laws. We will only collect this information where it is
                    reasonably necessary, and we have your consent, or where it
                    is required or authorised by or under Australian law.
                  </p>
                  <h3>Using Personal Information</h3>
                  <p>
                    JCI Remit only collects, holds and handles information about
                    you that is necessary for us to perform the services you
                    request from us, that is otherwise reasonably necessary for
                    our business activities or if required by law, court, or
                    tribunal order. We may use Personal Information we collect
                    about you for a number of purposes including:
                  </p>
                  <p>
                    <ul className="article-features-list">
                      <li>
                        Provide (or assess whether to provide) JCI Remit
                        Services.
                      </li>
                      <li>
                        Were applicable processing transactions for the delivery
                        of third-party goods or services available through our
                        Platforms.
                      </li>
                      <li>Customer support.</li>
                      <li>
                        Process transactions and send notices about your
                        transactions.
                      </li>
                      <li>
                        Resolve disputes, collect fees, and troubleshoot
                        problems.
                      </li>
                      <li>
                        Investigate and prevent potentially prohibited or
                        illegal activitiest.
                      </li>
                      <li>Enforce our Agreement with you.</li>
                      <li>
                        Learn more about your level of satisfaction, your
                        expectations of us and how we can meet them (for
                        example, in relation to JCI Remit Services).
                      </li>
                      <li>
                        Customise, measure, and improve JCI Remit Services and
                        the content, layout, and operation of our Platforms.
                      </li>
                      <li>
                        Deliver targeted marketing, service update notices, and
                        promotional offers based on your communication
                        preferences.
                      </li>
                      <li>
                        Contact you via telephone, text (SMS) or email
                        messaging, including as authorised by our Agreement.
                      </li>
                      <li>
                        Compare information for accuracy and verify it with
                        third parties.
                      </li>
                      <li>
                        Confirm your identity for the purposes of anti-money
                        laundering and counter-terrorism laws and assess
                        applications and your suitability for JCI Remit
                        Services.
                      </li>
                      <li>
                        To acquire goods or services from you or from your
                        employer.
                      </li>
                      <li>
                        For the purpose of performing data analytics including
                        to improve our services.
                      </li>
                      <li>
                        For other purposes to which you have consented; and
                      </li>
                      <li>
                        As required or permitted by relevant laws and
                        regulations.
                      </li>
                    </ul>
                  </p>
                  <p>
                    You agree that we may use your Personal Information for the
                    purposes for which we collect it and for related purposes
                    which would be reasonably expected by you
                  </p>
                  <p>
                    If all or some of your Personal Information is not collected
                    or cannot be verified, we may be unable to provide you with
                    JCI Remit Services or a customised experience, engage with
                    you, or do business with you.
                  </p>
                  <h3>How we share Personal Information with other parties</h3>
                  <p>We may share your Personal Information with:</p>
                  <ul className="article-features-list">
                    <li>Our affiliates and related companies</li>
                    <li>
                      Our Partners and service providers who help with our
                      business operations and services, including in relation to
                      transactions, fraud prevention, identity verification,
                      payment collection, marketing, customer service, and
                      technology services
                    </li>
                    <li>
                      Financial institutions that we may partner with to jointly
                      create and offer a product
                    </li>
                    <li>
                      {" "}
                      Relevant financial institutions /bodies/agencies including
                      to report account information, as permitted by law. When
                      we share your Personal Information with relevant financial
                      bodies, we authorise them to use that information for the
                      purposes of providing their identity verification services
                    </li>
                    <li>
                      {" "}
                      Banking partners as may be required by credit card
                      association rules for inclusion on their list of
                      terminated merchants
                    </li>
                    <li>
                      {" "}
                      Companies that we plan to merge with or be acquired by or
                      who may invest in us
                    </li>
                    <li>
                      {" "}
                      Law enforcement, government agencies or officials, or
                      other third parties pursuant to a subpoena, court order,
                      or other legal process or requirement applicable to JCI
                      Remit; when we need to do so to comply with law or credit
                      card rules; or when we believe, in our sole discretion,
                      that the disclosure of Personal Information is necessary
                      to prevent physical harm or financial loss, to report
                      suspected illegal activity or to investigate suspected
                      violations of our User Agreement
                    </li>
                    <li>
                      {" "}
                      Other third parties with your consent or direction to do
                      so.
                    </li>
                  </ul>
                  <p>
                    Please note that these third parties may be in other
                    countries where the laws on processing Personal Information
                    may be less stringent than in your jurisdiction. When we
                    disclose your Personal Information overseas, we will take
                    all reasonable measures to ensure that your information is
                    held, managed, and accessed in accordance with appropriate
                    standards for the handling of Personal Information.
                  </p>
                  <p>
                    To facilitate our global operations, we may share personal
                    information with JCI Remit and our affiliates and related
                    companies, including those based in Australia, United
                    States, United Kingdom, Canada, China, Nigeria, United Arab
                    Emirate (UAE) and New Zealand, and where we operate in Asia/
                    Middle East and Europe. .
                  </p>
                  <p>
                    We will only share your Personal Information with third
                    parties as described in this Privacy Policy or as otherwise
                    notified to you at the time of collection or with your
                    consent.
                  </p>
                  <p>
                    If you open an JCI Remit account directly on a third party
                    website or via a third party application, any information
                    that you enter on that website or application or Platform
                    (and not directly on JCI Remit’s Platforms) will be shared
                    with the owner of the third party website or application.
                    These sites are governed by their own privacy policies, and
                    you are encouraged to review their privacy policies before
                    providing them with Personal Information. JCI Remit is not
                    responsible for the content or information practices of such
                    third parties.
                  </p>
                  <h3>Consent</h3>
                  <p>
                    We may require your consent to use and/or disclose your
                    Personal Information if we need to use your information for
                    a purpose that is not related to the purpose for which it
                    was collected
                  </p>
                  <p>
                    If you do not consent to JCI Remit collecting, using and/or
                    disclosing your Personal Information for such other
                    purposes, this may affect JCI Remit’s ability to deliver and
                    improve our products and services, or to engage or do
                    business with you.
                  </p>
                  <p>
                    When you provide us with your name, date of birth and
                    residential address in establishing your profile with us,
                    you consent (or, where required, we will seek your further
                    consent) to us disclosing that information to financial
                    institutions, financial reporting bodies/agencies and other
                    external agencies, and requesting an assessment of whether
                    it matches personal information held by the body/agency to
                    assist in verifying your identity, including if required
                    under the Anti-Money Laundering and Counter-Terrorism
                    Financing Act 2006. The body/agency may provide us with that
                    assessment and use your personal information, and the names,
                    residential addresses, and dates of birth of other
                    individuals, to prepare that assessment.
                  </p>
                  <p>
                    If there is a failure to verify your identity in the way
                    described above, we will notify you within the secure JCI
                    Remit service or otherwise
                  </p>
                  <p>
                    If you do not want to proceed in this way to verify your
                    identity, you should contact us, as provided below.
                  </p>
                  <h3>Marketing and privacy</h3>
                  <p>
                    JCI Remit may send marketing materials from time to time to
                    those who have provided JCI Remit with Personal Information
                    about products and services offered by JCI Remit and our
                    Partners. We may use your Personal Information to make
                    inferences and present more relevant content.
                  </p>
                  <p>
                    If you signed up to receive newsletters or other marketing
                    communications from us, you can opt-out any time by clicking
                    the unsubscribe link at the bottom of the message
                  </p>
                  <p>
                    Even after you opt-out or update your marketing preferences,
                    please allow us sufficient time to process your marketing
                    preferences. Unless otherwise required to process your
                    requests earlier by law, it may take up to 14 business days
                    to process your opt out requests in relation to receipt of
                    electronic marketing materials such as emails and SMS, and
                    up to 60 days for all other marketing-related requests.
                  </p>
                  <p>
                    Even after you’ve opt-out of receiving marketing
                    communications from us, we may still contact you for
                    transactional or informational purposes. These include, for
                    example, customer service issues, product-related inquiries,
                    outstanding payment inquiries, surveys or recalls, or any
                    questions regarding a specific order.
                  </p>
                  <p>
                    You may also contact us on:
                    <h6>
                      <b>Email:</b> contact@jciremit.com
                    </h6>
                    <h6>
                      <b>WhatsApp:</b> +61 431 919 688
                    </h6>
                  </p>
                  <h3>Controlling Our Tracking Tools </h3>
                  <p>
                    Your browser may give you the ability to control cookies.
                    How you do so, however, depends on your browser and the type
                    of cookie. Certain browsers can be set to reject all browser
                    cookies. If you configure your computer to block all
                    cookies, you may disrupt certain web page features, and
                    limit the functionality we can provide when you visit or use
                    our Platforms. If you block or delete cookies, not all of
                    the tracking that we have described in this policy will
                    stop. If you continue without changing your settings, we
                    will assume that you are happy to receive all cookies on
                    this Site. You can change your cookie settings at any time.
                    Some browsers have a “Do Not Track” feature that lets you
                    tell Platforms that you do not want to have your online
                    activities tracked. These browser features are still not
                    uniform, so we are not currently set up to respond to those
                    signals.
                  </p>
                  <h3>Cookies and third party analytical services</h3>
                  <p>
                    We use cookies and track IP addresses via our Platforms so
                    we can improve our services provided by our Platforms and
                    enhance your user experience.
                  </p>
                  <p>
                    When you access our Platforms or use JCI Remit Services, we
                    (including our Partners and companies we work with) may
                    place small data files on your computer or other device.
                    These data files may be cookies, pixel tags, “Flash
                    cookies,” or other local storage provided by your browser or
                    associated applications (collectively ‘Cookies’). We use
                    Cookies to ascertain which web pages are visited and how
                    often, to make our Platforms more user friendly, to give you
                    a better experience when you return to a website and to
                    target advertising to you that we think you may be
                    interested in.
                  </p>
                  <p>
                    For example, Cookies allow us to save your password so you
                    do not have to re-enter it every time you visit our site.
                    Most web browsers automatically accept Cookies. You can find
                    information specific to your browser under the “help” menu.
                    You are free to decline our Cookies if your browser or
                    browser add-on permits, unless our Cookies are required to
                    prevent fraud or ensure the security of Platforms we
                    control. However, declining our Cookies may interfere with
                    your use of our Platforms and JCI Remit Services.
                  </p>
                  <h3>Controlling Online Interest-Based Ads </h3>
                  <p>
                    We sometimes work with online advertising vendors to provide
                    you with relevant and useful ads. This may include ads
                    served on or through our Platforms. This may also include
                    ads served on other companies’ Platforms. These ads may be
                    based on information collected by us or third parties.{" "}
                  </p>
                  <h3>Protecting Personal Information</h3>
                  <p>
                    JCI Remit will keep your Personal Information secure by
                    taking reasonable steps to protect it from misuse, loss, and
                    unauthorised access, use, modification, and disclosure.{" "}
                    <br></br>We protect your information using physical,
                    technical, and administrative security measures to reduce
                    the risks of loss, misuse, unauthorised access, disclosure
                    and alteration. <br></br> We also review our security
                    procedures periodically to consider appropriate new
                    technology and updated methods. Only properly authorised
                    people who have a need to access Personal Information to
                    perform their job will be able to see or use that
                    information. Even so, despite our reasonable efforts, no
                    security measure is ever perfect or impenetrable.
                  </p>
                  <h3>
                    Accessing and requesting correction of Personal Information
                  </h3>
                  <p>
                    We will strive to ensure that information about you is
                    accurate when we collect or use it. Subject to some
                    exceptions under privacy law, we will let you see the
                    information we hold about you and correct it if it is
                    inaccurate, incomplete or out-of-date. If we do not grant
                    you access to your Personal Information or do not agree to
                    correct your Personal Information we will tell you why.
                  </p>
                  <p>
                    If you wish to obtain access to and/or correct your Personal
                    Information held by JCI Remit, please contact JCI Remit on:
                    <strong>Email:</strong> contact@jciremit.com
                    <strong>WhatsApp: </strong> +61 431 919 688
                  </p>
                  <p>
                    Unless we do not agree to your request for access to
                    Personal Information, in most cases JCI Remit will provide
                    you with access as soon as reasonably possible following
                    receipt of your request. If you request corrections to your
                    Personal Information and JCI Remit agrees with your request,
                    these changes will be made as soon as practicable. If JCI
                    Remit does not agree to your request for correction, it will
                    notify you of the reasons it does not agree and will note
                    your request on the records it holds about you. If you are
                    dissatisfied with our refusal to provide you with access to,
                    or correct, your Personal Information you may also complain
                    to the applicable regulator in your jurisdiction.
                  </p>
                  <p>
                    Subject to the terms set out in the JCI Remit User
                    Agreement, you can also close your JCI Remit account through
                    our Platforms. If you close your JCI Remit account, we may
                    retain information from your account for a period of time to
                    collect any fees owed, resolve disputes, troubleshoot
                    problems, assist with any investigations, prevent fraud or
                    risk, enforce our User Agreement, or take other actions as
                    required or permitted by law.
                  </p>
                  <h3>Exchange Rate</h3>
                  <p>
                    Exchange rate on all our Platforms is determined by our
                    third-party exchange rate provider. JCI Remit Ltd does not
                    have any interference with the market FX Control and
                    essentially does not contribute to the final exchange rate
                    freeze on the time of exchange or afterwards. <br></br> What
                    is advertised on all Platforms we own are the rates provided
                    to us by our exchange rate provider and are displayed by us.{" "}
                    <br></br>
                    Market fluctuation varies and this can vary according to the
                    influx of exchange rate accumulation on the exchange of our
                    providers. <br></br> The final rate at the end of the
                    exchange is determined by the exchange rate provider and
                    this can vary from time to time.
                  </p>
                  <h3>Resolving your concerns</h3>
                  <p>
                    If you have a complaint regarding JCI Remit’s management of
                    your Personal Information, please prepare your complaint in
                    writing and email it to us at contact@jciremit.com <br></br>
                    JCI Remit will provide written acknowledgement of your
                    complaint<br></br> within 14 days of receipt. We will
                    investigate and advise you of the steps we have taken to
                    resolve your complaint within 30 days of receipt of your
                    complaint. <br></br>
                    If you require a copy of this Privacy Policy in a different
                    format, such as a PDF or hard copy, please contact us at
                    contact@jciremit.com and JCI Remit will use its reasonable
                    efforts to comply with your request.
                  </p>
                  <table className={"table-bordered"} style={{width:500, marginInline:'auto'}}>
                    <tbody>
                    <tr><td colSpan={2} className={'text-center'}>OAIC Details</td></tr>
                    <tr>
                      <td>Phone Enquiries</td>
                      <td>1300 363 992</td>
                    </tr>
                    <tr>
                      <td>Post</td>
                      <td>GPO Box 5218 Sydney NSW 2001</td>
                    </tr>
                    <tr>
                      <td>FaX</td>
                      <td>+61 2 9284 9666</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                {/* <div className="article-footer">
                  <div className="article-tags">
                    <span>
                      <i className="fas fa-bookmark" />
                    </span>
                    <a href="#">Fashion</a>,<a href="#">Games</a>,
                    <a href="#">Travel</a>
                  </div>
                  <div className="article-share">
                    <ul className="social">
                      <li>
                        <a href="#" target="_blank">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank">
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="account-create-area">
        <div className="container">
          <div className="account-create-content">
            <h2>Apply for an account in minutes</h2>
            <p>Get your JCI REMIT account today!</p>
            <a href="/home#download" className="btn btn-primary">
              Get Your JCI REMIT Account
            </a>
          </div>
        </div>
      </section>

      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={jcilogo}
                      height={130}
                      alt="logo"
                      style={{ marginTop: -50 }}
                    />
                  </Link>
                  <p>
                    JCI REMIT PTY LTD with ABN Number 18645908907 is a
                    Remittance Service Provider, that is authorized and
                    regulated by the Australian Transaction Reports and Analysis
                    Center, AUSTRAC.
                  </p>
                </div>
                <ul className="social-links">
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Company</h3>
                <ul className="list">
                  <li>
                    <a href="/about">About Us</a>
                  </li>
                  <li>
                    <a href="/home#aboutus">Services</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Support</h3>
                <ul className="list">
                  <li>
                    <Link to="/faq#introcontent">FAQ</Link>
                  </li>
                  <li>
                    <a href="/home#contactus">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>LEGAL</h3>
                <ul className="list">
                  <li>
                    <Link to="/policies">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/user-agreement">User Agreement</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <p>
              JCI REMIT PTY LTD @ {(new Date()).getFullYear()} JCI REMIT | All Rights Reserved <br></br>
              
            </p>
          </div>
        </div>
        <div className="map-image">
          <img src={map} alt="map" />
        </div>
      </footer>
      <div className="go-top">
        <i className="fas fa-arrow-up" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Policy);
