import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Iframe from "react-iframe";

const Paymentpage = (props) => {
  const location = useLocation();

  let uurl = null;

  if (document.getElementById("myId")) {
    uurl = document.getElementById("myId");
  }

  useEffect(() => {}, [uurl]);

  // const ojina = () => {};

  return (
    <div className="text-center">
      <Iframe
        url={location.state.url}
        width="100%"
        height="700px"
        id="myId"
        className="myClassname"
        display="initial"
        // onLoad={ojina()}
        position="relative"
      />
      {/* <br></br>
      <span >
        After making payments, kindly click here to
      </span>
      <br></br>
      <br></br>

      <Link to="/checkstatus">
        {" "}
        <button className="btn-shadow btn-wide fsize-1 btn btn-danger btn-lg">
          Return Back to JCI App
        </button>
      </Link> */}
    </div>
  );

  //return <div dangerouslySetInnerHTML={{ __html: "<iframe id='iframeId' src="+ location.state.url +" frameborder='0' style='overflow:hidden;height:100%;width:100%' height='100%' width='100%' allowfullscreen />"}} />;
};

export default Paymentpage;
