/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./assets/css/style.css";
import "./assets/css/animate.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/magnific-popup.min.css";
import "./assets/css/nice-select.css";
import "./assets/css/slick.min.css";
import "./assets/css/odometer.min.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/meanmenu.css";
import "./assets/css/responsive.css";
import { Link } from "react-router-dom";
import faq from "./assets/img/faq.png";
import logo from "./assets/img/logo.png";
import jcilogo from "../../src/assets/images/logo_png.png";
import email from "../../src/assets/images/email.png";
import whatsapp from "./assets/img/whatsapp.png";
import login from "../../src/assets/images/user.png";
import map from "./assets/img/map.png";
import { connect } from "react-redux";
import Notification from "../components/Notification";
// eslint-disable-next-line no-lone-blocks

const Agreement = (props) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <div>
      <Notification notify={notify} setNotify={setNotify} />
      {/* is-sticky */}
      <div className="navbar-area">
        <div className="luvion-responsive-nav">
          <div className="container">
            <div className="luvion-responsive-menu">
              <div className="logo">
                <Link to="/home">
                  <img src={logo} height={100} alt="logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="luvion-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/home">
                <img src={logo} height={130} alt="logo" />
              </Link>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
                style={{ display: "block" }}
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    {/*<Link to="/login" src={login} className="nav-link">*/}
                    {/*  <img src={login} alt="logo" style={{ marginRight: 8 }} />*/}
                    {/*  Login*/}
                    {/*</Link>*/}
                  </li>
                </ul>
                <div className="others-options">
                  {/*<Link*/}
                  {/*  to="/introreg"*/}
                  {/*  className="btn btn-primary"*/}
                  {/*  style={{ marginLeft: 20 }}*/}
                  {/*>*/}
                  {/*  Register*/}
                  {/*</Link>*/}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div
        className="page-title-area item-bg1 jarallax"
        data-jarallax='{"speed": 0.3}'
      >
        <div className="container">
          <div className="page-title-content">
            <h2>User Agreement</h2>
            <p>Our User Agreement Information</p>
          </div>
        </div>
      </div>

      <section className="blog-details-area ptb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="blog-details">
                <div className="article-content">
                  <h3>1. CONTRACT FORMATION AND OVERVIEW</h3>
                  <p>
                    a. This User Agreement{" "}
                    <strong>
                      <b>("Agreement")</b>
                    </strong>{" "}
                    is effective as of 1 December 2021 and The Agreement in its
                    absolute discretion from time to time, but changes will only
                    be effective from the date they are made and will not change
                    the terms on which you previously used the Service. It
                    describes the terms by which you will be bound you may
                    engage access and use the website (and for the purpose of
                    these The Agreement “website” will include our App (where
                    applicable) and the services associated with it (together,
                    the{" "}
                    <strong>
                      <b>"Service"</b>
                    </strong>
                    ). By accessing, registering with and requesting to use the
                    Service, you agree to be bound by the terms of these The
                    Agreement.
                  </p>
                  <p>
                    b. You do not have a standing arrangement to make
                    Transactions; we can decide in our discretion whether or not
                    to permit any Transaction you request. If you do not wish to
                    be bound by the Agreement do not access, register with or
                    use the Service. The language of these The Agreement is
                    English, and all Services, instructions and transactions
                    carried out in connection with it shall be in English. Do
                    not use the Service if you do not agree to be bound by these
                    terms. By using the Service, you are agreeing to the terms
                    of this Agreement.
                  </p>
                  <p>
                    c. As used throughout this Agreement, the terms "JCI Remit",
                    "we", "us", and "our" refer to applies to JCI Remit Pty Ltd (CAN
                    645 908 907) registered in the State of Queensland,
                    Australia and their affiliates and related companies,
                    together with its employees, directors, successors, and
                    assignees. The terms "you" and "your" refer to users of the
                    Service, whether as Senders or Recipients.
                  </p>
                  <p>
                    d. The Service was created: (a) to assist customers to send
                    money to their family and friends, and to receive money from
                    family and friends, from different locations; and (b)to
                    offer businesses the ability to transfer money and receive
                    money via several channels. However, registration for the
                    Service does not require us to accept or process any
                    Transaction that you request. Each Transaction we do accept
                    will be treated as a separate instruction. For security
                    reasons, we recommend that you only send money through the
                    Service to people or businesses you know personally. You
                    must not use the Service to send money to strangers, for
                    example sellers of goods and/or services, whether private or
                    retail.
                  </p>
                  <p>
                    e. Nothing in the Agreement or in any other information
                    provided by JCI Remit as part of the Service is intended to
                    be, nor should it be construed to be, legal, financial, tax
                    or other advice. You should consult your own professional
                    advisers as to the effects of Australian or foreign laws
                    which may apply to the Service.
                  </p>
                  <h3>
                    2.{" "}
                    <strong>
                      <b>MEANING</b>
                    </strong>{" "}
                    In these The Agreement:
                  </h3>
                  <p>
                    <strong>
                      <b>"App"</b>
                    </strong>{" "}
                    means JCI Remit’s our mobile applications, websites, via
                    email, cloud-based services, or other communication
                    platforms for the sending Transaction Requests.
                  </p>
                  <p>
                    <strong>
                      <b>"Business Day"</b>
                    </strong>
                    means Monday through Friday, except the legal public
                    holidays on which we are open for business for the execution
                    of Transaction Requests. (Note we may process your
                    transactions on all days of the week but for the purpose of
                    this Agreement, Business Day means as stated)
                  </p>
                  <p>
                    <strong>
                      <b>"Instruction"</b>
                    </strong>
                    means a Transaction Request.
                  </p>
                  <p>
                    <strong>
                      <b>"Receiver"</b>
                    </strong>
                    means someone who receives money through the Service.
                  </p>
                  <p>
                    <strong>
                      <b>"Payment Format"</b>
                    </strong>
                    means a valid method or process of payment such as a bank
                    account, debit card or credit card.
                  </p>
                  <p>
                    <strong>
                      <b>"Payout Amount"</b>
                    </strong>
                    means the amount paid out, after any foreign exchange
                    conversion, to the Receiver’s account exclusive of the
                    Service Fee.
                  </p>
                  <p>
                    <strong>
                      <b>"Recipient"</b>
                    </strong>
                    means someone receiving the Payout Amount either as a
                    Receiver; or someone who receives a payment through a
                    Transaction Request.
                  </p>
                  <p>
                    <strong>
                      <b>"Registration"</b>
                    </strong>
                    means the registration details of the customer as updated
                    from time to time.
                  </p>
                  <p>
                    <strong>
                      <b>"Sender"</b>
                    </strong>
                    means someone who uses the Service to send money.
                  </p>
                  <p>
                    <strong>
                      <b>"Service Fee"</b>
                    </strong>
                    means JCI Remit’s fee plus any additional charges or axes
                    applicable to each Transaction, which JCI Remit may charge
                    in its sole discretion in accordance with applicable laws,
                    as may be described on the JCI Remit website from time to
                    time.
                  </p>

                  <p>
                    <strong>
                      <b>"Service Provider"</b>
                    </strong>
                    means a local bank, money exchange house, or other
                    third-party service providers (e.g. mobile network
                    operators) in the destination Country with whom JCI Remit
                    works to provide the Service.
                  </p>

                  <p>
                    <strong>
                      <b>"Transaction"</b>
                    </strong>
                    means the transfer of money through the Service.
                  </p>

                  <p>
                    <strong>
                      <b>"Transaction Amount"</b>
                    </strong>
                    means the amount of money that the Sender wishes to send to
                    the Receiver as a Transaction, excluding any applicable
                    Service Fee and prior to any foreign exchange conversion.
                  </p>

                  <p>
                    <strong>
                      <b>"Transaction History"</b>
                    </strong>
                    means the record of your Transactions on our website which
                    you may access using your email and password registration
                    details.
                  </p>

                  <p>
                    <strong>
                      <b>"Transaction Request"</b>
                    </strong>
                    means a specific instruction from you requesting us to send
                    money to a Receiver through the Service.
                  </p>

                  <h3>3. JCI REMIT’s OBLIGATIONS</h3>
                  <p>
                    a. Subject to the provisions of this Agreement, we agree to
                    provide the Service to you using reasonable care. You
                    acknowledge that the Service may not be perfect, not
                    available, in whole or in part, in certain places,
                    countries, or jurisdictions or at different times.
                  </p>
                  <p>
                    b. JCI Remit is not obliged to process, and you have no
                    right to make any Transaction. When you submit a Transaction
                    Request, you are requesting that we process the Transaction
                    on your behalf and consenting to the execution of the
                    Transaction. We may, in our sole discretion, choose whether
                    or not to accept the offer to process that Transaction. If
                    we decide not to process the Transaction, we will notify you
                    promptly of that decision and repay to you the Transaction
                    Amount received by us, provided that we are not prohibited
                    by law from doing so. If we choose to proceed with the
                    Transaction we may still suspend or cancel it in our
                    discretion.
                  </p>
                  <p>
                    c. JCI Remit reserves the right to modify or discontinue the
                    Service or any part of the Service without notice, at any
                    time and from time to time.
                  </p>
                  <p>
                    d. We may, in our absolute discretion, refuse any
                    Transaction Request or impose limits on the Transaction
                    Amount. We may do so for a single, multiple or all
                    Transactions.
                  </p>
                  <p>
                    e. Delivery times stated on our on our website or platforms
                    are representative for the “normal” / average service and
                    are not a guarantee of an individual Service or Transaction
                    time.
                  </p>
                  <p>
                    f. We will attempt to process a Transaction promptly, but
                    any Transaction may be delayed or cancelled for several
                    reasons some within our control and others outside our
                    control. You acknowledge that you accept such delays.
                  </p>
                  <h3>
                    4.{" "}
                    <strong>
                      <b>USER OBLIGATIONS</b>
                    </strong>{" "}
                    You agree that:
                  </h3>
                  <p>
                    a. you will not access, use or attempt to use the Service to
                    provide any Instructions unless you have the legal capacity
                    to form a binding legal contract under the Governing law.
                  </p>
                  <p>
                    b. you may pay us a Service Fee in addition to the
                    Transaction Amount at the time that you submit your
                    Transaction Request. The Service Fee is non-refundable
                    unless expressly stated in these The Agreement or by the
                    Governing law.
                  </p>
                  <p>
                    c. You agree to reimburse JCI Remit for any fees or charges
                    it become liable for as a result of carrying your
                    Transaction.
                  </p>
                  <p>
                    d. You will provide us with identity documents that are
                    true, accurate, current and complete evidence of your
                    identity, and promptly update your personal information if
                    and when it changes and failure to will result in a
                    violation of this Agreement and the Governing law.
                  </p>
                  <p>
                    e. You provide us with details of one or more Payment
                    Formats.
                  </p>
                  <p>
                    f. You will provide us with true, accurate, current and
                    complete information required to provide the Service and any
                    other information which is required in relation to the
                    Recipient.
                  </p>
                  <p>
                    g. You bear the responsibility to make sure all the details
                    are accurate before submission. Once a Transaction Request
                    has been received it is not normally possible to change any
                    details of that Transaction Request. Check the details prior
                    to executing the Transaction Request.
                  </p>
                  <p>
                    h. You agree that exchange rate applies, and rates may vary
                    due to foreign exchange fluctuations, and this may impact
                    the final payout amount to your beneficiary.
                  </p>
                  <p>
                    i. You accept that you will only use the Service to send
                    money or perform transactions with people or businesses that
                    you know personally and not for transactions with third
                    parties you do not know and trust. You acknowledge that JCI
                    Remit may refuse to process your Transaction Request where
                    we suspect or believes the Transaction Request may be to
                    third parties we suspect or believe may involve fraudulent,
                    illegal, or unethical activities. If JCI Remit process the
                    Transaction Request for any such use of the Service, You
                    agree it is entirely at your own risk.
                  </p>
                  <p>
                    j. You accept that this Agreement or in any other
                    information provided by JCI Remit as part of the Service is
                    intended to be for delivering of the Service and should not
                    be construed to be, legal, financial, investment or other
                    advice.
                  </p>
                  <h3>
                    <strong>
                      <b>5. REFUSE, SUSPEND OR CANCEL</b>
                    </strong>
                  </h3>
                  <p>
                    JCI Remit may at its absolute discretion refuse a
                    Transaction at any time for any reason, and if we permit a
                    Transaction, we may still suspend, refuse, or cancel the
                    Transaction or the Service in part or full at any time for
                    any reason whatsoever if it considers it necessary to do so.
                    JCI Remit at its absolute discretion may also refuse
                    Transaction Requests and/or suspend or cancel your
                    Registration under this provision or for any reason
                    whatsoever were it deems it necessary for the protection of
                    its business and Your interest or for any breach by You of
                    this Agreement or any other policy of JCI Remit or Governing
                    law.
                  </p>
                  <h3>
                    <strong>
                      <b>6. TRANSACTION REQUEST</b>
                    </strong>
                  </h3>
                  <p>
                    b. You acknowledge that the Service may not be available, in
                    whole or in part, in certain regions, countries, or
                    jurisdictions.
                  </p>
                  <p>
                    c.{" "}
                    <strong>
                      <b>Cancellation of Transaction Requests.</b>
                    </strong>
                    Once we have received your Transaction Request, you may not
                    cancel it. In such circumstances you would need to contact
                    the Sender separately and explain that you require the
                    Transaction Request to be treated as cancelled. Please
                    therefore ensure that your Transaction Requests are
                    legitimate, accurate and complete.
                  </p>
                  <p>
                    d.{" "}
                    <strong>
                      <b>Cancellation of Transaction.</b>
                    </strong>
                    The cancellation of a Transaction shall be governed by, and
                    dealt in accordance with, these The Agreement. You shall
                    assist and co- operate with us in relation to all
                    cancellation requests we receive from Senders after a
                    Transaction has been initiated following your Transaction
                    Request. You shall, upon request from us, promptly refund to
                    us or a third party of our choice (including the Sender) all
                    monies you receive from a Transaction where we reasonably
                    believe that a Transaction has resulted from your use of the
                    Services in contravention of these The Agreement.
                  </p>
                  <h3>
                    <strong>
                      <b>7. COLLECTION OF INFORMATION</b>
                    </strong>
                  </h3>
                  <p>
                    a.{" "}
                    <strong>
                      <b>Customer Identification Program.</b>
                    </strong>
                    Under Australian law, We are required to assist in the fight
                    against money laundering activities and the funding of
                    terrorism by obtaining, verifying, and recording identifying
                    information about all customers. We will therefore require
                    you to supply us with personal identifying information
                    relating to you and the Recipient and we may also legally
                    consult other sources to obtain information about you and
                    the Recipient.
                  </p>

                  <p>
                    b.{" "}
                    <strong>
                      <b>Verification and Checks.</b>
                    </strong>
                    You agree to be bound by our Privacy Policy. We will verify
                    your residential address and personal details in order to
                    confirm your identity. We may also pass your personal
                    information to a credit reference agency, which may keep a
                    record of that information. Be assured that this is done
                    only to confirm your identity, and that we do not perform
                    credit checks and therefore your credit rating will be
                    unaffected. We may also need to verify the identity of a
                    Recipient in the same way. All information provided by you
                    will be treated securely and strictly in accordance with the
                    Privacy Act 1988 (Cth).
                  </p>
                  <p>
                    c.{" "}
                    <strong>
                      <b>Data Privacy Policy.</b>
                    </strong>
                    You consent to our processing your personal information for
                    the purposes of providing the Service, including for
                    verification purposes as set out in this clause. You also
                    consent to the use of such data to enable us and our
                    authorised third parties to communicate with you, and for
                    statutory, accounting, and legal purposes, in accordance
                    with the terms of Our Privacy Policy. You acknowledge that
                    you have read and consented to JCI Remit's Privacy Policy.
                    The Privacy Policy can be found by on our website.
                  </p>
                  <p>
                    d.{" "}
                    <strong>
                      <b>Disclosures.</b>
                    </strong>
                    We may be required by law to provide information about you,
                    your use of the Service and your Instructions to government
                    or other institutions as described in our Privacy Policy.
                    You acknowledge and consent to us doing this.
                  </p>
                  <h3>
                    <strong>
                      <b>8. INTELLECTUAL PROPERTY</b>
                    </strong>
                  </h3>
                  <p>
                    You acknowledge that the Service, including the content of
                    this website, text, graphics, logos, and images, as well as
                    all other JCI Remit copyrights, trademarks, logos, and
                    product and service names are owned exclusively by JCI
                    Remit.
                  </p>
                  <h3>
                    <strong>
                      <b>9. WARRANTIES AND LIABILITY</b>
                    </strong>
                  </h3>
                  <p>
                    a. We make reasonable efforts to ensure that Transactions
                    are processed in a timely manner, but we make no
                    representations or warranties regarding the time needed to
                    complete processing because the Service is dependent on many
                    factors outside our control. In any event, you may have a
                    right to a refund as expressly described herein. Where we
                    have materially breached these Agreement causing a Sender
                    loss, we will refund the Sender the Transaction Amount and
                    the Service Fee.
                  </p>
                  <p>
                    b. If a Transaction is delayed or fails, or if an executed
                    Transaction is not authorised, you may have a right to
                    receive a refund or compensation under laws relating to the
                    provision of international money transfer services. In the
                    case of any unauthorised or incorrectly executed
                    Transaction, any such right may be prejudiced if you do not
                    notify us of the unauthorised or incorrectly executed
                    Transaction without delay, or in any event within thirteen
                    months after the debit date.
                  </p>
                  <p>
                    c. Any claim for compensation made by you must be supported
                    by any available relevant documentation.
                  </p>
                  <p>
                    d. We do not accept any liability for loss or damages to you
                    or any third party resulting from non-payment or delay in
                    payment of a Payout Amount to a Receiver or failure to
                    perform an Instruction under the Service if you are in
                    breach of your obligations under this Agreement.
                  </p>
                  <h3>
                    <strong>
                      <b>10. INDEMNITY</b>
                    </strong>
                  </h3>
                  <p>
                    To the full extent permitted by applicable law, You
                    understand and acknowledge that you are liable for all
                    losses incurred in respect of an unauthorised Transaction or
                    Instruction or any other unauthorised use of the Service,
                    where you have acted fraudulently or negligently and you
                    agree to be responsible for and hold harmless JCI Remit,
                    Service Providers, and their respective subsidiaries,
                    officers, agents, partners, and employees harmless from any
                    claim or demand, including reasonable attorneys' fees, made
                    by any third party due to or arising out of your use of the
                    Service, your connection to the Service, your violation of
                    the Agreement, or your violation of any rights of another.
                  </p>

                  <h3>
                    <strong>
                      <b>11. LIMITATION OF LIABILITY</b>
                    </strong>
                  </h3>
                  <p>
                    If any loss that you suffer is not covered by a right to
                    payment under the laws referred to in this Agreement, we
                    will only accept liability for that loss up to a limit which
                    is the greater of A$250, unless otherwise agreed by us in
                    writing (IN ADDITION TO REFUNDING THE TRANSACTION AMOUNT AND
                    SERVICE FEES), INCLUDING BUT NOT LIMITED TO, DAMAGES FOR
                    LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
                    LOSSES (EVEN IF JCI REMIT HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES) RESULTING FROM NEGLIGENCE ON
                    THE PART OF JCI REMIT, DISBURSEMENT PARTNERS, OR THEIR
                    RESPECTIVE SUBSIDIARIES, OFFICERS, AGENTS, PARTNERS, OR
                    EMPLOYEES .The cap on our liability only limits a claim for
                    loss arising out of any single Transaction or series of
                    related Transactions, or (if a loss does not arise out of a
                    Transaction or related Transactions) any single act,
                    omission or event or related acts, omissions or events. This
                    means that if, for example, you suffer loss by reason of our
                    failure to perform our agreement with you under two
                    unrelated Transactions, you might be able to claim up to a
                    maximum of A$500.
                  </p>

                  <h3>
                    <strong>
                      <b>12. SERVICE DISRUPTIONS</b>
                    </strong>
                  </h3>
                  <p>
                    a. We do not accept liability for any failure to perform the
                    Service (e.g. your Transaction Instruction) as a result of
                    circumstances which could reasonably be considered to be due
                    to abnormal and unforeseen circumstances or outside our
                    control or due to our obligations under any applicable laws,
                    rules or regulations.
                  </p>
                  <p>
                    b. We do not accept liability for system malfunctions due to
                    our technology downtime which cannot reasonably be
                    considered to be under our control and that may affect the
                    accuracy or timeliness for the provision of Transaction or
                    Services to you.
                  </p>
                  <p>
                    c. We do not accept liability for any losses or delays in
                    any Transaction Payment arising out of the use of any
                    internet or communications service or caused by any browser
                    or other software which is not under our control or for
                    errors on the website or which results in the Service or
                    Transaction Payment becoming incomplete or results in
                    incorrect information provided by any party.
                  </p>
                  <h3>
                    <strong>
                      <b>13. USE OF THE APP</b>
                    </strong>
                  </h3>
                  <p>
                    a. By using our Services, be bound to the Agreement, we
                    grant you a non-transferable, non-exclusive licence to use
                    our platform, in accordance with this Agreement, our Privacy
                    Policy and any relevant laws as may be amended from time to
                    time. We reserve all other rights.
                  </p>
                  <p>
                    b. You acknowledge that the Services has not been developed
                    to meet your individual requirements, and that it is
                    therefore your responsibility to ensure that the Services
                    meet your requirements.
                  </p>
                  <p>
                    c. We may refuse any Transaction or limit the amount to be
                    transferred, either on a per transaction or aggregated
                    basis. These limits may be imposed on individual accounts or
                    linked accounts. We reserve the right at any time to modify
                    or discontinue all or any part of the Service.
                  </p>
                  <p>
                    d.{" "}
                    <strong>
                      <b>Delays.</b>
                    </strong>
                    Your Transaction may be delayed by our effort to verify your
                    identity and validate your Payment Formats and otherwise
                    comply with laws or manage our financial risk. You may be
                    entitled to a refund in certain circumstances, and you may
                    cancel your transaction at any time while it is pending.
                  </p>
                  <p>
                    e.{" "}
                    <strong>
                      <b>Commercial Transactions.</b>
                    </strong>
                    You should not use the Service to send money except to
                    people that you know. We are not responsible for, the
                    quality or delivery of goods or services that you pay for
                    using the Service. You accept that using the Service to pay
                    for goods and services is at your own risk.
                  </p>

                  <p>
                    f.{" "}
                    <strong>
                      <b>Unauthorized Transactions.</b>
                    </strong>
                    You may not use the Service in violation of this Agreement
                    or applicable laws, rules or regulations. It is a violation
                    of the Agreement to use the Service for any of the
                    following: sexually-oriented materials or services, gambling
                    activities , fraud, money-laundering, the funding of
                    terrorist organizations, or the purchase or sale of tobacco,
                    tobacco related paraphernalia, firearms, prescription drugs,
                    or other controlled substances; or to send money to a
                    Recipient that has violated the Agreement. If you use the
                    Service in connection with illegal conduct, JCI Remit will
                    report you to law enforcement.
                  </p>
                  <p>
                    g.{" "}
                    <strong>
                      <b>Ineligibility.</b>
                    </strong>
                    Your Payment Format must be issued by a financial
                    institution that has the legal approval to operate within
                    the Laws. We may refuse Transactions that are included on
                    the Specially Designated Nationals list, Non-cooperative
                    Countries and Territories list, and such other lists as
                    issued by different government agencies.
                  </p>
                  <p>
                    h.{" "}
                    <strong>
                      <b>No Changes.</b>
                    </strong>
                    We generally do not let you change the details of your
                    Transaction once it's submitted to us for processing. It is
                    your responsibility to make sure your Transaction details
                    are accurate.
                  </p>
                  <p>
                    i.{" "}
                    <strong>
                      <b>Restricted Activities.</b>
                    </strong>
                    In connection with your use of our website or the Service,
                    or in the course of your interactions with JCI Remit, a user
                    or a third party, you will not: 1. Breach this Agreement, or
                    any other agreement between you and JCI Remit; 2. Provide
                    false, inaccurate, or misleading information. 3. Refuse to
                    cooperate in an investigation or provide confirmation of
                    your identity; 4. Use an anonymizing proxy; 5.Provide
                    yourself a cash advance from your credit card (or help
                    others to do so); 6. Use any automatic device, or manual
                    process to monitor or copy our website.
                  </p>
                  <h3>
                    <strong>
                      <b>14. Refunds.</b>
                    </strong>
                  </h3>
                  <p>
                    You can cancel your transaction at any time prior to its
                    completion. Completion means that your recipient claimed the
                    money you sent either through cash pick-up, or bank account
                    deposit. Upon receipt of a cancellation request, we may
                    confirm with our Service Providers to determine whether the
                    transaction has been completed prior to initiating a refund.
                    <br></br>
                    <br></br>
                    The Transaction Amount will not be refunded after
                    completion. If, however, you are not satisfied with our
                    service for any reason we will always refund the fee amount.
                    All refunds will be credited to the same Payment Format used
                    to pay for the Transaction. Refunds are only made in the
                    currency used and will not be adjusted to account for
                    changes in the value currency from the time your Transaction
                    was submitted.
                  </p>

                  <h3>
                    <strong>
                      <b>15. COMPLAINTS</b>
                    </strong>
                  </h3>
                  <p>
                    a. If you wish to make a complaint about a Transaction or
                    Service, please send your complaint in writing via email:
                    <b>contact@jciremit.com</b>
                  </p>
                  <p>
                    b. We will acknowledge receipt of your complaint within 3
                    Business Days. We will investigate your complaint and come
                    back to you with the results of our investigation no later
                    than 14 Business Days after the receipt of our
                    acknowledgement of your complaint.
                  </p>

                  <h3>
                    <strong>
                      <b>16. GENERAL</b>
                    </strong>
                  </h3>

                  <p>
                    <strong>
                      <b>Governing law: </b>
                    </strong>
                    This Agreement shall be governed according to the laws of
                    the State of Queensland, and all activities performed in
                    connection with the Service shall be deemed to have been
                    performed in Brisbane, Queensland. Any controversy, dispute,
                    or claim arising out of or relating to the Service or
                    Agreement (a "Claim") shall be governed by and construed in
                    accordance with the laws of Queensland.
                  </p>
                  <h3>
                    <strong>
                      <b>17. MISCELLANEOUS</b>
                    </strong>
                  </h3>
                  <p>
                    <strong>
                      <b>Third party websites links and content.</b>
                    </strong>
                    Any external links to third-party websites or third party
                    content on our website are provided as a convenience to you
                    and does not imply JCI Remit's endorsement of the
                    third-party website or content. These sites are not
                    controlled by us in any way and we are not responsible for
                    the accuracy, completeness, legality or any other aspect of
                    these other sites including any content provided on them.
                    You access such websites at your own risk.
                  </p>
                  <p>
                    <strong>
                      <b>Entire Agreement.</b>
                    </strong>
                    The Agreement constitutes the entire agreement between you
                    and JCI Remit and governs your use of the Service,
                    superseding any prior agreements between you and JCI Remit.
                  </p>
                  <p>
                    <strong>
                      <b>No Waiver.</b>
                    </strong>
                    The failure of JCI Remit to exercise or enforce any right or
                    provision of the Agreement shall not constitute a waiver of
                    such right or provision. If any provision of the Agreement
                    is found by an arbitrator or court of competent jurisdiction
                    to be invalid, the parties nevertheless agree that the
                    arbitrator or court should endeavor to give appropriately
                    valid effect to the intention of the Agreement as reflected
                    in the provision, and the other provisions of the Agreement
                    shall remain in full force and effect.
                  </p>
                  <p>
                    <strong>
                      <b>Force Majeure.</b>
                    </strong>
                    We shall not be liable for any failure or delay in the
                    performance of the Service to the extent such failure or
                    delay is caused by matters beyond our reasonable control,
                    including, without limitation: changes in applicable laws;
                    closure or unavailability of required physical and network
                    infrastructure; sovereign default; power or internet
                    failure; civil unrest; war; and earthquake, fire, flood, or
                    other natural disasters.
                  </p>
                  <p>
                    <strong>
                      <b>Modification.</b>
                    </strong>
                    We may modify this Agreement from time to time without
                    notice to you, except as may be required by law. You can
                    review the most current version of the Agreement at any time
                    by reviewing this website. You may terminate your use of the
                    Service if you do not agree with any modification or
                    amendment. If you use the Service after the effective date
                    of an amendment or modification, you shall be deemed to have
                    accepted that amendment or modification. You agree that you
                    shall not modify this Agreement and acknowledge that any
                    attempts by you to modify this Agreement shall be void.
                  </p>
                  <p>
                    <strong>
                      <b>Other Terms.</b>
                    </strong>
                    This Agreement may be supplemented by terms applicable to
                    other promotions, our Referral Program, and other terms
                    applicable to you based upon your use of the Service. These
                    terms are incorporated into this Agreement by reference. To
                    the extent that any of these terms are determined to be in
                    conflict with this Agreement, this Agreement shall control.
                  </p>
                  <p>
                    <strong>
                      <b>Security</b>
                    </strong>
                    <p>
                      JCI Remit will keep your Personal Information secure by
                      taking reasonable steps to protect it from misuse, loss,
                      and unauthorised access, use, modification, and
                      disclosure.
                    </p>
                    <p>
                      We protect your information using physical, technical, and
                      administrative security measures to reduce the risks of
                      loss, misuse, unauthorised access, disclosure and
                      alteration.
                    </p>
                    <p>
                      We also review our security procedures periodically to
                      consider appropriate new technology and updated methods.
                      Only properly authorised people who have a need to access
                      Personal Information to perform their job will be able to
                      see or use that information. Even so, despite our
                      reasonable efforts, no security measure is ever perfect or
                      impenetrable.
                    </p>
                  </p>
                  <p>
                    <strong>
                      <b>Contact Information</b>
                    </strong>
                    <p>
                      Questions, notices, and requests for refunds or further
                      information should be sent to JCI Remit, as follows:
                    </p>
                    <strong>
                      <b>Email: </b>
                    </strong>{" "}
                    contact@jciremit.com<br></br>
                    <strong>
                      <b>WhatsApp: +61431919688</b>
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="account-create-area">
        <div className="container">
          <div className="account-create-content">
            <h2>Apply for an account in minutes</h2>
            <p>Get your JCI REMIT account today!</p>
            <a href="/home#download" className="btn btn-primary">
              Get Your JCI REMIT Account
            </a>
          </div>
        </div>
      </section>

      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={jcilogo}
                      height={130}
                      alt="logo"
                      style={{ marginTop: -50 }}
                    />
                  </Link>
                  <p>
                    JCI REMIT PTY LTD with ABN Number 18645908907 is a
                    Remittance Service Provider, that is authorized and
                    regulated by the Australian Transaction Reports and Analysis
                    Center, AUSTRAC.
                  </p>
                </div>
                <ul className="social-links">
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Company</h3>
                <ul className="list">
                  <li>
                    <a href="/about">About Us</a>
                  </li>
                  <li>
                    <a href="/home#aboutus">Services</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Support</h3>
                <ul className="list">
                  <li>
                    <Link to="/faq#introcontent">FAQ</Link>
                  </li>
                  <li>
                    <a href="/home#contactus">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>LEGAL</h3>
                <ul className="list">
                  <li>
                    <Link to="/policies">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/user-agreement">User Agreement</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <p>
              JCI REMIT PTY LTD @ {(new Date()).getFullYear()} JCI REMIT | All Rights Reserved <br></br>
              
            </p>
          </div>
        </div>
        <div className="map-image">
          <img src={map} alt="map" />
        </div>
      </footer>
      <div className="go-top">
        <i className="fas fa-arrow-up" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Agreement);
