/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Notification from "../components/Notification";
import SignInarea from "../components/SignInarea";
import "../login/css/style.css";
import img3 from "../assets/images/logo_png.png";
import backgroundImage from "../assets/images/water.png";

function Login() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  return (
    <section
      className="ftco-section"
      style={{
        backgroundImage: `url("${backgroundImage}")`,
        backgroundRepeat: "no-repeat",
        background: "cover",
        backgroundPositionX: "center",
      }}
    >
      <Notification notify={notify} setNotify={setNotify} />

      <div className="">
        <div className="w-50 text-left" style={{ marginRight: -60 }}>
          <a href="/" className="" style={{ marginLeft: 5 }}>
            <img src={img3} height={90} alt="" />
          </a>
        </div>
        <div className="text-center my-4" style={{ marginRight: 30 }}>
          <h2>
            <strong>LOGIN</strong>
          </h2>
        </div>
      </div>
      <div className="containers">
        <div className="rows justify-content-centers">
          <div className="col-md-6 text-center mb-5"></div>
        </div>
        <SignInarea notify={notify} setNotify={setNotify} />
      </div>
    </section>
  );
}

export default Login;
