import { mainAxios } from "../../utils/axios";
import ActionTypes from "../actiontype/ActionTypes";

const GetSingleObjectAction = (
  stateobject,
  setNotify,
  successactiontype,
  failureactiontype,
  setShow
) => {
  return async (dispatch) => {
    try {
      if (stateobject !== undefined) {
        let response = "";
        if (successactiontype === ActionTypes.GET_BUSINESS_BANK_SUCCESS) {
          response = await mainAxios.get(
            "/JCIBank/GetBusinessBankAccount/" + stateobject
          );
        } else if (
          successactiontype === ActionTypes.GET_CHARGES_BY_PAYMENTINTYPE_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Charges/GetChargesByPaymentInType/" + stateobject
          );
        } else if (
          successactiontype === ActionTypes.FETCH_USER_PROFILE_SUCCESS
        ) {
          response = await mainAxios.get("/Users/" + stateobject);
        } else if (
          successactiontype === ActionTypes.FETCH_USERS_BY_COUNTRY_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Users/GetTotalUsersByCountry/" + stateobject
          );
        } else if (
          successactiontype ===
          ActionTypes.FETCH_TRANSACTIONS_BY_COUNTRY_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Transactions/getTotalNoTransactions/" + stateobject
          );
        } else if (
          successactiontype === ActionTypes.FETCH_SINGLE_USER_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Users/getCustomerByEmail/" + stateobject
          );
        } else if (
          successactiontype === ActionTypes.FETCH_PENDING_TRANSACTIONS_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Transactions/getTotalNumberOfPendingTransactions"
          );
        } else if (successactiontype === ActionTypes.GET_APP_SETTINGS_SUCCESS) {
          response = await mainAxios.get("/AppSettings/GetappSettings");
        } else if (successactiontype === ActionTypes.GET_REFERRAL_SUCCESS) {
          response = await mainAxios.get(
            "/Referral/GetAllReferralCriteriaBonus",
            stateobject
          );
        }

        const dat = response.data.data;
        const message = response.data.message;
        const status = response.data.status;
        let data = [];
        data.push(dat);
        if (status === "success") {
          dispatch({ type: ActionTypes.LOADING_HIDE, payload: message });
          dispatch({ type: successactiontype, payload: data });
          // setNotify({
          //   isOpen: true,
          //   message: message,
          //   type: status,
          // });
          setShow(false);
        } else {
          if (successactiontype === ActionTypes.GET_BUSINESS_BANK_SUCCESS) {
            dispatch({
              type: ActionTypes.LOADING_HIDE,
              payload: message,
            });
            dispatch({ type: failureactiontype, payload: message });
            // setNotify({
            //   isOpen: true,
            //   message: message,
            //   type: "error",
            // });
            setShow(false);
          } else {
            dispatch({ type: ActionTypes.LOADING_HIDE, payload: message });
            dispatch({ type: failureactiontype, payload: message });
            // setNotify({
            //   isOpen: true,
            //   message: message,
            //   type: status,
            // });
            setShow(false);
          }
        }
      } else {
        const errormsg = "Kindly Supply all required information";
        dispatch({ type: ActionTypes.LOADING_HIDE, payload: stateobject });
        dispatch({ type: failureactiontype, payload: errormsg });
        // setNotify({
        //   isOpen: true,
        //   message: errormsg,
        //   type: "error",
        // });
        setShow(false);
      }
    } catch (error) {
      const errmsg = error.message;
      dispatch({ type: ActionTypes.LOADING_HIDE, payload: errmsg });
      dispatch({ type: failureactiontype, payload: errmsg });
      // setNotify({
      //   isOpen: true,
      //   message: error.message,
      //   type: "error",
      // });
      setShow(false);
    }
  };
};

export { GetSingleObjectAction };
