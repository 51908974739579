import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ShowLoading } from "../redux/actions/authaction";
import { GetAction } from "../redux/actions/getaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import DeleteRateModal from "./modals/DeleteRateModal";
import ViewRateModal from "./modals/ViewRateModal";
import $ from "jquery";
import DataTable from "datatables.net";
import EditRateModal from "./modals/EditRateModal";
import SelectSearch, { fuzzySearch } from "react-select-search";
import axios from "axios";
import { mainAxios } from "../utils/axios";

const Ratesarea = (props) => {
  const {
    setNotify,
    show,
    handleShow,
    setShow,
    fetchrates,
    allrates,
    allsendingcurrencystate,
    allreceivingcurrencystate,
    allcountriesstate,
    ratesLoading,
    setRatesLoading,
  } = props;
  const [rates, setRates] = useState({});

  useEffect(() => {
    $(document).ready(function () {
      $("#examtable").DataTable({
        responsive: !0,
        destroy: true,
      });
    });
  }, []);

  useEffect(() => {
    fetchrates(
      show,
      setNotify,
      ActionTypes.GET_RATE_SUCCESS,
      ActionTypes.GET_RATE_FAIL,
      setShow
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //this is to check for changes in ratesLoading variable
  useEffect(() => {
    if (!ratesLoading) {
      fetchrates(
        show,
        setNotify,
        ActionTypes.GET_RATE_SUCCESS,
        ActionTypes.GET_RATE_FAIL,
        setShow
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratesLoading]);

  useEffect(() => {
    if (allrates !== undefined) {
      setRates(allrates);
    }
  }, [allrates]);

  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [searchDetails, setSearchDetails] = useState({});
  const [searchStatus, setSearchStatus] = useState(false);

  const handleShowEdit = () => {
    setShowEdit(!showEdit);
  };
  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  };

  const handleShowView = () => {
    setShowView(!showView);
  };

  const [item, setItem] = useState({});

  function handleEdit(item) {
    setShowEdit(true);
    setItem(item);
  }

  function handleDelete(item) {
    setShowDelete(true);
    setItem(item);
  }

  const processStatus = (type) => {
    if (type === true) return "Active";
    else if (type === false) return "In-Active";
    else return "Others";
  };
  const processDisplay = (type) => {
    if (type === true) return "Displayed";
    else if (type === false) return "Not Displayed";
    else return "Others";
  };

  const receivingstatearray = [];
  const sendingstatearray = [];

  const handlesendingfrom = (cout) => {
    // const sendCountryCode = cout.substring(cout.length - 3, cout.length);
    const sendCountryCode = cout;
    setSearchDetails({ ...searchDetails, sendCountryCode });
  };

  const handlesendingfrom2 = (cout) => {
    // const receiveCountryCode = cout.substring(cout.length - 3, cout.length);
    const receiveCountryCode = cout;
    setSearchDetails({ ...searchDetails, receiveCountryCode });
  };

  const allcountriesarray = [];

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.alpha3Code;
      // newitem.value = item.country + " " + item.alpha3Code;
      newitem.value = item.alpha3Code;
      // newitem.currency = item.currency;
      allcountriesarray.push(newitem);
    }
  }

  if (allsendingcurrencystate) {
    for (var i = 0; i < allsendingcurrencystate.length; i++) {
      let item = allsendingcurrencystate[i];
      if (item !== null) {
        let newitem = {};
        newitem.name = item.countryCode;
        newitem.value = item.countryCode;
        // newitem.currency = item.currency;
        sendingstatearray.push(newitem);
      }
    }
  }
  if (allreceivingcurrencystate) {
    for (var j = 0; j < allreceivingcurrencystate.length; j++) {
      let item = allreceivingcurrencystate[j];
      if (item !== null) {
        let newitem = {};
        newitem.name = item.countryCode;
        newitem.value = item.countryCode;
        // newitem.currency = item.currency;
        receivingstatearray.push(newitem);
      }
    }
  }

  const handleSearch = async () => {
    const {sendCountryCode, receiveCountryCode} = searchDetails;

    if (!sendCountryCode || !receiveCountryCode) return

    try {
      fetchrates(
        show,
        setNotify,
        ActionTypes.GET_RATE_SUCCESS,
        ActionTypes.GET_RATE_FAIL,
        setShow,
        searchDetails
      );
      setSearchStatus(true);
    } catch (e) {
      setSearchStatus(false);
      console.log(e);
    }
  };

  const handleClearSearch = () => {
    setSearchStatus(false);
    fetchrates(
      show,
      setNotify,
      ActionTypes.GET_RATE_SUCCESS,
      ActionTypes.GET_RATE_FAIL,
      setShow
    );
  };

  const renderrow = (items) => {
    if (Object.keys(items).length !== 0) {
      return items.map((item, i) => {
        return (
          <>
            <tr>
              <td>{item.rateType}</td>
              {/* <td>{processDisplay(item.isUSDDisplay)}</td> */}
              <td>{item.sendCountryCode}</td>
              <td>{item.receiveCountryCode}</td>
              {/* <td>{item.amount}</td> */}
              <td>{item.amount_Local}</td>
              <td>{item.amount_USD}</td>
              <td>{processStatus(item.isActive)}</td>
              <td className="d-flex flex-wrap p-2" style={{ gap: "8px" }}>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-dark"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </button>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-danger"
                  onClick={() => handleDelete(item)}
                >
                  Delete
                </button>
              </td>
              {/* <td>
                <button
                  className="mb-2 mr-2 btn btn-warning"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </button>
                <button
                  className="mb-2 mr-2 btn btn-danger"
                  onClick={() => handleDelete(item)}
                >
                  Delete
                </button>
              </td> */}
            </tr>
          </>
        );
      });
    }
  };

  return (
    <>
      <div className="app-page-title app-page-title-simple">
        <div className="page-title-wrapper">
          <div className="page-title-heading" style={{ marginLeft: 16 }}>
            <div>
              <div className="page-title-head center-elem">
                <span className="d-inline-block">Rates Management</span>
              </div>
              <div className="page-title-subheading opacity-10">
                <nav className aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <span>Here you can manage all your rates</span>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
        <div className="widget-chat-wrapper-outer">
          <div className="widget-chart-content pt-3 pl-3 pb-1">
            <div className="widget-chart-flex mb-4">
              <div className="widget-numbers">
                <div className="widget-chart-flex">
                  <div className="fsize-4">
                    <span>Search rates by country pair</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-12 position-relative form-group">
                <div className="form-row">
                  <div className="col-md-5">
                    <label htmlFor="exampleGender">Send Country Code</label>
                    <SelectSearch
                      autoComplete="off"
                      options={allcountriesarray}
                      search={true}
                      onChange={handlesendingfrom}
                      filterOptions={fuzzySearch}
                      placeholder="Type code"
                    />
                  </div>
                  <div className="col-md-5">
                    <label htmlFor="exampleGender">Receive Country Code</label>
                    <SelectSearch
                      autoComplete="off"
                      options={allcountriesarray}
                      search={true}
                      onChange={handlesendingfrom2}
                      filterOptions={fuzzySearch}
                      placeholder="Type code"
                    />
                  </div>

                  <div className="col-md-3" style={{ marginLeft: 0 }}>
                    <label htmlfor="exampleDate"></label>
                    <div className="">
                      <button
                        className="btn-wide btn btn-dark mr-2"
                        style={{ marginTop: 12 }}
                        onClick={handleClearSearch}
                        disabled={!searchStatus}
                      >
                        Clear
                      </button>
                      <button
                        className="btn-wide btn btn-danger"
                        style={{ marginTop: 12 }}
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-xl-12">
        <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
          <div className="widget-chat-wrapper-outer">
            <div className="widget-chart-content pt-3 pl-3 pb-1">
              <div className="form-row">
                <div className="row ">
                  <div
                    className="ml-auto"
                    style={{ marginRight: 0, marginTop: -2 }}
                  >
                    <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                      <span className="text-success pl-2">
                        <button
                          className="btn-wide mb-2 mr-2 btn btn-shadow btn-danger btn-lg"
                          onClick={handleShow}
                        >
                          Create Rate
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-card mb-3 card">
        <div className="card-body">
          <table
            style={{ width: "100%" }}
            id="examtable"
            className="table table-hover table-striped table-bordered"
          >
            <thead style={{ textAlign: "center" }}>
              <tr>
                <th>Rate Type</th>
                {/* <th>USD Display</th> */}
                <th>Sending Country</th>
                <th>Receiving Country</th>
                {/* <th>Amount (#)</th> */}
                <th>Amount (Local)</th>
                <th>Amount (USD)</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>{renderrow(rates)}</tbody>
          </table>
        </div>
      </div>
      <ViewRateModal
        item={item}
        show={showView}
        setShow={setShowView}
        handleShowView={handleShowView}
      />
      <EditRateModal
        item={item}
        setNotify={setNotify}
        show={showEdit}
        handleEdit={handleEdit}
        setShow={setShowEdit}
        handleShowEdit={handleShowEdit}
        ratesLoading={ratesLoading}
        setRatesLoading={setRatesLoading}
      />
      <DeleteRateModal
        item={item}
        setNotify={setNotify}
        show={showDelete}
        handleDelete={handleDelete}
        setShow={setShowDelete}
        handleShowDelete={handleShowDelete}
        ratesLoading={ratesLoading}
        setRatesLoading={setRatesLoading}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allrates: state.allrates.allrates,
    allreceivingcurrencystate:
      state.allreceivingcurrencystate.allreceivingcurrencystate,
    allsendingcurrencystate:
      state.allsendingcurrencystate.allsendingcurrencystate,
    allcountriesstate: state.allcountriesstate.allcountriesstate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchrates: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      searchDetails
    ) => {
      dispatch(ShowLoading(setNotify));
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          searchDetails
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ratesarea);
