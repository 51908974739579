import ActionTypes from "../actiontype/ActionTypes";

const conversionstate = {};
const transactionstate = {};
const allreceivingcurrencystate = {};
const allsendingcurrencystate = {};

const alltransactionsending = {};

const alltransactionreceiving = {};

const allcustomertransactions = [];

const alltransactions = [];

const typetransactions = {};

const reftransactions = {};

const allcountries = {};

const advertconversionstate = {};

const receivingconversionstate = {};

const reporttransactions = {};
const exporttransactions = [];
const promotransactions = [];

const getAllCountries = () => {
  const allcountriess = localStorage.getItem("allcountries");
  try {
    if (allcountriess != null) {
      const obj = JSON.parse(allcountriess);
      return obj;
    } else {
      return allcountries;
    }
  } catch (error) {
    return allcountries;
  }
};

const getAllReceivingCurrency = () => {
  const receieving = localStorage.getItem("allreceivingcurrencystate");
  try {
    if (receieving != null) {
      const obj = JSON.parse(receieving);
      return obj;
    } else {
      return allreceivingcurrencystate;
    }
  } catch (error) {
    return allreceivingcurrencystate;
  }
};

const getAllTransactionsending = () => {
  const receieving = localStorage.getItem("alltransactionsending");
  try {
    if (receieving != null) {
      const obj = JSON.parse(receieving);
      return obj;
    } else {
      return alltransactionsending;
    }
  } catch (error) {
    return alltransactionsending;
  }
};

const getAllTransactionreceiving = () => {
  const receieving = localStorage.getItem("alltransactionreceiving");
  try {
    if (receieving != null) {
      const obj = JSON.parse(receieving);
      return obj;
    } else {
      return alltransactionreceiving;
    }
  } catch (error) {
    return alltransactionreceiving;
  }
};

const getAllSendingCurrency = () => {
  const sending = localStorage.getItem("allsendingcurrencystate");
  try {
    if (sending != null) {
      const obj = JSON.parse(sending);
      return obj;
    } else {
      return allsendingcurrencystate;
    }
  } catch (error) {
    return allsendingcurrencystate;
  }
};

const getCustomerTransactions = () => {
  const trans = localStorage.getItem("allcustomertransactions");
  try {
    if (trans != null) {
      const authobj = JSON.parse(trans);
      return authobj;
    } else {
      return allcustomertransactions;
    }
  } catch (error) {
    return allcustomertransactions;
  }
};

const getAllTransactions = () => {
  const trans = localStorage.getItem("alltransactions");
  try {
    if (trans != null) {
      const authobj = JSON.parse(trans);
      return authobj;
    } else {
      return alltransactions;
    }
  } catch (error) {
    return alltransactions;
  }
};

const getReportTransactions = () => {
  const trans = localStorage.getItem("reporttransactions");
  try {
    if (trans != null) {
      const authobj = JSON.parse(trans);
      return authobj;
    } else {
      return reporttransactions;
    }
  } catch (error) {
    return reporttransactions;
  }
};

const getPromoTransactions = () => {
  const trans = localStorage.getItem("promotransactions");
  try {
    if (trans != null) {
      const authobj = JSON.parse(trans);
      return authobj;
    } else {
      return promotransactions;
    }
  } catch (error) {
    return promotransactions;
  }
};

const existingPromoTransactions = getPromoTransactions();
const existingReports = getReportTransactions();
const existingreceivingCurrency = getAllReceivingCurrency();
const existingsendingCurrency = getAllSendingCurrency();
const existingAllTransactions = getAllTransactions();
const existingcountries = getAllCountries();

const existingTSending = getAllTransactionsending();
const existingTReceiving = getAllTransactionreceiving();

const alltsendingreducer = (state = existingTSending, action) => {
  switch (action.type) {
    case ActionTypes.GET_TRANSACTION_SENDING_SUCCESS:
      const allSending = {
        alltransactionsending: action.payload,
      };
      localStorage.setItem("alltransactionsending", JSON.stringify(allSending));
      return allSending;
    default:
      return state;
  }
};

const alltreceivingreducer = (state = existingTReceiving, action) => {
  switch (action.type) {
    case ActionTypes.GET_TRANSACTION_RECEIVING_SUCCESS:
      const allReceiving = {
        alltransactionreceiving: action.payload,
      };
      localStorage.setItem(
        "alltransactionreceiving",
        JSON.stringify(allReceiving)
      );
      return allReceiving;
    default:
      return state;
  }
};

const allcountiresreducer = (state = existingcountries, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_COUNTRY_SUCCESS:
      const allCountriess = {
        allcountries: action.payload,
      };
      localStorage.setItem("allcountries", JSON.stringify(allCountriess));
      return allCountriess;
    default:
      return state;
  }
};


const allpromotransreducer = (state = existingPromoTransactions, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_PROMO_TRANSACTION_SUCCESS:
      const allTransactions = {
        promotransactions: action.payload,
      };
      localStorage.setItem(
        "promotransactions",
        JSON.stringify(allTransactions)
      );
      return allTransactions;
    default:
      return state;
  }
};

const allreportreducer = (state = existingReports, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_AUSTRAC_REPORT_SUCCESS:
      const allReports = {
        reporttransactions: action.payload,
      };
      localStorage.setItem("reporttransactions", JSON.stringify(allReports));
      return allReports;
    default:
      return state;
  }
};

const allreceivingcurrencyreducer = (
  state = existingreceivingCurrency,
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_RECEIVING_CURRENCY_SUCCESS:
      const allReceivingCountries = {
        allreceivingcurrencystate: action.payload,
      };
      localStorage.setItem(
        "allreceivingcurrencystate",
        JSON.stringify(allReceivingCountries)
      );
      return allReceivingCountries;
    default:
      return state;
  }
};

const allsendingcurrencyreducer = (state = existingsendingCurrency, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_SENDING_CURRENCY_SUCCESS:
      const allSendingCountries = {
        allsendingcurrencystate: action.payload,
      };
      localStorage.setItem(
        "allsendingcurrencystate",
        JSON.stringify(allSendingCountries)
      );
      return allSendingCountries;
    default:
      return state;
  }
};

const conversionreducer = (state = conversionstate, action) => {
  switch (action.type) {
    case ActionTypes.ADD_CONVERT_SUCCESS:
      const currentConvertion = {
        conversionstate: action.payload,
      };
      return currentConvertion;
    default:
      return state;
  }
};

const transactionreducer = (state = transactionstate, action) => {
  switch (action.type) {
    case ActionTypes.ADD_TRANSACTION_SUCCESS:
      const addTransaction = {
        transactionstate: action.payload,
      };
      return addTransaction;
    default:
      return state;
  }
};

const alltransactionreducer = (state = existingAllTransactions, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_TRANSACTION_SUCCESS:
      const allTransactions = {
        alltransactions: action.payload,
      };
      localStorage.setItem("alltransactions", JSON.stringify(allTransactions));
      return allTransactions;
    default:
      return state;
  }
};

const typetransactionreducer = (state = typetransactions, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TYPE_TRANSACTION_SUCCESS:
      const typetransactionss = {
        typetransactions: action.payload,
      };
      return typetransactionss;
    default:
      return state;
  }
};
const exporttransactionreducer = (state = exporttransactions, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TRANSACTION_REPORT_SUCCESS:
      const typetransactionss = {
        exporttransactions: action.payload,
      };
      return typetransactionss;
    default:
      return state;
  }
};

const reftransactionreducer = (state = reftransactions, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_REF_TRANSACTION_SUCCESS:
      const reftransactionss = {
        reftransactions: action.payload.data,
      };
      return reftransactionss;
    default:
      return state;
  }
};

const advertconversionstatereducer = (
  state = advertconversionstate,
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_ADVERT_CONVERTION_SUCCESS:
      const reftransactions = {
        advertconversionstate: action.payload,
      };
      return reftransactions;
    default:
      return state;
  }
};

const receivingconversionstatereducer = (
  state = receivingconversionstate,
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_RECEIVING_CONVERTION_SUCCESS:
      const receivingtransactions = {
        receivingconversionstate: action.payload,
      };
      return receivingtransactions;
    default:
      return state;
  }
};

const customertransactionsreducer = (
  state = getCustomerTransactions,
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_CUSTOMER_TRANSACTION_SUCCESS:
      const customerTrans = {
        allcustomertransactions: action.payload,
      };
      localStorage.setItem(
        "allcustomertransactions",
        JSON.stringify(customerTrans)
      );
      return customerTrans;
    default:
      return state;
  }
};

export {
  transactionreducer,
  conversionreducer,
  allreceivingcurrencyreducer,
  allsendingcurrencyreducer,
  customertransactionsreducer,
  alltransactionreducer,
  allcountiresreducer,
  typetransactionreducer,
  reftransactionreducer,
  advertconversionstatereducer,
  allreportreducer,
  exporttransactionreducer,
  receivingconversionstatereducer,
  alltsendingreducer,
  alltreceivingreducer,
  allpromotransreducer,
};
