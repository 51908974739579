import React, {useState, useEffect} from 'react';
import { CreateAction } from '../../redux/actions/createaction';
import { connect } from 'react-redux';
import ActionTypes from "../../redux/actiontype/ActionTypes"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

    const AddPromoModal = (props) => {
    const { createpromo, setNotify, show, handleShow, setShow, addPromoLoading, setAddPromoLoading } = props;
    const [promodetails, setPromodetails] = useState({});

    const handleSubmit = e => {
        e.preventDefault();
        setAddPromoLoading(true);
        createpromo(promodetails, setNotify, ActionTypes.ADD_PROMO_SUCCESS, ActionTypes.ADD_PROMO_FAIL, setShow, setAddPromoLoading);
    };

    useEffect(() => {
    }, [show]);

    return (
      <Modal show={show} onHide={handleShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Promo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="col-md-12 position-relative form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="title">Title</label>
                  <input
                    name="title"
                    id="title"
                    placeholder="Title"
                    type="text"
                    className="form-control"
                    onChange={(event) => {
                      const title = event.target.value;
                      setPromodetails({ ...promodetails, ...{ title } });
                    }}
                  />
                </div>

                
                <div className="col-md-6">
                  <label htmlFor="exampleGender">Status</label>
                  <select
                    type="select"
                    id="isActive"
                    name="isActive"
                    className="mb-2 form-control"
                    onChange={(event) => {
                      const isActive = event.target.value;
                      setPromodetails({ ...promodetails, ...{ isActive } });
                    }}
                  >
                    <option> -- Select Status -- </option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-12 position-relative form-group">
              <div className="form-row">
                <div className="col-md-4">
                  <label htmlfor="startDate">Start Date</label>
                  <div className="position-relative form-group">
                    <input
                      name="date"
                      id="startDate"
                      placeholder="Start Date"
                      type="date"
                      className="form-control"
                      onChange={(event) => {
                        const startDate = event.target.value;
                        setPromodetails({ ...promodetails, ...{ startDate } });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlfor="startDate">End Date</label>
                  <div className="position-relative form-group">
                    <input
                      name="date"
                      id="endDate"
                      placeholder="End Date"
                      type="date"
                      className="form-control"
                      onChange={(event) => {
                        const endDate = event.target.value;
                        setPromodetails({ ...promodetails, ...{ endDate } });
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <label htmlFor="bonusValue">Bonus Value</label>
                  <input
                    name="bonusValue"
                    id="bonusValue"
                    placeholder="Bonus Value"
                    type="number"
                    min="1"
                    className="form-control"
                    onChange={(event) => {
                      const bonusValue = event.target.value;
                      setPromodetails({ ...promodetails, ...{ bonusValue } });
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShow}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={handleSubmit}
            disabled={addPromoLoading}
          >
            Add Promo
          </Button>
        </Modal.Footer>
      </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.loadingstate.isLoading,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
            createpromo: (promodetails, setNotify, successactiontype, failureactiontype, setShow, setAddPromoLoading) => {
                dispatch(CreateAction(promodetails, setNotify, successactiontype, failureactiontype, setShow, setAddPromoLoading)
            );
        },
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddPromoModal);
  