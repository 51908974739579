import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FetchAllCountry, ShowLoading } from "../redux/actions/authaction";
import { GetAction } from "../redux/actions/getaction";
import { GetSingleObjectAction } from "../redux/actions/getsoaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import DeleteAccountModal from "./modals/DeleteAccountModal";
import EditAccountModal from "./modals/EditAccountModal";
import $ from "jquery";
import SelectSearch, { fuzzySearch } from "react-select-search";

const Bankaccountsarea = (props) => {
  const {
    isLoading,
    setNotify,
    show,
    handleShow,
    setShow,
    fetchaccounts,
    fetchsingleaccount,
    allavailablebanks,
    fetchallcountry,
    allcountriesstate,
    addaccountLoading,
    setAddAccountLoading,
  } = props;

  const [banks, setBanks] = useState({});
  const countryarray = [];
  
  useEffect(() => {
    fetchallcountry(setNotify);
    fetchaccounts(
      show,
      setNotify,
      ActionTypes.GET_ALL_BANK_SUCCESS,
      ActionTypes.GET_ALL_BANK_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $("#examtable").DataTable({
        responsive: !0,
        destroy: true,
      });
    });
  }, [allavailablebanks]);

  useEffect(() => {
    if (allavailablebanks !== undefined) {
      setBanks(allavailablebanks);
    }
  }, [allavailablebanks]);

  useEffect(() => {
    if (!addaccountLoading) {
      fetchaccounts(
        show,
        setNotify,
        ActionTypes.GET_ALL_BANK_SUCCESS,
        ActionTypes.GET_ALL_BANK_FAIL,
        setShow
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addaccountLoading]);

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleShowEdit = () => {
    setShowEdit(!showEdit);
  };
  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  };

  const [item, setItem] = useState({});

  function handleEdit(item) {
    setShowEdit(true);
    setItem(item);
  }

  function handleDelete(item) {
    setShowDelete(true);
    setItem(item);
  }

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.alpha3Code;
      newitem.currency = item.currency;
      countryarray.push(newitem);
    }
  }

  const renderrow = (items) => {
    if (Object.keys(items).length !== 0) {
      return items.map((item, i) => {
        return (
          <>
            <tr>
              <td>{item.accountName}</td>
              <td>{item.country}</td>
              <td>{item.bankName}</td>
              <td>{item.bankAccountNumber}</td>
              <td>{item.bankSwiftCode}</td>
              <td className="d-flex flex-wrap p-2" style={{ gap: "8px" }}>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-dark"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </button>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-danger"
                  onClick={() => handleDelete(item)}
                >
                  Delete
                </button>
              </td>
              {/* <td><button className="mb-2 mr-2 btn btn-warning" onClick={() => handleEdit(item)}>Edit</button>
                        <button className="mb-2 mr-2 btn btn-danger" onClick={() => handleDelete(item)}>Delete</button></td> */}
            </tr>
          </>
        );
      });
    }
  };

  const handlereturnvalue = (cout) => {
    const _statecode = cout;
    fetchsingleaccount(
      _statecode,
      setNotify,
      ActionTypes.GET_BUSINESS_BANK_SUCCESS,
      ActionTypes.GET_BUSINESS_BANK_FAIL,
      setShow
    );
  };

  function refreshPage() {
    fetchaccounts(
      show,
      setNotify,
      ActionTypes.GET_BUSINESS_BANK_SUCCESS,
      ActionTypes.GET_BUSINESS_BANK_FAIL,
      setShow
    );
  }

  return (
    <>
      <div className="app-page-title app-page-title-simple">
        <div className="page-title-wrapper">
          <div className="page-title-heading" style={{ marginLeft: 16 }}>
            <div>
              <div className="page-title-head center-elem">
                <span className="d-inline-block">Bank Accounts Management</span>
              </div>
              <div className="page-title-subheading opacity-10">
                <nav className aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <span>Here you can manage all Bank Accounts</span>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-xl-12">
        <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
          <div className="widget-chat-wrapper-outer">
            <div className="widget-chart-content pt-3 pl-3 pb-1">
              <div className="form-row">
                <div className="ml-auto" style={{ marginRight: 15 }}>
                  <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                    <span className="text-success pl-2">
                      <button
                        className="btn-wide mb-2 mr-2 btn btn-shadow btn-danger btn-lg"
                        onClick={handleShow}
                      >
                        Create An Account
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-card mb-3 card">
        <div className="card-body">
          <table
            style={{ width: "100%" }}
            id="examtable"
            className="table table-hover table-striped table-bordered"
          >
            <thead style={{ textAlign: "center" }}>
              <tr>
                <th>Account Name</th>
                <th>Country</th>
                <th>Bank Name</th>
                <th>Bank Account Name</th>
                <th>Bank Swift Code</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {
                  renderrow(banks)
              }
            </tbody>
          </table>
        </div>
      </div>

      <EditAccountModal
        item={item}
        setNotify={setNotify}
        show={showEdit}
        handleEdit={handleEdit}
        setShow={setShowEdit}
        handleShowEdit={handleShowEdit}
        addaccountLoading={addaccountLoading}
        setAddAccountLoading={setAddAccountLoading}
      />
      <DeleteAccountModal
        item={item}
        setNotify={setNotify}
        show={showDelete}
        handleDelete={handleDelete}
        setShow={setShowDelete}
        handleShowDelete={handleShowDelete}
        addaccountLoading={addaccountLoading}
        setAddAccountLoading={setAddAccountLoading}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loadingstate.isLoading,
    allavailablebanks: state.allavailablebanks.allavailablebanks,
    allcountriesstate: state.allcountriesstate.allcountriesstate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchaccounts: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(ShowLoading(setNotify));
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchallcountry: (setNotify) => {
      dispatch(FetchAllCountry(setNotify));
    },
    fetchsingleaccount: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(ShowLoading(setNotify));
      dispatch(
        GetSingleObjectAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bankaccountsarea);
