import React, { useState, useEffect } from "react";
import AppHeader from "../components/AppHeader";
import AppSidebar from "../components/AppSidebar";
import Customerreferralarea from "../components/Customerreferralarea";
import Footer from "../components/Footer";
import AddReferralModal from "../components/modals/AddReferralModal";
import Referralarea from "../components/Referralarea";

export default function CustomerReferral(props) {
  const { activePage, setActivePage } = props;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [show, setShow] = useState(null);

  useEffect(() => {
    setActivePage("customerreferral");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShow = () => {
    setShow(!show);
  };

  const [addReferralLoading, setAddReferralLoading] = useState(false);

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
      <AppHeader notify={notify} setNotify={setNotify} />
      <div className="app-main">
        <AppSidebar activePage={activePage} setActivePage={setActivePage} />

        <div className="app-main__outer">
          <div className="app-main__inner">
            <Customerreferralarea
              show={show}
              notify={notify}
              setNotify={setNotify}
              handleShow={handleShow}
              setShow={setShow}
              addReferralLoading={addReferralLoading}
              setAddReferralLoading={setAddReferralLoading}
            />

            <Footer />
          </div>
        </div>
      </div>
      <div className="app-drawer-overlay d-none animated fadeIn" />
      <AddReferralModal
        notify={notify}
        setNotify={setNotify}
        show={show}
        handleShow={handleShow}
        setShow={setShow}
        addReferralLoading={addReferralLoading}
        setAddReferralLoading={setAddReferralLoading}
      />
    </div>
  );
}
