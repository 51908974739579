import ActionTypes from "../actiontype/ActionTypes";

const allpromos = [];

const allreferrals = [];

const walletbalance = {};

const allpurposestate = [];

const allusers = [];

const monthlyusers = [];

const countryusers = {};

const countrytransactions = {};

const pendingtransactions = {};

const singleuser = {};

const appsettings = {};

const austracreport = {};

const referraltransactions = [];

const adminreferraltransactions = [];

const getAdminReferralTransaction = () => {
  const auth = localStorage.getItem("adminreferraltransactions");
  try {
    if (auth != null) {
      const authobj = JSON.parse(auth);
      return authobj;
    } else {
      return adminreferraltransactions;
    }
  } catch (error) {
    return adminreferraltransactions;
  }
};

const getReferralTransactionState = () => {
  const auth = localStorage.getItem("referraltransactions");
  try {
    if (auth != null) {
      const authobj = JSON.parse(auth);
      return authobj;
    } else {
      return referraltransactions;
    }
  } catch (error) {
    return referraltransactions;
  }
};

const getWalletState = () => {
  const auth = localStorage.getItem("walletbalance");
  try {
    if (auth != null) {
      const authobj = JSON.parse(auth);
      return authobj;
    } else {
      return walletbalance;
    }
  } catch (error) {
    return walletbalance;
  }
};

const getPromoState = () => {
  const auth = localStorage.getItem("allpromos");
  try {
    if (auth != null) {
      const authobj = JSON.parse(auth);
      return authobj;
    } else {
      return allpromos;
    }
  } catch (error) {
    return allpromos;
  }
};

const getReferralState = () => {
  const auth = localStorage.getItem("allreferrals");
  try {
    if (auth != null) {
      const authobj = JSON.parse(auth);
      return authobj;
    } else {
      return allreferrals;
    }
  } catch (error) {
    return allreferrals;
  }
};

const getAllUserState = () => {
  const allus = localStorage.getItem("allusers");
  try {
    if (allus != null) {
      const authobj = JSON.parse(allus);
      return authobj;
    } else {
      return allusers;
    }
  } catch (error) {
    return allusers;
  }
};

const getAppSettings = () => {
  const appset = localStorage.getItem("appsettings");
  try {
    if (appset != null) {
      const authobj = JSON.parse(appset);
      return authobj;
    } else {
      return appsettings;
    }
  } catch (error) {
    return appsettings;
  }
};

const getMonthlyUsersState = () => {
  const monthusers = localStorage.getItem("monthlyusers");
  try {
    if (monthusers != null) {
      const authobj = JSON.parse(monthusers);
      return authobj;
    } else {
      return monthlyusers;
    }
  } catch (error) {
    return monthlyusers;
  }
};

const getPaymentPurpose = () => {
  const allpurpose = localStorage.getItem("allpurposestate");
  try {
    if (allpurpose != null) {
      const allpurposeobj = JSON.parse(allpurposestate);
      return allpurposeobj;
    } else {
      return allpurposestate;
    }
  } catch (error) {
    return allpurposestate;
  }
};

const existingPromo = getPromoState();
const existingReferral = getReferralState();
const existingPupose = getPaymentPurpose();
const existingUsers = getAllUserState();
const existingSettings = getAppSettings();
const existingWallet = getWalletState();
const existingTransactions = getReferralTransactionState();
const existingAdminReferrals = getAdminReferralTransaction();

const fetchadminreferralsreducer = (state = existingAdminReferrals, action) => {
  switch (action.type) {
    case ActionTypes.GET_REFERRAL_TRANSACTION_BY_ADMIN_SUCCESS:
      const fetchTransactions = {
        adminreferraltransactions: action.payload,
      };
      localStorage.setItem(
        "adminreferraltransactions",
        JSON.stringify(fetchTransactions)
      );
      return fetchTransactions;
    default:
      return state;
  }
};

const fetchcustomerreferralreducer = (state = existingTransactions, action) => {
  switch (action.type) {
    case ActionTypes.GET_REFERRAL_TRANSACTION_SUCCESS:
      const fetchTransactions = {
        referraltransactions: action.payload,
      };
      localStorage.setItem(
        "referraltransactions",
        JSON.stringify(fetchTransactions)
      );
      return fetchTransactions;
    default:
      return state;
  }
};

const fetchwalletreducer = (state = existingWallet, action) => {
  switch (action.type) {
    case ActionTypes.GET_WALLET_BALANCE_SUCCESS:
      const fetchWallet = {
        walletbalance: action.payload,
      };
      localStorage.setItem("walletbalance", JSON.stringify(fetchWallet));
      return fetchWallet;
    default:
      return state;
  }
};

const fetchpromosreducer = (state = existingPromo, action) => {
  switch (action.type) {
    case ActionTypes.GET_PROMOS_SUCCESS:
      const fetchPromos = {
        allpromos: action.payload,
      };
      localStorage.setItem("allpromos", JSON.stringify(fetchPromos));
      return fetchPromos;
    default:
      return state;
  }
};

const fetchreferralsreducer = (state = existingReferral, action) => {
  switch (action.type) {
    case ActionTypes.GET_REFERRAL_SUCCESS:
      const fetchReferrals = {
        allreferrals: action.payload,
      };
      localStorage.setItem("allreferrals", JSON.stringify(fetchReferrals));
      return fetchReferrals;
    default:
      return state;
  }
};

const fetchpurposesreducer = (state = existingPupose, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_PURPOSE_SUCCESS:
      const fetchPurpose = {
        allpurposestate: action.payload,
      };
      localStorage.setItem("allpurposestate", JSON.stringify(fetchPurpose));
      return fetchPurpose;
    default:
      return state;
  }
};

const fetchallusersreducer = (state = existingUsers, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_USERS_SUCCESS:
      const Fetchallusers = {
        allusers: action.payload,
      };
      localStorage.setItem("allusers", JSON.stringify(Fetchallusers));
      return Fetchallusers;
    default:
      return state;
  }
};

const fetchusersmonthlyreducer = (state = getMonthlyUsersState, action) => {
  switch (action.type) {
    case ActionTypes.GET_MONTHLY_USERS_SUCCESS:
      const Fetchmoutusers = {
        monthlyusers: action.payload,
      };
      localStorage.setItem("monthlyusers", JSON.stringify(Fetchmoutusers));
      return Fetchmoutusers;
    default:
      return state;
  }
};

const fetchcountryusersreducer = (state = countryusers, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_USERS_BY_COUNTRY_SUCCESS:
      const Fetchcountryusers = {
        countryusers: action.payload + " Users",
      };
      return Fetchcountryusers;
    default:
      return state;
  }
};

const fetchcountrytransactionsreducer = (
  state = countrytransactions,
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_TRANSACTIONS_BY_COUNTRY_SUCCESS:
      const FetchcountryTransactions = {
        countrytransactions: action.payload + " Transactions",
      };
      return FetchcountryTransactions;
    default:
      return state;
  }
};

const fetchpendingtransactionssreducer = (
  state = pendingtransactions,
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_PENDING_TRANSACTIONS_SUCCESS:
      const PendingTransaction = {
        pendingtransactions: action.payload,
      };
      return PendingTransaction;
    default:
      return state;
  }
};

const fetchsingleuserreducer = (state = singleuser, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_SINGLE_USER_SUCCESS:
      const FetchSingleUSerTransactions = {
        singleuser: action.payload,
      };
      return FetchSingleUSerTransactions;
    default:
      return state;
  }
};

const fetchaustracreportreducer = (state = austracreport, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_AUSTRAC_SUCCESS:
      const Fetchaustrac = {
        austracreport: action.payload,
      };
      return Fetchaustrac;
    default:
      return state;
  }
};

const fetchappsettingsreducer = (state = existingSettings, action) => {
  switch (action.type) {
    case ActionTypes.GET_APP_SETTINGS_SUCCESS:
      const GetAppSet = {
        appsettings: action.payload,
      };
      return GetAppSet;
    default:
      return state;
  }
};

export {
  fetchpromosreducer,
  fetchpurposesreducer,
  fetchallusersreducer,
  fetchaustracreportreducer,
  fetchcountryusersreducer,
  fetchusersmonthlyreducer,
  fetchcountrytransactionsreducer,
  fetchsingleuserreducer,
  fetchpendingtransactionssreducer,
  fetchappsettingsreducer,
  fetchreferralsreducer,
  fetchwalletreducer,
  fetchcustomerreferralreducer,
  fetchadminreferralsreducer,
};
