import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

    const ViewRateModal = (props) => {
      const { show, item, handleShowView } = props;

      useEffect(() => {}, [show]);

      const processRateType = (type) => {
        if (type === 0) return "Live Rate";
        else if (type === 1) return "Transaction Rate";
      };

      return (
        <Modal
          show={show}
          onHide={handleShowView}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div className="col-md-12" style={{ marginTop: 15 }}>
              <div className="card-shadow-primary profile-responsive card-border mb-7 card">
                <div className="dropdown-menu-header"></div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Rate Type</div>
                          <div className="widget-subheading">
                            {processRateType(item.rateType)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            Country Description
                          </div>
                          <div className="widget-subheading">
                            {item.countryDesc}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            Send Country Code
                          </div>
                          <div className="widget-subheading">
                            {item.sendCountryCode}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            Receive Country Code
                          </div>
                          <div className="widget-subheading">
                            {item.receiveCountryCode}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Amount (Local)</div>
                          <div className="widget-subheading">
                            {item.amount_Local}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Amount USD</div>
                          <div className="widget-subheading">
                            {item.amount_USD}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Active Status</div>
                          <div className="widget-subheading">
                            {item.isActive}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleShowView}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }

const mapStateToProps = (state) => {
    return {
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
            
        }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ViewRateModal);
  