import React, { useState, useEffect } from "react";
import {
  FetchAllCountryCodes,
  FetchStatesByCountryCode,
} from "../../redux/actions/authaction";
import { connect } from "react-redux";
import ActionTypes from "../../redux/actiontype/ActionTypes";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { PatchAction } from "../../redux/actions/patchaction";
import SelectSearch, { fuzzySearch } from "react-select-search";

function capitalize(word) {
  if (!word) return "";
  return word[0].toUpperCase() + word.substring(1).toLowerCase();
}

const EditBeneficiaryModal = (props) => {
  const {
    edittransaction,
    setNotify,
    show,
    setShow,
    item,
    handleShowEdit,
    beneficiaryLoading,
    setBeneficiaryLoading,
    allcountriesstate,
    allcountriescodestate,
    fetchallcountrycodes,
    authstate,
  } = props;
  const [userdetails, setUserdetails] = useState({});
  const [submitError, setSubmitError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitError("");
    if (
      userdetails.bankName !== undefined &&
      userdetails.accountName !== undefined &&
      userdetails.accountNumber !== undefined &&
      userdetails.accountCurrency !== undefined &&
      userdetails.accountSWiftCode !== undefined &&
      userdetails.bankAddress !== undefined &&
      userdetails.beneficiaryCountry !== undefined &&
      userdetails.beneficiaryAddress !== undefined &&
      userdetails.customerId !== undefined &&
      userdetails.bankCountry !== undefined
    ) {
      setBeneficiaryLoading(true);
      edittransaction(
        userdetails,
        item.id,
        setBeneficiaryLoading,
        setNotify,
        ActionTypes.EDIT_BENEFICARY_SUCCESS,
        ActionTypes.EDIT_BENEFICARY_FAIL,
        setShow
      );
    } else {
      // setNotify({
      //   isOpen: true,
      //   message:,
      //   type: "error",
      // });
      setSubmitError("kindly fill all required fields");
    }
  };

  useEffect(() => {
    if (Object.keys(item).length !== 0) {
      let id = item.id;
      let accountBSBCode = item.accountBSBCode;
      let accountCurrency = item.accountCurrency;
      let accountName = item.accountName;
      let accountNumber = item.accountNumber;
      let beneficiaryAddress = item.beneficiaryAddress;
      let beneficiaryCountry = item.beneficiaryCountry;
      let accountSWiftCode = item.accountSWiftCode;
      let bankAddress = item.bankAddress;
      let bankCity = item.bankCity;
      let bankName = item.bankName;
      let bankPostalCode = item.bankPostalCode;
      let bankState = item.bankState;
      let corresBankCountry = item.corresBankCountry;
      let corresBankIBAN = item.corresBankIBAN;
      let corresBankName = item.corresBankName;
      let corresAccountName = item.corresAccountName;
      let bankCountry = item.bankCountry;
      let customerId = item.customerId;
      let bankIdentifier = item.bankIdentifier;
      let bankIdentifierCode = item.bankIdentifierCode;

      setUserdetails({
        ...userdetails,
        ...{
          id,
          accountBSBCode,
          accountCurrency,
          accountName,
          accountNumber,
          beneficiaryAddress,
          beneficiaryCountry,
          accountSWiftCode,
          bankAddress,
          bankCity,
          bankName,
          bankPostalCode,
          bankState,
          corresBankCountry,
          corresBankIBAN,
          corresBankName,
          corresAccountName,
          bankIdentifier,
          bankIdentifierCode,
          bankCountry,
          customerId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const [error, setError] = useState({
    hasError: false,
    message: "",
  });

  useEffect(() => {
    setSubmitError("");
    fetchallcountrycodes(setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handlesendingfrom = (cout) => {
  //   const country = cout.substring(cout.length - 3, cout.length);
  //   setUserdetails({ ...userdetails, ...{ country } });
  // };

  // const handlesendingfrom2 = (cout) => {
  //   const corresBankCountry = cout.substring(cout.length - 3, cout.length);
  //   setUserdetails({ ...userdetails, ...{ corresBankCountry } });
  // };

  const allcountriesarray = [];

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.alpha3Code;
      newitem.currency = item.currency;
      allcountriesarray.push(newitem);
    }
  }
  const countryarray = [];

  if (allcountriescodestate) {
    for (var kk = 0; kk < allcountriescodestate.length; kk++) {
      let item = allcountriescodestate[kk];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.country;
      countryarray.push(newitem);
    }
  }

  const handlereceivingfrom = (cout) => {
    let beneficiaryCountry = cout;

    const accountCurrency = allcountriesarray.find(
      (k) => k.value === cout
    )?.currency;

    let customerId = authstate.nameid;
    setUserdetails({
      ...userdetails,
      ...{ accountCurrency, customerId, beneficiaryCountry },
    });
  };

  const handlereceivingfrom2 = (cout) => {
    const bankCountry = cout;
    setUserdetails({
      ...userdetails,
      ...{ bankCountry },
    });
  };

  useEffect(() => {}, [show]);

  return (
    <Modal
      show={show}
      onHide={handleShowEdit}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Beneficary Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form autoComplete="off">
          <div className="col-md-12 position-relative form-group">
            <div className="card-title">Enter beneficiary details</div>
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="accountName">Beneficiary Name *</label>
                <input
                  name="accountName"
                  id="accountName"
                  placeholder={item.accountName}
                  defaultValue={item.accountName}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const accountName = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ accountName },
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="beneficiaryAddress">
                  Beneficiary Address *
                </label>
                <input
                  name="beneficiaryAddress"
                  id="beneficiaryAddress"
                  placeholder={item.beneficiaryAddress}
                  defaultValue={item.beneficiaryAddress}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const beneficiaryAddress = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ beneficiaryAddress },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="bankName">Beneficiary Country *</label>
                <SelectSearch
                  // style={{ display: "none" }}
                  autoComplete="off"
                  options={allcountriesarray}
                  value={item.beneficiaryCountry}
                  search={true}
                  onChange={handlereceivingfrom}
                  filterOptions={fuzzySearch}
                  // placeholder="Select Country"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="accountNumber">Account Num / IBAN *</label>
                <input
                  name="accountNumber"
                  id="accountNumber"
                  placeholder={item.accountNumber}
                  defaultValue={item.accountNumber}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const accountNumber = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ accountNumber },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="card-title">enter beneficiary bank details</div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="bankName">Bank Name *</label>
                <input
                  name="bankName"
                  id="bankName"
                  placeholder={item.bankName}
                  defaultValue={item.bankName}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const bankName = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ bankName },
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="bankAddress">Bank Address *</label>
                <input
                  name="bankAddress"
                  id="bankAddress"
                  placeholder={item.bankAddress}
                  defaultValue={item.bankAddress}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const bankAddress = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ bankAddress },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="accountSWiftCode">SWIFT *</label>
                <input
                  name="accountSWiftCode"
                  id="accountSWiftCode"
                  placeholder={item.accountSWiftCode}
                  defaultValue={item.accountSWiftCode}
                  style={{
                    textTransform: "uppercase",
                  }}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const accountSWiftCode = event.target.value.toUpperCase();
                    setUserdetails({
                      ...userdetails,
                      ...{ accountSWiftCode },
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="country">Bank Country *</label>
                <SelectSearch
                  // style={{ display: "none" }}
                  autoComplete="off"
                  options={allcountriesarray}
                  value={item.bankCountry}
                  search={true}
                  onChange={handlereceivingfrom2}
                  filterOptions={fuzzySearch}
                  placeholder="Select"
                />
              </div>
            </div>
          </div>

          <div className="card-title">
            Corresponding bank details (Optional)
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlfor="exampleGender">Bank Name</label>

                <input
                  name="corresBankName"
                  id="corresBankName"
                  placeholder={item.corresBankName}
                  defaultValue={item.corresBankName}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const corresBankName = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ corresBankName },
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="exampleGender">Bank Address</label>

                <input
                  name="corresBankAddress"
                  id="corresBankAddress"
                  placeholder={item.corresBankAddress}
                  defaultValue={item.corresBankAddress}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const corresBankAddress = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ corresBankAddress },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="exampleName">Bank Identifier (ABA, BSB)</label>
                <select
                  type="select"
                  id="gender"
                  name="gender"
                  className="mb-2 form-control"
                  defaultValue={item.bankIdentifier}
                  onChange={(event) => {
                    const bankIdentifier = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ bankIdentifier },
                    });
                  }}
                >
                  <option>-- Select --</option>
                  <option value="ABA/Fed Wire/Routing No">
                    ABA/Fed Wire/Routing No
                  </option>
                  <option value="BSB">BSB</option>
                  <option value="Chips Number">Chips Number</option>
                  <option value="IFSC code">IFSC code </option>
                  <option value="Sort code">Sort code</option>
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="exampleGender">Bank Identifier Code</label>
                <input
                  name="amount"
                  id="exampleAmount"
                  placeholder={item.bankIdentifierCode}
                  defaultValue={item.bankIdentifierCode}
                  type="text"
                  style={{
                    textTransform: "uppercase",
                  }}
                  className="form-control"
                  onChange={(event) => {
                    const bankIdentifierCode = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ bankIdentifierCode },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="corresBankName">Account Name</label>
                <input
                  name="corresAccountName"
                  id="corresAccountName"
                  placeholder={item.corresAccountName}
                  defaultValue={item.corresAccountName}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const corresAccountName = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ corresAccountName },
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="corresBankIBAN">Account Number</label>
                <input
                  name="corresBankIBAN"
                  id="corresBankIBAN"
                  placeholder={item.corresBankIBAN}
                  defaultValue={item.corresBankIBAN}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const corresBankIBAN = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ corresBankIBAN },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </form>
        {submitError && (
          <h5
            className="text-danger my-3 text-center"
            style={{ justifySelf: "flex-start" }}
          >
            *{submitError}
          </h5>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShowEdit}>
          Close
        </Button>
        <Button
          variant="danger"
          disabled={beneficiaryLoading}
          onClick={handleSubmit}
        >
          Update Beneficary Details
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    allcountriesstate: state.allcountriesstate.allcountriesstate,
    allcountriescodestate: state.allcountriescodestate.allcountriescodestate,
    authstate: state.authstate.authstate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchallcountrystates: (setError, statecode) => {
      dispatch(FetchStatesByCountryCode(setError, statecode));
    },
    fetchallcountrycodes: (setError) => {
      dispatch(FetchAllCountryCodes(setError));
    },
    edittransaction: (
      fileupload,
      id,
      setLoading,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        PatchAction(
          fileupload,
          id,
          setLoading,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBeneficiaryModal);
