import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ShowLoading } from "../redux/actions/authaction";
import { GetAction } from "../redux/actions/getaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import DeletePromoModal from "./modals/DeletePromoModal";
import EditPromoModal from "./modals/EditPromoModal";
import $ from "jquery";
import DataTable from "datatables.net";
import { ExportCSV } from "../utils/excelExport";

const Promoarea = (props) => {
  const {
    setNotify,
    show,
    handleShow,
    setShow,
    fetchpromos,
    fetchpromotransactions,
    promotransactions,
    allpromos,
    addPromoLoading,
    setAddPromoLoading,
  } = props;
  const [promos, setPromos] = useState({});

  useEffect(() => {
    fetchpromos(
      show,
      setNotify,
      ActionTypes.GET_PROMOS_SUCCESS,
      ActionTypes.GET_PROMOS_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $("#examtable").DataTable({
        responsive: !0,
        destroy: true,
      });
    });
  }, []);

  //this is to check for changes in addpromoloading variable
  useEffect(() => {
    if (!addPromoLoading) {
      fetchpromos(
        show,
        setNotify,
        ActionTypes.GET_PROMOS_SUCCESS,
        ActionTypes.GET_PROMOS_FAIL,
        setShow
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPromoLoading]);

  useEffect(() => {
    if (allpromos !== undefined) {
      setPromos(allpromos);
    }
  }, [allpromos]);

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDownload, setShowDownload] = useState(false);

  const handleShowEdit = () => {
    setShowEdit(!showEdit);
  };
  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  };

  const [item, setItem] = useState({});

  function handleEdit(item) {
    setShowEdit(true);
    setItem(item);
  }

  function handleDelete(item) {
    setShowDelete(true);
    setItem(item);
  }

  function handleDownload(item) {
    setShowDownload(true);
    fetchpromotransactions(
      item.id,
      setNotify,
      ActionTypes.FETCH_PROMO_TRANSACTION_SUCCESS,
      ActionTypes.FETCH_PROMO_TRANSACTION_FAIL,
      setShowDownload
    );
  }

  useEffect(() => {
    if (showDownload) {
      if (promotransactions !== undefined) {
        let filename = "Promo Transactions";
        ExportCSV({ csvData: promotransactions, fileName: filename });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotransactions]);

  const processStatus = (type) => {
    if (type === true) return "Active";
    else if (type === false) return "In-Active";
    else return "Others";
  };

  const renderrow = (items) => {
    if (Object.keys(items).length !== 0) {
      return items.map((item, i) => {
        return (
          <>
            <tr key={i}>
              <td>{item.discountCode}</td>
              <td>{item.title}</td>
              <td>{item.bonusValue}</td>
              <td>{processStatus(item.isActive)}</td>
              <td>{item.startDate.substring(0, 10)}</td>
              <td>{item.endDate.substring(0, 10)}</td>
              <td className="d-flex flex-wrap p-2" style={{ gap: "8px" }}>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-dark"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </button>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-danger"
                  onClick={() => handleDelete(item)}
                >
                  Delete
                </button>
              </td>
              <td>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-danger"
                  onClick={() => handleDownload(item)}
                >
                  Download Transactions
                </button>
              </td>
            </tr>
          </>
        );
      });
    }
  };

  return (
    <>
      <div className="app-page-title app-page-title-simple">
        <div className="page-title-wrapper">
          <div className="page-title-heading" style={{ marginLeft: 16 }}>
            <div>
              <div className="page-title-head center-elem">
                <span className="d-inline-block">Promo Management</span>
              </div>
              <div className="page-title-subheading opacity-10">
                <nav className aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <span>Here you can manage all your promos</span>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-xl-12">
        <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
          <div className="widget-chat-wrapper-outer">
            <div className="widget-chart-content pt-3 pl-3 pb-1">
              <div className="form-row">
                <div className="ml-auto" style={{ marginRight: 15 }}>
                  <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                    <span className="text-success pl-2">
                      <button
                        className="btn-wide mb-2 mr-2 btn btn-shadow btn-danger btn-lg"
                        onClick={handleShow}
                      >
                        Create Promo
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-card mb-3 card">
        <div className="card-body">
          <table
            style={{ width: "100%" }}
            id="examtable"
            className="table table-hover table-striped table-bordered"
          >
            <thead style={{ textAlign: "center" }}>
              <tr>
                <th>Discount Code</th>
                <th>Title</th>
                <th>Bonus Value</th>
                <th>Status</th>
                <td>
                  <b>Start Date</b>
                </td>
                <td>
                  <b>End Date</b>
                </td>
                <th>Action</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>{renderrow(promos)}</tbody>
          </table>
        </div>
      </div>

      <EditPromoModal
        item={item}
        setNotify={setNotify}
        show={showEdit}
        handleEdit={handleEdit}
        setShow={setShowEdit}
        handleShowEdit={handleShowEdit}
        addPromoLoading={addPromoLoading}
        setAddPromoLoading={setAddPromoLoading}
      />
      <DeletePromoModal
        item={item}
        setNotify={setNotify}
        show={showDelete}
        handleDelete={handleDelete}
        setShow={setShowDelete}
        handleShowDelete={handleShowDelete}
        addPromoLoading={addPromoLoading}
        setAddPromoLoading={setAddPromoLoading}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allpromos: state.allpromos.allpromos,
    promotransactions: state.promotransactions.promotransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchpromos: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(ShowLoading(setNotify));
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchpromotransactions: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Promoarea);
