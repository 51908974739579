import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { FetchAllCountry } from "../redux/actions/authaction";
import { EditAction } from "../redux/actions/editaction";
import { GetSingleObjectAction } from "../redux/actions/getsoaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import backgroundImage from "../assets/images/water.png";
import { GetAction } from "../redux/actions/getaction";
import $ from "jquery";
import DataTable from "datatables.net";

const Myaccountarea = (props) => {
  const {
    setNotify,
    setShow,
    fetchprofiledetails,
    userprofilestate,
    authstate,
    editaccount,
    allcountriesstate,
    fetchallcountry,
    fetchbalance,
    fetchreferraltransactions,
  } = props;
  const [userprofile, setUserProfile] = useState({});
  const [accountdetails, setAccountdetails] = useState({});
  const [accountLoading, setAccountLoading] = useState(false);

  useEffect(() => {
    fetchallcountry(setNotify);
    let customerId = authstate.nameid;
    fetchprofiledetails(
      customerId,
      setNotify,
      ActionTypes.FETCH_USER_PROFILE_SUCCESS,
      ActionTypes.FETCH_USER_PROFILE_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let firstobject = userprofilestate[0];

    console.log(
      "bankIdentifier",
      firstobject.customerBankAccount.bankIdentifier
    );
    setUserProfile(firstobject);

    if (firstobject.customerBankAccount !== null) {
      const accSwiftCode = firstobject.customerBankAccount.accSwiftCode;
      const accountName = firstobject.customerBankAccount.accountName;
      const accNumber = firstobject.customerBankAccount.accNumber;
      const accBankName = firstobject.customerBankAccount.accBankName;
      const accCountry = firstobject.customerBankAccount.accCountry;
      const bankIdentifier = firstobject.customerBankAccount.bankIdentifier;
      const bankIdentifierCode =
        firstobject.customerBankAccount.bankIdentifierCode;
      const id = firstobject.customerBankAccount.id;
      let customerId = authstate.nameid;
      setAccountdetails({
        ...accountdetails,
        ...{
          accSwiftCode,
          accountName,
          accNumber,
          accBankName,
          accCountry,
          bankIdentifier,
          bankIdentifierCode,
          id,
          customerId,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userprofilestate, userprofile]);

  useEffect(() => {
    let customerId = authstate?.nameid;
    fetchbalance(
      customerId,
      setNotify,
      ActionTypes.GET_WALLET_BALANCE_SUCCESS,
      ActionTypes.GET_WALLET_BALANCE_FAIL,
      setShow
    );

    fetchreferraltransactions(
      customerId,
      setNotify,
      ActionTypes.GET_REFERRAL_TRANSACTION_SUCCESS,
      ActionTypes.GET_REFERRAL_TRANSACTION_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $("#examtable").DataTable({
        responsive: !0,
        destroy: true,
      });
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    editaccount(
      accountdetails,
      setNotify,
      ActionTypes.EDIT_BANK_SUCCESS,
      ActionTypes.EDIT_BANK_FAIL,
      setShow,
      setAccountLoading
    );
  };

  const handlesendingfrom = (cout) => {
    const countryCode = allcountriesarray.find(
      (c) => c.value === cout
    )?.alpha3Code;
    const country = cout;
    setAccountdetails({
      ...accountdetails,
      ...{ accCountryCode: countryCode, accCountry: country },
    });
  };

  function copyFunction(prop) {
    /* Get the text field */
    var copyText = document.getElementById(prop);

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    alert("Text Copied: " + copyText.value);
  }

  const allcountriesarray = [];

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.country;
      newitem.currency = item.currency;
      allcountriesarray.push(newitem);
    }
  }

  return (
    <>
      <section
        style={{
          backgroundImage: `url("${backgroundImage}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPositionY: "center",
          backgroundPositionX: "calc(15% + 310px)",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="app-page-title app-page-title-simple">
          <div className="page-title-wrapper">
            <div className="page-title-heading" style={{ marginLeft: 16 }}>
              <div>
                <div className="page-title-head center-elem">
                  <span className="d-inline-block">
                    <h2>My Account</h2>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Profile Account Details</h5>
                <form className>
                  <div className="position-relative form-group">
                    <label htmlFor="exampleAccountType">Account Type</label>
                    <input
                      name="exampleAccountType"
                      defaultValue={userprofile.accountType}
                      readOnly
                      id="exampleAccountType"
                      placeholder="Account Type"
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="position-relative form-group">
                    <label htmlFor="exampleAccountRole">Account Role</label>
                    <input
                      name="exampleAccountRole"
                      defaultValue={userprofile.userRole}
                      readOnly
                      id="exampleAccountRole"
                      placeholder="Account Role"
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="position-relative form-group">
                    <label htmlFor="exampleEmail">Email Address</label>
                    <input
                      name="exampleEmail"
                      readOnly
                      defaultValue={userprofile.email}
                      id="exampleEmail"
                      type="email"
                      placeholder="Email Address"
                      className="form-control"
                    />
                  </div>

                  <div className="position-relative form-group">
                    <label htmlFor="exampleFirstname">First Name</label>
                    <input
                      readOnly
                      name="exampleFirstname"
                      defaultValue={userprofile.firstName}
                      id="exampleFirstname"
                      placeholder="First Name"
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="position-relative form-group">
                    <label htmlFor="exampleLastname">Last Name</label>
                    <input
                      readOnly
                      name="exampleLastname"
                      defaultValue={userprofile.lastName}
                      id="exampleLastname"
                      placeholder="Last Name"
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="position-relative form-group">
                    <label htmlFor="examplePhone">Phone Number</label>
                    <input
                      name="examplePhone"
                      defaultValue={userprofile.phoneNumber}
                      id="examplePhone"
                      placeholder="Phone Number"
                      type="tel"
                      maxLength={11}
                      className="form-control"
                    />
                  </div>

                  <div className="form-row">
                    <div className="position-relative form-group col-md-6">
                      <label htmlFor="exampleGender">Gender</label>
                      <input
                        name="exampleGender"
                        defaultValue={userprofile.gender}
                        id="exampleGender"
                        placeholder="Gender"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="position-relative form-group col-md-6">
                      <label htmlFor="exampleDOB">D.O.B</label>
                      {/* .substring(0, 10) */}
                      <input
                        readOnly
                        name="exampleDOB"
                        defaultValue={userprofile.dateOfBirth?.substring(0, 10)}
                        id="exampleDOB"
                        placeholder="Date of Birth"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Account Details</h5>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="position-relative form-group">
                    <label htmlFor="examplePin">PIN</label>
                    <input
                      name="examplePin"
                      defaultValue={userprofile.pin}
                      id="examplePin"
                      placeholder="Pin"
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="form-row">
                    <div className="position-relative form-group col-md-8">
                      <label htmlFor="refCode">Referral Code</label>
                      <input
                        readOnly
                        name="refCode"
                        defaultValue={userprofile.referralDiscountCode}
                        id="refCode"
                        type="text"
                        className="form-control"
                      />
                    </div>

                    <div className="position-relative form-group col-md-4">
                      <label htmlfor="exampleDate"></label>
                      <div
                        className="widget-title ml-auto font-size-lg font-weight-normal text-muted"
                        style={{ marginTop: 7 }}
                      >
                        <span className="text-success pl-2">
                          <button
                            className="btn mb-2 mr-2 btn-shadow btn-danger btn-lg"
                            onClick={() => {
                              copyFunction("refCode");
                            }}
                          >
                            Copy
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="position-relative form-group col-md-8">
                      <label htmlFor="resetKey">Reset Key</label>
                      <input
                        readOnly
                        name="resetKey"
                        defaultValue={userprofile.resetKey}
                        id="resetKey"
                        type="text"
                        className="form-control"
                      />
                    </div>

                    <div className="position-relative form-group col-md-4">
                      <label htmlfor="exampleDate"></label>
                      <div
                        className="widget-title ml-auto font-size-lg font-weight-normal text-muted"
                        style={{ marginTop: 7 }}
                      >
                        <span className="text-success pl-2">
                          <button
                            className="btn mb-2 mr-2 btn-shadow btn-danger btn-lg"
                            onClick={() => {
                              copyFunction("resetKey");
                            }}
                          >
                            Copy
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  {(() => {
                    if (userprofile.accountType !== "Individual-Account") {
                      return (
                        <div className="position-relative form-group">
                          <label htmlFor="examplebusinessName">
                            Business Name
                          </label>
                          <input
                            name="examplebusinessName"
                            defaultValue={userprofile.businessName}
                            id="examplebusinessName"
                            placeholder="Business Name"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      );
                    } else {
                    }
                  })()}

                  {(() => {
                    if (userprofile.accountType !== "Individual-Account") {
                      return (
                        <div className="position-relative form-group">
                          <label htmlFor="examplebusinessRegNumber">
                            Business Reg. Number
                          </label>
                          <input
                            name="examplebusinessRegNumber"
                            defaultValue={userprofile.businessRegNumber}
                            id="examplebusinessRegNumber"
                            placeholder="Business Reg. Number"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      );
                    } else {
                    }
                  })()}
                </form>
              </div>
            </div>
            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Contact details</h5>
                <form onSubmit={(e) => e.preventDefault()}>
                  <fieldset className="position-relative form-group">
                    <div className="row">
                      <div className="position-relative form-group col-md-6">
                        <label htmlFor="exampleAddress">Address</label>
                        <input
                          readOnly
                          defaultValue={userprofile.address}
                          name="exampleAddress"
                          id="exampleAddress"
                          type="text"
                          className="form-control"
                        />
                      </div>

                      <div className="position-relative form-group col-md-6">
                        <label htmlFor="examplepostalCode">Postal Code</label>
                        <input
                          name="examplepostalCode"
                          id="examplepostalCode"
                          defaultValue={userprofile.postalCode}
                          placeholder="Postal Code"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="position-relative form-group col-md-4">
                        <label htmlFor="exampleCity">City</label>
                        <input
                          name="exampleCity"
                          defaultValue={userprofile.city}
                          id="exampleCity"
                          placeholder="City"
                          type="text"
                          className="form-control"
                        />
                      </div>

                      <div className="position-relative form-group col-md-4">
                        <label htmlFor="exampleState">State</label>
                        <input
                          name="exampleState"
                          defaultValue={userprofile.state}
                          id="exampleState"
                          placeholder="State"
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="position-relative form-group col-md-4">
                        <label htmlFor="exampleCountry">Country</label>
                        <input
                          name="exampleCountry"
                          id="exampleCountry"
                          defaultValue={userprofile.country}
                          placeholder="Country"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-xl-12">
          <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
            <div className="widget-chat-wrapper-outer">
              <div className="widget-chart-content pt-3 pl-3 pb-1">
                <div className="widget-chart-flex">
                  <div className="widget-numbers">
                    <div className="widget-chart-flex">
                      <div className="fsize-4">
                        <h5 className="card-title">
                          My Bank Account For Compensation
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-12 position-relative form-group">
                    <div className="form-row">
                      <div className="col-md-4">
                        <label htmlfor="accountName">Account Name</label>
                        <div className="position-relative form-group">
                          {/* defaultValue={userprofile.customerBankAccount.accountName} */}
                          <input
                            name="accountName"
                            id="accountName"
                            defaultValue={
                              userprofile.customerBankAccount?.accountName
                            }
                            placeholder="Account Name"
                            type="text"
                            className="form-control"
                            onChange={(event) => {
                              const accountName = event.target.value;
                              setAccountdetails({
                                ...accountdetails,
                                ...{ accountName },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="accBankName">Account Bank Name</label>
                        {/* defaultValue={userprofile.customerBankAccount.accBankName} */}
                        <input
                          name="accBankName"
                          defaultValue={
                            userprofile.customerBankAccount?.accBankName
                          }
                          id="accBankName"
                          placeholder="Account Bank Name"
                          type="text"
                          className="form-control"
                          onChange={(event) => {
                            const accBankName = event.target.value;
                            setAccountdetails({
                              ...accountdetails,
                              ...{ accBankName },
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlfor="accNumber">Account Number / IBAN</label>
                        <div className="position-relative form-group">
                          {/* defaultValue={userprofile.customerBankAccount.accNumber} */}
                          <input
                            name="accNumber"
                            defaultValue={
                              userprofile.customerBankAccount?.accNumber
                            }
                            id="accNumber"
                            placeholder="Account Number / IBAN"
                            type="number"
                            className="form-control"
                            onChange={(event) => {
                              const accNumber = event.target.value;
                              setAccountdetails({
                                ...accountdetails,
                                ...{ accNumber },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="accSwiftCode">Account Swift Code</label>
                        <input
                          name="accSwiftCode"
                          id="accSwiftCode"
                          defaultValue={
                            userprofile.customerBankAccount?.accSwiftCode
                          }
                          placeholder="Account Swift Code"
                          type="text"
                          className="form-control"
                          onChange={(event) => {
                            const accSwiftCode = event.target.value;
                            setAccountdetails({
                              ...accountdetails,
                              ...{ accSwiftCode },
                            });
                          }}
                        />
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="exampleName">
                          Bank Identifier (ABA, BSB)
                        </label>
                        <select
                          type="select"
                          id="gender"
                          name="gender"
                          className="mb-2 form-control"
                          defaultValue={
                            userprofile.customerBankAccount?.bankIdentifier
                          }
                          onChange={(event) => {
                            const bankIdentifier = event.target.value;
                            setAccountdetails({
                              ...accountdetails,
                              ...{ bankIdentifier },
                            });
                          }}
                        >
                          <option>
                            {userprofile.customerBankAccount?.bankIdentifier ||
                              "-- Select --"}
                          </option>
                          <option value="ABA/Fed Wire/Routing No">
                            ABA/Fed Wire/Routing No
                          </option>
                          <option value="BSB">BSB</option>
                          <option value="Chips Number">Chips Number</option>
                          <option value="IFSC code">IFSC code </option>
                          <option value="Sort code">Sort code</option>
                        </select>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="exampleGender">
                          Bank Identifier Code
                        </label>
                        <input
                          name="amount"
                          id="exampleAmount"
                          defaultValue={
                            userprofile.customerBankAccount?.bankIdentifierCode
                          }
                          type="text"
                          style={{
                            textTransform: "uppercase",
                          }}
                          className="form-control"
                          onChange={(event) => {
                            const bankIdentifierCode = event.target.value;
                            setAccountdetails({
                              ...accountdetails,
                              ...{ bankIdentifierCode },
                            });
                          }}
                        />
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="exampleGender">Account Country</label>

                        <SelectSearch
                          options={allcountriesarray}
                          search={true}
                          value={userprofile.customerBankAccount?.accCountry}
                          onChange={handlesendingfrom}
                          filterOptions={fuzzySearch}
                          placeholder="Choose your country"
                        />
                      </div>

                      <div className="col-md-4" style={{ marginLeft: 0 }}>
                        <label htmlfor="exampleDate"></label>
                        <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                          <span className="text-success pl-2">
                            <button
                              className="btn-wide mb-2 mr-2 btn btn-shadow btn-danger btn-lg"
                              onClick={handleSubmit}
                            >
                              Update Acct Details
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userprofilestate: state.userprofilestate.userprofilestate,
    authstate: state.authstate.authstate,
    allcountriesstate: state.allcountriesstate.allcountriesstate,
    walletbalance: state.walletbalance.walletbalance,
    referraltransactions: state.referraltransactions.referraltransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchprofiledetails: (
      customerId,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetSingleObjectAction(
          customerId,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    editaccount: (
      accountdetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setAccountLoading
    ) => {
      dispatch(
        EditAction(
          accountdetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setAccountLoading
        )
      );
    },
    fetchallcountry: (setError) => {
      dispatch(FetchAllCountry(setError));
    },
    fetchbalance: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchreferraltransactions: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Myaccountarea);
