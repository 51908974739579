import ActionTypes from "../actiontype/ActionTypes";

const allcountriesstate = [];

const allcountriescodestate = [];

const allcountries = [];

const getCountryCode = () => {
  const auth = localStorage.getItem("allcountriescodestate");
  try {
    if (auth != null) {
      const authobj = JSON.parse(auth);
      return authobj;
    } else {
      return allcountriescodestate;
    }
  } catch (error) {
    return allcountriescodestate;
  }
};
const getCountries = () => {
  const auth = localStorage.getItem("allcountriesstate");
  try {
    if (auth != null) {
      const authobj = JSON.parse(auth);
      return authobj;
    } else {
      return allcountriesstate;
    }
  } catch (error) {
    return allcountriesstate;
  }
};

const getAllCountries = () => {
  const auth = localStorage.getItem("allcountries");
  try {
    if (auth != null) {
      const authobj = JSON.parse(auth);
      return authobj;
    } else {
      return allcountries;
    }
  } catch (error) {
    return allcountries;
  }
};

const existingCodes = getCountryCode();
const existingCountries = getCountries();
const existingAllCon = getAllCountries();

const countrycodereducer = (state = existingCodes, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_COUNTRIES_CODE_SUCCESS:
      const fetchCountryCode = {
        allcountriescodestate: action.payload,
      };
      localStorage.setItem(
        "allcountriescodestate",
        JSON.stringify(fetchCountryCode)
      );
      return fetchCountryCode;
    default:
      return state;
  }
};

const fetchcountryreducer = (state = existingCountries, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_COUNTRIES_SUCCESS:
      const fetchCountry = {
        allcountriesstate: action.payload,
      };
      localStorage.setItem("allcountriesstate", JSON.stringify(fetchCountry));
      return fetchCountry;
    default:
      return state;
  }
};

const fetchallcountryreducer = (state = existingAllCon, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_COUNTRIES_SUCCESS:
      const fetchAllCountry = {
        allcountries: action.payload,
      };
      localStorage.setItem("allcountries", JSON.stringify(fetchAllCountry));
      return fetchAllCountry;
    default:
      return state;
  }
};

export { fetchcountryreducer, countrycodereducer, fetchallcountryreducer };
