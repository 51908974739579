import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ActionTypes from "../../redux/actiontype/ActionTypes";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { EditAction } from "../../redux/actions/editaction";
import { FetchStatesByCountryCode } from "../../redux/actions/authaction";
import SelectSearch, { fuzzySearch } from "react-select-search";
// import img from '../../assets/images/logo1.png';

const UpdateBankAccountModal = (props) => {
  const {
    setNotify,
    show,
    setShowAcctUpdate,
    handleShowAccountUpdate,
    editbankaccount,
    allcountriesstate,
    fetchallcountrystates,
    allstatesstate,
    authstate,
    userprofilestate,
  } = props;
  const [bankdetails, setBankDetails] = useState({});
  const [acctdetails, setAcctDetails] = useState({});
  const [updateBACKLoading, setUpdateBACKLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (acctdetails !== undefined) {
      setUpdateBACKLoading(true);
      editbankaccount(
        acctdetails,
        setNotify,
        ActionTypes.UPDATE_BANK_ACCOUNT_SUCCESS,
        ActionTypes.UPDATE_BANK_ACCOUNT_FAIL,
        setShowAcctUpdate,
        setUpdateBACKLoading
      );
    }
  };

  const handlereturnvalue = (cout) => {
    const countryCode = cout.substring(cout.length - 3, cout.length);
    const country = cout.substring(0, cout.length - 4);
    setAcctDetails({ ...acctdetails, ...{ country, countryCode } });
  };

  useEffect(() => {
    if (userprofilestate) {
      let firstobject = userprofilestate[0];

      if (firstobject.customerBankAccount) {
        setBankDetails(firstobject.customerBankAccount);

        let bankSwiftCode = firstobject.customerBankAccount.accSwiftCode;
        let countryCode = firstobject.customerBankAccount.countryCode;
        let country = firstobject.customerBankAccount.accCountry;
        let bankName = firstobject.customerBankAccount.accBankName;
        let bankAccountNumber = firstobject.customerBankAccount.accNumber;
        let accountName = firstobject.customerBankAccount.accountName;
        let id = firstobject.customerBankAccount.id;
        let customerId = firstobject.customerBankAccount.customerId;

        setAcctDetails({
          ...acctdetails,
          ...{
            id,
            customerId,
            bankSwiftCode,
            countryCode,
            country,
            bankName,
            accountName,
            bankAccountNumber,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [show]);

  const allcountriesarray = [];

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.country + " " + item.alpha3Code;
      newitem.currency = item.currency;
      allcountriesarray.push(newitem);
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleShowAccountUpdate}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Bank Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <div className="position-relative form-group">
                  <label htmlFor="accBankName">Bank Name</label>
                  <input
                    name="text"
                    id="accBankName"
                    defaultValue={bankdetails.accBankName}
                    placeholder="Account Bank Name"
                    type="text"
                    className="form-control"
                    onChange={(event) => {
                      const bankName = event.target.value;
                      setAcctDetails({ ...acctdetails, ...{ bankName } });
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="position-relative form-group">
                  <label htmlFor="accNumber">Account Number</label>
                  <input
                    name="text"
                    id="accNumber"
                    defaultValue={bankdetails.accNumber}
                    placeholder="Account Number"
                    type="text"
                    className="form-control"
                    onChange={(event) => {
                      const bankAccountNumber = event.target.value;
                      setAcctDetails({
                        ...acctdetails,
                        ...{ bankAccountNumber },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <div className="position-relative form-group">
                  <label htmlFor="accountName">Account Name</label>
                  <input
                    name="email"
                    id="accountName"
                    defaultValue={bankdetails.accountName}
                    className="form-control"
                    placeholder="Account Name"
                    type="text"
                    required
                    onChange={(event) => {
                      const accountName = event.target.value;
                      setAcctDetails({ ...acctdetails, ...{ accountName } });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlfor="accSwiftCode">Swift Codes</label>
                <div className="position-relative form-group">
                  <input
                    name="phone"
                    id="accSwiftCode"
                    placeholder="Account Swift Code"
                    style={{
                      textTransform: "uppercase",
                    }}
                    defaultValue={bankdetails.accSwiftCode}
                    type="text"
                    className="form-control col-md-12"
                    onChange={(event) => {
                      const bankSwiftCode = event.target.value;
                      setAcctDetails({ ...acctdetails, ...{ bankSwiftCode } });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-12">
                <div className="position-relative form-group">
                  <label htmlFor="exampleCountry">
                    <span className="text-danger"></span>Country Of Bank
                  </label>

                  <SelectSearch
                    options={allcountriesarray}
                    search={true}
                    onChange={handlereturnvalue}
                    filterOptions={fuzzySearch}
                    placeholder="Choose your country"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShowAccountUpdate}>
          Close
        </Button>

        <Button
          variant="danger"
          disabled={updateBACKLoading}
          onClick={handleSubmit}
        >
          {" "}
          Update Bank Account{" "}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    // isLoading: state.loadingstate.isLoading,
    allcountriesstate: state.allcountriesstate.allcountriesstate,
    allstatesstate: state.allstatesstate.allstatesstate,
    authstate: state.authstate.authstate,
    userprofilestate: state.userprofilestate.userprofilestate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editbankaccount: (
      accountdetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setUpdateBACKLoading
    ) => {
      dispatch(
        EditAction(
          accountdetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setUpdateBACKLoading
        )
      );
    },
    fetchallcountrystates: (setError, statecode) => {
      dispatch(FetchStatesByCountryCode(setError, statecode));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateBankAccountModal);
