export const formatNumber = (num) => {
    const formatter = new Intl.NumberFormat();
    const toNum = Number(num);
  
    return formatter.format(toNum);
  };

  export const numSequence = (max) => {
    let arr = [];
  
    for (let i = 0; i < max; i++) {
      arr[i] = i + 1;
    }
  
    return arr;
  };

  export const formatNaira = (num) => {
    var p = num.toFixed(2).split(".");
    return (
      p[0]
        .split("")
        .reverse()
        .reduce(function (acc, num, i, orig) {
          return num === "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
        }, "") +
      "." +
      p[1]
    );
  }