import React, { useState, useEffect } from "react";
import { FetchStatesByCountryCode } from "../../redux/actions/authaction";
import { connect } from "react-redux";
import ActionTypes from "../../redux/actiontype/ActionTypes";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CreateAction } from "../../redux/actions/createaction";

const EditTransactionModal = (props) => {
  const {
    edittransaction,
    setNotify,
    show,
    setShow,
    item,
    handleShowEdit,
    editTransactionLoading,
    setEditTransactionLoading,
  } = props;

  const [userdetails, setUserdetails] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditTransactionLoading(true);
    edittransaction(
      userdetails,
      setNotify,
      ActionTypes.EDIT_TRANSACTION_SUCCESS,
      ActionTypes.EDIT_TRANSACTION_FAIL,
      setShow,
      setEditTransactionLoading
    );
  };

  const getPayInMethodVal = (name) => {
    const methodVals = {
      POLI: 1,
      "Credit Card": 2,
      PayID: 3,
      "Bank Transfer": 4,
    };

    return methodVals[name];
  };

  const getStatusVal = (name) => {
    const statusVals = {
      Pending: 1,
      Processing: 2,
      Completed: 3,
      OnHold: 4,
      Failed: 5,
    };

    return statusVals[name];
  };

  useEffect(() => {
    if (Object.keys(item).length !== 0) {
      let id = item.id;
      let customerId = item.customerId;
      let payInMethod = item.payInMethod;
      let paymentReferenceNo = item.transactionRefNumber;
      let isPaidIn = item.isPaidIn;
      let transactionStatus = item.transactionStatus;
      let receiverId = item.receiverId;
      let dateProcessed = item.dateProcessed;

      setUserdetails({
        ...userdetails,
        ...{
          id,
          customerId,
          payInMethod,
          paymentReferenceNo,
          isPaidIn,
          transactionStatus,
          receiverId,
          dateProcessed,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {}, [show]);

  return (
    <Modal
      show={show}
      onHide={handleShowEdit}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="transactionRefNumber">
                  Transaction RefNumber
                </label>
                <input
                  name="transactionRefNumber"
                  id="transactionRefNumber"
                  placeholder={item.transactionRefNumber}
                  defaultValue={item.transactionRefNumber}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const paymentReferenceNo = event.target.value;
                    setUserdetails({
                      ...userdetails,
                      ...{ paymentReferenceNo },
                    });
                  }}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="exampleGender">Payment Method</label>
                <input
                  name="transactionRefNumber"
                  id="transactionRefNumber"
                  placeholder={item.payInMethod}
                  defaultValue={item.payInMethod}
                  type="text"
                  className="form-control"
                  readOnly={true}
                />
                {(() => {
                  if (item.payInMethod === null) {
                    return (
                      <p
                        className="user-details-title -mb-0"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        This is an incomplete transaction, you may not be able
                        to update it
                      </p>
                    );
                  } else {
                    return <></>;
                  }
                })()}
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="exampleGender">
                  {" "}
                  Customer Payment Received{" "}
                </label>
                <select
                  type="select"
                  id="isActive"
                  name="isActive"
                  className="mb-2 form-control"
                  defaultValue={item.isPaidIn}
                  onChange={(event) => {
                    const isPaidIn = event.target.value;
                    setUserdetails({ ...userdetails, ...{ isPaidIn } });
                  }}
                >
                  <option> -- Select Method -- </option>
                  <option value={true}> Yes</option>
                  <option value={false}> No </option>
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="transactionStatus">Transaction Status</label>
                <select
                  type="select"
                  id="isActive"
                  name="isActive"
                  className="mb-2 form-control"
                  defaultValue={getStatusVal(item.transactionStatus)}
                  onChange={(event) => {
                    <option value="4">Bank Transfer</option>;
                    const transactionStatus = parseInt(event.target.value);
                    setUserdetails({
                      ...userdetails,
                      ...{ transactionStatus },
                    });
                  }}
                >
                  <option> -- Select Method -- </option>
                  {/* <option selected="selected" value={processStatus(item.transactionStatus)}>{item.transactionStatus}</option> */}
                  <option value="1">Pending</option>
                  <option value="2">Processing</option>
                  <option value="3">Completed</option>
                  <option value="4">On Hold</option>
                  <option value="5">Failed</option>
                </select>
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlfor="amountToReceive">
                  Amount To Receive (LOCAL){" "}
                </label>
                <div className="position-relative form-group">
                  <input
                    name="phone"
                    id="amountToReceive"
                    defaultValue={item.amountToReceive_Local}
                    type="number"
                    className="form-control col-md-12"
                    onChange={(event) => {
                      const amountToReceive_Local = event.target.value;
                      setUserdetails({
                        ...userdetails,
                        ...{ amountToReceive_Local },
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label htmlfor="amountToReceive">Amount To Receive (USD)</label>
                <div className="position-relative form-group">
                  <input
                    name="phone"
                    id="amountToReceive"
                    defaultValue={item.amountToReceive_USD}
                    type="number"
                    className="form-control col-md-12"
                    onChange={(event) => {
                      const amountToReceive_USD = event.target.value;
                      setUserdetails({
                        ...userdetails,
                        ...{ amountToReceive_USD },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShowEdit}>
          Close
        </Button>
        <Button
          variant="danger"
          disabled={editTransactionLoading}
          onClick={handleSubmit}
        >
          Update Transaction
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    edittransaction: (
      userdetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setEditTransactionLoading
    ) => {
      dispatch(
        CreateAction(
          userdetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setEditTransactionLoading
        )
      );
    },
    fetchallcountrystates: (setError, statecode) => {
      dispatch(FetchStatesByCountryCode(setError, statecode));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTransactionModal);
