/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./assets/css/style.css";
import "./assets/css/animate.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/magnific-popup.min.css";
import "./assets/css/nice-select.css";
import "./assets/css/slick.min.css";
import "./assets/css/odometer.min.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/meanmenu.css";
import "./assets/css/responsive.css";
import { Link } from "react-router-dom";
import faq from "./assets/img/faq.png";
import mainmobile from "./assets/img/ICON.png";
import logo from "./assets/img/logo.png";
import jcilogo from "../../src/assets/images/logo_png.png";
import email from "../../src/assets/images/email.png";
import whatsapp from "./assets/img/whatsapp.png";
import login from "../../src/assets/images/user.png";
import map from "./assets/img/map.png";
import { connect } from "react-redux";
import Notification from "../components/Notification";
// eslint-disable-next-line no-lone-blocks

const FAQ = (props) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

   React.useEffect(() => {
     window.scrollTo(0, 0);
   }, [props.location]);

  return (
    <div>
      <Notification notify={notify} setNotify={setNotify} />
      {/* is-sticky */}
      <div className="navbar-area">
        <div className="luvion-responsive-nav">
          <div className="container">
            <div className="luvion-responsive-menu">
              <div className="logo">
                <Link to="/home">
                  <img src={logo} height={100} alt="logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="luvion-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/home">
                <img src={logo} height={130} alt="logo" />
              </Link>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
                style={{ display: "block" }}
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    {/*<Link to="/login" src={login} className="nav-link">*/}
                    {/*  <img src={login} alt="logo" style={{ marginRight: 8 }} />*/}
                    {/*  Login*/}
                    {/*</Link>*/}
                  </li>
                </ul>
                <div className="others-options">
                  {/*<Link*/}
                  {/*  to="/introreg"*/}
                  {/*  className="btn btn-primary"*/}
                  {/*  style={{ marginLeft: 20 }}*/}
                  {/*>*/}
                  {/*  Register*/}
                  {/*</Link>*/}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div
        id="introcontent"
        className="page-title-area item-bg1 jarallax"
        data-jarallax='{"speed": 0.3}'
      >
        <div className="container">
          <div className="page-title-content">
            <h2>FAQ</h2>
            <p>Frequently Asked Questions</p>
          </div>
        </div>
      </div>
      <section className="faq-area ptb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="faq-content">
                <h2>Frequently Asked Questions</h2>
                <div className="bar" />
                <p>These are the list of our frequently asked questions</p>
                <div className="faq-image">
                  <img src={mainmobile} alt="image" />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="faq-accordion">
                <ul className="accordion">
                  <li className="accordion-item">
                    <a
                      className="accordion-title active"
                      href="javascript:void(0)"
                    >
                      <i className="fas fa-plus" />
                      How do I send money with JCI Remit?
                    </a>
                    <p className="accordion-content show">
                      Sending money with JCI Remit requires that you register
                      first on our website or with the mobile app. Please ensure
                      that the details you provide are accurate and up to date.
                      You can now follow the screen prompt to do your
                      transaction
                    </p>
                  </li>
                  <li className="accordion-item">
                    <a
                      className="accordion-title active"
                      href="javascript:void(0)"
                    >
                      <i className="fas fa-plus" />
                      How safe is it to transfer money via JCI Remit?
                    </a>
                    <p className="accordion-content show">
                      Our Bank-Grade Security Systems ensure that your data and
                      transactions are safe and secure from malicious attacks.
                    </p>
                  </li>
                  <li className="accordion-item">
                    <a
                      className="accordion-title active"
                      href="javascript:void(0)"
                    >
                      <i className="fas fa-plus" />
                      How much does a money transfer cost?
                    </a>
                    <p className="accordion-content show">
                      We charge minimal or Zero fees depending on the country of
                      destination and mode of payments chosen.
                    </p>
                  </li>
                  <li className="accordion-item">
                    <a
                      className="accordion-title active"
                      href="javascript:void(0)"
                    >
                      <i className="fas fa-plus" />
                      How long will my transfer take?
                    </a>
                    <p className="accordion-content show">
                      Transactions are generally processed instantaneously,
                      however some transactions could take longer depending on
                      factors such as country of destination and payment method.
                    </p>
                  </li>
                  <li className="accordion-item">
                    <a
                      className="accordion-title active"
                      href="javascript:void(0)"
                    >
                      <i className="fas fa-plus" />
                      How do I resolve transaction dispute?
                    </a>
                    <p className="accordion-content show">
                      Our customer support team is always available 24/7 to
                      assist whenever you need it. Contact us via email:
                      <span style={{ Color: "red" }}>
                        contact@jciremit.com
                      </span>{" "}
                      or chat on WhatsApp: +61431919688.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="account-create-area">
        <div className="container">
          <div className="account-create-content">
            <h2>Apply for an account in minutes</h2>
            <p>Get your JCI REMIT account today!</p>
            <a href="/home#download" className="btn btn-primary">
              Get Your JCI REMIT Account
            </a>
          </div>
        </div>
      </section>

      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={jcilogo}
                      height={130}
                      alt="logo"
                      style={{ marginTop: -50 }}
                    />
                  </Link>
                  <p>
                    JCI REMIT PTY LTD with ABN Number 18645908907 is a
                    Remittance Service Provider, that is authorized and
                    regulated by the Australian Transaction Reports and Analysis
                    Center, AUSTRAC.
                  </p>
                </div>
                <ul className="social-links">
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Company</h3>
                <ul className="list">
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <a href="/home#aboutus">Services</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Support</h3>
                <ul className="list">
                  <li>
                    <a href="#">FAQ</a>
                  </li>
                  <li>
                    <a href="/home#contactus">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>LEGAL</h3>
                <ul className="list">
                  <li>
                    <Link to="/policies">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/user-agreement">User Agreement</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <p>
              JCI REMIT PTY LTD @ {(new Date()).getFullYear()} JCI REMIT | All Rights Reserved <br></br>
              
            </p>
          </div>
        </div>
        <div className="map-image">
          <img src={map} alt="map" />
        </div>
      </footer>
      <div className="go-top">
        <i className="fas fa-arrow-up" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
