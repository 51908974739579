/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import {
  UserRegisterAuthAction,
  ShowLoading,
  FetchAllCountry,
  FetchStatesByCountryCode,
  CheckIfEmailExist,
  CheckIfPhoneExist,
} from "../../redux/actions/authaction";
import { usePasswordValidation } from "../../utils/passwordValidation";
import Notification from "../../components/Notification";
import { Link } from "react-router-dom";
import StepOneReg from "../../components/StepOneReg";
import StepTwoReg from "../../components/StepTwoReg";
import StepThreeReg from "../../components/StepThreeReg";
import StepZilla from "react-stepzilla";
import "../../login/css/style.css";
import "../main.css";
import StepFourReg from "../../components/StepFourReg";
import img1 from "../../assets/images/JCI.png";

const Registration = (props) => {
  const {
    registeruser,
    isLoading,
    fetchallcountry,
    fetchallcountrystates,
    checkifemailexist,
    checkifphoneexist,
    allcountriesstate,
    allstatesstate,
    isEmailValid,
    isPhoneValid,
  } = props;
  const [userdetails, setUserdetails] = useState({});
  const [emailValid, setEmailValid] = useState(false);
  const history = useHistory();
  const pwderrmsg1 =
    "Your password must match each other and it must have a special character, a number, Upper & lower case letters and not less than 8 characters";
  const pwderrmsg2 =
    "Your password must has a special character, a number, Upper & lower case letters and not less than 8 characters";
  const [err, setErr] = useState();
  const [showerr, setShowErr] = useState(false);
  const [errmsg, setErrmsg] = useState();
  const [emailerrortext, setEmailErrortext] = useState();
  const [phoneerrortext, setPhoneErrorText] = useState();
  const [accountype, setAccountype] = useState(false);
  const [response, setResponse] = useState("");

  const [error, setError] = useState({
    hasError: false,
    message: "",
  });

  useEffect(() => {
    fetchallcountry(setError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: password.firstPassword,
      secondPassword: password.secondPassword,
    });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    if (!isEmailValid) {
      setEmailErrortext("Email already exist!");
    } else {
      setEmailErrortext("");
    }
  }, [isEmailValid]);

  useEffect(() => {
    if (!isPhoneValid) {
      setPhoneErrorText("Phone number already exist!");
    } else {
      setPhoneErrorText("");
    }
  }, [isPhoneValid]);

  const handlereturnvalue = (cout) => {
    const _statecode = cout.substring(cout.length - 3, cout.length);
    fetchallcountrystates(setError, _statecode);
    const countryofResidence = cout.substring(0, cout.length - 4);
    setUserdetails({ ...userdetails, ...{ countryofResidence } });
  };

  const handlereturnvalue2 = (cout) => {
    const countryOfNationality = cout.substring(0, cout.length - 4);
    setUserdetails({ ...userdetails, ...{ countryOfNationality } });
  };

  const handlereturnvalueforstates = (state) => {
    setUserdetails({ ...userdetails, ...{ state } });
  };

  const checkemail = (email) => {
    let reg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (reg.test(email)) {
      // this is a valid email address
      setEmailValid(true);
      checkifemailexist(setError, email);
    } else {
      // invalid email, maybe show an error to the user.
      setEmailValid(false);
    }
  };

  const checkphone = (phone) => {
    checkifphoneexist(setError, phone);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    registeruser(userdetails, history, setError, setNotify, setResponse);
  };

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
    setShowErr(true);
    if (validLength && hasNumber && upperCase && lowerCase && specialChar) {
      if (match) {
        setErrmsg("");
        setErr(false);
      }
    } else {
      setErrmsg(pwderrmsg2);
      setErr(true);
    }
  };

  const setSecond = (event) => {
    setShowErr(true);
    setPassword({ ...password, secondPassword: event.target.value });
    if (
      validLength &&
      hasNumber &&
      upperCase &&
      lowerCase &&
      specialChar &&
      match
    ) {
      setErrmsg("");
      setErr(false);
      const password = event.target.value;
      setUserdetails({ ...userdetails, ...{ password } });
    } else {
      setErrmsg(pwderrmsg1);
      setErr(true);
    }
  };

  const steps = [
    {
      name: "Personal",
      component: (
        <StepOneReg
          setUserdetails={setUserdetails}
          userdetails={userdetails}
          isPhoneValid={isPhoneValid}
          checkphone={checkphone}
          phoneerrortext={phoneerrortext}
          allcountriesstate={allcountriesstate}
          handlereturnvalue={handlereturnvalue}
          handlereturnvalue2={handlereturnvalue2}
        />
      ),
    },
    {
      name: "Address",
      component: (
        <StepTwoReg
          setUserdetails={setUserdetails}
          userdetails={userdetails}
          allstatesstate={allstatesstate}
          handlereturnvalueforstates={handlereturnvalueforstates}
          checkemail={checkemail}
          emailerrortext={emailerrortext}
          isPhoneValid={isPhoneValid}
          checkphone={checkphone}
          phoneerrortext={phoneerrortext}
          allcountriesstate={allcountriesstate}
          handlereturnvalue={handlereturnvalue}
          handlereturnvalue2={handlereturnvalue2}
        />
      ),
    },
    {
      name: "Security",
      component: (
        <StepThreeReg
          setUserdetails={setUserdetails}
          userdetails={userdetails}
          isEmailValid={isEmailValid}
          emailValid={emailValid}
          checkemail={checkemail}
          emailerrortext={emailerrortext}
          setFirst={setFirst}
          setSecond={setSecond}
          showerr={showerr}
          errmsg={errmsg}
        />
      ),
    },
    {
      name: "Final.....",
      component: (
        <StepFourReg
          setUserdetails={setUserdetails}
          userdetails={userdetails}
          accountype={accountype}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
        />
      ),
    },
  ];

  return (
    <section className="ftco-section">
      <Notification notify={notify} setNotify={setNotify} />
      <div className="containers">
        <div className="rows justify-content-centers">
          <div className="col-md-6 text-center mb-5"></div>
        </div>

        <div className="rows justify-content-centers">
          <div className="col-md-12 col-lg-10">
            <div className="wrap d-md-flex">
              <div className="image img ">
                <div className="overlay">
                  <div className="text-center">
                    <img
                      src={img1}
                      alt=""
                      height="auto"
                      width="40%"
                      style={{ marginTop: "80%" }}
                    />
                  </div>
                  <h3
                    className="mb-2 pt-3"
                    style={{
                      color: "white",
                      fontSize: 30,
                      textAlign: "center",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Welcome to
                  </h3>
                  <h3
                    className="mb-1"
                    style={{
                      color: "white",
                      fontSize: 60,
                      textAlign: "center",
                      fontFamily: "sans-serif",
                    }}
                  >
                    {" "}
                    JCI REMIT
                  </h3>
                  <p className="ml-1 text-white text-center">
                    What would you like to do, Login to access our account, We
                    guarantee you seemless, fast and reliable cross border money
                    transfer without hassles
                  </p>
                </div>
              </div>

              <div className="login-wrap p-4 p-md-5">
                <div className="d-flex">
                  <div className="w-100">
                    <h3
                      className="mb-4"
                      style={{ color: "black", fontSize: 30 }}
                    >
                      Sign Up
                    </h3>
                  </div>
                  <div className="w-100">
                    <p className="social-media d-flex justify-content-end">
                      <a
                        href="https://www.apple.com"
                        target="_blank"
                        className="social-icon d-flex align-items-center justify-content-centers"
                      >
                        <span className="fa fa-apple" />
                      </a>
                      <a
                        href="https://www.google.com"
                        target="_blank"
                        className="social-icon d-flex align-items-center justify-content-centers"
                      >
                        <span className="fa fa-android" />
                      </a>
                    </p>
                  </div>
                </div>

                <form action="#" className="signin-form">
                  <div className="dropdown-menu-header-inner bg-danger">
                    <div
                      className="menu-header-image"
                      style={{
                        backgroundImage: "url(../login/images/bg-1.jpeg)",
                      }}
                    />
                  </div>

                  <StepZilla
                    steps={steps}
                    stepsNavigation={false}
                    prevBtnOnLastStep={true}
                    preventEnterSubmission={true}
                  />
                </form>
                <div className="form-group d-md-flex">
                  <div className="w-50 text-left">
                    <label className="checkbox-wrap checkbox-primary mb-0">
                      &nbsp;
                      {/* <input type="checkbox" defaultChecked /> */}
                      {/* <span className="checkmark" /> */}
                    </label>
                  </div>
                  <div className="w-50 text-md-right">
                    {/* <a href="/resetpassword">Reset Password</a> */}
                  </div>
                </div>
                <p className="text-center">
                  Already a member?{" "}
                  <Link to="/login" className="checkbox-primary checkbox-wrap2">
                    Sign In
                  </Link>
                  <Link
                    to="/"
                    className="checkbox-success checkbox-wrap2"
                    style={{ marginLeft: 32, marginTop: 8 }}
                  >
                    Go Home
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state,
    isLoading: state.loadingstate.isLoading,
    isEmailValid: state.emailcheckstate.isValid,
    isPhoneValid: state.phonecheckstate.isValid,
    allcountriesstate: state.allcountriesstate.allcountriesstate,
    allstatesstate: state.allstatesstate.allstatesstate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registeruser: (userdetails, history, setError, setNotify, setResponse) => {
      dispatch(ShowLoading(userdetails));
      dispatch(
        UserRegisterAuthAction(
          userdetails,
          history,
          setError,
          setNotify,
          setResponse
        )
      );
    },
    fetchallcountry: (setError) => {
      dispatch(FetchAllCountry(setError));
    },
    fetchallcountrystates: (setError, statecode) => {
      dispatch(FetchStatesByCountryCode(setError, statecode));
    },
    checkifemailexist: (setError, email) => {
      dispatch(CheckIfEmailExist(setError, email));
    },
    checkifphoneexist: (setError, phone) => {
      dispatch(CheckIfPhoneExist(setError, phone));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
