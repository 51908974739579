import React, { useState } from "react";
import AppHeader from "../components/AppHeader";
import AppSidebar from "../components/AppSidebar";
import Footer from "../components/Footer";
import Successarea from "../components/Successarea";

export default function TransSuccess(props) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
      {/* <AppHeader notify={notify} setNotify={setNotify} /> */}
      <div className="app-main">
        {/* <AppSidebar /> */}
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Successarea notify={notify} setNotify={setNotify} />
            {/* <Footer /> */}
          </div>
        </div>
      </div>
      <div className="app-drawer-overlay d-none animated fadeIn" />
    </div>
  );
}
