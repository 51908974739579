import React, {useState, forwardRef, useImperativeHandle} from 'react';
const StepThreeReg = forwardRef((props, ref) => {

    let { setUserdetails, userdetails, setFirst, setSecond, showerr, errmsg, isEmailValid, emailValid, checkemail, emailerrortext, } = props;

    const [uiError, setUiError] = useState(false);
    const [uiError2, setUiError2] = useState(false);
    

    useImperativeHandle(ref, () => ({
        
        isValidated() {
          if(!userdetails.pin) {
                setUiError(false);             
                setUiError2(true);
                return false;
          } else {
            let lent = userdetails.pin;
            if(lent.length < 6 || lent.length > 6)
            {
                setUiError2(true);
                setUiError(false); 
                return false;
            }
            // all good, let's proceed
            setUiError(false);
            setUiError2(false);
            return true;
          }
        }
      }));

    return (
      <div id="step-2">
        <div id="accordion" className="accordion-wrapper mb-3">
          <div className="card">
            <div id="headingOne" className="card-header">
              <button
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
                className="text-left m-0 p-0 btn btn-link btn-block"
              >
                <span className="form-heading text-danger">
                  Security Information<p>Enter your security details below</p>
                </span>
              </button>
            </div>
            <div
              data-parent="#accordion"
              id="collapseOne"
              aria-labelledby="headingOne"
              className="collapse show"
            >
              <div className="card-body">
                <div className="form-row">
                  <div className="col-md-12">
                    <div className="position-relative form-group">
                      <label htmlFor="accessPin">Access PIN</label>
                      <input
                        name="accessPin"
                        id="accessPin"
                        placeholder="Access Pin"
                        minLength="6"
                        maxLength="6"
                        type="text"
                        defaultValue={userdetails.pin}
                        className="form-control"
                        onChange={(event) => {
                          const pin = event.target.value;
                          setUserdetails({ ...userdetails, ...{ pin } });
                        }}
                      />
                      {uiError2 && (
                        <div
                          className="bg-danger"
                          style={{
                            marginTop: "1rem",
                            display: "table",
                            color: "white",
                          }}
                        >
                          You need to suppy access pin of 6 characters
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-12">
                    <div className="position-relative form-group">
                      <label htmlFor="exampleEmail">
                        <span className="text-danger">*</span> Email
                      </label>
                      <input
                        type="email"
                        autoComplete="off"
                        name="email"
                        id="exampleEmail"
                        className="form-control"
                        placeholder="Email"
                        defaultValue={userdetails.email}
                        required
                        style={{
                          backgroundColor:
                            isEmailValid && emailValid ? "" : "red",
                          fontSize: "11px",
                        }}
                        onChange={(event) => {
                          const email = event.target.value;
                          setUserdetails({ ...userdetails, ...{ email } });
                          checkemail(email);
                        }}
                      />
                      <span className="text-danger">{emailerrortext}</span>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-6">
                    <div className="position-relative form-group">
                      <label htmlFor="examplePassword">
                        <span className="text-danger"></span>Password
                      </label>
                      <input
                        name="password"
                        id="examplePassword"
                        placeholder="Password"
                        type="password"
                        className="form-control"
                        defaultValue={userdetails.password}
                        onChange={(event) => {
                          setFirst(event);
                        }}
                        onKeyUp={(event) => {
                          setFirst(event);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="position-relative form-group">
                      <label htmlFor="examplePasswordRep">
                        <span className="text-danger"></span>Re-type Password
                      </label>
                      <input
                        name="passwordrep"
                        id="examplePasswordRep"
                        placeholder="Confirm Password"
                        type="password"
                        className="form-control"
                        defaultValue={userdetails.password}
                        onKeyUp={(event) => {
                          setSecond(event);
                        }}
                        onChange={(event) => {
                          setSecond(event);
                        }}
                      />
                    </div>
                    <p>
                      {" "}
                      {showerr ? (
                        <span
                          style={{ fontSize: "11px", color: "red", width: 15 }}
                        >
                          {errmsg}
                        </span>
                      ) : (
                        <span />
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
})

export default StepThreeReg
