/* eslint-disable no-extend-native */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./assets/css/style.css";
import "./assets/css/animate.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/magnific-popup.min.css";
import "./assets/css/nice-select.css";
import "./assets/css/slick.min.css";
import "./assets/css/odometer.min.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/meanmenu.css";
import "./assets/css/responsive.css";
import { Link } from "react-router-dom";
import jcilogo from "../../src/assets/images/logo_png.png";
import getmore from "../../src/assets/images/getmore.jpg";
import circle from "./assets/img/circle.png";
import logo from "./assets/img/logo.png";
import remmittance from "./assets/img/remmittance.png";
import dollar from "./assets/img/dollar.png";
import services from "./assets/img/services.png";
import bitcoin from "../../src/assets/images/bitcoin.png";
import service from "../../src/assets/images/service.png";
import debitcard from "../../src/assets/images/debitcard.png";
import email from "../../src/assets/images/email.png";
import apple from "../../src/assets/images/appstore.png";
import playstore from "../../src/assets/images/playstore.png";
import whatsapp from "./assets/img/whatsapp.png";
import i247 from "./assets/img/24.png";
import sideimg from "./assets/img/sideimg.png";
import earth from "./assets/img/globe.png";
import piggybank from "../../src/assets/images/piggybank.png";
import login from "../../src/assets/images/user.png";
import shield from "../../src/assets/images/shield.png";
import map from "./assets/img/map.png";
import mainmobile from "./assets/img/ICON.png";
import { connect } from "react-redux";
import { GetAction } from "../redux/actions/getaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import { CreateAction, CreateAction2 } from "../redux/actions/createaction";
import Notification from "../components/Notification";
// eslint-disable-next-line no-lone-blocks

const Home = (props) => {
  const {
    fetchallreceivingcountry,
    fetchallsendingcountry,
    allreceivingcurrencystate,
    allsendingcurrencystate,
    createtransact,
    sendcontact,
    advertconversionstate,
    receivingconversionstate,
  } = props;

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [show, setShow] = useState(false);
  const [transferdetails, setTransferDetails] = useState({});
  const [contactdetails, setContactdetails] = useState();
  const [beta, setBeta] = useState({});
  const [alpha, setAlpha] = useState({});
  const [transferdetails2, setTransferDetails2] = useState({});
  const [istransactionLoading, setIstransactionLoading] = useState(false);
  const [sendContactLoading, setSendContactLoading] = useState(false);
  const [converttype, setConverttype] = useState(true);

  useEffect(() => {return;
    fetchallreceivingcountry(
      show,
      setNotify,
      ActionTypes.GET_ALL_RECEIVING_CURRENCY_SUCCESS,
      ActionTypes.GET_ALL_RECEIVING_CURRENCY_FAIL,
      setShow
    );
    fetchallsendingcountry(
      show,
      setNotify,
      ActionTypes.GET_ALL_SENDING_CURRENCY_SUCCESS,
      ActionTypes.GET_ALL_SENDING_CURRENCY_FAIL,
      setShow
    );

    setConverttype(true);
    let converttype = "sending";
    let receieveCountry = "";
    let sendCountry = "";
    setTransferDetails({
      ...transferdetails,
      converttype,
      receieveCountry,
      sendCountry,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const formatNumber = () => {
  Number.prototype.format = function (n, x) {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
  };

  // }

  useEffect(() => {
    if (receivingconversionstate) {
      let content = receivingconversionstate.amountToReceive_Local.toFixed(2);
      setBeta(content);
    }
  }, [receivingconversionstate]);

  useEffect(() => {
    if (advertconversionstate) {
      let content = advertconversionstate.amountToReceive_Local.toFixed(2);
      setAlpha(content);
    }
  }, [advertconversionstate]);

  const renderReceivingFromOptions = () => {
    if (allreceivingcurrencystate) {
      return allreceivingcurrencystate.map((item, i) => {
        return (
          <option value={item.countryCode}>
            {item.name} ({item.currency})
          </option>
        );
      });
    }
  };

  const renderSendingFromOptions = () => {
    if (allsendingcurrencystate) {
      return allsendingcurrencystate.map((item, i) => {
        return (
          <option value={item.countryCode}>
            {item.name} ({item.currency})
          </option>
        );
      });
    }
  };

  useEffect(() => {
    if (!sendContactLoading) {
      setContactdetails({
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
      });
    }
  }, [sendContactLoading]);

  const sendContact = () => {
    setSendContactLoading(true);
    if (contactdetails) {
      sendcontact(
        contactdetails,
        setNotify,
        ActionTypes.SEND_CONTACT_SUCCESS,
        ActionTypes.SEND_CONTACT_FAIL,
        setShow,
        setSendContactLoading
      );
      // setContactdetails({});
    }
  };

  const processBudget = (amount, type, data) => {
    if (converttype !== undefined) {
      if (transferdetails.sendCountry !== undefined) {
        if (type === "sending") {
          setIstransactionLoading(true);
          createtransact(
            amount,
            data,
            setNotify,
            ActionTypes.ADD_ADVERT_CONVERTION_SUCCESS,
            ActionTypes.ADD_ADVERT_CONVERTION_FAIL,
            setShow,
            setIstransactionLoading
          );
        } else {
          setIstransactionLoading(true);
          createtransact(
            data,
            setNotify,
            ActionTypes.ADD_RECEIVING_CONVERTION_SUCCESS,
            ActionTypes.ADD_RECEIVING_CONVERTION_FAIL,
            setShow,
            setIstransactionLoading
          );
        }
      } else {
        setNotify({
          isOpen: true,
          message: "kindly fill empty spaces",
          type: "error",
        });
      }
    } else {
      setNotify({
        isOpen: true,
        message: "please select convertion type",
        type: "error",
      });
    }
  };

  const processBudget2 = (amount, type, data) => {
    if (converttype !== undefined) {
      if (data.receieveCountry !== undefined) {
        setIstransactionLoading(true);
        createtransact(
          amount,
          data,
          setNotify,
          ActionTypes.ADD_RECEIVING_CONVERTION_SUCCESS,
          ActionTypes.ADD_RECEIVING_CONVERTION_FAIL,
          setShow,
          setIstransactionLoading
        );
      } else {
        setNotify({
          isOpen: true,
          message: "kindly select the receiving country",
          type: "error",
        });
      }
    } else {
      setNotify({
        isOpen: true,
        message: "please select convertion type",
        type: "error",
      });
    }
  };

  return (
    <div>
      <Notification notify={notify} setNotify={setNotify} />
      {/* is-sticky */}
      <div className="navbar-area">
        <div className="luvion-responsive-nav">
          <div className="container">
            <div className="luvion-responsive-menu">
              <div className="logo">
                <Link to="/home">
                  {/* <img src="assets/img/logo.png" alt="logo" />
                  <img src="assets/img/black-logo.png" alt="logo" /> */}
                  <img src={jcilogo} height={50} alt="logo" />
                </Link>
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                  style={{ display: "block" }}
                >
                  <ul className="navbar-nav">
                    <li className="nav-item"></li>
                  </ul>
                  <div className="others-options">
                    {/* <Link
                      to="/login"
                      className="btn btn-primary showe"
                      // style={{ marginLeft: 20 }}
                    >
                      Login
                    </Link>
                    <Link
                      to="/login"
                      src={login}
                      className="nav-link hide"
                      style={{ color: "white" }}
                    >
                      <img src={login} alt="logo" style={{ marginRight: 8 }} />
                      Login
                    </Link>
                    <Link
                      to="/login"
                      className="btn btn-primary"
                      // style={{ marginLeft: 20 }}
                    >
                      Login
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="luvion-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/home">
                <img src={logo} height={120} alt="logo" />
                {/* <img src={black_logo} alt="logo" /> */}
              </Link>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
                style={{ display: "block", marginTop: -40 }}
              >
                {/* <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/login" src={login} className="nav-link">
                      <img src={login} alt="logo" style={{ marginRight: 8 }} />
                      Login
                    </Link>
                  </li>
                </ul> */}
                <div className="others-options">
                  {/*<Link*/}
                  {/*  to="/login"*/}
                  {/*  className="btn btn-primary"*/}
                  {/*  style={{ marginLeft: 10 }}*/}
                  {/*>*/}
                  {/*  Login*/}
                  {/*</Link>*/}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div className="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="main-banner-content">
                <h1 className="superhead">
                  Super Fast and Seemless Money Transfer Across the Globe.
                </h1>
                <p style={{ fontSize: 13.5 }} className="texthead">
                  with International payments to over 100 countries and
                  territories, sending money to loved ones. paying and receiving
                  money from customers and suppliers. JCI Remit is Simply
                  Better!
                </p>
                {/*<Link to="/login" className="btn btn-primary">*/}
                {/*  Get Started*/}
                {/*</Link>*/}
                {/*<a*/}
                {/*  href="#calculator"*/}
                {/*  className="btn btn-primary"*/}
                {/*  style={{ marginLeft: 16 }}*/}
                {/*>*/}
                {/*  Rate Calculator*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="featured-boxes-area">
        <div className="container">
          <div className="featured-boxes-inner">
            <div className="row m-0">
              <div className="col-lg-3 col-sm-6 col-md-6 p-0">
                <div className="single-featured-box">
                  <div className="icon">
                    <img src={piggybank} alt="Price" />
                  </div>
                  <h3>Transparent Pricing</h3>
                  <p style={{ textAlign: "left" }}>
                    Super fast, Get more on your money transfer. Get the best
                    exchange rates online with low transfer fee so that you can
                    saveand send more on every transfer.
                  </p>
                  <a href="#aboutus" className="read-more-btn">
                    Read More
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-6 p-0">
                <div className="single-featured-box">
                  <div className="icon">
                    <img src={shield} alt="Price" />
                  </div>
                  <h3>Safe and Secure</h3>
                  <p style={{ textAlign: "left" }}>
                    We work round the clock to keep your account and money
                    protected from attacks with our Bank-Grade Security Systems
                  </p>
                  <a href="#aboutus" className="read-more-btn">
                    Read More
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-6 p-0">
                <div className="single-featured-box">
                  <div className="icon" style={{ paddingTop: 8 }}>
                    <img src={earth} alt="24/7 icon" />
                  </div>
                  <h3>Global Reach</h3>
                  <p>Make transfers to over 100 countries</p>
                  <a href="#aboutus" className="read-more-btn">
                    Read More
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-6 p-0">
                <div className="single-featured-box">
                  <div className="icon" style={{ paddingTop: 8 }}>
                    {/* <i className="flaticon-call" /> */}
                    <img src={i247} alt="24/7 icon" />
                  </div>
                  <h3>24/7 Support</h3>
                  <p>we're here to help you whenever you need it</p>
                  <a href="#aboutus" className="read-more-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-area ptb-70" id="calculator">
        <div className="container-fluid p-0">
          <div className="overview-box">
            <div className="section-title">
              <h2>Rate Calculator</h2>
              <div className="bar" />
            </div>

            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                      <div className="container-fluid p-0">
                        <div className="overview-box">
                          {/* <div className="overview-content"> */}

                          <div className="custom">
                            <div className="content left-content">
                              <h2 style={{ marginTop: -80, fontSize: 36 }}>
                                Get More on your Online Money Transfers
                              </h2>

                              <h5 style={{ marginTop: 30 }}>
                                More Safety {"  "} | More Convenience {"  "} |
                                More value
                              </h5>
                              <img src={getmore} alt="alt img is here" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="money-transfer-form">
                        <form>
                          <div className="form-group">
                            <label>Sending From</label>
                            <div className="money-transfer-field">
                              <input
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                value={beta}
                                onChange={(event) => {
                                  let amount = event.target.value;
                                  let amountToSend = Number(amount);
                                  setBeta(amount);
                                  setConverttype(true);
                                  let converttype = "sending";
                                  setTransferDetails({
                                    ...transferdetails,
                                    converttype,
                                    amountToSend,
                                  });

                                  let data = {
                                    amountToSend: amountToSend,
                                    converttype: "sending",
                                    receieveCountry:
                                      transferdetails.receieveCountry,
                                    sendCountry: transferdetails.sendCountry,
                                  };

                                  processBudget(amountToSend, "sending", data);
                                }}
                                style={{
                                  backgroundColor: "#ff3304",
                                  borderBlockColor: "#ff3304",
                                  color: "white",
                                }}
                              />
                              <div className="amount-currency-select">
                                <select
                                  className="nice-select"
                                  onChange={(event) => {
                                    let sendCountry = event.target.value;
                                    setTransferDetails({
                                      ...transferdetails,
                                      ...{ sendCountry },
                                    });
                                    setTransferDetails2({
                                      ...transferdetails2,
                                      sendCountry,
                                    });

                                    if (
                                      transferdetails.amountToReceive ===
                                      undefined
                                    ) {
                                      let data = {
                                        amountToReceive: "",
                                        converttype: "sending",
                                        receieveCountry:
                                          transferdetails.receieveCountry,
                                        sendCountry: event.target.value,
                                      };
                                      processBudget(
                                        transferdetails.amountToSend,
                                        "sending",
                                        data
                                      );


                                      // let data = {
                                      //   amountToSend:
                                      //     transferdetails.amountToSend,
                                      //   converttype: "sending",
                                      //   receieveCountry: event.target.value,
                                      //   sendCountry:
                                      //     transferdetails.sendCountry,
                                      // };
                                      // processBudget(
                                      //   transferdetails.amountToSend,
                                      //   "sending",
                                      //   data
                                      // );

                                    } else {
                                      let data = {
                                        amountToReceive:
                                          transferdetails.amountToReceive,
                                        converttype: "receiving",
                                        receieveCountry:
                                          transferdetails.receieveCountry,
                                        sendCountry: sendCountry,
                                      };
                                      processBudget2(
                                        transferdetails.amountToReceive,
                                        "receiving",
                                        data
                                      );
                                    }
                                  }}
                                >
                                  <option value="">
                                    Select Sending Country
                                  </option>
                                  {renderSendingFromOptions()}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="currency-info">
                            <div className="bar" />
                            <span>
                              <strong>Based on Today's Exchange Rate</strong>
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Recipient gets</label>
                            <div className="money-transfer-field">
                              <input
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                value={alpha}
                                onChange={(event) => {
                                  let amount = event.target.value;
                                  let amountToReceive = Number(amount);

                                  setAlpha(amount);
                                  let converttype = "receiving";
                                  setConverttype(false);
                                  setTransferDetails2({
                                    ...transferdetails2,
                                    amountToReceive,
                                    converttype,
                                  });

                                  setTransferDetails({
                                    ...transferdetails,
                                    amountToReceive,
                                    converttype,
                                  });

                                  let data = {
                                    amountToReceive: amountToReceive,
                                    converttype: "receiving",
                                    receieveCountry:
                                      transferdetails.receieveCountry,
                                    sendCountry: transferdetails.sendCountry,
                                  };

                                  if (
                                    event.target.value.toString().length > 1
                                  ) {
                                    processBudget2(
                                      amountToReceive,
                                      "receiving",
                                      data
                                    );
                                  }
                                }}
                                style={{
                                  backgroundColor: "#ff3304",
                                  borderBlockColor: "#ff3304",
                                  color: "white",
                                }}
                              />
                              <div className="amount-currency-select">
                                <select
                                  className="nice-select"
                                  onChange={(event) => {
                                    let receieveCountry = event.target.value;
                                    setTransferDetails2({
                                      ...transferdetails2,
                                      receieveCountry,
                                    });
                                    setTransferDetails({
                                      ...transferdetails,
                                      receieveCountry,
                                    });

                                    if (
                                      transferdetails.amountToSend === undefined
                                    ) {
                                      let data = {
                                        amountToSend: "",
                                        converttype: "receiving",
                                        receieveCountry: event.target.value,
                                        sendCountry:
                                          transferdetails.sendCountry,
                                      };
                                      processBudget2(
                                        transferdetails2.amountToReceive,
                                        "receiving",
                                        data
                                      );
                                    } else {
                                      let data = {
                                        amountToSend:
                                          transferdetails.amountToSend,
                                        converttype: "sending",
                                        receieveCountry: event.target.value,
                                        sendCountry:
                                          transferdetails.sendCountry,
                                      };
                                      processBudget(
                                        transferdetails.amountToSend,
                                        "sending",
                                        data
                                      );
                                    }
                                  }}
                                >
                                  <option value="">
                                    Select Receiving Country
                                  </option>
                                  {renderReceivingFromOptions()}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="money-transfer-info">
                            {(() => {
                              if (
                                advertconversionstate === undefined &&
                                receivingconversionstate === undefined
                              ) {
                                return (
                                  <div className="flex mt-8 text-center justify-center w-full rounded-full bg-alt-purple">
                                    <div
                                      style={{
                                        borderTopColor: "transparent",
                                      }}
                                      className="w-10 h-10  border-4 border-white border-dotted my-2 rounded-full animate-spin"
                                    ></div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    className="money-transfer-info"
                                    style={{ marginTop: 16 }}
                                  >
                                    <div className="currency-info">
                                      <div className="bar" />
                                      <span>
                                        <strong>
                                          {" "}
                                          {(() => {
                                            if (converttype) {
                                              return (
                                                <>
                                                  <h6 style={{ fontSize: 20 }}>
                                                    Recipient gets (USD):{" "}
                                                    <b>
                                                      {advertconversionstate?.amountToReceive_USD.format(
                                                        2
                                                      )}
                                                    </b>
                                                  </h6>
                                                  <h6>
                                                    <b
                                                      style={{ color: "green" }}
                                                    >
                                                      Recipient gets (Local):{" "}
                                                      {advertconversionstate?.amountToReceive_Local.format(
                                                        2
                                                      )}
                                                    </b>
                                                  </h6>
                                                </>
                                              );
                                            } else {
                                              return (
                                                <>
                                                  {(() => {
                                                    if (
                                                      receivingconversionstate
                                                    ) {
                                                      return (
                                                        <>
                                                          <h6>
                                                            <b
                                                              style={{
                                                                color: "green",
                                                              }}
                                                            >
                                                              You will send
                                                              (Local):{" "}
                                                              {receivingconversionstate?.amountToReceive_Local.format(
                                                                2
                                                              )}
                                                            </b>
                                                          </h6>
                                                          <h6>
                                                            Recipient gets
                                                            (USD):{" "}
                                                            <b>
                                                              {receivingconversionstate?.amountToReceive_USD.format(
                                                                2
                                                              )}
                                                            </b>
                                                          </h6>
                                                        </>
                                                      );
                                                    } else {
                                                      return <></>;
                                                    }
                                                  })()}
                                                </>
                                              );
                                            }
                                          })()}
                                          {(() => {
                                            if (converttype) {
                                              return (
                                                <h6 style={{ fontSize: 12 }}>
                                                  You will send:{" "}
                                                  <b>
                                                    {advertconversionstate?.amountToSend.format(
                                                      2
                                                    )}
                                                  </b>
                                                </h6>
                                              );
                                            } else {
                                              return <></>;
                                            }
                                          })()}
                                        </strong>
                                      </span>
                                    </div>
                                    <Link
                                      to="/login"
                                      type="submit"
                                      className="btn btn-primary col-md-12"
                                    >
                                      Proceed
                                    </Link>
                                  </div>
                                );
                              }
                            })()}
                          </div>
                          {/* <button type="submit" className="btn btn-primary">
                            Get Started
                          </button> */}
                          <div className="terms-info">
                            <p>
                              By clicking proceed, you agree with our{" "}
                              <b>
                                <Link to="/user-agreement">
                                  policy , terms and conditions
                                </Link>
                              </b>
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-area ptb-70 bg-f7fafd">
        <div className="container-fluid p-0">
          <div className="overview-box">
            <div className="overview-image">
              <div className="images">
                <img src={sideimg} alt="alt img is here" />
                <div className="circle-img">
                  <img src={circle} alt="alt img here" />
                </div>
              </div>
            </div>
            <div className="overview-content">
              <div className="content">
                <h2>How to get started</h2>
                <div className="bar" />
                <p>
                  Money transfer made easy with the JCI Remit app, Get on board
                  in 6 easy steps.
                </p>
                <ul className="services-list">
                  <li>
                    <span>
                      <i className="flaticon-check-mark" /> Sign up
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="flaticon-check-mark" /> Sign In
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="flaticon-check-mark" /> Enter Amount and
                      Initiate Transaction
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="flaticon-check-mark" /> Add Beneficiary
                      Details
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="flaticon-check-mark" /> Select Prefered
                      Payment Method
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="flaticon-check-mark" /> Make Payments and
                      Complete Transaction
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features-area ptb-70 bg-f6f4f8" id="aboutus">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
            <div className="bar" />
            <p>
              We use latest technology and our vast global banking network to
              help you save on ever international payment and receivable.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-features-box">
                <div className="icon">
                  {/* <i className="flaticon-settings" /> */}
                  <img
                    src={remmittance}
                    className="wow animate__animated animate__zoomIn"
                    alt="alt img here"
                  />
                </div>
                <h3 style={{ textTransform: "uppercase", color: "black" }}>
                  Remittance
                </h3>
                <p>
                  Sending money across the globe has never been this easy. For
                  hassle-free secure and quick remittance worldwide, visit our
                  website or use our Mobile App. Choose from our wide range of
                  money transfer services.<br></br>
                  <br></br>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-features-box">
                <div className="icon bg-f78acb">
                  <img
                    src={dollar}
                    className="wow animate__animated animate__zoomIn"
                    alt="alt img here"
                  />
                </div>
                <h3 style={{ textTransform: "uppercase", color: "black" }}>
                  FOREIGN EXCHANGE
                </h3>
                <p>
                  We provide competitive exchange rate on all international
                  currencies. Get the most out of your money for all personal or
                  corporate currency exchange needs.<br></br>
                  <br></br>
                  <br></br>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-features-box">
                <div className="icon bg-cdf1d8">
                  {/* <i className="flaticon-menu" /> */}

                  <img
                    src={service}
                    className="wow animate__animated animate__zoomIn"
                    alt="alt img here"
                  />
                </div>
                <h3 style={{ textTransform: "uppercase", color: "black" }}>
                  CORPORATE SERVICES
                </h3>
                <p>
                  A comprehensive range of specialized services to
                  multinational, large, medium and small-scale enterprises. This
                  service caters for clients' that register for our corporate
                  services aiming to provide assistance and guidance in
                  optimizing business efficiency
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-features-box">
                <div className="icon bg-c679e3">
                  <img
                    src={services}
                    className="wow animate__animated animate__zoomIn"
                    alt="alt img here"
                  />
                </div>
                <h3 style={{ textTransform: "uppercase", color: "black" }}>
                  VALUE ADDED SERVICES
                </h3>
                <p>
                  Our value-added services are designed to provide you
                  simplified payment solutions for your utility bills, credit
                  card bills, mobile top-ups and so much more to make your life
                  easy and convenient.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-features-box">
                <img
                  src={debitcard}
                  className="wow animate__animated animate__zoomIn"
                  alt="alt img here"
                />
                <h3 style={{ textTransform: "uppercase", color: "black" }}>
                  PAYMENT SOLUTIONS
                </h3>
                <p>
                  Grow revenue, reduce costs, and enhance profitability. Use our
                  comprehensive network to move money seamlessly across the
                  globe, in real-time.<br></br>
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ready-to-talk">
        <div className="container">
          <div className="ready-to-talk-content">
            <h3>Get Started</h3>
            <p>Our team is here to answer your question about JCI REMIT</p>
            <a href="#contactus" className="btn btn-primary">
              Contact Us
            </a>
            {/* <span>
              <a href="#">Or, get started now with a free trial</a>
            </span> */}
          </div>
        </div>
      </section>
      <div className="partner-area">
        <div className="container">
          <h3>
            Join the train of successful businesses and organizations that use
            JCI REMIT
          </h3>
        </div>
      </div>
      <section className="app-download-area" id="download">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12" style={{ marginTop: 60 }}>
              <div className="app-image">
                <div className="main-image">
                  <img
                    src={mainmobile}
                    className="wow animate__animated animate__fadeInLeft"
                    alt="alt mg here"
                  />
                </div>

                <div className="circle-img">
                  <img src={circle} alt="alt mg here" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="app-download-content">
                <h2>Download our app</h2>
                <div className="bar" />
                <p>
                  Get set up and start transferring payments with our
                  user-friendly app. Live exchange rates with our all in on
                  currency converter.
                </p>
                <div className="btn-box">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.jci.jciRemitMobile"
                    target="_blank"
                  >
                    <img src={playstore} height={60} alt="alt img here" />
                  </a>
                  <a
                    href="https://apps.apple.com/ng/app/jci-remit/id1598764368"
                    target="_blank"
                  >
                    <img
                      src={apple}
                      style={{ marginLeft: 16 }}
                      height={60}
                      alt="apple img here"
                    />
                  </a>

                  {/* https://apps.apple.com/ng/app/jci-remit/id1598764368 
                  https://play.google.com/store/apps/details?id=com.jci.jciRemitMobile*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="account-create-area">
        <div className="container">
          <div className="account-create-content">
            <h2>Apply for an account in minutes</h2>
            <p>Get your JCI REMIT account today!</p>
            <a href="/home#download" className="btn btn-primary">
              Get Your JCI REMIT Account
            </a>
          </div>
        </div>
      </section>
      <section className="contact-area ptb-70" id="contactus">
        <div className="container">
          <div className="section-title">
            <h2>Drop us message for any query</h2>
            <div className="bar" />
            <p>
              Our Support team is always happy to help answer your questions.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="contact-info">
                <ul>
                  <li>
                    <div className="icon">
                      <img
                        src={email}
                        className="wow animate__animated animate__zoomIn"
                        alt="alt img here"
                      />
                    </div>
                    <span>Email</span>
                    <span
                      className="__cf_email__"
                      data-cfemail="e38b868f8f8ca38f96958a8c8dcd808c8e"
                    >
                      contact@jciremit.com
                    </span>
                  </li>
                  <li>
                    <div className="icon">
                      <img
                        src={whatsapp}
                        style={{ marginBottom: 6 }}
                        className="wow animate__animated animate__fadeInUp"
                        alt="alt mg here"
                      />
                    </div>
                    <span>Phone</span>
                    <a href="tel:+61431919688">+61431919688</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="contact-form">
                <form>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={contactdetails?.name}
                          className="form-control2 col-md-12"
                          required
                          data-error="Please enter your name"
                          placeholder="Name"
                          onChange={(event) => {
                            let name = event.target.value;
                            setContactdetails({
                              ...contactdetails,
                              name,
                            });
                          }}
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={contactdetails?.email}
                          className="form-control2 col-md-12"
                          required
                          data-error="Please enter your email"
                          placeholder="Email"
                          onChange={(event) => {
                            let email = event.target.value;
                            setContactdetails({
                              ...contactdetails,
                              email,
                            });
                          }}
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <input
                          type="number"
                          name="phone_number"
                          id="phone_number"
                          required
                          value={contactdetails?.phoneNumber}
                          data-error="Please enter your number"
                          className="form-control2 col-md-12"
                          placeholder="Phone"
                          onChange={(event) => {
                            let phoneNumber = event.target.value;
                            setContactdetails({
                              ...contactdetails,
                              phoneNumber,
                            });
                          }}
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="msg_subject"
                          id="msg_subject"
                          className="form-control2 col-md-12"
                          required
                          value={contactdetails?.subject}
                          data-error="Please enter your subject"
                          placeholder="Subject"
                          onChange={(event) => {
                            let subject = event.target.value;
                            setContactdetails({
                              ...contactdetails,
                              subject,
                            });
                          }}
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control2 col-md-12"
                          id="message"
                          cols={30}
                          rows={6}
                          value={contactdetails?.message}
                          required
                          data-error="Write your message"
                          placeholder="Your Message"
                          defaultValue={""}
                          onChange={(event) => {
                            let message = event.target.value;
                            setContactdetails({
                              ...contactdetails,
                              message,
                            });
                          }}
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div
                        // type="submit"
                        className="btn btn-primary"
                        onClick={sendContact}
                      >
                        Send Message
                      </div>
                      <div id="msgSubmit" className="h3 text-center hidden" />
                      <div className="clearfix" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-map">
          <img src={map} alt="alt img here" />
        </div>
      </section>

      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={jcilogo}
                      height={130}
                      alt="logo"
                      style={{ marginTop: -50 }}
                    />
                  </Link>
                  <p>
                    JCI REMIT PTY LTD with ABN Number 18645908907 is a
                    Remittance Service Provider, that is authorized and
                    regulated by the Australian Transaction Reports and Analysis
                    Center, AUSTRAC.
                  </p>
                </div>
                <ul className="social-links">
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Company</h3>
                <ul className="list">
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <a href="#aboutus">Services</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Support</h3>
                <ul className="list">
                  <li>
                    <Link to="/faq#introcontent">FAQ</Link>
                  </li>
                  <li>
                    <a href="#contactus">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget">
                <h3>LEGAL</h3>
                <ul className="list">
                  <li>
                    <Link to="/policies">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/user-agreement">User Agreement</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <p>
              JCI REMIT PTY LTD @ {(new Date()).getFullYear()} JCI REMIT | All Rights Reserved <br></br>

            </p>
          </div>
        </div>
        <div className="map-image">
          <img src={map} alt="map" />
        </div>
      </footer>
      <div className="go-top">
        <i className="fas fa-arrow-up" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allreceivingcurrencystate:
      state.allreceivingcurrencystate.allreceivingcurrencystate,
    allsendingcurrencystate:
      state.allsendingcurrencystate.allsendingcurrencystate,
    advertconversionstate: state.advertconversionstate.advertconversionstate,
    receivingconversionstate:
      state.receivingconversionstate.receivingconversionstate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchallreceivingcountry: (
      show,
      setNotify,
      successaction,
      failaction,
      setShow
    ) => {
      dispatch(GetAction(show, setNotify, successaction, failaction, setShow));
    },
    fetchallsendingcountry: (
      show,
      setNotify,
      successaction,
      failaction,
      setShow
    ) => {
      dispatch(GetAction(show, setNotify, successaction, failaction, setShow));
    },
    createtransact: (
      amount,
      transactiondetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setIsLoading
    ) => {
      dispatch(
        CreateAction2(
          amount,
          transactiondetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setIsLoading
        )
      );
    },
    sendcontact: (
      contactdetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setIsLoading
    ) => {
      dispatch(
        CreateAction(
          contactdetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setIsLoading
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
