/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { LogOutAuthAction, ShowLoading } from "../redux/actions/authaction";
import { connect } from "react-redux";
import { useHistory } from "react-router";

const CustSidemenu = (props) => {
  const { logoutuser, activePage } = props;
  const history = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    logoutuser(history);
  };

  return (
    <div className="scrollbar-sidebar">
      <div className="app-sidebar__inner">
        <ul className="vertical-nav-menu">
          <li style={{ margin: 32, marginLeft: 32 }}>
            {/* <span className="app-sidebar__heading text-danger">Modules</span> */}
          </li>
          <li>
            {(() => {
              if (activePage === "sendmoney") {
                return (
                  <Link className="mm-active text-danger" to="/sendmoney">
                    <i className="metismenu-icon pe-7s-cash " />
                    Send Money
                  </Link>
                );
              } else {
                return (
                  <Link to="/sendmoney" className="">
                    <i className="metismenu-icon pe-7s-cash " />
                    Send Money
                  </Link>
                );
              }
            })()}
          </li>
          <li>
            {(() => {
              if (activePage === "transactionhistory") {
                return (
                  <Link className="mm-active " to="/transactionhistory">
                    <i className="metismenu-icon pe-7s-note2" />
                    Transaction History
                  </Link>
                );
              } else {
                return (
                  <Link to="/transactionhistory" className="">
                    <i className="metismenu-icon pe-7s-note2" />
                    Transaction History
                  </Link>
                );
              }
            })()}
          </li>
          <li>
            {(() => {
              if (activePage === "beneficiaries") {
                return (
                  <Link className="mm-active " to="/beneficiaries">
                    <i className="metismenu-icon pe-7s-users" />
                    Beneficiaries
                  </Link>
                );
              } else {
                return (
                  <Link to="/beneficiaries" className="">
                    <i className="metismenu-icon pe-7s-users" />
                    Beneficiaries
                  </Link>
                );
              }
            })()}
          </li>
          <li>
            {(() => {
              if (activePage === "myaccount") {
                return (
                  <Link className="mm-active " to="/myaccount">
                    <i className="metismenu-icon pe-7s-user" />
                    My Profile
                  </Link>
                );
              } else {
                return (
                  <Link to="/myaccount" className="">
                    <i className="metismenu-icon pe-7s-user" />
                    My Profile
                  </Link>
                );
              }
            })()}
          </li>
          <li>
            {(() => {
              if (activePage === "mywallet") {
                return (
                  <Link className="mm-active " to="/mywallet">
                    <i className="metismenu-icon pe-7s-cash" />
                    My Wallet
                  </Link>
                );
              } else {
                return (
                  <Link to="/mywallet" className="">
                    <i className="metismenu-icon pe-7s-cash" />
                    My Wallet
                  </Link>
                );
              }
            })()}
          </li>

          <li>
            <a onClick={handleLogout} className="text-danger">
              <i className="metismenu-icon pe-7s-back text-danger" />
              Log Out
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loadingstate.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutuser: (history) => {
      dispatch(ShowLoading(""));
      dispatch(LogOutAuthAction(history));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustSidemenu);
