/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Notification from "../components/Notification";
// import SignInarea from "../components/SignInarea";
import "../login/css/style.css";
import img3 from "../assets/images/logo_png.png";
import backgroundImage from "../assets/images/water.png";
import NewRegistration from "./Registration/NewRegistration";

function Registration() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  return (
    <section
      className="ftco-section"
      style={{
        backgroundImage: `url("${backgroundImage}")`,
        backgroundRepeat: "no-repeat",
        background: "cover",
        backgroundPositionX: "center",
      }}
    >
      <Notification notify={notify} setNotify={setNotify} />

      <div className="" style={{ backgroundColor: "transparent" }}>
        <div className="w-50 text-left">
          <a href="/" className="" style={{ marginLeft: 30 }}>
            <img src={img3} height={65} alt="" />
          </a>
        </div>
        <div className="text-center mt-4" style={{ marginRight: 30 }}>
          <h2 style={{ marginTop: 30 }}>
            <strong style={{ color: "black" }}>SIGN UP</strong>
          </h2>
          <span style={{ color: "gray" }}>
            Are you moving money for personal or business purposes?
          </span>
        </div>
      </div>
      <div className="containers" style={{ backgroundColor: "transparent" }}>
        <div className="rows justify-content-centers">
          <div className="col-md-6 text-center -mb-8"></div>
        </div>
        <NewRegistration notify={notify} setNotify={setNotify} />
      </div>
    </section>
  );
}

export default Registration;
