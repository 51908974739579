import React, {useState} from 'react';
import Notification from '../components/Notification';
import ResetPasswordarea from '../components/ResetPasswordarea';
import '../login/css/style.css';
import img3 from "../assets/images/logo_png.png";
import backgroundImage from "../assets/images/water.png";

function ResetPassword() {

    const [notify, setNotify] = useState({
        isOpen: false,
        message: '',
        type: '',
    })


    return (
      <section
        className="ftco-section"
        style={{
          backgroundImage: `url("${backgroundImage}")`,
          backgroundRepeat: "no-repeat",
          background: "cover",
          backgroundPositionX: "center",
        }}
      >
        <Notification notify={notify} setNotify={setNotify} />
        <div className="form-group d-md-flex">
        <div className="w-50 text-left" style={{ marginRight: -110 }}>
          <a href="/" className="" style={{ marginLeft: 5 }}>
            <img src={img3} height={90} alt="" />
          </a>
        </div>
        <div className="text-center" style={{ marginRight: 30 }}>
          <h2>
            <strong>FORGOT PASSWORD</strong>
          </h2>
        </div>
      </div>
        <div className="containers">
          <div className="rows justify-content-centers">
            <div className="col-md-6 text-center mb-5"></div>
          </div>

          <ResetPasswordarea notify={notify} setNotify={setNotify} />
        </div>
      </section>
    );
}

export default ResetPassword;
