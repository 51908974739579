import React from "react";
import { Link } from "react-router-dom";
import { LogOutAuthAction, ShowLoading } from "../redux/actions/authaction";
import { connect } from "react-redux";
import { useHistory } from "react-router";

const Sidemenu = (props) => {
  const { logoutuser, activePage } = props;
  const history = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    logoutuser(history);
  };

  return (
    <div className="scrollbar-sidebar">
      <div className="app-sidebar__inner">
        <ul className="vertical-nav-menu">
          {/* <li className="app-sidebar__heading text-danger">Modules</li> */}
          <li>
            {(() => {
              if (activePage === "dashboard") {
                return (
                  <Link className="mm-active " to="/dashboard">
                    <i className="metismenu-icon pe-7s-home " />
                    Dashboard
                  </Link>
                );
              } else {
                return (
                  <Link to="/dashboard" className="">
                    <i className="metismenu-icon pe-7s-home " />
                    Dashboard
                  </Link>
                );
              }
            })()}
          </li>
          <li>
            {(() => {
              if (activePage === "transactions") {
                return (
                  <Link className="mm-active " to="/transactions">
                    <i className="metismenu-icon pe-7s-cart " />
                    Transactions
                  </Link>
                );
              } else {
                return (
                  <Link to="/transactions" className="">
                    <i className="metismenu-icon pe-7s-cart " />
                    Transactions
                  </Link>
                );
              }
            })()}
          </li>

          <li>
            {(() => {
              if (activePage === "users") {
                return (
                  <Link className="mm-active " to="/users">
                    <i className="metismenu-icon pe-7s-users " />
                    User & Verifications
                  </Link>
                );
              } else {
                return (
                  <Link to="/users" className="">
                    <i className="metismenu-icon pe-7s-users " />
                    User & Verifications
                  </Link>
                );
              }
            })()}
          </li>
          <li>
            {(() => {
              if (activePage === "charges") {
                return (
                  <Link className="mm-active " to="/charges">
                    <i className="metismenu-icon pe-7s-helm " />
                    Charges Management
                  </Link>
                );
              } else {
                return (
                  <Link to="/charges" className="">
                    <i className="metismenu-icon pe-7s-helm " />
                    Charges Management
                  </Link>
                );
              }
            })()}
          </li>
          <li>
            {(() => {
              if (activePage === "rates") {
                return (
                  <Link className="mm-active " to="/rates">
                    <i className="metismenu-icon pe-7s-tools " />
                    Rates Management
                  </Link>
                );
              } else {
                return (
                  <Link to="/rates" className="">
                    <i className="metismenu-icon pe-7s-tools " />
                    Rates Management
                  </Link>
                );
              }
            })()}
          </li>
          <li>
            {(() => {
              if (activePage === "promos") {
                return (
                  <Link className="mm-active " to="/promos">
                    <i className="metismenu-icon pe-7s-target " />
                    Promo Management
                  </Link>
                );
              } else {
                return (
                  <Link to="/promos" className="">
                    <i className="metismenu-icon pe-7s-target " />
                    Promo Management
                  </Link>
                );
              }
            })()}
          </li>
          {/* <li>
            {(() => {
              if (activePage === "referral") {
                return (
                  <Link className="mm-active " to="/referral">
                    <i className="metismenu-icon pe-7s-refresh-2 " />
                    Referral Management
                  </Link>
                );
              } else {
                return (
                  <Link to="/referral" className="">
                    <i className="metismenu-icon pe-7s-refresh-2 " />
                    Referral Management
                  </Link>
                );
              }
            })()}
          </li> */}
          <li>
            {(() => {
              if (activePage === "customerreferral") {
                return (
                  <Link className="mm-active " to="/customerreferral">
                    <i className="metismenu-icon pe-7s-refresh " />
                    Customer Referral
                  </Link>
                );
              } else {
                return (
                  <Link to="/customerreferral" className="">
                    <i className="metismenu-icon pe-7s-refresh " />
                    Customer Referral
                  </Link>
                );
              }
            })()}
          </li>
          <li>
            {(() => {
              if (activePage === "accounts") {
                return (
                  <Link className="mm-active " to="/accounts">
                    <i className="metismenu-icon pe-7s-cash " />
                    Bank Accounts
                  </Link>
                );
              } else {
                return (
                  <Link to="/accounts" className="">
                    <i className="metismenu-icon pe-7s-cash " />
                    Bank Accounts
                  </Link>
                );
              }
            })()}
          </li>
          <li>
            {(() => {
              if (activePage === "ausreport") {
                return (
                  <Link className="mm-active " to="/ausreport">
                    <i className="metismenu-icon pe-7s-print " />
                    Austrac Report
                  </Link>
                );
              } else {
                return (
                  <Link to="/ausreport" className="">
                    <i className="metismenu-icon pe-7s-print " />
                    Austrac Report
                  </Link>
                );
              }
            })()}
          </li>
          <li>
            <button className="text-danger btn btn-icon" onClick={handleLogout}>
              <i className="metismenu-icon pe-7s-back text-danger mr-2" />
              <span className="ml-2">Log Out</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loadingstate.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutuser: (history) => {
      dispatch(ShowLoading(""));
      dispatch(LogOutAuthAction(history));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
