import React, { useState, forwardRef, useImperativeHandle } from "react";
import SelectSearch, { fuzzySearch } from "react-select-search";

const StepOneReg = forwardRef((props, ref) => {
  // const StepOneReg = (props) => {

  let {
    setUserdetails,
    userdetails,
    isPhoneValid,
    checkphone,
    phoneerrortext,
    allcountriesstate,
    handlereturnvalue2,
  } = props;
  const allcountriesarray = [];
  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.country + " " + item.alpha3Code;
      newitem.currency = item.currency;
      allcountriesarray.push(newitem);
    }
  }
  const [uiError2, setUiError2] = useState(false);
  const [uiError, setUiError] = useState(false);
  const [uiError3, setUiError3] = useState(false);
  const [uiError4, setUiError4] = useState(false);

  useImperativeHandle(ref, () => ({
    isValidated() {
      setUiError4(false);
      setUiError3(false);
      setUiError2(false);
      setUiError(false);
      if (!userdetails.firstName) {
        // setUiError(false);
        setUiError2(true);
        return false;
      } else if (!userdetails.lastName) {
        setUiError(true);
        return false;
      } else if (!userdetails.phoneNumber) {
        setUiError3(true);
        return false;
      } else if (!userdetails.countryOfNationality) {
        setUiError4(true);
        return false;
      } else {
        setUiError4(false);
        setUiError3(false);
        setUiError2(false);
        setUiError(false);
        return true;
      }
    },
  }));

  return (
    <div id="step-1">
      <div id="accordion" className="accordion-wrapper mb-3">
        <div className="card">
          <div id="headingOne" className="card-header">
            <button
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              className="text-left m-0 p-0 btn btn-link btn-block"
            >
              <span className="form-heading text-danger">
                Personal Information<p>Enter your personal details</p>
              </span>
            </button>
          </div>
          <div
            data-parent="#accordion"
            id="collapseOne"
            aria-labelledby="headingOne"
            className="collapse show"
          >
            <div className="card-body">
              <div className="form-row">
                <div className="col-md-12">
                  <div className="position-relative form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      name="text"
                      id="firstName"
                      defaultValue={userdetails.firstName}
                      placeholder="First Name"
                      type="text"
                      className="form-control"
                      onChange={(event) => {
                        const firstName = event.target.value;
                        setUserdetails({ ...userdetails, ...{ firstName } });
                      }}
                    />
                    {uiError2 && (
                      <div
                        className="bg-danger"
                        style={{
                          marginTop: "1rem",
                          display: "table",
                          color: "white",
                        }}
                      >
                        You need to suppy first name
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="position-relative form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      name="text"
                      id="lastName"
                      placeholder="Last Name"
                      defaultValue={userdetails.lastName}
                      type="text"
                      className="form-control"
                      onChange={(event) => {
                        const lastName = event.target.value;
                        setUserdetails({ ...userdetails, ...{ lastName } });
                      }}
                    />
                    {uiError && (
                      <div
                        className="bg-danger"
                        style={{
                          marginTop: "1rem",
                          display: "table",
                          color: "white",
                        }}
                      >
                        You need to suppy last name
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <label htmlfor="examplePhone">Phone Number</label>
                  <div className="position-relative form-group">
                    <input
                      name="phone"
                      id="examplePhone"
                      maxLength={11}
                      defaultValue={userdetails.phoneNumber}
                      placeholder="Phone Number"
                      type="tel"
                      className="form-control col-md-12"
                      style={{
                        backgroundColor: isPhoneValid ? "" : "red",
                      }}
                      onChange={(event) => {
                        const phoneNumber = event.target.value;
                        setUserdetails({
                          ...userdetails,
                          ...{ phoneNumber },
                        });
                        checkphone(phoneNumber);
                      }}
                    />
                    {uiError3 && (
                      <div
                        className="bg-danger"
                        style={{
                          marginTop: "1rem",
                          display: "table",
                          color: "white",
                        }}
                      >
                        You need to suppy your phone number
                      </div>
                    )}
                    <span className="text-danger">{phoneerrortext}</span>
                  </div>
                </div>

                <div className="col-md-8">
                  <label htmlFor="exampleCountry">
                    <span className="text-danger"></span>Select Country Of
                    Residence
                  </label>
                  <div className="position-relative form-group">
                    <SelectSearch
                      options={allcountriesarray}
                      search={true}
                      id="CountrySelect01"
                      // renderValue={userdetails.countryOfNationality}
                      defaultValue={userdetails.countryOfNationality}
                      onChange={handlereturnvalue2}
                      filterOptions={fuzzySearch}
                      placeholder="Choose your country"
                    />
                    {uiError4 && (
                      <div
                        className="bg-danger"
                        style={{
                          marginTop: "1rem",
                          display: "table",
                          color: "white",
                        }}
                      >
                        You need to suppy your country of residence
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  {(() => {
                    if (userdetails.countryOfNationality) {
                      return (
                        <>
                          <span
                            className="form-heading text-danger"
                            style={{ fontSize: 24, color: "green" }}
                          >
                            <p>Selected</p>
                          </span>
                          <span
                            style={{
                              fontSize: 24,
                              color: "red",
                              marginTop: 32,
                            }}
                          >
                            {userdetails.countryOfNationality}
                          </span>
                        </>
                      );
                    } else {
                      return <></>;
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default StepOneReg;
