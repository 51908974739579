import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ActionTypes from "../../redux/actiontype/ActionTypes";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { EditAction } from "../../redux/actions/editaction";

const EditAccountModal = (props) => {
  const {
    editaccount,
    setNotify,
    show,
    setShow,
    item,
    handleShowEdit,
    allcountriesstate,
    addaccountLoading,
    setAddAccountLoading,
  } = props;
  const [accountdetails, setAccountdetails] = useState({});
  const countryarray = [];

  const handleSubmit = (e) => {
    e.preventDefault();
    setAddAccountLoading(true);
    editaccount(
      accountdetails,
      setNotify,
      ActionTypes.UPDATE_BANK_ACCOUNT_SUCCESS,
      ActionTypes.UPDATE_BANK_ACCOUNT_FAIL,
      setShow,
      setAddAccountLoading
    );
  };

  useEffect(() => {
    if (Object.keys(item).length !== 0) {
      let id = item.id;
      let accountName = item.accountName;
      let bankAccountNumber = item.bankAccountNumber;
      let bankName = item.bankName;
      let bankSwiftCode = item.bankSwiftCode;
      let country = item.country;
      let countryCode = item.countryCode;
      let bankIdentifier = item.bankIdentifier;
      let bankIdentifierCode = item.bankIdentifierCode;

      setAccountdetails({
        ...accountdetails,
        ...{
          id,
          accountName,
          bankAccountNumber,
          bankName,
          bankSwiftCode,
          country,
          countryCode,
          bankIdentifier,
          bankIdentifierCode,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handlereturnvalue = (cout) => {
    const countryCode = countryarray.find((c) => c.value === cout)?.countryCode;
    const country = cout;
    setAccountdetails({ ...accountdetails, ...{ countryCode, country } });
  };

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.country;
      newitem.currency = item.currency;
      newitem.countryCode = item.alpha3Code;
      countryarray.push(newitem);
    }
  }

  useEffect(() => {}, [show]);

  return (
    <Modal
      show={show}
      onHide={handleShowEdit}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="exampleGender">Country</label>

                <SelectSearch
                  options={countryarray}
                  search={true}
                  value={item.country}
                  onChange={handlereturnvalue}
                  filterOptions={fuzzySearch}
                  placeholder="Choose your country"
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="bankName">Bank Name</label>
                <input
                  name="bankName"
                  id="bankName"
                  placeholder={item.bankName}
                  defaultValue={item.bankName}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const bankName = event.target.value;
                    const id = item.id;
                    setAccountdetails({
                      ...accountdetails,
                      ...{ bankName, id },
                    });
                  }}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="bankSwiftCode">Bank Swift Code</label>
                <input
                  name="bankSwiftCode"
                  id="bankSwiftCode"
                  placeholder={item.bankSwiftCode}
                  defaultValue={item.bankSwiftCode}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const bankSwiftCode = event.target.value;
                    setAccountdetails({
                      ...accountdetails,
                      ...{ bankSwiftCode },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="bankAccountNumber">Bank Account Number</label>
                <input
                  name="bankAccountNumber"
                  id="bankAccountNumber"
                  placeholder={item.bankAccountNumber}
                  defaultValue={item.bankAccountNumber}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const bankAccountNumber = event.target.value;
                    setAccountdetails({
                      ...accountdetails,
                      ...{ bankAccountNumber },
                    });
                  }}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="accountName">Account Name</label>
                <input
                  name="accountName"
                  id="accountName"
                  placeholder={item.accountName}
                  defaultValue={item.accountName}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const accountName = event.target.value;
                    setAccountdetails({
                      ...accountdetails,
                      ...{ accountName },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="bankIdentifier">Bank Identifier</label>
                <select
                  type="select"
                  id="gender"
                  name="gender"
                  className="mb-2 form-control"
                  defaultValue={item.bankIdentifier}
                  onChange={(event) => {
                    const bankIdentifier = event.target.value;
                    setAccountdetails({
                      ...accountdetails,
                      ...{ bankIdentifier },
                    });
                  }}
                >
                  <option value="">-- Select --</option>
                  <option value="ABA/Fed Wire/Routing No">
                    ABA/Fed Wire/Routing No.
                  </option>
                  <option value="BSB">BSB</option>
                  <option value="Chips Number">Chips Number</option>
                  <option value="IFSC code">IFSC code </option>
                  <option value="Sort code">Sort code</option>
                </select>
              </div>

              <div className="col-md-6">
                <label htmlFor="bankIdentifierCode">Bank Identifier Code</label>
                <input
                  name="bankIdentifierCode"
                  id="bankIdentifierCode"
                  placeholder={item.bankIdentifierCode}
                  defaultValue={item.bankIdentifierCode}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const bankIdentifierCode = event.target.value;
                    setAccountdetails({
                      ...accountdetails,
                      ...{ bankIdentifierCode },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShowEdit}>
          Close
        </Button>
        <Button
          variant="danger"
          disabled={addaccountLoading}
          onClick={handleSubmit}
        >
          Update Account
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    allcountriesstate: state.allcountriesstate.allcountriesstate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editaccount: (
      accountdetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setChargesLoading
    ) => {
      dispatch(
        EditAction(
          accountdetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setChargesLoading
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAccountModal);
