import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CreateAction } from "../redux/actions/createaction";
import { downloadXLSFile } from "../redux/actions/downloadaction";
import moment from "moment";
import { GetAction } from "../redux/actions/getaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import { ExportCSV } from "../utils/excelExport";

const Austracreportarea = (props) => {
  const { fetchreporttransactions, setNotify, reporttransactions, setShow } =
    props;
  const [austracdetails, setAustracdetails] = useState({});

  useEffect(() => {
    if (reporttransactions !== undefined) {
      if (austracdetails.fromDateText) {
        let filename =
          "Austrac Report between " +
          austracdetails.fromDateText +
          " and " +
          austracdetails.toDateText;
        ExportCSV({ csvData: reporttransactions, fileName: filename });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reporttransactions]);

  const handleSubmit = () => {
    const from = moment(austracdetails.fromDateText);
    const to = moment(austracdetails.toDateText);
    let sfrom = from.format("DD-MM-YYYY");
    let sto = to.format("DD-MM-YYYY");
    let stateobject = {};
    stateobject.startDate = sfrom;
    stateobject.endDate = sto;

    fetchreporttransactions(
      stateobject,
      setNotify,
      ActionTypes.FETCH_AUSTRAC_REPORT_SUCCESS,
      ActionTypes.FETCH_AUSTRAC_REPORT_FAIL,
      setShow
    );
    // downloadXLSFile(
    //   "https://api.jciremit.com/api/Transactions/ExportAustracReportByDateRange/" +
    //     sfrom +
    //     "/" +
    //     sto
    // );
  };
  function formatNaira(num) {
    var p = num.toFixed(2).split(".");
    return (
      // "₦" +
      p[0]
        .split("")
        .reverse()
        .reduce(function (acc, num, i, orig) {
          return num === "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
        }, "") +
      "." +
      p[1]
    );
  }
  const renderrow = (items) => {
    if (Object.keys(items).length !== 0) {
      return items.map((item, i) => {
        return (
          <>
            <tr>
              <td>{item.id}</td>
              <td>
                <b>{item.transactionRefNo}</b>
              </td>
              <td>{item.dateMoneyReceived.substring(0, 10)}</td>
              <td>{item.currencyCode}</td>
              <td>{formatNaira(item.transactionAmount)}</td>
              <td>{item.senderFullName}</td>
              <td>{item.senderCountry}</td>
              <td>{item.senderEmail}</td>
              <td>{item.receiverFullName}</td>
              <td>{item.receiverCountry}</td>
              <td>{item.receiverAccountNumber}</td>
              <td>{item.receiverBankCountry}</td>
            </tr>
          </>
        );
      });
    }
  };

  return (
    <>
      <div className="app-page-title app-page-title-simple">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div>
              <div className="page-title-head center-elem">
                <span className="d-inline-block">Austrac Reports</span>
              </div>
              <div className="page-title-subheading opacity-10">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <span>Here you can manage austrac reports</span>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-xl-12">
        <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
          <div className="widget-chat-wrapper-outer">
            <div className="widget-chart-content pt-3 pl-3 pb-1">
              <div className="form-row">
                <div className="col-md-12 position-relative form-group">
                  <div className="form-row">
                    {/* <div className="col-md-3">
                      <div className="col-md-3 col-xl-3">
                        <div
                          className="card no-shadow rm-border bg-transparent text-right"
                          style={{ marginTop: 30 }}
                        >
                          <img
                            src="assets/images/logo-inverse.jpeg"
                            alt="alt img here"
                          />
                          <div className="widget-chart-content" />
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-4">
                      <label htmlFor="fromDateText">From Date</label>
                      <div className="position-relative form-group">
                        <input
                          name="date"
                          id="fromDateText"
                          placeholder="From Date"
                          type="date"
                          className="form-control"
                          onChange={(event) => {
                            const fromDateText = event.target.value;
                            setAustracdetails({
                              ...austracdetails,
                              ...{ fromDateText },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="toDateText">To Date</label>
                      <div className="position-relative form-group">
                        <input
                          name="date"
                          id="toDateText"
                          placeholder="To Date"
                          type="date"
                          className="form-control"
                          onChange={(event) => {
                            const toDateText = event.target.value;
                            setAustracdetails({
                              ...austracdetails,
                              ...{ toDateText },
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-4" style={{ marginLeft: 0 }}>
                      {/* <label htmlfor="exampleDate"></label> */}
                      <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                        <span className="text-success pl-2">
                          <button
                            className="btn-wide mb-2 mr-2 btn btn-shadow btn-danger btn-lg"
                            onClick={handleSubmit}
                          >
                            Export Report
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-card mb-3 card">
        <div className="card-body">
          {reporttransactions ? (
            <table
              style={{ width: "100%" }}
              id="example"
              className="table table-hover table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th>No</th>
                  <th>Transaction Ref No</th>
                  <th>Date Money Received</th>
                  <th>Currency Code</th>
                  <th>Transaction Amount</th>
                  <th>Sender FullName</th>
                  <th>Sender Country</th>
                  <th>Sender Email</th>
                  <th>Receiver FullName</th>
                  <th>Receiver Country</th>
                  <th>Receiver Account Number</th>
                  <th>Receiver Bank Country</th>
                </tr>
              </thead>
              <tbody>{renderrow(reporttransactions)}</tbody>
            </table>
          ) : (
            <p>No selected date range</p>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    austracreport: state.austracreport.austracreport,
    reporttransactions: state.reporttransactions.reporttransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchaustracreport: (
      austracdetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setAustracLoading
    ) => {
      dispatch(
        CreateAction(
          austracdetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setAustracLoading
        )
      );
    },
    fetchreporttransactions: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Austracreportarea);
