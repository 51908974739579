/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { EditAction } from "../redux/actions/editaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import { CreateAction } from "../redux/actions/createaction";
import img1 from "../assets/images/JCI.png";

const ResetPasswordarea = (props) => {
  const { resetpwd, isLoading, setNotify } = props;
  const [userdetails, setUserdetails] = useState({});
  // const history = useHistory();
  // const [password, setPassword] = useState(true);
  const [loginloading, setLoginLoading] = useState(false);

  const handleSubmit = (e) => {
    if (
      userdetails.newPassword !== undefined &&
      userdetails.resetKey !== undefined &&
      userdetails.newPassword !== "" &&
      userdetails.resetKey !== ""
    ) {
      e.preventDefault();
      setLoginLoading(true);
      resetpwd(
        userdetails,
        setNotify,
        ActionTypes.RESET_PASSWORD_SUCCESS,
        ActionTypes.RESET_PASSWORD_FAIL,
        setLoginLoading,
        setLoginLoading
      );
    } else {
      setNotify({
        isOpen: true,
        message: "kindly fill all empty spaces",
        type: "error",
      });
    }
  };

  useEffect(() => {}, [isLoading]);
  //to chaeck the login state of the user on loads
  useEffect(() => {}, []);

  return (
    <div className="rows justify-content-centers">
      <div className="col-md-8 col-lg-6">
        <div
          className="wrap d-md-flex"
          style={{ backgroundColor: "transparent", marginTop: -50 }}
        >
          <div className="login-wrap p-4 col-md-12">
            <div className="d-flex">
              <div className="w-100"></div>
              {/* <div className="w-100">
                <p className="social-media d-flex justify-content-end">
                  <a
                    href="https://www.apple.com"
                    target="_blank"
                    className="social-icon d-flex align-items-center justify-content-centers"
                  >
                    <span className="fa fa-apple" />
                  </a>
                  <a
                    href="https://www.google.com"
                    target="_blank"
                    className="social-icon d-flex align-items-center justify-content-centers"
                  >
                    <span className="fa fa-android" />
                  </a>
                </p>
              </div> */}
            </div>
            <form action="#" className="signin-form">
              <div className="dropdown-menu-header-inner bg-danger">
                <div
                  className="menu-header-image"
                  style={{
                    backgroundImage: "url(../login/images/bg-1.jpeg)",
                  }}
                />
              </div>
              <div className="form-group mb-3 mt-2">
                <label className="label" htmlFor="newPassword">
                  New Password
                </label>
                <input
                  type="password"
                  className="form-controls form-control-height"
                  placeholder="Password"
                  required
                  id="newPassword"
                  onChange={(event) => {
                    const newPassword = event.target.value;
                    setUserdetails({ ...userdetails, ...{ newPassword } });
                  }}
                />
              </div>

              <div className="form-group mb-3 mt-2">
                <label className="label" htmlFor="resetKey">
                  Reset Key
                </label>
                <input
                  type="text"
                  className="form-controls form-control-height"
                  placeholder="Reset Key"
                  required
                  id="resetKey"
                  onChange={(event) => {
                    const resetKey = event.target.value;
                    setUserdetails({ ...userdetails, ...{ resetKey } });
                  }}
                />
              </div>

              <div className="form-group mt-3">
                <button
                  type="submit"
                  className="form-controls form-control-height btn btn-primary rounded submit px-3"
                  disabled={loginloading}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
              {/* <div className="d-md-flex">
                <div className="w-50 text-left">
                  <Link
                    to="/introreg"
                    className="checkbox-primary checkbox-wrap2"
                  >
                    Sign Up
                  </Link>
                </div>
                <div className="w-50 text-md-right">
                  <a href="/resetpassword">Reset Password</a>
                </div>
              </div> */}
            </form>
            <p className="text-center">
              Already a member?{" "}
              <Link to="/login" className="checkbox-primary checkbox-wrap2">
                Sign In
              </Link>
              {/* <Link
                to="/"
                className="checkbox-success checkbox-wrap2"
                style={{ marginLeft: 32 }}
              >
                Go Home
              </Link> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loadingstate.isLoading,
    authstate: state.authstate.authstate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetpwd: (
      pwddetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setCpwdLoading
    ) => {
      dispatch(
        CreateAction(
          pwddetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setCpwdLoading
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordarea);
