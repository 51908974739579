import React,  { useState, useEffect } from 'react'
import AppHeader from '../components/AppHeader'
import AppSidebar from '../components/AppSidebar'
import Footer from '../components/Footer'
import Transactionsarea from '../components/Transactionsarea'

export default function Transactions(props) {

    const {activePage, setActivePage} = props;

    const [notify, setNotify] = useState({
        isOpen: false,
        message: '',
        type: '',
    })

    useEffect(() => {
        setActivePage('transactions');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(!show);
    }

    const [transactionLoading, setTransactionLoading] = useState(false);

    return (
        <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
            <AppHeader notify={notify} setNotify={setNotify}/>
            <div className="app-main">
            
                <AppSidebar activePage={activePage} setActivePage={setActivePage}/>

                <div className="app-main__outer">
                    <div className="app-main__inner">                        

                        <Transactionsarea show={show} notify={notify} setNotify={setNotify} handleShow={handleShow} setShow={setShow} transactionLoading={transactionLoading} setTransactionLoading={setTransactionLoading}/>

                        <Footer/>
                    
                    </div>
                </div>        
            </div>
            <div className="app-drawer-overlay d-none animated fadeIn" />
        </div>
        
    )
}
