const ActionTypes = {
  // auth action types
  USER_REGISTRATION_SUCCESS: "USER_REGISTRATION_SUCCESS",
  USER_REGISTRATION_FAIL: "USER_REGISTRATION_FAIL",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "USER_LOGIN_FAIL",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAIL: "USER_LOGOUT_FAIL",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAIL: "UPDATE_PASSWORD_FAIL",
  UPDATE_PIN_SUCCESS: "UPDATE_PIN_SUCCESS",
  UPDATE_PIN_FAIL: "UPDATE_PIN_FAIL",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAIL: "UPDATE_PROFILE_FAIL",
  UPDATE_BANK_ACCOUNT_SUCCESS: "UPDATE_BANK_ACCOUNT_SUCCESS",
  UPDATE_BANK_ACCOUNT_FAIL: "UPDATE_BANK_ACCOUNT_FAIL",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",

  //loading state action types
  LOADING_SHOW: "LOADING_SHOW",
  LOADING_HIDE: "LOADING_HIDE",

  //verification action types
  MAIL_EXIST_SUCCESS: "MAIL_EXIST_SUCCESS",
  MAIL_EXIST_FAIL: "MAIL_EXIST_FAIL",
  PHONE_EXIST_SUCCESS: "PHONE_EXIST_SUCCESS",
  PHONE_EXIST_FAIL: "PHONE_EXIST_FAIL",

  //fetch action types
  FETCH_COUNTRIES_SUCCESS: "FETCH_COUNTRIES_SUCCESS",
  FETCH_COUNTRIES_FAIL: "FETCH_COUNTRIES_FAIL",
  FETCH_ALL_COUNTRIES_SUCCESS: "FETCH_ALL_COUNTRIES_SUCCESS",
  FETCH_ALL_COUNTRIES_FAIL: "FETCH_ALL_COUNTRIES_FAIL",
  FETCH_COUNTRIES_CODE_SUCCESS: "FETCH_COUNTRIES_CODE_SUCCESS",
  FETCH_COUNTRIES_CODE_FAIL: "FETCH_COUNTRIES_CODE_FAIL",
  FETCH_STATES_SUCCESS: "FETCH_STATES_SUCCESS",
  FETCH_STATES_FAIL: "FETCH_STATES_FAIL",

  FETCH_CUSTOMER_TRANSACTION_SUCCESS: "FETCH_CUSTOMER_TRANSACTION_SUCCESS",
  FETCH_CUSTOMER_TRANSACTION_FAIL: "FETCH_CUSTOMER_TRANSACTION_FAIL",
  FETCH_ALL_TRANSACTION_SUCCESS: "FETCH_ALL_TRANSACTION_SUCCESS",
  FETCH_ALL_TRANSACTION_FAIL: "FETCH_ALL_TRANSACTION_FAIL",
  FETCH_TYPE_TRANSACTION_SUCCESS: "FETCH_TYPE_TRANSACTION_SUCCESS",
  FETCH_TYPE_TRANSACTION_FAIL: "FETCH_TYPE_TRANSACTION_FAIL",
  FETCH_REF_TRANSACTION_SUCCESS: "FETCH_REF_TRANSACTION_SUCCESS",
  FETCH_REF_TRANSACTION_FAIL: "FETCH_REF_TRANSACTION_FAIL",
  FETCH_AUSTRAC_SUCCESS: "FETCH_AUSTRAC_SUCCESS",
  FETCH_AUSTRAC_FAIL: "FETCH_AUSTRAC_FAIL",
  FETCH_USER_PROFILE_SUCCESS: "FETCH_USER_PROFILE_SUCCESS",
  FETCH_USER_PROFILE_FAIL: "FETCH_USER_PROFILE_FAIL",
  FETCH_ALL_USERS_SUCCESS: "FETCH_ALL_USERS_SUCCESS",
  FETCH_ALL_USERS_FAIL: "FETCH_ALL_USERS_FAIL",
  FETCH_USERS_BY_COUNTRY_SUCCESS: "FETCH_USERS_BY_COUNTRY_SUCCESS",
  FETCH_USERS_BY_COUNTRY_FAIL: "FETCH_USERS_BY_COUNTRY_FAIL",
  FETCH_TRANSACTIONS_BY_COUNTRY_SUCCESS:
    "FETCH_TRANSACTIONS_BY_COUNTRY_SUCCESS",
  FETCH_TRANSACTIONS_BY_COUNTRY_FAIL: "FETCH_TRANSACTIONS_BY_COUNTRY_FAIL",
  FETCH_SINGLE_USER_SUCCESS: "FETCH_SINGLE_USER_SUCCESS",
  FETCH_SINGLE_USER_FAIL: "FETCH_SINGLE_USER_FAIL",
  FETCH_PENDING_TRANSACTIONS_SUCCESS: "FETCH_PENDING_TRANSACTIONS_SUCCESS",
  FETCH_PENDING_TRANSACTIONS_FAIL: "FETCH_PENDING_TRANSACTIONS_FAIL",
  FETCH_FUNDS_SUCCCESS: "FETCH_FUNDS_SUCCCESS",
  FETCH_FUNDS_FAIL: "FETCH_FUNDS_FAIL",
  FETCH_TRANSACTION_REPORT_SUCCESS: "FETCH_TRANSACTION_REPORT_SUCCESS",
  FETCH_TRANSACTION_REPORT_FAIL: "FETCH_TRANSACTION_REPORT_FAIL",
  FETCH_AUSTRAC_REPORT_SUCCESS: "FETCH_AUSTRAC_REPORT_SUCCESS",
  FETCH_AUSTRAC_REPORT_FAIL: "FETCH_AUSTRAC_REPORT_FAIL",
  FETCH_PROMO_TRANSACTION_SUCCESS: "FETCH_PROMO_TRANSACTION_SUCCESS",
  FETCH_PROMO_TRANSACTION_FAIL: "FETCH_PROMO_TRANSACTION_FAIL",

  //create/add action types
  ADD_CHARGES_SUCCESS: "ADD_CHARGES_SUCCESS",
  ADD_CHARGES_FAIL: "ADD_CHARGES_FAIL",
  ADD_PROMO_SUCCESS: "ADD_PROMO_SUCCESS",
  ADD_PROMO_FAIL: "ADD_PROMO_FAIL",
  ADD_REFERRAL_SUCCESS: "ADD_REFERRAL_SUCCESS",
  ADD_REFERRAL_FAIL: "ADD_REFERRAL_FAIL",
  ADD_RATE_SUCCESS: "ADD_RATE_SUCCESS",
  ADD_RATE_FAIL: "ADD_RATE_FAIL",
  ADD_BANK_SUCCESS: "ADD_BANK_SUCCESS",
  ADD_BANK_FAIL: "ADD_BANK_FAIL",
  ADD_CONVERT_SUCCESS: "ADD_CONVERT_SUCCESS",
  ADD_CONVERT_FAIL: "ADD_CONVERT_FAIL",
  ADD_TRANSACTION_SUCCESS: "ADD_TRANSACTION_SUCCESS",
  ADD_TRANSACTION_FAIL: "ADD_TRANSACTION_FAIL",
  ADD_ADVERT_CONVERTION_SUCCESS: "ADD_ADVERT_CONVERTION_SUCCESS",
  ADD_ADVERT_CONVERTION_FAIL: "ADD_ADVERT_CONVERTION_FAIL",
  SEND_CONTACT_SUCCESS: "SEND_CONTACT_SUCCESS",
  SEND_CONTACT_FAIL: "SEND_CONTACT_FAIL",
  ADD_RECEIVING_CONVERTION_SUCCESS: "ADD_RECEIVING_CONVERTION_SUCCESS",
  ADD_RECEIVING_CONVERTION_FAIL: "ADD_RECEIVING_CONVERTION_FAIL",

  ADD_EXISTING_BENEFICIARY_SUCCESS: "ADD_EXISTING_BENEFICIARY_SUCCESS",
  ADD_EXISTING_BENEFICIARY_FAIL: "ADD_EXISTING_BENEFICIARY_FAIL",
  ADD_NEW_BENEFICIARY_SUCCESS: "ADD_NEW_BENEFICIARY_SUCCESS",
  ADD_NEW_BENEFICIARY_FAIL: "ADD_NEW_BENEFICIARY_FAIL",
  ADD_NEW_BENEFICIARY_SUCCESS2: "ADD_NEW_BENEFICIARY_SUCCESS2",
  ADD_NEW_BENEFICIARY_FAIL2: "ADD_NEW_BENEFICIARY_FAIL2",
  ADD_PAYMENTS_TO_TRANSACTION_SUCCESS: "ADD_PAYMENTS_TO_TRANSACTION_SUCCESS",
  ADD_PAYMENTS_TO_TRANSACTION_FAIL: "ADD_PAYMENTS_TO_TRANSACTION_FAIL",
  CONFIRM_POLI_PAYMENTS_SUCCESS: "CONFIRM_POLI_PAYMENTS_SUCCESS",
  CONFIRM_POLI_PAYMENTS_FAIL: "CONFIRM_POLI_PAYMENTS_FAIL",
  CONFIRM_CC_PAYMENTS_SUCCESS: "CONFIRM_CC_PAYMENTS_SUCCESS",
  CONFIRM_CC_PAYMENTS_FAIL: "CONFIRM_CC_PAYMENTS_FAIL",
  UPLOAD_PAYMENTS_CONFIRMATION_SUCCESS: "UPLOAD_PAYMENTS_CONFIRMATION_SUCCESS",
  UPLOAD_PAYMENTS_CONFIRMATION_FAIL: "UPLOAD_PAYMENTS_CONFIRMATION_FAIL",
  ADD_TRANSACTION_RECEIVER_SUCCESS: "ADD_TRANSACTION_RECEIVER_SUCCESS",
  ADD_TRANSACTION_RECEIVER_FAIL: "ADD_TRANSACTION_RECEIVER_FAIL",
  DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS: "DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS",
  DOWNLOAD_TRANSACTION_RECEIPT_FAIL: "DOWNLOAD_TRANSACTION_RECEIPT_FAIL",
  GET_APP_SETTINGS_SUCCESS: "GET_APP_SETTINGS_SUCCESS",
  GET_APP_SETTINGS_FAIL: "GET_APP_SETTINGS_FAIL",
  GET_WALLET_BALANCE_SUCCESS: "GET_WALLET_BALANCE_SUCCESS",
  GET_WALLET_BALANCE_FAIL: "GET_WALLET_BALANCE_FAIL",
  GET_REFERRAL_TRANSACTION_SUCCESS: "GET_REFERRAL_TRANSACTION_SUCCESS",
  GET_REFERRAL_TRANSACTION_FAIL: "GET_REFERRAL_TRANSACTION_FAIL",
  GET_REFERRAL_TRANSACTION_BY_ADMIN_SUCCESS:
    "GET_REFERRAL_TRANSACTION_BY_ADMIN_SUCCESS",
  GET_REFERRAL_TRANSACTION_BY_ADMIN_FAIL:
    "GET_REFERRAL_TRANSACTION_BY_ADMIN_FAIL",

  //get action types
  GET_CHARGES_SUCCESS: "GET_CHARGES_SUCCESS",
  GET_CHARGES_FAIL: "GET_CHARGES_FAIL",
  GET_CHARGES_BY_PAYMENTINTYPE_SUCCESS: "GET_CHARGES_BY_PAYMENTINTYPE_SUCCESS",
  GET_CHARGES_BY_PAYMENTINTYPE_FAIL: "GET_CHARGES_BY_PAYMENTINTYPE_FAIL",
  GET_PROMOS_SUCCESS: "GET_PROMOS_SUCCESS",
  GET_PROMOS_FAIL: "GET_PROMOS_FAIL",
  GET_REFERRAL_SUCCESS: "GET_REFERRAL_SUCCESS",
  GET_REFERRAL_FAIL: "GET_REFERRAL_FAIL",
  GET_RATE_SUCCESS: "GET_RATE_SUCCESS",
  GET_RATE_FAIL: "GET_RATE_FAIL",
  GET_BANK_SUCCESS: "GET_BANK_SUCCESS",
  GET_BANK_FAIL: "GET_BANK_FAIL",
  GET_ALL_BANK_SUCCESS: "GET_ALL_BANK_SUCCESS",
  GET_ALL_BANK_FAIL: "GET_ALL_BANK_FAIL",
  GET_BUSINESS_BANK_SUCCESS: "GET_BUSINESS_BANK_SUCCESS",
  GET_BUSINESS_BANK_FAIL: "GET_BUSINESS_BANK_FAIL",
  GET_ALL_SENDING_CURRENCY_SUCCESS: "GET_ALL_SENDING_CURRENCY_SUCCESS",
  GET_ALL_SENDING_CURRENCY_FAIL: "GET_ALL_SENDING_CURRENCY_FAIL",
  GET_ALL_RECEIVING_CURRENCY_SUCCESS: "GET_ALL_RECEIVING_CURRENCY_SUCCESS",
  GET_ALL_RECEIVING_CURRENCY_FAIL: "GET_ALL_RECEIVING_CURRENCY_FAIL",

  GET_ADVERT_SENDING_CURRENCY_SUCCESS: "GET_ADVERT_SENDING_CURRENCY_SUCCESS",
  GET_ADVERT_SENDING_CURRENCY_FAIL: "GET_ADVERT_SENDING_CURRENCY_FAIL",
  GET_ADVERT_RECEIVING_CURRENCY_SUCCESS:
    "GET_ADVERT_RECEIVING_CURRENCY_SUCCESS",
  GET_ADVERT_RECEIVING_CURRENCY_FAIL: "GET_ADVERT_RECEIVING_CURRENCY_FAIL",

  GET_USER_RECEIVERS_SUCCESS: "GET_USER_RECEIVERS_SUCCESS",
  GET_USER_RECEIVERS_FAIL: "GET_USER_RECEIVERS_FAIL",
  GET_MONTHLY_USERS_SUCCESS: "GET_MONTHLY_USERS_SUCCESS",
  GET_MONTHLY_USERS_FAIL: "GET_MONTHLY_USERS_FAIL",
  GET_IJC_GLOBAL_ACCOUNT_SUCCESS: "GET_IJC_GLOBAL_ACCOUNT_SUCCESS",
  GET_IJC_GLOBAL_ACCOUNT_FAIL: "GET_IJC_GLOBAL_ACCOUNT_FAIL",
  GET_ALL_COUNTRY_SUCCESS: "GET_ALL_COUNTRY_SUCCESS",
  GET_ALL_COUNTRY_FAIL: "GET_ALL_COUNTRY_FAIL",

  GET_TRANSACTION_RECEIVING_SUCCESS: "GET_TRANSACTION_RECEIVING_SUCCESS",
  GET_TRANSACTION_RECEIVING_FAIL: "GET_TRANSACTION_RECEIVING_FAIL",

  GET_TRANSACTION_SENDING_SUCCESS: "GET_TRANSACTION_SENDING_SUCCESS",
  GET_TRANSACTION_SENDING_FAIL: "GET_TRANSACTION_SENDING_FAIL",

  GET_CUSTOMER_DOWNLOAD_PDF_SUCCESS: "GET_CUSTOMER_DOWNLOAD_PDF_SUCCESS",
  GET_CUSTOMER_DOWNLOAD_PDF_FAIL: "GET_CUSTOMER_DOWNLOAD_PDF_FAIL",
  //edit/update action types
  EDIT_CHARGES_SUCCESS: "EDIT_CHARGES_SUCCESS",
  EDIT_CHARGES_FAIL: "EDIT_CHARGES_FAIL",
  EDIT_PROMO_SUCCESS: "EDIT_PROMO_SUCCESS",
  EDIT_PROMO_FAIL: "EDIT_PROMO_FAIL",
  EDIT_REFERRAL_SUCCESS: "EDIT_REFERRAL_SUCCESS",
  EDIT_REFERRAL_FAIL: "EDIT_REFERRAL_FAIL",
  EDIT_REFERRAL_TRANSACTION_BY_ADMIN_SUCCESS:
    "EDIT_REFERRAL_TRANSACTION_BY_ADMIN_SUCCESS",
  EDIT_REFERRAL_TRANSACTION_BY_ADMIN_FAIL:
    "EDIT_REFERRAL_TRANSACTION_BY_ADMIN_FAIL",
  EDIT_BANK_SUCCESS: "EDIT_BANK_SUCCESS",
  EDIT_BANK_FAIL: "EDIT_BANK_FAIL",
  EDIT_RATE_SUCCESS: "EDIT_RATE_SUCCESS",
  EDIT_RATE_FAIL: "EDIT_RATE_FAIL",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAIL: "EDIT_USER_FAIL",
  EDIT_TRANSACTION_SUCCESS: "EDIT_TRANSACTION_SUCCESS",
  EDIT_TRANSACTION_FAIL: "EDIT_TRANSACTION_FAIL",
  EDIT_BENEFICARY_SUCCESS: "EDIT_BENEFICARY_SUCCESS",
  EDIT_BENEFICARY_FAIL: "EDIT_BENEFICARY_FAIL",
  EDIT_TRANSACTION_LIMIT_SUCCESS: "EDIT_TRANSACTION_LIMIT_SUCCESS",
  EDIT_TRANSACTION_LIMIT_FAIL: "EDIT_TRANSACTION_LIMIT_FAIL",

  //delete action types
  DELETE_CHARGES_SUCCESS: "DELETE_CHARGES_SUCCESS",
  DELETE_CHARGES_FAIL: "DELETE_CHARGES_FAIL",
  DELETE_PROMO_SUCCESS: "DELETE_PROMO_SUCCESS",
  DELETE_PROMO_FAIL: "DELETE_PROMO_FAIL",
  DELETE_REFERRAL_SUCCESS: "DELETE_REFERRAL_SUCCESS",
  DELETE_REFERRAL_FAIL: "DELETE_REFERRAL_FAIL",
  DELETE_BANK_SUCCESS: "DELETE_BANK_SUCCESS",
  DELETE_BANK_FAIL: "DELETE_BANK_FAIL",
  DELETE_RATE_SUCCESS: "DELETE_RATE_SUCCESS",
  DELETE_RATE_FAIL: "DELETE_RATE_FAIL",
  DELETE_BENEFICIARY_SUCCESS: "DELETE_BENEFICIARY_SUCCESS",
  DELETE_BENEFICIARY_FAIL: "DELETE_BENEFICIARY_FAIL",
  //get payment purpose
  FETCH_PURPOSE_SUCCESS: "FETCH_PURPOSE_SUCCESS",
  FETCH_PURPOSE_FAIL: "FETCH_PURPOSE_FAIL",

  //get payment response
  GET_PAYMENT_STATUS_SUCCESS: "GET_PAYMENT_STATUS_SUCCESS",
  GET_PAYMENT_STATUS_FAIL: "GET_PAYMENT_STATUS_FAIL",
};

export default ActionTypes;
