/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Notification from "../components/Notification";
import "../login/css/style.css";
import img3 from "../assets/images/logo_png.png";
import backgroundImage from "../assets/images/water.png";
import { Link } from "react-router-dom";

function RegSuccess() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  return (
    <section
      className="ftco-section"
      style={{
        backgroundImage: `url("${backgroundImage}")`,
        backgroundRepeat: "no-repeat",
        background: "cover",
        backgroundPositionX: "center",
      }}
    >
      <Notification notify={notify} setNotify={setNotify} />

      <div className="form-group d-md-flex">
        <div
          className="w-50 text-left text-success"
          style={{ marginRight: -220 }}
        >
          <a href="/" className="" style={{ marginLeft: 5 }}>
            <img src={img3} height={90} alt="" />
          </a>
        </div>
        <div
          className="text-center"
          style={{ marginRight: 30, marginTop: 120 }}
        >
          <h2>
            <strong className="text-success" >
              REGISTRATION SUCCESSFUL
            </strong>
          </h2>
        </div>
      </div>
      <div className="containers">
        <div className="rows justify-content-centers">
          <div className="col-md-6 text-center mb-5"></div>
        </div>

        <div className="rows justify-content-centers">
          <div className="col-md-8 col-lg-6">
            <div
              className="wrap d-md-flex"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="login-wrap p-4 col-md-12">
                <div className="d-flex">
                  <div className="w-900 text-center">
                    <h3
                      className="mb-4 m-4"
                      style={{ color: "black", fontSize: 30 }}
                    >
                      Congratulations, Your account has been successfully
                      created. Kindly check your email for further instructions.
                    </h3>
                  </div>
                </div>
                <form action="#" className="signin-form">
                  <div className="dropdown-menu-header-inner bg-danger">
                    <div
                      className="menu-header-image"
                      style={{
                        backgroundImage: "url(../login/images/bg-1.jpeg)",
                      }}
                    />
                  </div>

                  <div className="form-group d-md-flex">
                    <div className="w-50 text-left"></div>
                    {/* <Link to="/resetpassword" className="w-50 text-md-right">
                      <a>Reset Password?</a>
                    </Link> */}
                  </div>
                </form>
                <h5 className="text-center">
                  You can now {"   "}
                  <Link to="/login" className="checkbox-primary checkbox-wrap2">
                    Sign In
                  </Link>
                  {/* <Link
                to="/"
                className="checkbox-success checkbox-wrap2"
                style={{ marginLeft: 32 }}
              >
                Go Home
              </Link> */}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegSuccess;

