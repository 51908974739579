import React, { useState, useEffect } from 'react';
import AppHeader from '../components/AppHeader';
import AppSidebar from '../components/AppSidebar';
import Footer from '../components/Footer';
import AddAccountsModal from '../components/modals/AddAccountsModal';
import Bankaccountsarea from '../components/Bankaccountsarea';

export default function BankAccount(props) {
    const {activePage, setActivePage} = props;

    const [notify, setNotify] = useState({
        isOpen: false,
        message: '',
        type: '',
    })

    useEffect(() => {
        setActivePage('accounts');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(!show);
    }

    const [addaccountLoading, setAddAccountLoading] = useState(false);

    return (
        <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
            <AppHeader notify={notify} setNotify={setNotify} />
            <div className="app-main">            
                <AppSidebar activePage={activePage} setActivePage={setActivePage}/>
                <div className="app-main__outer">
                    <div className="app-main__inner">                        

                        <Bankaccountsarea show={show} notify={notify} setNotify={setNotify} handleShow={handleShow} setShow={setShow} addaccountLoading={addaccountLoading} setAddAccountLoading={setAddAccountLoading}/>

                        <Footer/>
                    
                    </div>
                </div>        
            </div>
            <div className="app-drawer-overlay d-none animated fadeIn"/>
            <AddAccountsModal notify={notify} setNotify={setNotify} show={show}  handleShow={handleShow} setShow={setShow} addaccountLoading={addaccountLoading} setAddAccountLoading={setAddAccountLoading}/>
        </div>
        
    )
}
