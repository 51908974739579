import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FetchAllCountry } from "../redux/actions/authaction";
import { GetSingleObjectAction } from "../redux/actions/getsoaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import backgroundImage from "../assets/images/water.png";
import { GetAction } from "../redux/actions/getaction";
import $ from "jquery";
import DataTable from "datatables.net";

const Mywalletarea = (props) => {
  const {
    setNotify,
    setShow,
    fetchprofiledetails,
    authstate,
    allcountriesstate,
    fetchallcountry,
    walletbalance,
    fetchbalance,
    referraltransactions,
    fetchreferraltransactions,
  } = props;

  useEffect(() => {
    fetchallcountry(setNotify);
    let customerId = authstate.nameid;
    fetchprofiledetails(
      customerId,
      setNotify,
      ActionTypes.FETCH_USER_PROFILE_SUCCESS,
      ActionTypes.FETCH_USER_PROFILE_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let customerId = authstate?.nameid;
    fetchbalance(
      customerId,
      setNotify,
      ActionTypes.GET_WALLET_BALANCE_SUCCESS,
      ActionTypes.GET_WALLET_BALANCE_FAIL,
      setShow
    );

    fetchreferraltransactions(
      customerId,
      setNotify,
      ActionTypes.GET_REFERRAL_TRANSACTION_SUCCESS,
      ActionTypes.GET_REFERRAL_TRANSACTION_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderrow = (items) => {
    if (Object.keys(items).length !== 0) {
      return items.map((item, i) => {
        return (
          <>
            <tr>
              <td>{item.total_No_Transaction}</td>
              <td>{item.payout_Balance}</td>
              <td>{item.status}</td>
            </tr>
          </>
        );
      });
    }
  };

  useEffect(() => {
    $(document).ready(function () {
      $("#examtable").DataTable({
        responsive: !0,
        destroy: true,
      });
    });
  }, []);

  const allcountriesarray = [];

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.country + " " + item.alpha3Code;
      newitem.currency = item.currency;
      allcountriesarray.push(newitem);
    }
  }

  return (
    <>
      <section
        style={{
          backgroundImage: `url("${backgroundImage}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPositionY: "center",
          backgroundPositionX: "calc(15% + 310px)",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="app-page-title app-page-title-simple">
          <div className="page-title-wrapper">
            <div className="page-title-heading" style={{ marginLeft: 16 }}>
              <div>
                <div className="page-title-head center-elem">
                  <span className="d-inline-block">
                    <h2>My Account</h2>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">My Wallet</h5>
                <form className>
                  <h3>
                    Balance:
                    <strong
                      className={`${
                        walletbalance > 0 ? "text-success" : "text-danger"
                      } ml-2`}
                    >
                      {" "}
                      {walletbalance}
                    </strong>
                  </h3>
                </form>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-left">
              <div className="widget-content p-0 w-100">
                <div className="widget-content-outer">
                  <div className="widget-content-right w-100">
                    <h4
                      className="text-muted opacity-10 card-title"
                      style={{ marginLeft: 16 }}
                    >
                      My Wallet
                    </h4>
                    <div
                      className="progress-bar-xs progress"
                      style={{
                        marginLeft: 16,
                        marginRight: 16,
                      }}
                    >
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        aria-valuenow={71}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>
                  <div
                    className="col-md-12 position-relative form-group"
                    style={{ marginTop: 24 }}
                  >
                    <div className="form-row">
                      <div className="position-relative form-group col-md-6">
                        <label htmlFor="exampleAmount">Payout Balance</label>
                        <h5 className="text-muted opacity-10 card-title">
                          {referraltransactions?.balance}
                        </h5>
                      </div>
                      <div className="position-relative form-group col-md-6">
                        <label htmlFor="exampleAmount">
                          Total number of transactions
                        </label>
                        <h5 className="text-muted opacity-10 card-title">
                          {referraltransactions?.totalNumberOfTransaction}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // userprofilestate: state.userprofilestate.userprofilestate,
    authstate: state.authstate.authstate,
    allcountriesstate: state.allcountriesstate.allcountriesstate,
    walletbalance: state.walletbalance.walletbalance,
    referraltransactions: state.referraltransactions.referraltransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchprofiledetails: (
      customerId,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetSingleObjectAction(
          customerId,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchallcountry: (setError) => {
      dispatch(FetchAllCountry(setError));
    },
    fetchbalance: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchreferraltransactions: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mywalletarea);
