import React, { useEffect, useState } from 'react'
import AppHeader from '../components/AppHeader'
import AppSidebar from '../components/AppSidebar'
import Footer from '../components/Footer'
import Myaccountarea from '../components/Myaccountarea'
import Mywalletarea from '../components/Mywalletarea'

export default function MyWallet(props) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [show, setShow] = useState(false);
  const { activePage, setActivePage } = props;
  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    setActivePage("mywallet");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
      <AppHeader notify={notify} setNotify={setNotify} />
      <div className="app-main">
        <AppSidebar activePage={activePage} setActivePage={setActivePage} />

        <div className="app-main__outer">
          <div className="app-main__inner">
            <Mywalletarea
              show={show}
              notify={notify}
              setNotify={setNotify}
              handleShow={handleShow}
              setShow={setShow}
            />
            <Footer />
          </div>
        </div>
      </div>
      <div className="app-drawer-overlay d-none animated fadeIn" />
    </div>
  );
}
