/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { downloadAxios } from "../../utils/axios";
import { useState } from "react";
import { saveAs } from "file-saver";
import Notification from "../Notification";

const ViewTransactionModal = (props) => {
  const { show, item, handleShowView } = props;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [pdfdata, setPdfdata] = useState({});
  useEffect(() => {}, [show]);

  const handleTelexDownload = async (item) => {
    console.log(item);
    try {
      const res = await downloadAxios
        .get(
          `/TransactionReceipt/download-receipt/` +
            item.id +
            `/` +
            item.senderEmail
        )
        .then((response) => {
          if (response?.data?.status === 200) {
            setPdfdata(response.data.path);
            setNotify({
              isOpen: true,
              message: response.data.message,
              type: "success",
            });
            saveAs(response.data.path, "telex.pdf");
          } else {
            setPdfdata(response.data.path);
            setNotify({
              isOpen: true,
              message: response.data.message,
              type: "error",
            });
            saveAs(response.data.path, "telex.pdf");
          }
        })
        .then((response) => {
          if (response?.data?.status === 200) {
            setPdfdata(response.data.path);
            setNotify({
              isOpen: true,
              message: response.data.message,
              type: "success",
            });
            saveAs(response.data.path, "telex.pdf");
          } else {
            setPdfdata(response.data.path);
            setNotify({
              isOpen: true,
              message: response.data.message,
              type: "error",
            });
            saveAs(response.data.path, "telex.pdf");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleShowView}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <Notification notify={notify} setNotify={setNotify} />
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <div className="card-shadow-primary profile-responsive card-border card">
            <div className="dropdown-menu-header">
              <div className="dropdown-menu-header-inner bg-danger">
                <div className="menu-header-content btn-pane-right">
                  <div>
                    <h4 className="menu-header-title">{item.fullName}</h4>
                    {/* <h6 className="menu-header-subtitle">{item.userRole}</h6> */}
                    <h6 className="menu-header-subtitle">
                      Transaction Details
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex">
              <ul className="list-group list-group-flush col-md-6">
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Full Name</div>
                        <div className="widget-subheading">{item.fullName}</div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Sender Email</div>
                        <div className="widget-subheading">
                          {item.senderEmail}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">
                          Transaction Reference No
                        </div>
                        <div className="widget-subheading">
                          {item.transactionRefNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Date Sent</div>
                        <div className="widget-subheading">
                          {item.dateSent?.substring(0, 10)}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Transaction Type</div>
                        <div className="widget-subheading">
                          {item.transactionType}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Sending From</div>
                        <div className="widget-subheading">
                          {item.sendingCountry}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Receiving</div>
                        <div className="widget-subheading">
                          {item.receivingCountry}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Total Amount Sent</div>
                        <div className="widget-subheading">
                          <b>{item.sendingCurrency}</b>
                          {item.amountToSend}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              {/* col 2 */}
              <ul className="list-group list-group-flush col-md-6">
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">
                          Total Amount Charged
                        </div>
                        <div className="widget-subheading">
                          {item.amountToCharge}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">
                          Total Amount Received (LOCAL)
                        </div>
                        <div className="widget-subheading">
                          {/* <b>{item.receivingCurrency}</b> */}
                          {item.amountToReceive_Local}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">
                          Total Amount Received (USD)
                        </div>
                        <div className="widget-subheading">
                          {/* <b>{item.receivingCurrency}</b> */}
                          {item.amountToReceive_USD}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Pay In Method</div>
                        <div className="widget-subheading">
                          {item.payInMethod}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Transaction Status</div>
                        <div className="widget-subheading">
                          {item.transactionStatus}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Exchange Rate</div>
                        <div className="widget-subheading">
                          {item.exchangeRate}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Payment Purpose</div>
                        <div className="widget-subheading">
                          {item.paymentPurpose}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                {/* Payment confirmation */}

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">
                          Payment Confirmation
                        </div>
                        {!item.paymentConfirmationURL ? (
                          <div className="widget-subheading text-danger">
                            No payment confirmation
                          </div>
                        ) : (
                          <>
                            <div className="widget-subheading">
                              {/* <h5>{item.paymentConfirmationURL}</h5> */}

                              <a
                                className="btn btn-link"
                                style={{ padding: "15px 0", gridColumn: "1/3" }}
                                href={item.paymentConfirmationURL}
                                target="_blank"
                                // onClick={() => {
                                //   let url = item.paymentConfirmationURL;
                                //   let filename = `payment_confirmation_${item.customerId}_${item.id}`;
                                //   fetch(url).then(function (t) {
                                //     return t.blob().then((b) => {
                                //       var a = document.createElement("a");
                                //       a.href = URL.createObjectURL(b);
                                //       a.setAttribute("download", filename);
                                //       a.click();
                                //     });
                                //   });
                                // }}
                              >
                                Download here.
                              </a>
                            </div>
                            <div className="mt-2">
                              <img src={item.paymentConfirmationURL} alt="." />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
                {/* <button onClick={() => handleTelexDownload(item)}>
                  Download Receipt
                </button> */}
                {(() => {
                  if (item.transactionStatus === "Completed") {
                    return (
                      <button
                        style={{ width: "-webkit-fill-available" }}
                        className="btn-wide btn btn-dark"
                        onClick={() => handleTelexDownload(item)}
                      >
                        Download Receipt
                      </button>
                    );
                  }
                })()}
              </ul>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleShowView}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTransactionModal);
