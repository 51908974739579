import { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Register from './pages/Register';
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Userdetails from "./pages/Userdetails";
import Transactions from "./pages/Transactions";
import TransactionDetails from "./pages/TransactionDetails";
import Rates from "./pages/Rates";
import RateDetails from "./pages/RateDetails";
import Charges from "./pages/Charges";
import ChargeDetails from "./pages/ChargeDetails";
import Promos from "./pages/Promos";
// import Referral from "./pages/Referral";
import CustomerReferral from "./pages/CustomerReferral";
import AustracReport from "./pages/AustracReport";
import SendMoney from "./pages/SendMoney";
import TransactionHistory from "./pages/TransactionHistory";
import Beneficiary from "./pages/Beneficiary";
import NewBeneficiary from "./pages/NewBeneficiary";
import MyAccount from "./pages/MyAccount";
import MyWallet from "./pages/MyWallet";
import BankAccount from "./pages/BankAccount";
import Paymentpage from "./components/Paymentpage";
import LandingPage from "./pages/LandingPage";
import ResetPassword from "./pages/ResetPassword";
import Registration from "./pages/Registration/Registration";
import Registration2 from "./pages/Registration";
import Home from "./frontend/home";
import Aboutus from "./frontend/Aboutus";
import Policy from "./frontend/Policy";
import Agreement from "./frontend/Agreement";
import CheckTransaction from "./pages/CheckTransaction";
import TransSuccess from "./pages/TransSuccess";
import TransFail from "./pages/TransFail";
import Transdummy from "./pages/Transdummy";
import RegSuccess from "./pages/RegSuccess";
import IntroRegistration from "./pages/IntroRegistration";
import FAQ from "./frontend/FAQ";

// Datatables styles
import "./assets/css/dataTables.css";

function App() {
  const [activePage, setActivePage] = useState("");

  return (
    <Router>
      <Switch>
        {/* <Route exact path="/register">
          <Register />
        </Route> */}
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/about">
          <Aboutus />
        </Route>
        <Route exact path="/policies">
          <Policy />
        </Route>
        <Route exact path="/user-agreement">
          <Agreement />
        </Route>
        <Route exact path="/faq">
          <FAQ />
        </Route>
        <Route exact path="/">
          <LandingPage activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route exact path="/dashboard">
          <Dashboard activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route exact path="/users">
          <Users activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route exact path="/userdetails">
          <Userdetails activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route path="/transactions">
          <Transactions activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route path="/transactiondetails">
          <TransactionDetails
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </Route>
        <Route exact path="/rates">
          <Rates activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route exact path="/ratedetails">
          <RateDetails activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route exact path="/charges">
          <Charges activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route exact path="/chargedetails">
          <ChargeDetails
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </Route>
        {/* <Route path="*">
          <Home />
        </Route> */}
        {/* <Route component={NotFound} /> */}
        <Route exact path="/promos">
          <Promos activePage={activePage} setActivePage={setActivePage} />
        </Route>
        {/* <Route exact path="/referral">
          <Referral activePage={activePage} setActivePage={setActivePage} />
        </Route> */}
        <Route exact path="/customerreferral">
          <CustomerReferral
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </Route>
        <Route exact path="/ausreport">
          <AustracReport
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </Route>
        <Route exact path="/sendmoney">
          <SendMoney activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route exact path="/transactionhistory">
          <TransactionHistory
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </Route>
        <Route exact path="/beneficiaries">
          <Beneficiary activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route exact path="/addbeneficiary">
          <NewBeneficiary
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </Route>
        <Route exact path="/myaccount">
          <MyAccount activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route exact path="/mywallet">
          <MyWallet activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route exact path="/accounts">
          <BankAccount activePage={activePage} setActivePage={setActivePage} />
        </Route>
        <Route exact path="/paymentpage">
          <Paymentpage activePage={activePage} setActivePage={setActivePage} />
        </Route>

        <Route
          exact
          activePage={activePage}
          setActivePage={setActivePage}
          path="/payment/Success"
        >
          <TransSuccess />
        </Route>
        <Route
          exact
          activePage={activePage}
          setActivePage={setActivePage}
          path="/payment/Failed"
        >
          <TransFail />
        </Route>
        <Route
          exact
          activePage={activePage}
          setActivePage={setActivePage}
          path="/NotVerified"
        >
          <Transdummy />
        </Route>

        <Route exact path="/payment/cancelled">
          <Paymentpage activePage={activePage} setActivePage={setActivePage} />
        </Route>

        <Route exact path="/resetpassword">
          <ResetPassword />
        </Route>
        <Route exact path="/reg">
          <Registration />
        </Route>
        <Route exact path="/register">
          <Registration2 />
        </Route>
        <Route exact path="/checkstatus">
          <CheckTransaction />
        </Route>
        <Route exact path="/regsuccess">
          <RegSuccess />
        </Route>
        <Route exact path="/introreg">
          <IntroRegistration />
        </Route>
        {/* <Route exact path='/'>
            <Home/>
          </Route> */}
        {/* <Route
            exact
            path={`/paynow`}
            component={Paynow}
          /> */}
      </Switch>
    </Router>
  );
}

export default App;
