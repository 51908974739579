import { mainAxios } from "../../utils/axios";
import ActionTypes from "../actiontype/ActionTypes";

const GetArrayAction = (
  stateobject,
  setNotify,
  successactiontype,
  failureactiontype,
  setShow
) => {
  return async (dispatch) => {
    try {
      if (stateobject !== undefined) {
        let response = "";
        if (successactiontype === ActionTypes.FETCH_REF_TRANSACTION_SUCCESS) {
          response = await mainAxios.get(
            "/Transactions/GetTransactionReportByRefNo/" + stateobject
          );
        } else if (successactiontype === ActionTypes.FETCH_FUNDS_SUCCCESS) {
          response = await mainAxios.get("/AppSettings/GetSourceOfFund");
        }

        const dat = response.data;
        const status = response.status;
        let data = [];
        data.push(dat);
        if (status === 200) {
          dispatch({
            type: ActionTypes.LOADING_HIDE,
            payload: "Data Fetch Successfully",
          });
          dispatch({ type: successactiontype, payload: dat });
          // setNotify({
          //   isOpen: true,
          //   message: "Data Fetch Successfully",
          //   type: "success",
          // });
          setShow(false);
        } else {
          dispatch({
            type: ActionTypes.LOADING_HIDE,
            payload: "Failed to fetch data",
          });
          dispatch({
            type: failureactiontype,
            payload: "Failed to fetch data",
          });
          // setNotify({
          //   isOpen: true,
          //   message: "Failed to fetch data",
          //   type: "error",
          // });
          setShow(false);
        }
      } else {
        const errormsg = "Kindly Supply all required information";
        dispatch({ type: ActionTypes.LOADING_HIDE, payload: stateobject });
        dispatch({ type: failureactiontype, payload: errormsg });
        // setNotify({
        //   isOpen: true,
        //   message: errormsg,
        //   type: "error",
        // });
        setShow(false);
      }
    } catch (error) {
      const errmsg = error.message;
      dispatch({ type: ActionTypes.LOADING_HIDE, payload: errmsg });
      dispatch({ type: failureactiontype, payload: errmsg });
      // setNotify({
      //   isOpen: true,
      //   message: error.message,
      //   type: "error",
      // });
      setShow(false);
    }
  };
};

const GetsingleArrayAction = (
  successactiontype,
  failureactiontype,
  setNotify
) => {
  return async (dispatch) => {
    try {
      if (setNotify !== undefined) {
        let response = "";
        if (successactiontype === ActionTypes.FETCH_FUNDS_SUCCCESS) {
          response = await mainAxios.get("/AppSettings/GetSourceOfFund");
        }

        const dat = response.data;
        const status = response.status;
        let data = [];
        data.push(dat);
        if (status === 200) {
          // dispatch({
          //   type: ActionTypes.LOADING_HIDE,
          //   payload: "Data Fetch Successfully",
          // });
          dispatch({ type: successactiontype, payload: dat });
          // setNotify({
          //   isOpen: true,
          //   message: "Data Fetch Successfully",
          //   type: "success",
          // });
        } else {
          dispatch({
            type: ActionTypes.LOADING_HIDE,
            payload: "Failed to fetch data",
          });
          dispatch({
            type: failureactiontype,
            payload: "Failed to fetch data",
          });
          // setNotify({
          //   isOpen: true,
          //   message: "Failed to fetch data",
          //   type: "error",
          // });
          setNotify(false);
        }
      } else {
        const errormsg = "Kindly Supply all required information";
        dispatch({ type: ActionTypes.LOADING_HIDE, payload: "" });
        dispatch({ type: failureactiontype, payload: errormsg });
        setNotify({
          isOpen: true,
          message: errormsg,
          type: "error",
        });
        setNotify(false);
      }
    } catch (error) {
      const errmsg = error.message;
      dispatch({ type: ActionTypes.LOADING_HIDE, payload: errmsg });
      dispatch({ type: failureactiontype, payload: errmsg });
      // setNotify({
      //   isOpen: true,
      //   message: error.message,
      //   type: "error",
      // });
      //   setShow(false);
    }
  };
};

export { GetArrayAction, GetsingleArrayAction };
