import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetAction } from "../redux/actions/getaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import $ from "jquery";
import ViewTransactionModal from "./modals/ViewTransactionModal";
import Moment from "react-moment";
import backgroundImage from "../assets/images/water.png";
import DataTable from "datatables.net";
import { downloadAxios } from "../utils/axios";
import { saveAs } from "file-saver";
import axios from "axios";

const Transactionhistoryarea = (props) => {
  const { setNotify, show, setShow, fetchhistory, allcustomertransactions } =
    props;
  const [transactions, setTransactions] = useState({});
  const [pdfdata, setPdfdata] = useState({});
  const [item, setItem] = useState({});
  const [showView, setShowView] = useState(false);

  useEffect(() => {
    fetchhistory(
      show,
      setNotify,
      ActionTypes.FETCH_CUSTOMER_TRANSACTION_SUCCESS,
      ActionTypes.FETCH_CUSTOMER_TRANSACTION_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allcustomertransactions !== undefined) {
      setTransactions(allcustomertransactions);
      $("#examtable").DataTable({
        responsive: !0,
        destroy: true,
        "order": [[ 7, 'desc' ]]
      });
    }
  }, [allcustomertransactions]);

  const handleTelexDownload = async (item) => {
    try {
      const res = await downloadAxios
        .get(
          `/TransactionReceipt/download-receipt/` +
            item.id +
            `/` +
            item.senderEmail
        )
        .then((response) => {
          if (response?.data?.status === 200) {
            setPdfdata(response.data.path);
            setNotify({
              isOpen: true,
              message: response.data.message,
              type: "success",
            });
            saveAs(response.data.path, "telex.pdf");
          } else {
            setPdfdata(response.data.path);
            setNotify({
              isOpen: true,
              message: response.data.message,
              type: "error",
            });
            saveAs(response.data.path, "telex.pdf");
          }
        })
        .then((response) => {
          if (response?.data?.status === 200) {
            setPdfdata(response.data.path);
            setNotify({
              isOpen: true,
              message: response.data.message,
              type: "success",
            });
            saveAs(response.data.path, "telex.pdf");
          } else {
            setPdfdata(response.data.path);
            setNotify({
              isOpen: true,
              message: response.data.message,
              type: "error",
            });
            saveAs(response.data.path, "telex.pdf");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   $(document).ready(function () {
  //     $("#examtable").DataTable({ responsive: !0, destroy: true, order: [[8, "desc"]] });
  //   });
  // }, []);

  function viewUser(item) {
    setShowView(true);
    setItem(item);
  }

  const handleShowView = () => {
    setShowView(!showView);
  };

  const renderrow = (items) => {
    if (Object.keys(items).length !== 0) {
      return items.map((item, i) => {
        return (
          <>
            <tr style={{ textAlign: "center" }}>
              <td>
                <b>{item.transactionRefNumber}</b>
              </td>
              <td>{item.fullName}</td>
              <td>{item.senderEmail}</td>
              <td style={{ textAlign: "right" }}>
                <b>
                  {item.sendingCurrency}
                  {item.amountToSend}
                </b>
              </td>
              <td style={{ textAlign: "right" }}>
                <b>
                  {/* {item.receivingCurrency}  transactionStatus*/}
                  {item.amountToReceive_Local}
                </b>
              </td>
              <td>{item.amountToReceive_USD}</td>
              <td>{item.transactionStatus}</td>
              <td>
                <Moment format="L">{item.dateSent}</Moment>
              </td>
              <td className="d-flex flex-wrap p-2" style={{ gap: "8px" }}>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-danger"
                  onClick={() => viewUser(item)}
                >
                  View
                </button>

                {(() => {
                  if (item.transactionStatus === "Completed") {
                    return (
                      <button
                        style={{ width: "-webkit-fill-available" }}
                        className="btn-wide btn btn-dark"
                        onClick={() => handleTelexDownload(item)}
                      >
                        Download Telex
                      </button>
                    );
                  }
                })()}
              </td>
            </tr>
          </>
        );
      });
    }
  };

  return (
    <>
      <section
        style={{
          backgroundImage: `url("${backgroundImage}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPositionY: "center",
          backgroundPositionX: "calc(15% + 310px)",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="app-page-title app-page-title-simple">
          <div className="page-title-wrapper">
            <div className="page-title-heading" style={{ marginLeft: 16 }}>
              <div>
                <div className="page-title-head center-elem">
                  <span className="d-inline-block">
                    <h2>Transaction History</h2>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-card mb-3">
          <div className="card-body">
            {transactions.length ? (
              <table
                style={{ width: "100%" }}
                id="examtable"
                className="table table-hover table-striped table-bordered"
              >
                <thead>
                  <tr>
                    <th>Reference Number</th>
                    <th>Full Name</th>
                    <th>Sender Email</th>
                    <th>Amount Sent</th>
                    <th>Amount Received (LOCAL)</th>
                    <th>Amount Received (USD)</th>
                    <th>Status</th>
                    <th>Date</th>
                    {/* <th>Rate</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{renderrow(transactions)}</tbody>
                {/* <tfoot>
                <tr>
                  <th>Reference Number</th>
                  <th>Full Name</th>
                  <th>Sender Email</th>
                  <th>Amount Sent</th>
                  <th>Amount Received</th>
                  <th>Date</th>
                  <th>Rate</th>
                  <th>Action</th>
                </tr>
              </tfoot> */}
              </table>
            ) : null}
          </div>
        </div>
        <ViewTransactionModal
          item={item}
          show={showView}
          setShow={setShowView}
          handleShowView={handleShowView}
        />
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allcustomertransactions:
      state.allcustomertransactions.allcustomertransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchhistory: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transactionhistoryarea);
