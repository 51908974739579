import jsPDF from "jspdf";
import "jspdf-autotable";
import img from '../assets/images/logo1.PNG';
// Date Fns is used to format the dates we receive
// from our API call
// import { format } from "date-fns";

// define a receiptGenerator function that accepts a tickets argument
const receiptGenerator = (receiptObject, setActiveDownload) => {//receiptObject
  // initialize jsPDF
    var doc = new jsPDF('p', 'pt');
    doc.addImage(img, 'PNG', 0, 0);
    doc.setFontSize(15);
    doc.text(22, 150, 'JCI REMIT PTY LTD             Outward Remittance Advice');
    doc.addFont('helvetica', 'normal');
    doc.setFontSize(12);
    // setFontSize(size)setFillColor
    doc.text(25, 180, 'Transaction Ref No: ' + receiptObject.referenceNUmber);
    doc.text(475, 180, 'Date: ' + receiptObject.dateNow.substring(0, 10));

    doc.setDrawColor(0);
    doc.setFillColor(217,37,80);
    doc.rect(25, 210, 268, 27, 'F'); //Fill and Border
    doc.setFontSize(11);
    // doc.setFontType('normal');
    doc.setTextColor('white')
    doc.text('REMITTANCE DETAILS', 100, 227);

    doc.setDrawColor(0);
    doc.setFillColor(217,37,80);
    doc.rect(300, 210, 270, 27, 'F'); //Fill and Border
    doc.setFontSize(11);
    // doc.setFontType('normal');
    doc.setTextColor('white')
    doc.text('EXCHANGE RATE', 390, 227);

    doc.setTextColor('black')
    doc.text(25, 260, 'Transaction Amount                                       ' + receiptObject.totalAmountToCharge);

    doc.text(25, 290, 'Provider Rate Charges                                  ' + receiptObject.rateCharges);

    doc.text(25, 320, 'Provider Flat Charges                                    ' + receiptObject.providerFlatCharges);
    
    doc.text(25, 350, 'Transaction Charges                                      ' + receiptObject.transactionCharges);

    doc.text(25, 380, 'Bonus                                                             ' + receiptObject.bonus);


    doc.text(25, 410, 'Cost of Remittance                                         ' + receiptObject.transactionCharges);

    // doc.text(25, 410, 'Total Amount Charged                                         ' + receiptObject.totalAmountToCharge);
    //totalAmountToCharge

    doc.text(25, 440, 'Amount of Remittance                                    ' + receiptObject.totalAmountToReceive);

    doc.text(305, 260, 'Exchange Rate                                       ' + receiptObject.rate);

    doc.text(305, 290, 'Value Date                                              ' + receiptObject.dateSent.substring(0, 10));

    // eslint-disable-next-line no-useless-concat
    doc.text(305, 320, 'Other Bank Charge                                 ' + '0');



    doc.setDrawColor(0);
    doc.setFillColor(217,37,80);
    doc.rect(25, 450, 268, 27, 'F'); //Fill and Border
    doc.setFontSize(11);
    // doc.setFontType('normal');
    doc.setTextColor('white')
    doc.text('SENDER DETAILS', 115, 468);

    doc.setDrawColor(0);
    doc.setFillColor(217,37,80);
    doc.rect(300, 450, 270, 27, 'F'); //Fill and Border
    doc.setFontSize(11);
    // doc.setFontType('normal');
    doc.setTextColor('white')
    doc.text('BENEFICIARY & BANK DETAILS', 350, 468);

    doc.setTextColor('black')
    doc.text(25, 530, "Sender's Name                              " + receiptObject.customerName.substring(0, 25));

    doc.text(25, 560, "Sender's Address                          " + receiptObject.customerAddress.substring(0, 25));

    doc.text(25, 590, "Sending Currency Code                  " + receiptObject.sendingCurrencyCode);

    //sendingCurrencyCode

    doc.text(305, 500, 'Beneficiary Account Number            ' + receiptObject.receiverAccountNumber);

    doc.text(305, 530, 'Beneficiary Account Name               ' + receiptObject.receiverAccountName.substring(0, 25));

    doc.text(305, 560, 'Beneficiary Address                            ' + receiptObject.receiverBankAddress.substring(0, 25));

    doc.text(305, 590, 'Bank Swift Code                               ' + receiptObject.receiverAccountSwiftCode);

    doc.text(305, 620, 'Bank Name                                       ' + receiptObject.receiverBankName.substring(0, 25));
  
    doc.text(305, 650, 'Bank Address                                    ' + receiptObject.receiverBankAddress.substring(0, 25));


    doc.setDrawColor(0);
    doc.setFillColor(217,37,80);
    doc.rect(25, 660, 268, 27, 'F'); //Fill and Border
    doc.setFontSize(11);
    // doc.setFontType('normal');
    doc.setTextColor('white')
    doc.text('PURPOSE OF PAYMENT', 100, 678);

    doc.setDrawColor(0);
    doc.setFillColor(217,37,80);
    doc.rect(300, 660, 270, 27, 'F'); //Fill and Border
    doc.setFontSize(11);
    // doc.setFontType('normal');
    doc.setTextColor('white')
    doc.text('PAYMENT REFERENCE', 380, 678);
    
    doc.setTextColor('black')
    doc.text(30, 720, 'nil');
    doc.text(305, 720, 'Payment Reference Number              ' + receiptObject.referenceNUmber);
    doc.setFontSize(8);
    doc.text(230, 810, 'contact@jciremit.com | www.jciremit.com');
    doc.text(80, 820, 'JCI REMIT PTY LTD is a company registered in Australisa under registration number 647132063 Beneficiary | www.jciremit.com');
    doc.text(7, 830, 'JCI REMIT PTY LTD is a Remittance Service Provider that is authorised and regulated by the Australian Transaction Reports and Analysis Centre www.jciremit.com');
      

  
    doc.save('receipt.pdf');
    setActiveDownload(false);
};

export default receiptGenerator;