/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Notification from "../components/Notification";
import "../login/css/style.css";
import img3 from "../assets/images/logo_png.png";
import img1 from "../assets/images/company.png";
import img2 from "../assets/images/profile.png";
import backgroundImage from "../assets/images/water.png";
import { Link } from "react-router-dom";

function IntroRegistration() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  let personal = "personal";

  return (
    <section
      className="ftco-section"
      style={{
        backgroundImage: `url("${backgroundImage}")`,
        backgroundRepeat: "no-repeat",
        background: "cover",
        backgroundPositionX: "center",
      }}
    >
      <Notification notify={notify} setNotify={setNotify} />

      <div className="">
        <div
          className="w-50 text-left text-success"
          // style={{ marginRight: -315 }}
        >
          <a href="/" className="" style={{ marginLeft: 5 }}>
            <img src={img3} height={90} alt="" />
          </a>
        </div>
        <div className="mt-4 text-center" style={{ marginRight: 30 }}>
          <h2>
            <span style={{ fontWeight: "900", color: "black" }}>SIGN UP</span>
          </h2>
          <h5
            className="text-center mt-4"
            style={{ fontSize: 25, color: "gray" }}
          >
            Are you moving for personal or business purposes?
          </h5>
        </div>
      </div>
      <div className="containers">
        <div className="rows justify-content-centers">
          <div className="col-md-6 text-center mb-5"></div>
        </div>

        <div className="rows justify-content-centers">
          <div className="col-md-8 col-lg-6">
            <Link
              to={{
                pathname: `/register`,
                state: { type: "personal" },
              }}
              className="wrap d-md-flex"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="login-wrap p-4 col-md-12">
                <div className="d-flex">
                  <div className="w-900">
                    <img src={img1} height={90} alt="" className="ml-4" />
                    <h2
                      className="mb-4 m-4"
                      style={{
                        color: "black",
                        fontSize: 35,
                        fontWeight: "bolder",
                        color: "#ed3237",
                      }}
                    >
                      Personal
                    </h2>
                    <h6 className="m-4">
                      For individuals who wants to make international transfers
                      to friends and loved ones
                    </h6>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-8 col-lg-6">
            <Link
              to={{
                pathname: `/register`,
                state: { type: "business" },
              }}
              className="wrap d-md-flex"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="login-wrap p-4 col-md-12">
                <div className="d-flex">
                  <div className="w-900">
                    <img src={img2} height={90} alt="" className="ml-4" />
                    <h2
                      className="mb-4 m-4"
                      style={{
                        color: "black",
                        fontSize: 35,
                        fontWeight: "bolder",
                        color: "#ed3237",
                      }}
                    >
                      Business
                    </h2>
                    <h6 className="m-4">
                      For businesses who wants to pay foreign suppliers,
                      contractors and gig workers
                    </h6>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <p
            className="text-center"
            style={{
              marginTop: 100,
              fontSize: 23,
              color: "black",
              padding: "0 15px",
            }}
          >
            Already have an Account ?{" "}
            <Link to="/login" className="">
              <span style={{ color: "#ed3237" }}>Login</span>
            </Link>
            {/* <Link
                to="/"
                className="checkbox-success checkbox-wrap2"
                style={{ marginLeft: 32 }}
              >
                Go Home
              </Link> */}
          </p>
        </div>
      </div>
    </section>
  );
}

export default IntroRegistration;
