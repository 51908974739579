import { mainAxios, countryAxios } from "../../utils/axios";
import ActionTypes from "../actiontype/ActionTypes";

const GetAction = (
  stateobject,
  setNotify,
  successactiontype,
  failureactiontype,
  setShow,
  params = ""
) => {
  return async (dispatch) => {
    try {
      if (stateobject !== undefined) {
        let response = "";
        if (successactiontype === ActionTypes.GET_CHARGES_SUCCESS) {
          response = await mainAxios.get("/Charges/GetAllCharges");
        } else if (successactiontype === ActionTypes.GET_PROMOS_SUCCESS) {
          response = await mainAxios.get("/Promo/getAllPromos", stateobject);
        } else if (successactiontype === ActionTypes.GET_RATE_SUCCESS) {
          response = await mainAxios.get(
            params
              ? `Rates/getRatesByDescription/${params.sendCountryCode}-${params.receiveCountryCode}`
              : "/Rates/getRateList",
            stateobject
          );
        } else if (successactiontype === ActionTypes.GET_BANK_SUCCESS) {
          response = await mainAxios.get(
            "/JCIBank/GetAllBankAccountBySendingCountry/" + stateobject
          );
        } else if (successactiontype === ActionTypes.GET_ALL_BANK_SUCCESS) {
          response = await mainAxios.get(
            "/JCIBank/GetAllBankAccount",
            stateobject
          );
        } else if (
          successactiontype === ActionTypes.GET_ALL_RECEIVING_CURRENCY_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Rates/getAllReceiveCountryLiveRate",
            stateobject
          );
        } else if (
          successactiontype === ActionTypes.GET_ALL_SENDING_CURRENCY_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Rates/getAllSendCountryLiveRate",
            stateobject
          );
        } else if (
          successactiontype === ActionTypes.GET_TRANSACTION_SENDING_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Rates/getAllSendCountryTransactionRate",
            stateobject
          );
        } else if (
          successactiontype === ActionTypes.GET_TRANSACTION_RECEIVING_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Rates/getAllReceiveTransactionRate",
            stateobject
          );
        } else if (
          successactiontype === ActionTypes.GET_USER_RECEIVERS_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Transactions/getUserReceivers",
            stateobject
          );
        } else if (
          successactiontype === ActionTypes.DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Transactions/downloadTransactionReceiptJson/" + stateobject
          );
        } else if (
          successactiontype === ActionTypes.FETCH_CUSTOMER_TRANSACTION_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Transactions/getAllCustomertransactions/"
          );
        } else if (
          successactiontype === ActionTypes.FETCH_AUSTRAC_REPORT_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Transactions/ExportAustracReportByDateRange/" +
              stateobject.startDate +
              "/" +
              stateobject.endDate
          );
        } else if (
          successactiontype === ActionTypes.FETCH_TRANSACTION_REPORT_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Transactions/ExportTransactionReportByDateRange/" +
              stateobject.startDate +
              "/" +
              stateobject.endDate +
              "/" +
              stateobject.transType
          );
        } else if (
          successactiontype === ActionTypes.FETCH_PROMO_TRANSACTION_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Promo/getAllPromoTransactions/" + stateobject
          );
        } else if (
          successactiontype === ActionTypes.FETCH_ALL_TRANSACTION_SUCCESS
        ) {
          response = await mainAxios.get("/Transactions/getAllTransactions/");
        } else if (successactiontype === ActionTypes.FETCH_ALL_USERS_SUCCESS) {
          response = await mainAxios.get("/Users/getAllCustomers/");
        } else if (
          successactiontype === ActionTypes.GET_MONTHLY_USERS_SUCCESS
        ) {
          response = await mainAxios.get("/Users/getTotalUsersMonthly");
        } else if (
          successactiontype === ActionTypes.GET_IJC_GLOBAL_ACCOUNT_SUCCESS
        ) {
          response = await mainAxios.get(
            "/JCIBank/GetBusinessBankAccount/" + stateobject
          );
        } else if (successactiontype === ActionTypes.GET_ALL_COUNTRY_SUCCESS) {
          response = await countryAxios.get("/countries");
        } else if (
          successactiontype === ActionTypes.GET_WALLET_BALANCE_SUCCESS
        ) {
          response = await mainAxios.get(
            "/ReferralPayment/getSumCustomerReferralTransaction/" + stateobject
          );
        } else if (
          successactiontype === ActionTypes.GET_REFERRAL_TRANSACTION_SUCCESS
        ) {
          response = await mainAxios.get(
            "/ReferralPayment/getCustomerReferralTransaction/" + stateobject
          );
        } else if (
          successactiontype ===
          ActionTypes.GET_REFERRAL_TRANSACTION_BY_ADMIN_SUCCESS
        ) {
          response = await mainAxios.get(
            "/ReferralPayment/getCustomerReferralTransactionByAdmin/"
          );
        } else if (
          successactiontype === ActionTypes.GET_CUSTOMER_DOWNLOAD_PDF_SUCCESS
        ) {
          response = await mainAxios.get(
            "/TransactionReceipt/download-receipt/" +
              stateobject.transId +
              "/" +
              stateobject.email
          );
        }

        const { data } = response.data;
        const message = response.data.message;
        const status = response.data.status;
        if (status === "success") {
          dispatch({ type: ActionTypes.LOADING_HIDE, payload: message });
          dispatch({ type: successactiontype, payload: data });
          setShow(false);
        } else {
          dispatch({ type: ActionTypes.LOADING_HIDE, payload: message });
          dispatch({ type: failureactiontype, payload: message });
          // setNotify({
          //   isOpen: true,
          //   message: message,
          //   type: status,
          // });
          setShow(false);
        }
      } else {
        const errormsg = "Kindly Supply all required information";
        dispatch({ type: ActionTypes.LOADING_HIDE, payload: stateobject });
        dispatch({ type: failureactiontype, payload: errormsg });
        // setNotify({
        //   isOpen: true,
        //   message: errormsg,
        //   type: "error",
        // });
        setShow(false);
      }
    } catch (error) {
      const errmsg = error.message;
      dispatch({ type: ActionTypes.LOADING_HIDE, payload: errmsg });
      dispatch({ type: failureactiontype, payload: errmsg });
      // setNotify({
      //   isOpen: true,
      //   message: error.message,
      //   type: "error",
      // });
      setShow(false);
    }
  };
};

// Fetch Payment Purpose
const GetPurposeAction = (
  stateobject,
  setNotify,
  successactiontype,
  failureactiontype,
  setShow
) => {
  return async (dispatch) => {
    try {
      if (stateobject !== undefined) {
        let response = "";
        if (successactiontype === ActionTypes.FETCH_PURPOSE_SUCCESS) {
          response = await mainAxios.get(
            "/Transactions/getPaymentPurpose",
            stateobject
          );
        }

        const data = response.data.data;
        const message = response.data.message;
        const status = response.data.status;
        if (status === "success") {
          dispatch({ type: ActionTypes.LOADING_HIDE, payload: message });
          dispatch({ type: successactiontype, payload: data });
          // setNotify({
          //   isOpen: true,
          //   message: message,
          //   type: status,
          // });
          setShow(false);
        } else {
          dispatch({ type: ActionTypes.LOADING_HIDE, payload: message });
          dispatch({ type: failureactiontype, payload: message });
          // setNotify({
          //   isOpen: true,
          //   message: message,
          //   type: status,
          // });
          setShow(false);
        }
      } else {
        const errormsg = "Kindly Supply all required information";
        dispatch({ type: ActionTypes.LOADING_HIDE, payload: stateobject });
        dispatch({ type: failureactiontype, payload: errormsg });
        // setNotify({
        //   isOpen: true,
        //   message: errormsg,
        //   type: "error",
        // });
        setShow(false);
      }
    } catch (error) {
      const errmsg = error.message;
      dispatch({ type: ActionTypes.LOADING_HIDE, payload: errmsg });
      dispatch({ type: failureactiontype, payload: errmsg });
      // setNotify({
      //   isOpen: true,
      //   message: error.message,
      //   type: "error",
      // });
      setShow(false);
    }
  };
};

export { GetAction, GetPurposeAction };
