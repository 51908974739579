import React, { useState, useEffect } from "react";
import { CreateAction } from "../../redux/actions/createaction";
import { connect } from "react-redux";
import ActionTypes from "../../redux/actiontype/ActionTypes";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { FetchAllCountryCodes } from "../../redux/actions/authaction";

const AddNewBeneficiaryModal = (props) => {
  const {
    createcharge,
    setNotify,
    show,
    handleShow,
    setShow,
    authstate,
    chargesLoading,
    setChargesLoading,
    allcountriesstate,
    fetchallcountry,
    allcountriescodestate,
  } = props;
  const [chargedetails, setChargedetails] = useState({});

  const handleSubmit = (e) => {
    if (
      chargedetails.bankName !== undefined &&
      chargedetails.accountName !== undefined &&
      chargedetails.accountNumber !== undefined &&
      chargedetails.accountCurrency !== undefined &&
      chargedetails.accountSWiftCode !== undefined &&
      chargedetails.bankAddress !== undefined &&
      chargedetails.beneficiaryCountry !== undefined &&
      chargedetails.beneficiaryAddress !== undefined &&
      chargedetails.customerId !== undefined &&
      chargedetails.bankCountry !== undefined
    ) {
      e.preventDefault();
      setChargesLoading(true);
      createcharge(
        chargedetails,
        setNotify,
        ActionTypes.ADD_NEW_BENEFICIARY_SUCCESS2,
        ActionTypes.ADD_NEW_BENEFICIARY_FAIL2,
        setShow,
        setChargesLoading
      );
    } else {
      setNotify({
        isOpen: true,
        message: "kindly fill all required fields",
        type: "error",
      });
    }
  };

  const handlereceivingfrom = (cout) => {
    let beneficiaryCountry = cout;

    const accountCurrency = allcountriesarray.find(
      (k) => k.value === cout
    )?.currency;

    let customerId = authstate.nameid;
    setChargedetails({
      ...chargedetails,
      ...{ accountCurrency, customerId, beneficiaryCountry },
    });
  };

  const handlereceivingfrom2 = (cout) => {
    const bankCountry = cout;
    setChargedetails({
      ...chargedetails,
      ...{ bankCountry },
    });
  };

  const allcountriesarray = [];
  const countryarray = [];

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.alpha3Code;
      newitem.currency = item.currency;
      allcountriesarray.push(newitem);
    }
  }

  if (allcountriesstate) {
    for (var kk = 0; kk < allcountriesstate.length; kk++) {
      let item = allcountriesstate[kk];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.alpha3Code;
      newitem.currency = item.currency;
      countryarray.push(newitem);
    }
  }

  useEffect(() => {}, [show]);

  useEffect(() => {
    fetchallcountry(setNotify);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal show={show} onHide={handleShow} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Beneficiary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          {/* <form> */}
          <div className="card-title">Enter beneficiary details</div>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="accountName">Beneficiary Name *</label>
                <input
                  name="accountName"
                  id="accountName"
                  placeholder="Beneficiary Name"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const accountName = event.target.value;
                    setChargedetails({
                      ...chargedetails,
                      ...{ accountName },
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="beneficiaryAddress">
                  Beneficiary Address *
                </label>
                <input
                  name="beneficiaryAddress"
                  id="beneficiaryAddress"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const beneficiaryAddress = event.target.value;
                    setChargedetails({
                      ...chargedetails,
                      ...{ beneficiaryAddress },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="bankName">Beneficiary Country *</label>
                <SelectSearch
                  // style={{ display: "none" }}
                  autoComplete="off"
                  options={countryarray}
                  search={true}
                  onChange={handlereceivingfrom}
                  filterOptions={fuzzySearch}
                  placeholder="Select"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="accountNumber">Account Num / IBAN *</label>
                <input
                  name="accountNumber"
                  id="accountNumber"
                  placeholder="Account Number/IBAN"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const accountNumber = event.target.value;
                    setChargedetails({
                      ...chargedetails,
                      ...{ accountNumber },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="card-title">enter beneficiary bank details</div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="bankName">Bank Name *</label>
                <input
                  name="bankName"
                  id="bankName"
                  placeholder="Bank Name"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const bankName = event.target.value;
                    setChargedetails({
                      ...chargedetails,
                      ...{ bankName },
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="bankAddress">Bank Address *</label>
                <input
                  name="bankAddress"
                  id="bankAddress"
                  placeholder="Bank Address"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const bankAddress = event.target.value;
                    setChargedetails({
                      ...chargedetails,
                      ...{ bankAddress },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="accountSWiftCode">SWIFT *</label>
                <input
                  name="accountSWiftCode"
                  id="accountSWiftCode"
                  placeholder="SWIFT *"
                  style={{
                    textTransform: "uppercase",
                  }}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const accountSWiftCode = event.target.value.toUpperCase();
                    setChargedetails({
                      ...chargedetails,
                      ...{ accountSWiftCode },
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="country">Bank Country *</label>
                <SelectSearch
                  // style={{ display: "none" }}
                  autoComplete="off"
                  options={countryarray}
                  search={true}
                  onChange={handlereceivingfrom2}
                  filterOptions={fuzzySearch}
                  placeholder="Select"
                />
              </div>
            </div>
          </div>

          <div className="card-title">
            Corresponding bank details (Optional)
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlfor="exampleGender">Bank Name</label>

                <input
                  name="corresBankName"
                  id="corresBankName"
                  placeholder="Corresponding Bank Name"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const corresBankName = event.target.value;
                    setChargedetails({
                      ...chargedetails,
                      ...{ corresBankName },
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="exampleGender">
                  <strong> Bank Address</strong>
                </label>

                <input
                  name="corresBankAddress"
                  id="corresBankAddress"
                  placeholder="Corresponding Bank Address"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const corresBankAddress = event.target.value;
                    setChargedetails({
                      ...chargedetails,
                      ...{ corresBankAddress },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="exampleName">Bank Identifier (ABA, BSB)</label>
                <select
                  type="select"
                  id="gender"
                  name="gender"
                  className="mb-2 form-control"
                  onChange={(event) => {
                    const bankIdentifier = event.target.value;
                    setChargedetails({
                      ...chargedetails,
                      ...{ bankIdentifier },
                    });
                  }}
                >
                  <option value="">-- Select --</option>
                  <option value="ABA/Fed Wire/Routing No">
                    ABA/Fed Wire/Routing No.
                  </option>
                  <option value="BSB">BSB</option>
                  <option value="Chips Number">Chips Number</option>
                  <option value="IFSC code">IFSC code </option>
                  <option value="Sort code">Sort code</option>
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="exampleGender">Bank Identifier Code</label>
                <input
                  name="amount"
                  id="exampleAmount"
                  placeholder="Enter Code"
                  type="text"
                  style={{
                    textTransform: "uppercase",
                  }}
                  className="form-control"
                  onChange={(event) => {
                    const bankIdentifierCode = event.target.value;
                    setChargedetails({
                      ...chargedetails,
                      ...{ bankIdentifierCode },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="corresBankName">Account Name</label>
                <input
                  name="corresAccountName"
                  id="corresAccountName"
                  placeholder="Account Name"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const corresAccountName = event.target.value;
                    setChargedetails({
                      ...chargedetails,
                      ...{ corresAccountName },
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="corresBankIBAN">Account Number</label>
                <input
                  name="corresBankIBAN"
                  id="corresBankIBAN"
                  placeholder="Corresponding Account Number"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const corresBankIBAN = event.target.value;
                    setChargedetails({
                      ...chargedetails,
                      ...{ corresBankIBAN },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {/* </form> */}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShow}>
          Close
        </Button>
        <Button
          variant="danger"
          onClick={handleSubmit}
          disabled={chargesLoading}
        >
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    allcountriesstate: state.allcountriesstate.allcountriesstate,
    allcountriescodestate: state.allcountriescodestate.allcountriescodestate,
    authstate: state.authstate.authstate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchallcountry: (setError) => {
      dispatch(FetchAllCountryCodes(setError));
    },
    createcharge: (
      chargedetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setChargesLoading
    ) => {
      dispatch(
        CreateAction(
          chargedetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setChargesLoading
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewBeneficiaryModal);
