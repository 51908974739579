import React from "react";
import { useHistory } from "react-router";

const Successarea = () => {
  const history = useHistory();
  

  function myFunction() {
    history.push("/transactionhistory");
  }

  window.onload = function () {
    var reloading = sessionStorage.getItem("reloading");
    if (reloading) {
      sessionStorage.removeItem("reloading");
      myFunction();
    }
  };

  function reloadP() {
    sessionStorage.setItem("reloading", "true");
    document.location.reload();
  }

  return (
    <div className="text-center">
      <h2>Payment Completed</h2>
      <h5 style={{ marginBottom: 100 }} className="text-success">
        Transaction was Successful
      </h5>
      <div
        className="btn-wide mb-8 mr-2 btn btn-shadow btn-danger btn-lg"
        onClick={reloadP}
        style={{ marginBottom: 300, textAlign: "center" }}
      >
        Go back to JCI App
      </div>
    </div>
  );
};

export default Successarea;
