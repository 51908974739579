/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import img1 from '../assets/images/logo-inverse2.png';
import img2 from '../assets/images/appstore.png';
import img3 from '../assets/images/playstore.png';

export default function Footer() {
    return (
        <div className="app-wrapper-footer">
            <div className="app-footer">
            <div className="app-footer__inner">
            <div className="app-footer-left">
                <div className="footer-dots">
                <div className="dropdown">
                    <a aria-haspopup="true" aria-expanded="false" data-toggle="dropdown" className="dot-btn-wrapper">
                        <img src={img1} height={50} alt=""/>
                    </a>
                    
                </div>
                <ul className="header-megamenu nav">
                <li className="nav-item">
                    <a data-placement="top" rel="popover-focus" data-offset={300} data-toggle="popover-custom" className="nav-link" style={{paddingTop: 10}}>
                    <span className=" text-danger"></span>
                    </a>
                </li>
                
                </ul>
            
                <div className="dots-separator" />
                <div className="dropdown">
                    <a className="dot-btn-wrapper" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false">
                    <i className="dot-btn-icon lnr-earth icon-gradient bg-love-kiss" />
                    </a>
                </div>
                <div className="dots-separator" />                
                </div>
            </div>
            <div className="app-footer-right">
                <ul className="header-megamenu nav">
                <li className="nav-item">
                    <a data-placement="top" rel="popover-focus" data-offset={300} data-toggle="popover-custom" className="nav-link">
                    <img src={img2} height={30} alt=""/>
                    </a>
                </li>
                <li className="nav-item">
                    <a data-placement="top" rel="popover-focus" data-offset={300} data-toggle="popover-custom" className="nav-link">
                    <img src={img3} height={30} alt=""/>
                    </a>
                </li>
                <li className="nav-item">
                    <a data-placement="top" rel="popover-focus" data-offset={300} data-toggle="popover-custom" className="nav-link" href="https://google.com" target="_blank">
                        Privacy Policy
                    </a>
                </li>
                <li className="nav-item">
                    <a data-placement="top" rel="popover-focus" data-offset={300} data-toggle="popover-custom" className="nav-link">
                    Terms and Condition
                    </a>
                </li>
                </ul>
            </div>
            </div>
        </div>
        </div>
                
    )
}
