import { mainAxios } from "../../utils/axios";
import ActionTypes from "../actiontype/ActionTypes";

const CreateAction = (
  stateobject,
  setNotify,
  successactiontype,
  failureactiontype,
  setShow,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      if (stateobject !== undefined) {
        let response = "";
        if (successactiontype === ActionTypes.ADD_CHARGES_SUCCESS) {
          response = await mainAxios.post(
            "/Charges/createCharges",
            stateobject
          );
        } else if (successactiontype === ActionTypes.ADD_PROMO_SUCCESS) {
          response = await mainAxios.post("/Promo/CreatePromo", stateobject);
        } else if (successactiontype === ActionTypes.ADD_REFERRAL_SUCCESS) {
          response = await mainAxios.post(
            "/Referral/createReferralCriteriaBonus",
            stateobject
          );
        } else if (successactiontype === ActionTypes.ADD_RATE_SUCCESS) {
          response = await mainAxios.post("/Rates/createRate", stateobject);
        } else if (successactiontype === ActionTypes.ADD_BANK_SUCCESS) {
          response = await mainAxios.post(
            "/JCIBank/createBankAccount",
            stateobject
          );
        } else if (successactiontype === ActionTypes.ADD_CONVERT_SUCCESS) {
          response = await mainAxios.post(
            "/Transactions/convertSendingToReceiving",
            stateobject
          );
        } else if (successactiontype === ActionTypes.SEND_CONTACT_SUCCESS) {
          response = await mainAxios.post(
            "/pro/inquire/send",
            stateobject
          );
        } else if (successactiontype === ActionTypes.ADD_TRANSACTION_SUCCESS) {
          response = await mainAxios.post(
            "/Transactions/createTransaction",
            stateobject
          );
        } else if (successactiontype === ActionTypes.DELETE_PROMO_SUCCESS) {
          response = await mainAxios.delete(
            "/Promo/DeletePromo/" + stateobject
          );
        } else if (successactiontype === ActionTypes.DELETE_REFERRAL_SUCCESS) {
          response = await mainAxios.delete(
            "/Referral/DeleteReferralCriteriaBonus/" + stateobject
          );
        } else if (successactiontype === ActionTypes.DELETE_RATE_SUCCESS) {
          response = await mainAxios.delete("/Rates/deleteRate/" + stateobject);
        } else if (successactiontype === ActionTypes.FETCH_AUSTRAC_SUCCESS) {
          response = await mainAxios.get(
            "/Transactions/ExportAustracReportByDateRange/" +
              stateobject.fromDateText +
              "/" +
              stateobject.toDateText
          );
        } else if (successactiontype === ActionTypes.DELETE_BANK_SUCCESS) {
          response = await mainAxios.delete(
            "/JCIBank/DeleteBankAccount/" + stateobject
          );
        } else if (
          successactiontype === ActionTypes.DELETE_BENEFICIARY_SUCCESS
        ) {
          response = await mainAxios.delete(
            "/Transactions/deleteReceiver/" + stateobject
          );
        } else if (successactiontype === ActionTypes.EDIT_USER_SUCCESS) {
          response = await mainAxios.put(
            "/Users/updateCustomerByAdmin/",
            stateobject
          );
        } else if (
          successactiontype ===
          ActionTypes.EDIT_REFERRAL_TRANSACTION_BY_ADMIN_SUCCESS
        ) {
          response = await mainAxios.put(
            `/Users/PayCustomerReferralReward/${stateobject.customerId}`,
            { id: stateobject.id, status: stateobject.status }
          );
          // response = await mainAxios.put(
          //   "/ReferralPayment/updateSingleCustomerPayout",
          //   stateobject
          // );
        } else if (successactiontype === ActionTypes.EDIT_TRANSACTION_SUCCESS) {
          response = await mainAxios.put(
            "/Transactions/updateTransactionByAdmin/",
            stateobject
          );
        } else if (successactiontype === ActionTypes.EDIT_RATE_SUCCESS) {
          response = await mainAxios.put("/Rates/updateRate", stateobject);
        } else if (successactiontype === ActionTypes.EDIT_CHARGES_SUCCESS) {
          response = await mainAxios.put(
            "/Charges/updateCharges/",
            stateobject
          );
        } else if (
          successactiontype === ActionTypes.EDIT_TRANSACTION_LIMIT_SUCCESS
        ) {
          response = await mainAxios.put(
            "/AppSettings/updateAppSettings",
            stateobject
          );
        } else if (successactiontype === ActionTypes.RESET_PASSWORD_SUCCESS) {
          let url = "/Users/resetPassword";
          response = await mainAxios.put(url, stateobject);
        } else if (successactiontype === ActionTypes.EDIT_PROMO_SUCCESS) {
          response = await mainAxios.put("/Promo/UpdatePromo", stateobject);
        } else if (successactiontype === ActionTypes.EDIT_REFERRAL_SUCCESS) {
          response = await mainAxios.put(
            "/Referral/updateReferralCriteriaBonus",
            stateobject
          );
        } else if (
          successactiontype === ActionTypes.FETCH_TYPE_TRANSACTION_SUCCESS
        ) {
          response = await mainAxios.get(
            "/Transactions/GetTransactionByType/" + stateobject
          );
        } else if (
          successactiontype === ActionTypes.ADD_NEW_BENEFICIARY_SUCCESS2
        ) {
          response = await mainAxios.post(
            "/Transactions/addReceiver/",
            stateobject
          );
        } else if (
          successactiontype === ActionTypes.ADD_ADVERT_CONVERTION_SUCCESS
        ) {
          if (stateobject.converttype === "sending") {
            response = await mainAxios.post(
              "/Transactions/advert/convertSendingToReceiving",
              stateobject
            );
          } else if (stateobject.converttype === "receiving") {
            response = await mainAxios.post(
              "/Transactions/advert/convertReceivingToSending",
              stateobject
            );
          } else {
            response = await mainAxios.post(
              "/Transactions/advert/convertSendingToReceiving",
              stateobject
            );
          }
        } else if (
          successactiontype === ActionTypes.ADD_RECEIVING_CONVERTION_SUCCESS
        ) {
          if (stateobject.converttype === "receiving") {
            response = await mainAxios.post(
              "/Transactions/advert/convertReceivingToSending",
              stateobject
            );
          } else {
            response = await mainAxios.post(
              "/Transactions/advert/convertSendingToReceiving",
              stateobject
            );
          }
        }

        const { data } = response.data;
        const message = response.data.message;
        const status = response.data.success;
        
        if (status) {
          dispatch({ type: successactiontype, payload: data });
          setNotify({
            isOpen: true,
            message: message,
            type: 'success',
          });
          setShow(false);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          dispatch({ type: failureactiontype, payload: message });
          setNotify({
            isOpen: true,
            message: message,
            type: 'error',
          });
          setShow(false);
        }
      } else {
        const errormsg = "Kindly Supply all required information";
        setIsLoading(false);
        dispatch({ type: failureactiontype, payload: errormsg });
        setNotify({
          isOpen: true,
          message: errormsg,
          type: "error",
        });
        setShow(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        let errmsg = "";
        if (error.response.data.data !== null) {
          let errorarray = error.response.data.errors;
          let list = prepareError(errorarray);
          errmsg = list;
        } else if (error.response.data.message) {
          errmsg = error.response.data.message;
        }

        dispatch({ type: failureactiontype, payload: errmsg });
        setNotify({
          isOpen: true,
          message: errmsg,
          type: "error",
        });
        setShow(false);
      }
    }
  };
};

const CreateAction2 = (
  amount,
  stateobject,
  setNotify,
  successactiontype,
  failureactiontype,
  setShow,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      if (stateobject !== undefined) {
        let response = "";
        if (successactiontype === ActionTypes.ADD_NEW_BENEFICIARY_SUCCESS2) {
          response = await mainAxios.post(
            "/Transactions/addReceiver/",
            stateobject
          );
        } else if (
          successactiontype === ActionTypes.ADD_ADVERT_CONVERTION_SUCCESS
        ) {
          stateobject.amountToSend = amount;
          if (stateobject.converttype === "sending") {
            response = await mainAxios.post(
              "/Transactions/advert/convertSendingToReceiving",
              stateobject
            );
          } else if (stateobject.converttype === "receiving") {
            response = await mainAxios.post(
              "/Transactions/advert/convertReceivingToSending",
              stateobject
            );
          } else {
            response = await mainAxios.post(
              "/Transactions/advert/convertSendingToReceiving",
              stateobject
            );
          }
        } else if (
          successactiontype === ActionTypes.ADD_RECEIVING_CONVERTION_SUCCESS
        ) {
          stateobject.amountToReceive = amount;
          if (stateobject.converttype === "receiving") {
            response = await mainAxios.post(
              "/Transactions/advert/convertReceivingToSending",
              stateobject
            );
          } else {
            response = await mainAxios.post(
              "/Transactions/advert/convertSendingToReceiving",
              stateobject
            );
          }
        }

        const { data } = response.data;
        const message = response.data.message;
        const status = response.data.status;
        if (status === "success") {
          dispatch({ type: successactiontype, payload: data });
          // setNotify({
          //   isOpen: true,
          //   message: message,
          //   type: status,
          // });
          setShow(false);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          dispatch({ type: failureactiontype, payload: message });
          setNotify({
            isOpen: true,
            message: message,
            type: status,
          });
          setShow(false);
        }
      } else {
        const errormsg = "Kindly Supply all required information";
        setIsLoading(false);
        dispatch({ type: failureactiontype, payload: errormsg });
        setNotify({
          isOpen: true,
          message: errormsg,
          type: "error",
        });
        setShow(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        let errmsg = "";
        if (error.response.data.data !== null) {
          let errorarray = error.response.data.errors;
          let list = prepareError(errorarray);
          errmsg = list;
        } else if (error.response.data.message) {
          errmsg = error.response.data.message;
        }

        dispatch({ type: failureactiontype, payload: errmsg });
        setNotify({
          isOpen: true,
          message: errmsg,
          type: "error",
        });
        setShow(false);
      }
    }
  };
};

const CreateNewAction = (
  token,
  history,
  successactiontype,
  failureactiontype,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      if (token !== undefined) {
        let response = "";
        if (successactiontype === ActionTypes.GET_PAYMENT_STATUS_SUCCESS) {
          response = await mainAxios.post(
            "/Transactions/confirmPOLIPayment",
            token
          );
        }

        let { data } = response.data;
        let message = response.data.message;
        let returndata = response.data.data;

        if (returndata) {
          dispatch({ type: successactiontype, payload: data });
          history.push("/Success");
          setIsLoading(false);
        } else {
          setIsLoading(false);
          history.push("/Failed");
          dispatch({ type: failureactiontype, payload: message });
        }
      } else {
        const errormsg = "Kindly Supply all required information";
        setIsLoading(false);
        history.push("/NotVerified");
        dispatch({ type: failureactiontype, payload: errormsg });
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        let errmsg = "";
        if (error.response.data.data !== null) {
          let errorarray = error.response.data.errors;
          let list = prepareError(errorarray);
          errmsg = list;
        } else if (error.response.data.message) {
          errmsg = error.response.data.message;
        }
        history.push("/NotVerified");
        dispatch({ type: failureactiontype, payload: errmsg });
      } else {
        history.push("/NotVerified");
      }
    }
  };
};

// const prepareError = (array) => {
//   let errorlist = "";
//   if (array) {
//     for (const [key, value] of Object.entries(array)) {
//       errorlist += value[0] + "\n";
//     }
//   }
//   return errorlist;
// };


const prepareError = (array) => {
  let errorlist = "";
  if (array) {
    let peju = array[Object.keys(array)[Object.keys(array).length - 1]];
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(array)) {
      if (value === peju) {
        errorlist += value[0] + "\n";
      } else {
        // eslint-disable-next-line no-useless-concat
        errorlist += value[0] + "," + "\n";
      }
    }
  }
  return errorlist;
};

export { CreateAction, CreateNewAction, CreateAction2 };
