/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ShowLoading, FetchAllCountryCodes } from "../redux/actions/authaction";
import { CreateAction } from "../redux/actions/createaction";
import { PatchAction } from "../redux/actions/patchaction";
import { GetPurposeAction, GetAction } from "../redux/actions/getaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import { useHistory } from "react-router";
import SelectSearch, { fuzzySearch } from "react-select-search";
import "./styles.css";
import { Link } from "react-router-dom";
import creditcardimg from "../assets/images/CC.png";
import receiptGenerator from "../utils/receiptGenerator";
import "../assets/main.d810cf0ae7f39f28f336.css";
import backgroundImage from "../assets/images/water.png";
import { formatNumber } from "../utils/extras";

const Sendmoneyarea = (props) => {
  const {
    fetchallcountry,
    allcountriesstate,
    fetchallreceivingcountry,
    fetchallsendingcountry,
    allpurposestate,
    fetchallpurpose,
    fetchaccounts,
    setNotify,
    show,
    createbeneficiary,
    setShow,
    createconvert,
    createtransact,
    conversionstate,
    authstate,
    fetchallijcaccount,
    allbanks,
    transactionstate,
    allreceivingcurrencystate,
    allsendingcurrencystate,
    alltransactionreceiving,
    alltransactionsending,
    alluserreceivers,
    fetchalluserreceivers,
    addExistBeneficiary,
    addpaymentstotransaction,
    downloadreceiptaction,
    uploadconfrimationaction,
    redirecturl,
    downloadreceiptstatus,
    allcountriescodestate,
  } = props;


  //let ijcbankaccount = {};

  const [transferdetails, setTransferDetails] = useState({});
  const [beneficiarydetails, setBeneficiarydetails] = useState({});
  const [beneficiaryid, setBeneficiaryId] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [istransactionLoading, setIstransactionLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [, setBeneficiaryLoading] = useState(false);
  const [addbeneficiaryLoading, setAddBeneficiaryLoading] = useState(true);
  const [uploadConfirmationLoading, setUploadConfirmationLoading] =
    useState(false);
  const [paymenttype, setPaymenttype] = useState("");
  const [screen, setScreen] = useState(0);
  const [recactive, setRecActive] = useState(false);
  const [recactive2, setRecActive2] = useState(true);
  const [isNigeria, setIsNigeria] = useState(false);
  const history = useHistory();
  const [benAdded, setBenAdded] = useState(false);
  const [uploaddetails, setUploadDetails] = useState(false);
  const [stepone, setStepOne] = useState(true);
  const [steptwo, setStepTwo] = useState(false);
  const [stepthree, setStepThree] = useState(false);
  const [activeDownload, setActiveDownload] = useState(false);
  const [istrans, setIsTrans] = useState(false);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const countryarray = [];
  const receivingstatearray = [];
  const sendingstatearray = [];
  const alllbanksarray = [];

  

  let benearray = [];

  useEffect(() => {
    if (paymenttype) {
    }
  }, [paymenttype]);

  useEffect(() => {
    if (screen) {
    }
  }, [screen]);

  const uploadConfirmation = () => {
    setUploadConfirmationLoading(true);
    setFirst(true);
    uploadconfrimationaction(
      uploaddetails,
      transactionstate.id,
      setUploadConfirmationLoading,
      setNotify,
      ActionTypes.UPLOAD_PAYMENTS_CONFIRMATION_SUCCESS,
      ActionTypes.UPLOAD_PAYMENTS_CONFIRMATION_FAIL,
      setShow
    );
  };

  useEffect(() => {
    if (uploadConfirmationLoading === false && first === true) {
      setSecond(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadConfirmationLoading]);

  const resetScreen = () => {
    setScreen(0);
  };

  const proceedPayment = () => {
    setBeneficiaryLoading(true);
    let type = null;
    if (paymenttype.type === "creditcard") {
      type = 2;
      setScreen(2);
    } else if (paymenttype.type === "manual") {
      type = 4;
      setScreen(4);
    } else if (paymenttype.type === "poli") {
      type = 1;
      setScreen(1);
    } else if (paymenttype.type === "payid") {
      type = 3;
      setScreen(3);
    }

    if (type !== null) {
      addpaymentstotransaction(
        type,
        transactionstate.id,
        setBeneficiaryLoading,
        setNotify,
        ActionTypes.ADD_PAYMENTS_TO_TRANSACTION_SUCCESS,
        ActionTypes.ADD_PAYMENTS_TO_TRANSACTION_FAIL,
        setShow
      );

      fetchaccounts(
        transferdetails.sendCountry,
        setNotify,
        ActionTypes.GET_BANK_SUCCESS,
        ActionTypes.GET_BANK_FAIL,
        setShow
      );
    }
  };

  useEffect(() => {
    if (redirecturl) {
      history.push("/paymentpage", { url: redirecturl.paymentLinkURL });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirecturl]);

  useEffect(() => {
    if (
      submitted &&
      transferdetails.purpose &&
      transferdetails.description &&
      transferdetails.sendCountry &&
      transferdetails.receieveCountry
    ) {
      createTransaction(conversionstate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionstate]);

  const createTransaction = (props) => {
    let transactionParams = {};
    transactionParams.customerId = authstate.nameid;
    transactionParams.amountToSend = props.amountToSend;
    transactionParams.exchangeRate = props.rate;
    transactionParams.paymentPurpose = transferdetails.purpose;
    transactionParams.paymentDescription = transferdetails.description;
    transactionParams.sendingCountry = transferdetails.sendCountry;
    transactionParams.receivingCountry = transferdetails.receieveCountry;
    transactionParams.amountToReceive_USD = props.amountToReceive_USD;
    transactionParams.amountToReceive_Local = props.amountToReceive_Local;
    transactionParams.bonusCode = transferdetails.bonusCode;

    setIstransactionLoading(true);

    createtransact(
      transactionParams,
      setNotify,
      ActionTypes.ADD_TRANSACTION_SUCCESS,
      ActionTypes.ADD_TRANSACTION_FAIL,
      setShow,
      setIstransactionLoading
    );
    setSubmitted(false);
  };

  //fetch all countries
  useEffect(() => {
    fetchallcountry(setNotify);
    setStepOne(true);

    fetchallpurpose(
      show,
      setNotify,
      ActionTypes.FETCH_PURPOSE_SUCCESS,
      ActionTypes.FETCH_PURPOSE_FAIL,
      setShow
    );
    fetchallreceivingcountry(
      show,
      setNotify,
      ActionTypes.GET_TRANSACTION_RECEIVING_SUCCESS,
      ActionTypes.GET_TRANSACTION_RECEIVING_FAIL,
      setShow
    );
    fetchallsendingcountry(
      show,
      setNotify,
      ActionTypes.GET_TRANSACTION_SENDING_SUCCESS,
      ActionTypes.GET_TRANSACTION_SENDING_FAIL,
      setShow
    );
    fetchalluserreceivers(
      show,
      setNotify,
      ActionTypes.GET_USER_RECEIVERS_SUCCESS,
      ActionTypes.GET_USER_RECEIVERS_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //handle the return value from country dropdown selection
  const handlesendingfrom = (cout) => {
    const sendCountry = cout;
    setTransferDetails({ ...transferdetails, ...{ sendCountry } });
  };


  const [ijcbankaccount,setijcbankaccount] = useState({});
  const [getBankId,SetgetBankId] = useState(0);

  useEffect(()=>
  {
     console.log("Just to Rerender") ;
     changejciaccount(getBankId)

  },[getBankId])

  const changejciaccount = (Id) => {

    console.log("All Banks", allbanks);

    console.log("Selected ID ", Id);
    const ijcbankaccountUpdated = allbanks.filter(bnk=> bnk.id == Id);
    console.log("JCI Bank ", ijcbankaccountUpdated[0]);
    setijcbankaccount(ijcbankaccountUpdated[0]);
  };

  const handlereceivingfrom = (cout) => {
    let beneficiaryCountry = cout;
    const receieveCountry = cout;

    setTransferDetails({ ...transferdetails, ...{ receieveCountry } });

    // nigeria to abroad
    // abroad to corresponding
    if (receieveCountry === "NGA") {
      setIsNigeria(true);
    }

    const accountCurrency = countryarray.find(
      (k) => k.value === cout
    )?.currency;

    let customerId = authstate.nameid;
    setBeneficiarydetails({
      ...beneficiarydetails,
      ...{ accountCurrency, customerId, beneficiaryCountry },
    });
  };

  const handlereceivingfrom2 = (cout) => {
    const bankCountry = cout;
    setBeneficiarydetails({
      ...beneficiarydetails,
      ...{ bankCountry },
    });
  };

  const handleSubmit = (e) => {
    if (
      transferdetails.amountToSend !== undefined &&
      transferdetails.sendCountry !== undefined &&
      transferdetails.receieveCountry !== undefined &&
      transferdetails.description !== undefined &&
      transferdetails.purpose !== undefined &&
      transferdetails.amountToSend !== "" &&
      transferdetails.amountToSend !== 0 &&
      transferdetails.sendCountry !== "" &&
      transferdetails.receieveCountry !== "" &&
      transferdetails.description !== "" &&
      transferdetails.purpose !== ""
    ) {
      e.preventDefault();
      setIsLoading(true);
      setSubmitted(true);
      setIsTrans(true);
      createconvert(
        transferdetails,
        setNotify,
        ActionTypes.ADD_CONVERT_SUCCESS,
        ActionTypes.ADD_CONVERT_FAIL,
        setShow,
        setIsLoading
      );
    } else {
      setNotify({
        isOpen: true,
        message: "kindly fill all empty spaces",
        type: "error",
      });
    }
  };

  const proceedToTwo = () => {
    if (transactionstate) {
      setStepOne(false);
      setStepTwo(true);
      setIsTrans(false);
    }
  };

  const proceedToThree = () => {
    setStepTwo(false);
    setStepThree(true);
  };

  const backToThree = () => {
    resetScreen();
  };

  const backToOne = () => {
    setStepOne(true);
    setStepTwo(false);
  };

  const backToTwo = () => {
    setStepThree(false);
    setStepTwo(true);
  };

  useEffect(() => {
    if (!addbeneficiaryLoading) {
      handleReset();
      proceedToThree();
    }
  }, [addbeneficiaryLoading]);

  useEffect(() => {
    if (downloadreceiptstatus !== undefined) {
      processReceipt(downloadreceiptstatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadreceiptstatus]);

  const processReceipt = (downloadreceiptstatus) => {
    //processing receipt here...
    if (activeDownload) {
      receiptGenerator(downloadreceiptstatus, setActiveDownload);
    }
  };

  const postBeneficiaryDetails = (e) => {
    e.preventDefault();
    if (
      beneficiarydetails.accountNumber !== undefined &&
      beneficiarydetails.accountCurrency !== undefined &&
      beneficiarydetails.customerId !== undefined &&
      beneficiarydetails.beneficiaryCountry !== undefined &&
      beneficiarydetails.accountSWiftCode !== undefined &&
      beneficiarydetails.beneficiaryAddress !== undefined &&
      beneficiarydetails.bankAddress !== undefined &&
      beneficiarydetails.accountName !== undefined &&
      beneficiarydetails.bankName !== undefined
    ) {
      if (transactionstate.id) {
        setAddBeneficiaryLoading(true);
        setBenAdded(true);
        createbeneficiary(
          beneficiarydetails,
          transactionstate.id,
          setAddBeneficiaryLoading,
          setNotify,
          ActionTypes.ADD_NEW_BENEFICIARY_SUCCESS,
          ActionTypes.ADD_NEW_BENEFICIARY_FAIL,
          setShow
        );
      }
    } else {
      setNotify({
        isOpen: true,
        message: "kindly fill all empty spaces",
        type: "error",
      });
    }
  };

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  const addExistingBeneficiary = () => {
    if (beneficiaryid.receiverID) {
      setAddBeneficiaryLoading(true);
      setBenAdded(true);
      addExistBeneficiary(
        beneficiaryid.receiverID,
        transactionstate.id,
        setAddBeneficiaryLoading,
        setNotify,
        ActionTypes.ADD_EXISTING_BENEFICIARY_SUCCESS,
        ActionTypes.ADD_EXISTING_BENEFICIARY_FAIL,
        setShow
      );
    } else {
      setNotify({
        isOpen: true,
        message: "kindly select a beneficiary or add a new beneficiary",
        type: "error",
      });
    }
  };

  const downloadReceipt = () => {
    setIsDownloading(true);
    setActiveDownload(true);
    // history.push("/transactionhistory");
    downloadreceiptaction(
      transactionstate.id,
      setNotify,
      ActionTypes.DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS,
      ActionTypes.DOWNLOAD_TRANSACTION_RECEIPT_FAIL,
      setIsDownloading
    );
  };

  const toggleform = () => {
    setRecActive(!recactive);
    setRecActive2(!recactive2);
  };

  function findUnique(arr, predicate) {
    var found = {};
    arr.forEach((d) => {
      found[predicate(d)] = d;
    });
    return Object.keys(found).map((key) => found[key]);
  }

  if (alluserreceivers) {
    for (var q = 0; q < alluserreceivers.length; q++) {
      let item = alluserreceivers[q];
      let newitem = {};
      newitem.name = item.accountName;
      newitem.value = item.customerId;
      // newitem.bankName = item.bankName;
      newitem.currency = item.accountCurrency;
      newitem.country = item.beneficiaryCountry;
      benearray.push({ ...item, ...newitem });
    }
    // filter beneficiary array by account number
    // var result = findUnique(alluserreceivers, (d) => d.accountNumber);
    // benearray = result;
  }

  if (alltransactionsending) {
    for (var i = 0; i < alltransactionsending.length; i++) {
      let item = alltransactionsending[i];
      if (item !== null) {
        let newitem = {};
        newitem.name = `${item.name} (${item.currency})`;
        newitem.value = item.countryCode;
        newitem.currency = item.currency;
        sendingstatearray.push(newitem);
      }
    }
  }
  if (alltransactionreceiving) {
    for (var j = 0; j < alltransactionreceiving.length; j++) {
      let item = alltransactionreceiving[j];
      if (item !== null) {
        let newitem = {};
        newitem.name = `${item.name} (${item.currency})`;
        newitem.value = item.countryCode;
        newitem.currency = item.currency;
        receivingstatearray.push(newitem);
      }
    }
  }

  if (allbanks) {
    for (var kk = 0; kk < allbanks.length; kk++) {
      let item = allbanks[kk];
      let newitem = {};
      newitem.name = item.bankName;
      newitem.value = item.id;
      alllbanksarray.push(newitem);
    }
  }

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      newitem.value = item.alpha3Code;
      newitem.currency = item.currency;
      countryarray.push(newitem);
    }
  }

  return (
    <>
      {" "}
      <section
        style={{
          backgroundImage: `url("${backgroundImage}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPositionY: "center",
          backgroundPositionX: "calc(15% + 310px)",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="app-page-title app-page-title-simple">
          <div className="page-title-wrapper">
            <div className="page-title-heading" style={{ marginLeft: 16 }}>
              <div>
                <div className="page-title-head center-elem">
                  <span className="d-inline-block">
                    <h2>Send Money</h2>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="main-card mb-3">
              <div className="card-body">
                <div id="smartwizard" className="sw-main sw-theme-default">
                  <ul className="forms-wizard nav nav-tabs step-anchor">
                    <li className="nav-item active">
                      <a href="#step-1" className="nav-link">
                        <em>1</em>
                        <span>Conversion</span>
                      </a>
                    </li>
                    <li className="nav-item active">
                      <a href="#step-2" className="nav-link">
                        <em>2</em>
                        <span>Beneficiary Details</span>
                      </a>
                    </li>
                    <li className="nav-item active">
                      <a href="#step-3" className="nav-link">
                        <em>3</em>
                        <span>Choose Payment</span>
                      </a>
                    </li>
                  </ul>

                  <div className="tab-pane step-content" hidden={!stepone}>
                    <div className="row">
                      {(!transactionstate || !istrans) && (
                        <div className="col-12">
                          <div className="card-shadow-primary card-border mb-3">
                            <div className="dropdown-menu-header">
                              <div className="dropdown-menu-header-inner bg-danger">
                                <div
                                  className="menu-header-image"
                                  // style={{
                                  //   backgroundImage:
                                  //     'url("assets/images/dropdown-header/city2.jpg")',
                                  // }}
                                />
                                <div className="menu-header-content">
                                  <span className="form-heading text-white">
                                    <strong>SEND MONEY</strong>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="scroll-area-sm convert">
                              <div
                                className="scrollbar-container"
                                style={{ marginBottom: -32 }}
                              >
                                <ul className="list-group list-group-flush">
                                  <li className="" style={{ margin: 8 }}>
                                    <div className="widget-content p-0">
                                      <div className="col-md-12 position-relative form-group">
                                        <div className="form-row">
                                          <div className="position-relative form-group col-md-6">
                                            <label htmlFor="exampleAmount">
                                              <strong>Amount</strong>
                                            </label>
                                            <input
                                              name="amount"
                                              id="exampleAmount"
                                              placeholder="Amount you are sending"
                                              type="number"
                                              min="0"
                                              className="form-control"
                                              onChange={(event) => {
                                                let amount = event.target.value;
                                                let amountToSend =
                                                  Number(amount);
                                                setTransferDetails({
                                                  ...transferdetails,
                                                  ...{ amountToSend },
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="position-relative form-group col-md-6">
                                            <label htmlFor="exampleGender">
                                              <strong>Sending From</strong>
                                            </label>

                                            <SelectSearch
                                              autoComplete="off"
                                              options={sendingstatearray}
                                              search={true}
                                              onChange={handlesendingfrom}
                                              filterOptions={fuzzySearch}
                                              placeholder="Choose your country"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li
                                    className=""
                                    style={{ margin: 8, marginTop: -16 }}
                                  >
                                    <div className="widget-content p-0">
                                      <div className="col-md-12 position-relative form-group">
                                        <div className="form-row">
                                          <div className="col-md-6">
                                            <label htmlFor="exampleGender">
                                              <strong>Receiving</strong>
                                            </label>
                                            <SelectSearch
                                              // style={{ display: "none" }}
                                              autoComplete="off"
                                              options={receivingstatearray}
                                              search={true}
                                              onChange={handlereceivingfrom}
                                              filterOptions={fuzzySearch}
                                              placeholder="Choose your country"
                                            />
                                          </div>
                                          <div className="col-md-6 position-relative form-group">
                                            <label htmlFor="exampleGender">
                                              <strong style={{ marginTop: 8 }}>
                                                Payment Purpose
                                              </strong>
                                            </label>
                                            <div className="form-row">
                                              <select
                                                type="select"
                                                id="namePrefix"
                                                name="namePrefix"
                                                className="custom-select col-md-12"
                                                onChange={(event) => {
                                                  const purpose =
                                                    event.target.value;
                                                  setTransferDetails({
                                                    ...transferdetails,
                                                    ...{ purpose },
                                                  });
                                                }}
                                              >
                                                <option defaultValue>
                                                  Select
                                                </option>
                                                {allpurposestate?.map(
                                                  (purpose, index) => (
                                                    <option
                                                      key={index}
                                                      value={purpose}
                                                    >
                                                      {purpose}
                                                    </option>
                                                  )
                                                )}
                                                <option value="Goods &amp; Services">
                                                  Goods &amp; Services
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li
                                    style={{
                                      marginLeft: 8,
                                      marginRight: 8,
                                      marginTop: -16,
                                    }}
                                  >
                                    <div className="widget-content p-0">
                                      <div className="col-md-12 position-relative form-group">
                                        <div className="form-row">
                                          <div className="col-md-6">
                                            <div className="position-relative form-group">
                                              <label htmlFor="exampleText">
                                                <strong>
                                                  Transaction Description
                                                </strong>
                                              </label>
                                              <input
                                                name="text"
                                                id="Paymentdescription"
                                                className="form-control"
                                                onChange={(event) => {
                                                  const description =
                                                    event.target.value;
                                                  setTransferDetails({
                                                    ...transferdetails,
                                                    ...{ description },
                                                  });
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="position-relative form-group col-md-6">
                                            <label htmlFor="bonusCode">
                                              <strong>
                                                Promo Code (Optional)
                                              </strong>
                                            </label>
                                            <input
                                              name="bonusCode"
                                              id="bonusCode"
                                              style={{
                                                textTransform: "uppercase",
                                              }}
                                              placeholder="Promo Code"
                                              type="text"
                                              className="form-control"
                                              onChange={(event) => {
                                                let bonusCode =
                                                  event.target.value;
                                                setTransferDetails({
                                                  ...transferdetails,
                                                  ...{ bonusCode },
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="widget-content p-0">
                              <div className="col-md-12 position-relative form-group">
                                <div className="form-row">
                                  <div className="col-md-6">
                                    {/* <div className="position-relative form-group">
                                              <label htmlFor="exampleText">
                                                <strong>
                                                  Transaction Description
                                                </strong>
                                              </label>
                                              <textarea
                                                name="text"
                                                id="Paymentdescription"
                                                className="form-control"
                                                onChange={(event) => {
                                                  const description =
                                                    event.target.value;
                                                  setTransferDetails({
                                                    ...transferdetails,
                                                    ...{ description },
                                                  });
                                                }}
                                              />
                                            </div> */}
                                  </div>
                                  <div className="position-relative form-group col-md-6">
                                    <div
                                      className="text-center d-block"
                                      style={{
                                        marginLeft: 8,
                                        marginRight: 8,
                                        marginTop: -32,
                                        marginBottom: 16,
                                      }}
                                    >
                                      {(() => {
                                        if (isLoading) {
                                          return (
                                            <button
                                              type="submit"
                                              className="mr-2 btn btn-danger btn-lg btn-block"
                                            >
                                              <span className="absolute text-center font-normal inset-y-0 flex items-center pl-3">
                                                Loading...
                                              </span>
                                            </button>
                                          );
                                        } else {
                                          return (
                                            <button
                                              className="mr-2 btn btn-danger btn-lg btn-block"
                                              disabled={isLoading}
                                              onClick={handleSubmit}
                                            >
                                              Proceed
                                            </button>
                                          );
                                        }
                                      })()}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {transactionstate && istrans && (
                        <div className="col-12">
                          <div className="card-shadow-primary profile-responsive card-border mb-3">
                            <div className="dropdown-menu-header">
                              <div className="dropdown-menu-header-inner bg-danger">
                                <div
                                  className="menu-header-image"
                                  // style={{
                                  //   backgroundImage:
                                  //     'url("assets/images/dropdown-header/abstract1.jpg")',
                                  // }}
                                />
                                <div className="menu-header-content">
                                  <span className="form-heading text-white">
                                    <strong>CONFIRM TRANSACTION</strong>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <ul className="list-group list-group-flush"></ul>
                          </div>

                          {/* {(() => {
                            if (transactionstate && istrans) {
                              return ( */}
                          <div className="card-hover-shadow card-border mb-3">
                            <div className="" style={{ margin: 16 }}>
                              <div className="widget-content p-0">
                                <div className="widget-content-outer">
                                  <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                      <div className="widget-heading">
                                        Convertion Status{" "}
                                      </div>
                                    </div>
                                    <div className="widget-content-right">
                                      <div
                                        className="widget-numbers text-success"
                                        style={{ fontSize: 16 }}
                                      >
                                        {(() => {
                                          if (conversionstate) {
                                            return (
                                              <span className="user-details-title">
                                                Success
                                              </span>
                                            );
                                          } else {
                                            return (
                                              <h3 className="user-details-title">
                                                null
                                              </h3>
                                            );
                                          }
                                        })()}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="" style={{ margin: 16 }}>
                              <div className="widget-content p-0">
                                <div className="widget-content-outer">
                                  <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                      <div className="widget-heading">
                                        Transaction Status{" "}
                                      </div>
                                    </div>
                                    <div className="widget-content-right">
                                      <div
                                        className="widget-numbers text-warning"
                                        style={{ fontSize: 16 }}
                                      >
                                        {transactionstate?.transactionStatus}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="" style={{ margin: 16 }}>
                              <div className="widget-content p-0">
                                <div className="widget-content-outer">
                                  <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                      <div className="widget-heading">
                                        Amount To Send
                                      </div>
                                    </div>
                                    <div className="widget-content-right">
                                      <div
                                        className="widget-numbers text-danger"
                                        style={{ fontSize: 16 }}
                                      >
                                        {formatNumber(
                                          conversionstate?.amountToSend
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="" style={{ margin: 16 }}>
                              <div className="widget-content p-0">
                                <div className="widget-content-outer">
                                  <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                      <div className="widget-heading">
                                        Amount To Receive (Local){" "}
                                      </div>
                                    </div>
                                    <div className="widget-content-right">
                                      <div
                                        className="widget-numbers text-danger"
                                        style={{ fontSize: 16 }}
                                      >
                                        {formatNumber(
                                          conversionstate?.amountToReceive_Local
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="" style={{ margin: 16 }}>
                              <div className="widget-content p-0">
                                <div className="widget-content-outer">
                                  <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                      <div className="widget-heading">
                                        Amount To Receive (USD){" "}
                                      </div>
                                    </div>
                                    <div className="widget-content-right">
                                      <div
                                        className="widget-numbers text-danger"
                                        style={{ fontSize: 16 }}
                                      >
                                        {formatNumber(
                                          conversionstate?.amountToReceive_USD
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="" style={{ margin: 16 }}>
                              <div className="widget-content p-0">
                                <div className="widget-content-outer">
                                  <div className="widget-content-wrapper">
                                    <div className="widget-content-right">
                                      {/* <span>
                                        Clik this button to continue{" "}
                                        <b>&#8594;</b>{" "}
                                      </span> */}
                                      <button
                                        style={{ marginBottom: 16 }}
                                        className="btn-shadow-danger btn btn-danger btn-lg"
                                        onClick={proceedToTwo}
                                      >
                                        Proceed
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* );
                            } else {
                              return <div></div>;
                            }
                          })()} */}
                        </div>
                      )}
                    </div>
                    <div className="divider" />
                    <div className="clearfix"></div>
                  </div>

                  <div className="tab-pane step-content" hidden={!steptwo}>
                    <div id="accordion" className="accordion-wrapper mb-3">
                      <div className="col-md-12">
                        <div
                          id="headingOne"
                          className=""
                          style={{ margin: 20 }}
                        >
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                            className="text-left m-0 p-0 btn btn-link btn-block"
                          >
                            <span className="form-heading text-danger">
                              SELECT EXISTING BENEFICIARY OR ADD A NEW
                              BENEFICIARY
                            </span>
                          </button>
                        </div>
                        <div
                          data-parent="#accordion"
                          id="collapseOne"
                          aria-labelledby="headingOne"
                          className="collapse show"
                        >
                          <div className="card-body">
                            <div className="row" hidden={!recactive2}>
                              <div className="col-md-12 col-lg-12">
                                <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-left">
                                  <div className="widget-content p-0 w-100">
                                    <div className="widget-content-outer">
                                      <div
                                        className="widget-content-wrapper"
                                        style={{ marginBottom: 25 }}
                                      >
                                        <div className="widget-content-left fsize-1 col-md-12">
                                          <h5
                                            className="text-muted opacity-10"
                                            style={{ marginLeft: 4 }}
                                          >
                                            Select Existing Beneficiary
                                          </h5>
                                          <br></br>
                                          <div
                                            className="progress-bar-xs progress"
                                            style={{
                                              marginLeft: 4,
                                              marginRight: 4,
                                            }}
                                          >
                                            <div
                                              className="progress-bar bg-danger"
                                              role="progressbar"
                                              aria-valuenow={71}
                                              aria-valuemin={0}
                                              aria-valuemax={100}
                                              style={{ width: "100%" }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-12 position-relative form-group ">
                                        <div className="form-row">
                                          <div className="col-md-12">
                                            <label htmlFor="exampleGender">
                                              Select Beneficiary
                                            </label>

                                            <select
                                              type="select"
                                              id="namePrefix"
                                              // style={{ display: "none" }}
                                              // autoComplete="off"
                                              name="namePrefix"
                                              className="custom-select col-md-12"
                                              onChange={(event) => {
                                                const receiverID =
                                                  event.target.value;
                                                setBeneficiaryId({
                                                  ...beneficiaryid,
                                                  ...{ receiverID },
                                                });
                                              }}
                                            >
                                              <option value="0">
                                                {" "}
                                                Select Beneficiary{" "}
                                              </option>
                                              {transferdetails &&
                                                alluserreceivers
                                                  ?.filter(
                                                    (item) =>
                                                      item.beneficiaryCountry ===
                                                      transferdetails.receieveCountry
                                                  )
                                                  .sort(
                                                    (a, b) =>
                                                      a.accountName <
                                                      b.accountName
                                                  )
                                                  ?.map((item) => (
                                                    <option
                                                      key={item.id}
                                                      value={item.id}
                                                    >
                                                      {item.accountName} -{" "}
                                                      {item.bankName} -{" "}
                                                      {item.accountNumber}
                                                    </option>
                                                  ))}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div
                              className="col-md-12 position-relative form-group"
                              hidden={!recactive2}
                            >
                              <span className="form-heading text-danger">
                                OR <br></br>
                              </span>
                            </div> */}

                            <div className="row" hidden={!recactive2}>
                              {/* <div
                              className="col-md-12 position-relative form-group"
                              hidden={!recactive2}
                            >
                              <span className="form-heading text-danger">
                                OR <br></br>
                              </span>
                            </div> */}
                              <div className="col-md-12 col-lg-12">
                              <span className="form-heading text-danger" style={{fontSize: 34, fontWeight: "bold", marginRight: 32, marginBottom: 100, paddingBottom: 30}}>
                                OR 
                              </span>
                                <button
                                  className="my-3 btn btn-danger btn-icon btn-icon-left"
                                  onClick={toggleform}
                                  style={{
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    marginTop: 150
                                  }}
                                >
                                  {" "}
                                  <i className="pe-7s-add-user btn-icon-wrapper">
                                    {" "}
                                  </i>
                                  Add New Beneficiary
                                </button>
                              </div>
                            </div>

                            <div className="row" hidden={!recactive}>
                              <div className="col-md-12 col-lg-12">
                                <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-left">
                                  <div className="widget-content p-0 w-100">
                                    {/* <button
                                      className="mr-2 btn btn-danger btn-icon btn-icon-left"
                                      onClick={toggleform}
                                      style={{
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                      }}
                                    >
                                      {" "}
                                      <i className="pe-7s-add-user btn-icon-wrapper">
                                        {" "}
                                      </i>
                                      Add Existing Beneficiary
                                    </button> */}
                                    <div className="widget-content-outer">
                                      <div
                                        hidden={!recactive}
                                        className="col-md-12 position-relative form-group"
                                        style={{ marginTop: 5 }}
                                      >
                                        <div
                                          className="card-title"
                                          style={{ marginTop: 16 }}
                                        >
                                          Enter beneficiary details
                                        </div>
                                        <div className="form-row">
                                          <div className="col-md-6">
                                            <label htmlFor="exampleGender">
                                              Beneficiary Name *
                                            </label>
                                            <input
                                              name="accountName"
                                              id="accountName"
                                              placeholder="Beneficiary Name"
                                              type="text"
                                              className="form-control"
                                              onChange={(event) => {
                                                const accountName =
                                                  event.target.value;
                                                setBeneficiarydetails({
                                                  ...beneficiarydetails,
                                                  ...{ accountName },
                                                });
                                              }}
                                            />
                                          </div>

                                          <div className="col-md-6">
                                            <label htmlFor="exampleGender">
                                              Beneficiary Address *
                                            </label>
                                            <input
                                              name="beneficiaryAddress"
                                              id="beneficiaryAddress"
                                              placeholder="Beneficiary Address"
                                              type="text"
                                              className="form-control"
                                              onChange={(event) => {
                                                const beneficiaryAddress =
                                                  event.target.value;
                                                setBeneficiarydetails({
                                                  ...beneficiarydetails,
                                                  ...{ beneficiaryAddress },
                                                });
                                              }}
                                            />
                                          </div>

                                          <div
                                            className="col-md-6"
                                            style={{ marginTop: 16 }}
                                          >
                                            <label htmlFor="exampleGender">
                                              Beneficiary Country *
                                            </label>
                                            <SelectSearch
                                              // style={{ display: "none" }}
                                              autoComplete="off"
                                              options={countryarray}
                                              search={true}
                                              onChange={handlereceivingfrom}
                                              filterOptions={fuzzySearch}
                                              placeholder="Select"
                                            />
                                          </div>

                                          <div
                                            className="col-md-6"
                                            style={{ marginTop: 16 }}
                                          >
                                            <label htmlFor="accountNumber">
                                              Account Num / IBAN *
                                            </label>
                                            <input
                                              name="accountNumber"
                                              id="accountNumber"
                                              placeholder="Account Number/IBAN"
                                              type="text"
                                              className="form-control"
                                              onChange={(event) => {
                                                const accountNumber =
                                                  event.target.value;
                                                setBeneficiarydetails({
                                                  ...beneficiarydetails,
                                                  ...{ accountNumber },
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        hidden={!recactive}
                                        className="col-md-12 position-relative form-group"
                                        style={{ marginTop: 5 }}
                                      >
                                        <div className="card-title">
                                          enter beneficiary bank details
                                        </div>
                                        <div className="form-row">
                                          <div className="col-md-6">
                                            <label htmlFor="exampleGender">
                                              Bank Name *
                                            </label>
                                            <input
                                              name="bankName"
                                              id="bankName"
                                              placeholder="Bank Name"
                                              type="text"
                                              className="form-control"
                                              onChange={(event) => {
                                                const bankName =
                                                  event.target.value;
                                                setBeneficiarydetails({
                                                  ...beneficiarydetails,
                                                  ...{ bankName },
                                                });
                                              }}
                                            />
                                          </div>

                                          <div className="col-md-6">
                                            <label htmlFor="exampleGender">
                                              Bank Address *
                                            </label>
                                            <input
                                              name="bankAddress"
                                              id="bankAddress"
                                              placeholder="Bank Address"
                                              type="text"
                                              className="form-control"
                                              onChange={(event) => {
                                                const bankAddress =
                                                  event.target.value;
                                                setBeneficiarydetails({
                                                  ...beneficiarydetails,
                                                  ...{ bankAddress },
                                                });
                                              }}
                                            />
                                          </div>

                                          <div
                                            className="col-md-6"
                                            style={{ marginTop: 16 }}
                                          >
                                            <label htmlFor="accountSWiftCode">
                                              SWIFT *
                                            </label>
                                            <input
                                              name="accountSWiftCode"
                                              id="accountSWiftCode"
                                              placeholder="SWIFT *"
                                              style={{
                                                textTransform: "uppercase",
                                              }}
                                              type="text"
                                              className="form-control"
                                              onChange={(event) => {
                                                const accountSWiftCode =
                                                  event.target.value.toUpperCase();
                                                setBeneficiarydetails({
                                                  ...beneficiarydetails,
                                                  ...{ accountSWiftCode },
                                                });
                                              }}
                                            />
                                          </div>

                                          <div
                                            className="col-md-6"
                                            style={{ marginTop: 16 }}
                                          >
                                            <label htmlFor="exampleGender">
                                              Bank Country *
                                            </label>
                                            <SelectSearch
                                              // style={{ display: "none" }}
                                              autoComplete="off"
                                              options={countryarray}
                                              search={true}
                                              onChange={handlereceivingfrom2}
                                              filterOptions={fuzzySearch}
                                              placeholder="Select"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        hidden={!recactive}
                                        className="col-md-12 position-relative form-group"
                                      >
                                        <div className="card-title">
                                          Corresponding bank details (Optional)
                                        </div>
                                        <div className="form-row">
                                          <div className="col-md-6">
                                            <label htmlFor="exampleGender">
                                              Bank Name
                                            </label>
                                            <input
                                              name="corresBankName"
                                              id="corresBankName"
                                              placeholder="Corresponding Bank Name"
                                              type="text"
                                              className="form-control"
                                              onChange={(event) => {
                                                const corresBankName =
                                                  event.target.value;
                                                setBeneficiarydetails({
                                                  ...beneficiarydetails,
                                                  ...{ corresBankName },
                                                });
                                              }}
                                            />
                                          </div>

                                          <div className="col-md-6">
                                            <label htmlFor="exampleGender">
                                              Bank Address
                                            </label>
                                            <input
                                              name="corresBankAddress"
                                              id="corresBankAddress"
                                              placeholder="Corresponding Bank Address"
                                              type="text"
                                              className="form-control"
                                              onChange={(event) => {
                                                const corresBankAddress =
                                                  event.target.value;
                                                setBeneficiarydetails({
                                                  ...beneficiarydetails,
                                                  ...{ corresBankAddress },
                                                });
                                              }}
                                            />
                                          </div>

                                          <div
                                            className="position-relative form-group col-md-6"
                                            style={{ marginTop: 16 }}
                                          >
                                            <label htmlFor="exampleName">
                                              Bank Identifier(ABA, BSB)
                                            </label>
                                            <select
                                              type="select"
                                              id="gender"
                                              name="gender"
                                              className="mb-2 form-control"
                                              onChange={(event) => {
                                                const bankIdentifier =
                                                  event.target.value;
                                                setTransferDetails({
                                                  ...transferdetails,
                                                  ...{ bankIdentifier },
                                                });
                                              }}
                                            >
                                              <option value="">
                                                -- Select --
                                              </option>
                                              <option value="ABA/Fed Wire/Routing No">
                                                ABA/Fed Wire/Routing No.
                                              </option>
                                              <option value="BSB">BSB</option>
                                              <option value="Chips Number">
                                                Chips Number
                                              </option>
                                              <option value="IFSC code">
                                                IFSC code{" "}
                                              </option>
                                              <option value="Sort code">
                                                Sort code
                                              </option>
                                            </select>
                                          </div>

                                          <div
                                            className="col-md-6"
                                            style={{ marginTop: 16 }}
                                          >
                                            <label htmlFor="exampleGender">
                                              Bank Identifier Code
                                            </label>
                                            <input
                                              name="amount"
                                              id="exampleAmount"
                                              placeholder="Enter Code"
                                              style={{
                                                textTransform: "uppercase",
                                              }}
                                              type="text"
                                              className="form-control"
                                              onChange={(event) => {
                                                const bankIdentifierCode =
                                                  event.target.value;
                                                setBeneficiarydetails({
                                                  ...beneficiarydetails,
                                                  ...{ bankIdentifierCode },
                                                });
                                              }}
                                            />
                                          </div>

                                          <div
                                            className="col-md-6"
                                            style={{ marginTop: 16 }}
                                          >
                                            <label htmlFor="exampleGender">
                                              Account Name
                                            </label>
                                            <input
                                              name="corresAccountName"
                                              id="corresAccountName"
                                              placeholder="Account Name"
                                              type="text"
                                              className="form-control"
                                              onChange={(event) => {
                                                const corresAccountName =
                                                  event.target.value;
                                                setBeneficiarydetails({
                                                  ...beneficiarydetails,
                                                  ...{ corresAccountName },
                                                });
                                              }}
                                            />
                                          </div>

                                          <div
                                            className="col-md-6"
                                            style={{ marginTop: 16 }}
                                          >
                                            <label htmlFor="exampleGender">
                                              Account Number
                                            </label>
                                            <input
                                              name="corresBankIBAN"
                                              id="corresBankIBAN"
                                              placeholder="Corresponding Account Number"
                                              type="number"
                                              className="form-control"
                                              onChange={(event) => {
                                                const corresBankIBAN =
                                                  event.target.value;
                                                setBeneficiarydetails({
                                                  ...beneficiarydetails,
                                                  ...{ corresBankIBAN },
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {recactive ? (
                            <div className="d-flex justify-content-between form-group col-md-12 my-4">
                              <button
                                className="btn btn-danger btn-icon btn-icon-left"
                                onClick={toggleform}
                                style={{
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                }}
                              >
                                <i className="pe-7s-back btn-icon-wrapper"> </i>
                                Back
                                {/* Add Existing Beneficiary */}
                              </button>
                              <button
                                type="button"
                                id="prev-btn"
                                className="btn btn-danger"
                                onClick={postBeneficiaryDetails}
                              >
                                Proceed
                              </button>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-between form-group col-md-12 my-4">
                              <button
                                type="button"
                                id="prev-btn"
                                className="btn btn-danger"
                                onClick={backToOne}
                              >
                                Previous
                              </button>
                              <button
                                type="button"
                                id="prev-btn"
                                className="btn btn-danger"
                                onClick={addExistingBeneficiary}
                              >
                                Proceed
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="divider" />
                  </div>

                  <div className="tab-pane step-content" hidden={!stepthree}>
                    <div className="no-results">
                      {(() => {
                        if (screen === 0) {
                          return (
                            <div className="widget-content-outer col-md-12">
                              <div className="widget-content-left fsize-1">
                                <h3
                                  className="text-muted opacity-10"
                                  style={{ marginLeft: 16, marginBottom: -20 }}
                                >
                                  Choose Payment Option
                                </h3>
                                <br></br>
                                <span style={{ marginLeft: "16px" }}>
                                  Now you can make payment for the transaction
                                </span>
                              </div>

                              <div
                                className="widget-content-wrapper"
                                style={{ marginBottom: 95 }}
                              ></div>

                              <div className="col-md-12 position-relative form-group">
                                <div className="form-row">
                                  <div className="col-md-4">
                                    <label className="form-check-label">
                                      <div className=" no-shadow rm-border bg-transparent text-center">
                                        <img
                                          src="assets/images/poli.jfif"
                                          alt=""
                                          width={100}
                                          height={80}
                                        />
                                        <br></br>
                                        <input
                                          name="radio1"
                                          type="radio"
                                          value="poli"
                                          className="form-check-input"
                                          onChange={(event) => {
                                            let type = event.target.value;
                                            setPaymenttype({
                                              ...paymenttype,
                                              ...{ type },
                                            });
                                          }}
                                        />
                                        <strong
                                          htmlFor="exampleGender"
                                          style={{ marginLeft: 10 }}
                                        >
                                          Direct Debit ( POLI )
                                        </strong>
                                      </div>
                                    </label>
                                  </div>
                                  <div className="col-md-4">
                                    <label className="form-check-label">
                                      <div className=" no-shadow rm-border bg-transparent text-center">
                                        <img
                                          src="assets/images/transfer.png"
                                          alt=""
                                          width={100}
                                          height={80}
                                        />
                                        <br></br>
                                        <input
                                          name="radio1"
                                          type="radio"
                                          value="manual"
                                          className="form-check-input"
                                          onChange={(event) => {
                                            let type = event.target.value;
                                            setPaymenttype({
                                              ...paymenttype,
                                              ...{ type },
                                            });
                                          }}
                                        />
                                        <strong
                                          htmlFor="exampleGender"
                                          style={{ marginLeft: 10 }}
                                        >
                                          Domestic Wire Transfer ( Manual )
                                        </strong>
                                      </div>
                                    </label>
                                  </div>
                                  <div className="col-md-4">
                                    <label className="form-check-label">
                                      <div className=" no-shadow rm-border bg-transparent text-center">
                                        <img
                                          src={creditcardimg}
                                          alt=""
                                          width={125}
                                          height={80}
                                        />
                                        <br></br>
                                        <input
                                          disabled={true}
                                          name="radio1"
                                          type="radio"
                                          value="creditcard"
                                          className="form-check-input"
                                          onChange={(event) => {
                                            let type = event.target.value;
                                            setPaymenttype({
                                              ...paymenttype,
                                              ...{ type },
                                            });
                                          }}
                                        />
                                        <strong
                                          htmlFor="exampleGender"
                                          style={{ marginLeft: 10 }}
                                        >
                                          Credit Card
                                        </strong>{" "}
                                        <br></br>
                                        <span>Coming Soon...</span>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="widget-content-wrapper"
                                style={{ marginBottom: 95, marginTop: 60 }}
                              >
                                <button
                                  onClick={backToTwo}
                                  className="btn-wide btn btn-danger"
                                  style={{ marginRight: 16 }}
                                >
                                  {" "}
                                  Previous{" "}
                                </button>

                                {(() => {
                                  if (paymenttype) {
                                    return (
                                      <>
                                        <button
                                          onClick={proceedPayment}
                                          className="btn-wide btn btn-danger"
                                          style={{ marginRight: 16 }}
                                        >
                                          {" "}
                                          Proceed{" "}
                                        </button>
                                      </>
                                    );
                                  } else {
                                    return <></>;
                                  }
                                })()}
                              </div>
                            </div>
                          );
                        } else if (screen === 1) {
                          return (
                            <div className="widget-content-outer col-md-12">
                              <div className="row">
                                <div className="col-md-12 col-lg-12">
                                  <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-left">
                                    <div className="widget-content p-0 w-100">
                                      <div className="widget-content-outer">
                                        <div
                                          className="widget-content-wrapper"
                                          style={{ marginBottom: 50 }}
                                        >
                                          <div className="widget-content-left fsize-1 col-md-12">
                                            <h5 className="text-muted opacity-10">
                                              Direct Deposit - POLI Payment
                                            </h5>
                                            <br></br>
                                            <div
                                              className="progress-bar-xs progress"
                                              style={{ marginRight: 16 }}
                                            >
                                              <div
                                                className="progress-bar bg-danger"
                                                role="progressbar"
                                                aria-valuenow={71}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                                style={{ width: "100%" }}
                                              ></div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-12 position-relative form-group">
                                          <div className="form-row text-center">
                                            <div className="position-relative form-group col-md-12 left_margin">
                                              <br></br>

                                              <div className=" no-shadow rm-border bg-transparent text-center">
                                                <img
                                                  src="assets/images/poli.jfif"
                                                  width={200}
                                                  height={150}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <h2 className="text-center col-md-12">
                                          Please hold on for a moment as the app
                                          redirects you to an external page to
                                          complete your payments...
                                        </h2>

                                        <div
                                          className=""
                                          style={{ marginTop: 95 }}
                                        >
                                          <div className="form-row">
                                            <span className="text-success pl-2">
                                              <button
                                                onClick={backToThree}
                                                className="btn-wide btn btn-danger"
                                                style={{ marginLeft: 12 }}
                                              >
                                                {" "}
                                                Previous{" "}
                                              </button>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (screen === 4) {
                          return (
                            <div className="widget-content-outer col-md-12">
                              <div className="row">
                                <div className="col-md-12 col-lg-12">
                                  <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-left">
                                    <div className="widget-content p-0 w-100">
                                      <div className="widget-content-outer">
                                        <div
                                          className="widget-content-wrapper"
                                          style={{ marginBottom: 25 }}
                                        >
                                          <div className="widget-content-left fsize-1 col-md-12">
                                            <h5
                                              className="text-muted opacity-10"
                                              style={{ marginLeft: 16 }}
                                            >
                                              Domestic Wire Transfer
                                            </h5>
                                            <br></br>
                                            <div
                                              className="progress-bar-xs progress"
                                              style={{
                                                marginLeft: 16,
                                                marginRight: 16,
                                              }}
                                            >
                                              <div
                                                className="progress-bar bg-danger"
                                                role="progressbar"
                                                aria-valuenow={71}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                                style={{ width: "100%" }}
                                              ></div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-12 position-relative form-group ">
                                          <div className="form-row">
                                            <div className="col-md-6">
                                              <label htmlFor="exampleGender">
                                                Select Bank
                                              </label>
                                              <SelectSearch
                                                // style={{ display: "none" }}
                                                autoComplete="off"
                                                options={alllbanksarray}
                                                search={true}
                                                onChange={(id)=> SetgetBankId(id)}
                                                filterOptions={fuzzySearch}
                                                placeholder="Choose your bank"
                                              />
                                            </div>
                                            <div
                                              className="col-md-6"
                                              style={{ marginTop: 1 }}
                                            >
                                              <div className="position-relative form-group">
                                                <label htmlFor="exampleText">
                                                  Transaction Description
                                                </label>
                                                <input
                                                  type="text"
                                                  name="fileToUpload"
                                                  defaultValue={
                                                    transactionstate.paymentDescription
                                                  }
                                                  className="form-control"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12 col-lg-12">
                                  <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-left">
                                    <div className="widget-content p-0 w-100">
                                      <div className="widget-content-outer">
                                        <div
                                          className="widget-content-wrapper"
                                          style={{ marginBottom: 55 }}
                                        >
                                          <div className="widget-content-left card-shadow-danger border mb-3 card card-body border-danger col-md-6">
                                            <h5 className="card-title">
                                              Transaction Reference Number
                                            </h5>
                                            {
                                              transactionstate.transactionRefNumber
                                            }
                                          </div>

                                          <div className="widget-content-right card-shadow-danger border mb-3 card card-body border-danger col-md-6">
                                            <h5 className="card-title">
                                              Payment Method
                                            </h5>
                                            Domestic Wire Transfer
                                          </div>
                                        </div>

                                        <div className="widget-content-right w-100">
                                          <h5
                                            className="text-muted opacity-10 card-title"
                                            style={{
                                              marginLeft: 16,
                                              marginTop: -16,
                                            }}
                                          >
                                            JCI Remit Account Details
                                          </h5>
                                          <div
                                            className="progress-bar-xs progress"
                                            style={{
                                              marginLeft: 16,
                                              marginRight: 16,
                                            }}
                                          >
                                            <div
                                              className="progress-bar bg-danger"
                                              role="progressbar"
                                              aria-valuenow={71}
                                              aria-valuemin={0}
                                              aria-valuemax={100}
                                              style={{ width: "100%" }}
                                            ></div>
                                          </div>
                                        </div>

                                        <div
                                          className="col-md-12 position-relative form-group"
                                          style={{ marginTop: 24 }}
                                        >
                                          <div className="form-row">
                                            <div className="position-relative form-group col-md-4">
                                              <label htmlFor="exampleAmount">
                                                Account Name
                                              </label>
                                              <h5 className="text-muted opacity-10 card-title">
                                                {" "}
                                                {
                                                  ijcbankaccount?.accountName
                                                }{" "}
                                              </h5>
                                            </div>
                                            <div className="position-relative form-group col-md-4">
                                              <label htmlFor="exampleAmount">
                                                Bank Account Number
                                              </label>
                                              <h5 className="text-muted opacity-10 card-title">
                                                {" "}
                                                {
                                                  ijcbankaccount?.bankAccountNumber
                                                }
                                              </h5>
                                            </div>

                                            <div className="position-relative form-group col-md-4">
                                              <label htmlFor="exampleAmount">
                                                Bank Name
                                              </label>
                                              <h5 className="text-muted opacity-10 card-title">
                                                {" "}
                                                {ijcbankaccount?.bankName}
                                              </h5>
                                            </div>

                                            <div className="position-relative form-group col-md-4">
                                              <label htmlFor="exampleAmount">
                                                Bank Swift Code
                                              </label>
                                              <h5 className="text-muted opacity-10 card-title">
                                                {" "}
                                                {ijcbankaccount?.bankSwiftCode}
                                              </h5>
                                            </div>

                                            <div className="position-relative form-group col-md-4">
                                              <label htmlFor="exampleAmount">
                                                Bank Identifier
                                              </label>
                                              <h5 className="text-muted opacity-10 card-title">
                                                {" "}
                                                {ijcbankaccount?.bankIdentifier}
                                              </h5>
                                            </div>

                                            <div className="position-relative form-group col-md-4">
                                              <label htmlFor="exampleAmount">
                                                Bank Identifier Code
                                              </label>
                                              <h5 className="text-muted opacity-10 card-title">
                                                {" "}
                                                {
                                                  ijcbankaccount?.bankIdentifierCode
                                                }
                                              </h5>
                                            </div>

                                            <div className="position-relative form-group col-md-4">
                                              <label htmlFor="exampleAmount">
                                                Country
                                              </label>
                                              <h5 className="text-muted opacity-10 card-title">
                                                {ijcbankaccount?.country}{" "}
                                              </h5>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 position-relative form-group">
                                <div className="form-row w-50 m-auto">
                                  {!uploaddetails.fileToUpload ? (
                                    <div className="position-relative form-group col-md-12">
                                      <span>
                                        <input
                                          type="file"
                                          name="fileToUpload"
                                          accept="image/*"
                                          className="mb-2 btn btn-danger btn-lg btn-block"
                                          onChange={(event) => {
                                            const fileToUpload =
                                              event.target.files[0];
                                            setUploadDetails({
                                              ...uploaddetails,
                                              ...{ fileToUpload },
                                            });
                                          }}
                                        />
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="card w-100">
                                      <img
                                        src={URL.createObjectURL(
                                          uploaddetails.fileToUpload
                                        )}
                                        class="card-img-top"
                                        alt="..."
                                      />
                                      <div className="card-body">
                                        {/* <p className="card-text">
                                          Some quick example text to build on
                                          the card title and make up the bulk of
                                          the card's content.
                                        </p> */}
                                        {!uploadConfirmationLoading ? (
                                          <button
                                            className="mb-2 btn btn-danger btn-lg btn-block btn-icon btn-icon-right"
                                            style={{ marginRight: -3 }}
                                            onClick={uploadConfirmation}
                                          >
                                            Upload Payment Confirmation{" "}
                                            <i className="pe-7s-cloud-upload btn-icon-wrapper">
                                              {" "}
                                            </i>
                                          </button>
                                        ) : (
                                          <button
                                            disabled
                                            className="mb-2 btn btn-danger btn-lg btn-block btn-icon btn-icon-right"
                                            style={{ marginRight: -3 }}
                                          >
                                            Uploading...{" "}
                                            <i className="pe-7s-cloud-upload btn-icon-wrapper">
                                              {" "}
                                            </i>
                                          </button>
                                        )}
                                        <button
                                          className="btn btn-outline-danger btn-block mt-2"
                                          onClick={() => {
                                            setUploadDetails({
                                              ...uploaddetails,
                                              ...{ fileToUpload: null },
                                            });
                                          }}
                                        >
                                          Discard
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {/* {uploaddetails.fileToUpload ? (
                                    <div className="position-relative form-group col-md-12">
                                      <span>
                                        {!uploadConfirmationLoading ? (
                                          <button
                                            className="mb-2 mr-2 btn btn-danger btn-lg btn-block btn-icon btn-icon-right"
                                            style={{ marginRight: -3 }}
                                            onClick={uploadConfirmation}
                                          >
                                            Upload Payment Confirmation{" "}
                                            <i className="pe-7s-cloud-upload btn-icon-wrapper">
                                              {" "}
                                            </i>
                                          </button>
                                        ) : (
                                          <button
                                            disabled
                                            className="mb-2 mr-2 btn btn-danger btn-lg btn-block btn-icon btn-icon-right"
                                            style={{ marginRight: -3 }}
                                          >
                                            Uploading...{" "}
                                            <i className="pe-7s-cloud-upload btn-icon-wrapper">
                                              {" "}
                                            </i>
                                          </button>
                                        )}
                                      </span>
                                    </div>
                                  ) : null} */}
                                </div>

                                <div
                                  id="transactionCompleteMessage"
                                  className="form-row w-50 m-auto"
                                  style={{ display: "none" }}
                                >
                                  <div className="card w-100 mt-4">
                                    <div className="card-body">
                                      <h4 className="mb-2 text-success">
                                        Payment receipt Upload Successful
                                      </h4>
                                      <p className="card-text">
                                        The payment advice has been sent to your
                                        email.
                                        <br />
                                        Thank you.
                                      </p>

                                      <button
                                        className="btn btn-outline-danger btn-block mt-2"
                                        onClick={() =>
                                          window.location.replace("/sendmoney")
                                        }
                                      >
                                        Return home
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <span
                                style={{ fontSize: 11, textAlign: "justify" }}
                              ></span>

                              <div className="row">
                                <div className="col-md-12 col-lg-12">
                                  <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-left">
                                    <div className="widget-content p-0 w-100">
                                      <div className="widget-content-outer">
                                        <div className="widget-content-wrapper">
                                          <div
                                            className="widget-content-left card-shadow-danger border card card-body border-danger"
                                            style={{
                                              textAlign: "justify",
                                              fontSize: 12,
                                            }}
                                          >
                                            <h5 className="card-title text-danger">
                                              <b>Warning:</b>
                                            </h5>
                                            You must ensure that the
                                            beneficiary’s account details are
                                            correct. JCI REMIT cannot validate
                                            the details provided (including
                                            matching an account name to an
                                            account number) and cannot guarantee
                                            any other financial services
                                            provider will validate these
                                            details. If you provide an incorrect
                                            account number other banks may
                                            credit that account number despite
                                            the beneficiary’s name not matching
                                            that provided, which may result in a
                                            loss of funds. JCI REMIT is not
                                            liable for loss of funds should you
                                            provide incorrect payment
                                            instructions.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="ml-auto" hidden={!second}>
                                <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                  <span className="text-success pl-2">
                                    <button
                                      disabled={isDownloading}
                                      className="btn-wide btn btn-danger btn-icon btn-icon-right"
                                      onClick={downloadReceipt}
                                      style={{
                                        marginTop: 15,
                                        marginRight: 16,
                                      }}
                                    >
                                      Download Payment Receipt
                                      <i className="pe-7s-cloud-download btn-icon-wrapper">
                                        {" "}
                                      </i>{" "}
                                    </button>
                                  </span>
                                </div>
                              </div> */}

                              <div className="col-md-12 position-relative form-group">
                                {/* <div className="form-row">
                                  <div className="position-relative form-group col-md-3">
                                    <span>
                                      <a href="#"></a>
                                    </span>
                                  </div>
                                  <div className="ml-auto">
                                    <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                      <span className="text-success pl-2">
                                        <Link to="/transactionhistory">
                                          <button
                                            className="btn-wide btn btn-danger btn-icon btn-icon-right"
                                            style={{
                                              paddingLeft: 100,
                                              paddingRight: 100,
                                            }}
                                          >
                                            Finish
                                          </button>
                                        </Link>
                                      </span>
                                    </div>
                                  </div>
                                </div> */}
                                <div
                                  className="progress-bar-xs progress"
                                  style={{ marginTop: 8, marginBottom: 8 }}
                                >
                                  <div
                                    className="progress-bar bg-danger"
                                    role="progressbar"
                                    aria-valuenow={71}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                    style={{ width: "100%" }}
                                  ></div>
                                </div>
                              </div>
                              <div
                                className="ml-auto"
                                hidden={!second}
                                style={{ marginTop: 35, marginRight: 16 }}
                              >
                                <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                  <span className="text-success pl-2">
                                    <a href="/sendmoney">
                                      <div
                                        style={{
                                          paddingLeft: 100,
                                          paddingRight: 100,
                                        }}
                                      >
                                        {"  "}
                                        Return home
                                      </div>
                                    </a>
                                  </span>
                                </div>
                              </div>

                              <div className="" style={{ marginTop: 35 }}>
                                <div className="form-row">
                                  <span className="text-success pl-2">
                                    <button
                                      className="btn-wide btn btn-danger"
                                      style={{ marginLeft: 12 }}
                                      onClick={resetScreen}
                                    >
                                      {" "}
                                      Previous{" "}
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (screen === 2) {
                          return (
                            <div className="widget-content-outer col-md-12">
                              <div
                                className="widget-content-wrapper"
                                style={{ marginBottom: 10 }}
                              >
                                <div className="widget-content-left fsize-1 col-md-12">
                                  <h5 className="text-muted opacity-10">
                                    <strong>CREDIT CARD PAYMENT</strong>
                                  </h5>
                                  <br></br>
                                </div>
                              </div>

                              <div className="col-md-10 position-relative form-group ml-auto">
                                <div className="form-row">
                                  <div className="position-relative form-group col-md-10">
                                    <label htmlFor="exampleAmount">
                                      Card Number
                                    </label>
                                    <input
                                      name="amount"
                                      id="exampleAmount"
                                      placeholder="Card Number"
                                      type="number"
                                      min="0"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-10 position-relative form-group ml-auto">
                                <div className="form-row">
                                  <div className="position-relative form-group col-md-10">
                                    <label htmlFor="exampleAmount">
                                      Card Holder's Name
                                    </label>
                                    <input
                                      name="amount"
                                      id="exampleAmount"
                                      placeholder="Card Holder's Name"
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-10 position-relative form-group ml-auto">
                                <div className="form-row">
                                  <div className="position-relative form-group col-md-5">
                                    <label htmlFor="exampleAmount">
                                      Expiry Date
                                    </label>
                                    <input
                                      name="amount"
                                      id="exampleAmount"
                                      placeholder="MM/YY"
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>

                                  <div className="position-relative form-group col-md-5">
                                    <label htmlFor="exampleAmount">CVV</label>
                                    <input
                                      name="amount"
                                      id="exampleAmount"
                                      placeholder="CVV"
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="ml-auto">
                                <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted ">
                                  <span className="text-success pl-2">
                                    <button className="btn-wide btn btn-danger  btn-lg btn-icon btn-icon-right col-md-8">
                                      Pay Now{" "}
                                      <i className="pe-7s-cash btn-icon-wrapper">
                                        {" "}
                                      </i>{" "}
                                    </button>
                                  </span>
                                </div>
                              </div>
                              {/* style={{marginRight: 190, marginTop: 8, marginBottom:10}} */}
                              <div className="ml-auto float-right">
                                <div className="form-row">
                                  <span className="text-success pl-2">
                                    <button
                                      className="btn-wide btn btn-danger"
                                      style={{ marginLeft: 8, marginTop: 8 }}
                                      onClick={resetScreen}
                                    >
                                      {" "}
                                      Go back{" "}
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </div>

                    <div className="divider" />
                    <div className="clearfix">
                      {/* <button type="button" id="prev-btn" className="btn-shadow float-right btn-wide mr-3 btn-danger btn" onClick={backToTwo}>Previous</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    transactionstate: state.transactionstate.transactionstate,
    authstate: state.authstate.authstate,
    allbanks: state.allbanks.allbanks,
    allcountriesstate: state.allcountriesstate.allcountriesstate,
    conversionstate: state.conversionstate.conversionstate,
    allreceivingcurrencystate:
      state.allreceivingcurrencystate.allreceivingcurrencystate,
    allsendingcurrencystate:
      state.allsendingcurrencystate.allsendingcurrencystate,
    alltransactionsending: state.alltransactionsending.alltransactionsending,
    alltransactionreceiving:
      state.alltransactionreceiving.alltransactionreceiving,
    allpurposestate: state.allpurposestate.allpurposestate,
    alluserreceivers: state.alluserreceivers.alluserreceivers,
    redirecturl: state.redirecturl.redirecturl,
    allcountries: state.allcountries.allcountries,
    downloadreceiptstatus: state.downloadreceiptstatus.downloadreceiptstatus,
    allcountriescodestate: state.allcountriescodestate.allcountriescodestate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchallcountry: (setError) => {
      dispatch(FetchAllCountryCodes(setError));
    },
    fetchaccounts: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(ShowLoading(setNotify));
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchallreceivingcountry: (
      show,
      setNotify,
      successaction,
      failaction,
      setShow
    ) => {
      dispatch(GetAction(show, setNotify, successaction, failaction, setShow));
    },
    fetchallsendingcountry: (
      show,
      setNotify,
      successaction,
      failaction,
      setShow
    ) => {
      dispatch(GetAction(show, setNotify, successaction, failaction, setShow));
    },
    fetchallpurpose: (show, setNotify, successaction, failaction, setShow) => {
      dispatch(
        GetPurposeAction(show, setNotify, successaction, failaction, setShow)
      );
    },
    fetchallijcaccount: (
      show,
      setNotify,
      successaction,
      failaction,
      setShow
    ) => {
      dispatch(GetAction(show, setNotify, successaction, failaction, setShow));
    },
    fetchcounties: (show, setNotify, successaction, failaction, setShow) => {
      dispatch(GetAction(show, setNotify, successaction, failaction, setShow));
    },
    fetchalluserreceivers: (
      show,
      setNotify,
      successaction,
      failaction,
      setShow
    ) => {
      dispatch(GetAction(show, setNotify, successaction, failaction, setShow));
    },
    createconvert: (
      convertiondetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setIsLoading
    ) => {
      dispatch(
        CreateAction(
          convertiondetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setIsLoading
        )
      );
    },
    createtransact: (
      transactiondetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setIsLoading
    ) => {
      dispatch(
        CreateAction(
          transactiondetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setIsLoading
        )
      );
    },
    createbeneficiary: (
      transactiondetails,
      id,
      setIsLoading,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        PatchAction(
          transactiondetails,
          id,
          setIsLoading,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    addExistBeneficiary: (
      receiverID,
      id,
      setIsLoading,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        PatchAction(
          receiverID,
          id,
          setIsLoading,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    addpaymentstotransaction: (
      receiverID,
      id,
      setIsLoading,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        PatchAction(
          receiverID,
          id,
          setIsLoading,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    downloadreceiptaction: (
      id,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(id, setNotify, successactiontype, failureactiontype, setShow)
      );
    },
    uploadconfrimationaction: (
      fileupload,
      id,
      setBeneficiaryLoading,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        PatchAction(
          fileupload,
          id,
          setBeneficiaryLoading,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sendmoneyarea);
