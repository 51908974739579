import React, { useState, useEffect } from "react";
import { CreateAction } from "../../redux/actions/createaction";
import { connect } from "react-redux";
import ActionTypes from "../../redux/actiontype/ActionTypes";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const AddReferralModal = (props) => {
  const {
    createreferrral,
    setNotify,
    show,
    handleShow,
    setShow,
    addReferralLoading,
    setAddReferralLoading,
  } = props;
  const [referraldetails, setReferraldetails] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setAddReferralLoading(true);
    createreferrral(
      referraldetails,
      setNotify,
      ActionTypes.ADD_REFERRAL_SUCCESS,
      ActionTypes.ADD_REFERRAL_FAIL,
      setShow,
      setAddReferralLoading
    );
  };

  useEffect(() => {}, [show]);

  return (
    <Modal show={show} onHide={handleShow} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Referral</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="minAmount">Min Amount</label>
                <input
                  name="minAmount"
                  id="minAmount"
                  placeholder="Min Amount"
                  type="number"
                  min="1"
                  className="form-control"
                  onChange={(event) => {
                    const minAmount = event.target.value;
                    setReferraldetails({
                      ...referraldetails,
                      ...{ minAmount },
                    });
                  }}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="bonusValue">Bonus Value</label>
                <input
                  name="bonusValue"
                  id="bonusValue"
                  placeholder="Bonus Value"
                  type="number"
                  min="1"
                  className="form-control"
                  onChange={(event) => {
                    const bonusValue = event.target.value;
                    setReferraldetails({
                      ...referraldetails,
                      ...{ bonusValue },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShow}>
          Close
        </Button>
        <Button
          variant="danger"
          onClick={handleSubmit}
          disabled={addReferralLoading}
        >
          Add Referral
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loadingstate.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createreferrral: (
      referraldetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setAddReferralLoading
    ) => {
      dispatch(
        CreateAction(
          referraldetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setAddReferralLoading
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddReferralModal);
