import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ActionTypes from "../../redux/actiontype/ActionTypes";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CreateAction } from "../../redux/actions/createaction";

const EditPromoModal = (props) => {
  const {
    editpromo,
    setNotify,
    show,
    setShow,
    item,
    handleShowEdit,
    addPromoLoading,
    setAddPromoLoading,
  } = props;
  const [promodetails, setPromodetails] = useState({});

  useEffect(() => {
    if (Object.keys(item).length !== 0) {
      let id = item.id;
      let title = item.title;
      let isActive = item.isActive;
      let startDate = item.startDate;
      let endDate = item.endDate;
      let bonusValue = item.bonusValue;

      setPromodetails({
        ...promodetails,
        ...{
          id,
          title,
          isActive,
          endDate,
          startDate,
          bonusValue,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setAddPromoLoading(true);
    editpromo(
      promodetails,
      setNotify,
      ActionTypes.EDIT_PROMO_SUCCESS,
      ActionTypes.EDIT_PROMO_FAIL,
      setShow,
      setAddPromoLoading
    );
  };

  const processStatus = (type) => {
    if (type) return "Active";
    else return "In-Active";
  };

  useEffect(() => {}, [show]);

  return (
    <Modal
      show={show}
      onHide={handleShowEdit}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Promo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="title">Title</label>
                <input
                  name="title"
                  id="title"
                  placeholder={item.title}
                  defaultValue={item.title}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const title = event.target.value;
                    const id = item.id;
                    setPromodetails({ ...promodetails, ...{ title, id } });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="exampleGender">Status</label>
                <select
                  type="select"
                  id="isActive"
                  name="isActive"
                  className="mb-2 form-control"
                  onChange={(event) => {
                    const isActive = event.target.value;
                    const id = item.id;
                    setPromodetails({ ...promodetails, ...{ isActive, id } });
                  }}
                >
                  <option> -- Select Status -- </option>
                  <option value="true">Active</option>
                  <option value="false">In-Active</option>
                </select>
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-4">
                <label htmlfor="startDate">Start Date</label>
                <div className="position-relative form-group">
                  <input
                    name="date"
                    id="startDate"
                    placeholder={item.startDate}
                    defaultValue={item.startDate}
                    type="date"
                    className="form-control"
                    onChange={(event) => {
                      const startDate = event.target.value;
                      const id = item.id;
                      setPromodetails({
                        ...promodetails,
                        ...{ startDate, id },
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <label htmlfor="startDate">End Date</label>
                <div className="position-relative form-group">
                  <input
                    name="date"
                    id="endDate"
                    placeholder={item.endDate}
                    defaultValue={item.endDate}
                    type="date"
                    className="form-control"
                    onChange={(event) => {
                      const endDate = event.target.value;
                      setPromodetails({ ...promodetails, ...{ endDate } });
                    }}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="bonusValue">Bonus Value</label>
                <input
                  name="bonusValue"
                  id="bonusValue"
                  placeholder={item.bonusValue}
                  defaultValue={item.bonusValue}
                  type="number"
                  min="1"
                  className="form-control"
                  onChange={(event) => {
                    const bonusValue = event.target.value;
                    const id = item.id;
                    setPromodetails({ ...promodetails, ...{ bonusValue, id } });
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShowEdit}>
          Close
        </Button>
        <Button
          variant="danger"
          disabled={addPromoLoading}
          onClick={handleSubmit}
        >
          Update Promo
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loadingstate.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editpromo: (
      promodetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setAddPromoLoading
    ) => {
      // dispatch(ShowLoading(promodetails));
      dispatch(
        CreateAction(
          promodetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setAddPromoLoading
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPromoModal);
