import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ActionTypes from "../../redux/actiontype/ActionTypes";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CreateAction } from "../../redux/actions/createaction";
import SelectSearch, { fuzzySearch } from "react-select-search";

const EditRateModal = (props) => {
  const {
    editrate,
    setNotify,
    show,
    setShow,
    item,
    allsendingcurrencystate,
    allreceivingcurrencystate,
    handleShowEdit,
    ratesLoading,
    setRatesLoading,
    allcountriesstate,
  } = props;
  const [ratedetails, setRatedetails] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    setRatesLoading(true);
    editrate(
      ratedetails,
      setNotify,
      ActionTypes.EDIT_RATE_SUCCESS,
      ActionTypes.EDIT_RATE_FAIL,
      setShow,
      setRatesLoading
    );
  };

  const receivingstatearray = [];
  const sendingstatearray = [];
  //handle the return value from country dropdown selection
  // const handlesendingfrom = (cout) => {
  //   const sendCountryCode = cout;
  //   const id = item.id;
  //   setRatedetails({
  //     ...ratedetails,
  //     ...{ sendCountryCode, id },
  //   });
  // };

  const handlesendingfrom = (cout) => {
    // const sendCountryCode = cout.substring(cout.length - 3, cout.length);
    const sendCountryCode = cout;
    setRatedetails({ ...ratedetails, ...{ sendCountryCode } });
  };

  const handlesendingfrom2 = (cout) => {
    // const receiveCountryCode = cout.substring(cout.length - 3, cout.length);
    const receiveCountryCode = cout;
    setRatedetails({ ...ratedetails, ...{ receiveCountryCode } });
  };

  const allcountriesarray = [];

  if (allcountriesstate) {
    for (var k = 0; k < allcountriesstate.length; k++) {
      let item = allcountriesstate[k];
      let newitem = {};
      newitem.name = item.country;
      // newitem.value = item.country + " " + item.alpha3Code;
      newitem.value = item.alpha3Code;
      // newitem.currency = item.currency;
      allcountriesarray.push(newitem);
    }
  }

  const handlereceivingfrom = (cout) => {
    const receiveCountryCode = cout;
    // let country = receieveCountry;
    setRatedetails({ ...ratedetails, ...{ receiveCountryCode } });
    // const accountCurrency = cout;
    // nigeria to abroad
    // abroad to corresponding
    // if (receieveCountry === "NGA") {
    //   setIsNigeria(true);
    // }
    // let customerId = authstate.nameid;
    // setBeneficiarydetails({
    //   ...beneficiarydetails,
    //   ...{ accountCurrency, customerId, country },
    // });
  };

  if (allsendingcurrencystate) {
    for (var i = 0; i < allsendingcurrencystate.length; i++) {
      let item = allsendingcurrencystate[i];
      if (item !== null) {
        let newitem = {};
        newitem.name = item.countryCode;
        newitem.value = item.countryCode;
        // newitem.currency = item.currency;
        sendingstatearray.push(newitem);
      }
    }
  }
  if (allreceivingcurrencystate) {
    for (var j = 0; j < allreceivingcurrencystate.length; j++) {
      let item = allreceivingcurrencystate[j];
      if (item !== null) {
        let newitem = {};
        newitem.name = item.countryCode;
        newitem.value = item.countryCode;
        // newitem.currency = item.currency;
        receivingstatearray.push(newitem);
      }
    }
  }

  const processStatus = (type) => {
    if (type) return "Active";
    else return "In-Active";
  };

  useEffect(() => {
    if (Object.keys(item).length !== 0) {
      let id = item.id;
      let amount_USD = item.amount_USD;
      let amount_Local = item.amount_Local;
      let countryDesc = item.countryDesc;
      let isActive = item.isActive;
      // let isUSDDisplay = item.isUSDDisplay;
      let rateType = item.rateType;
      let receiveCountryCode = item.receiveCountryCode;
      let sendCountryCode = item.sendCountryCode;

      setRatedetails({
        ...ratedetails,
        ...{
          id,
          amount_USD,
          amount_Local,
          countryDesc,
          isActive,
          // isUSDDisplay,
          rateType,
          receiveCountryCode,
          sendCountryCode,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const processDisplay = (type) => {
    if (type) return "Yes";
    else return "No";
  };

  useEffect(() => {}, [show]);

  return (
    <Modal
      show={show}
      onHide={handleShowEdit}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Rate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="exampleGender">Rate Type</label>
                <select
                  type="select"
                  id="rateType"
                  name="rateType"
                  defaultValue={item.rateType}
                  className="mb-2 form-control"
                  onChange={(event) => {
                    const rateType = event.target.value;
                    const id = item.id;
                    setRatedetails({ ...ratedetails, ...{ rateType, id } });
                  }}
                >
                  <option> Select Rate Type </option>
                  <option selected="selected" value={item.rateType}>
                    {item.rateType}
                  </option>
                  <option value="LiveRate">LiveRate</option>
                  <option value="TransactionRate">TransactionRate</option>
                </select>
              </div>

              <div className="col-md-6">
                <label htmlFor="countryDesc">Country Description</label>
                <input
                  name="countryDesc"
                  id="countryDesc"
                  placeholder={item.countryDesc}
                  defaultValue={item.countryDesc}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const countryDesc = event.target.value;
                    const id = item.id;
                    setRatedetails({ ...ratedetails, ...{ countryDesc, id } });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="sendCountryCode">Send Country Code</label>

                {/* <input
                  name="sendCountryCode"
                  id="sendCountryCode"
                  defaultValue={item.sendCountryCode}
                  placeholder={item.sendCountryCode}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const sendCountryCode = event.target.value;
                    const id = item.id;
                    setRatedetails({
                      ...ratedetails,
                      ...{ sendCountryCode, id },
                    });
                  }}
                /> */}
                <SelectSearch
                  style={{ display: "none" }}
                  autoComplete="off"
                  options={allcountriesarray}
                  search={true}
                  value={
                    allcountriesarray.find(
                      (k) => k.value === item.sendCountryCode
                    )?.value
                  }
                  onChange={handlesendingfrom}
                  filterOptions={fuzzySearch}
                  placeholder="Choose your country"
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="receiveCountryCode">Receive Country Code</label>
                {/* <input
                  name="receiveCountryCode"
                  id="receiveCountryCode"
                  defaultValue={item.receiveCountryCode}
                  placeholder={item.receiveCountryCode}
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    const receiveCountryCode = event.target.value;
                    setRatedetails({
                      ...ratedetails,
                      ...{ receiveCountryCode },
                    });
                  }}
                /> */}

                <SelectSearch
                  style={{ display: "none" }}
                  autoComplete="off"
                  options={allcountriesarray}
                  search={true}
                  value={
                    allcountriesarray.find(
                      (k) => k.value === item.receiveCountryCode
                    )?.value
                  }
                  onChange={handlesendingfrom2}
                  filterOptions={fuzzySearch}
                  placeholder="Choose your country"
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-6">
                <label htmlFor="amount_Local">Amount (Local)</label>
                <input
                  name="amount_Local"
                  id="amount_Local"
                  placeholder={item.amount_Local}
                  defaultValue={item.amount_Local}
                  type="number"
                  className="form-control"
                  onChange={(event) => {
                    const amount_Local = event.target.value;
                    const id = item.id;
                    setRatedetails({ ...ratedetails, ...{ amount_Local, id } });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="amount_USD">Amount (USD)</label>
                <input
                  name="amount_USD"
                  id="amount_USD"
                  placeholder={item.amount_USD}
                  defaultValue={item.amount_USD}
                  type="number"
                  className="form-control"
                  onChange={(event) => {
                    const amount_USD = event.target.value;
                    const id = item.id;
                    setRatedetails({ ...ratedetails, ...{ amount_USD, id } });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="exampleGender">Active Status</label>
                <select
                  type="select"
                  id="isActive"
                  name="isActive"
                  defaultValue={item.isActive}
                  className="mb-2 form-control"
                  onChange={(event) => {
                    const isActive = event.target.value;
                    const id = item.id;
                    setRatedetails({ ...ratedetails, ...{ isActive, id } });
                  }}
                >
                  <option> -- Select Status -- </option>
                  {/* <option selected="selected" value={item.isActive}>
                    {processStatus(item.isActive)}
                  </option> */}
                  <option value="true">Active</option>
                  <option value="false">In-Active</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShowEdit}>
          Close
        </Button>
        <Button variant="danger" disabled={ratesLoading} onClick={handleSubmit}>
          Update Rate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    // isLoading: state.loadingstate.isLoading,
    allreceivingcurrencystate:
      state.allreceivingcurrencystate.allreceivingcurrencystate,
    allsendingcurrencystate:
      state.allsendingcurrencystate.allsendingcurrencystate,
    allcountriesstate: state.allcountriesstate.allcountriesstate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editrate: (
      ratedetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setRatesLoading
    ) => {
      dispatch(
        CreateAction(
          ratedetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setRatesLoading
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditRateModal);
