import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ShowLoading } from "../redux/actions/authaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import DeleteReferralModal from "./modals/DeleteReferralModal";
import EditReferralModal from "./modals/EditReferralModal";
import $ from "jquery";
import DataTable from "datatables.net";
import { GetSingleObjectAction } from "../redux/actions/getsoaction";

const Referralarea = (props) => {
  const {
    setNotify,
    show,
    handleShow,
    setShow,
    fetchreferrals,
    allreferrals,
    addReferralLoading,
    setAddReferralLoading,
  } = props;
  const [referrals, setReferrals] = useState({});

  useEffect(() => {
    fetchreferrals(
      show,
      setNotify,
      ActionTypes.GET_REFERRAL_SUCCESS,
      ActionTypes.GET_REFERRAL_FAIL,
      setShow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $("#examtable").DataTable({
        responsive: !0,
        destroy: true,
      });
    });
  }, []);

  //this is to check for changes in addreferralloading variable
  useEffect(() => {
    if (!addReferralLoading) {
      fetchreferrals(
        show,
        setNotify,
        ActionTypes.GET_REFERRAL_SUCCESS,
        ActionTypes.GET_REFERRAL_FAIL,
        setShow
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addReferralLoading]);

  useEffect(() => {
    if (allreferrals !== undefined && allreferrals[0] !== null) {
      setReferrals(allreferrals[0]);
    }
  }, [allreferrals]);

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleShowEdit = () => {
    setShowEdit(!showEdit);
  };
  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  };

  const [item, setItem] = useState({});

  function handleEdit(item) {
    setShowEdit(true);
    setItem(item);
  }

  function handleDelete(item) {
    setShowDelete(true);
    setItem(item);
  }

  const renderrow = (items) => {
    if (Object.keys(items).length !== 0) {
      return items.map((item, i) => {
        return (
          <>
            <tr>
              {/* bonusValue: 10 id: 5 minAmount: 100 */}
              <td>{item.minAmount}</td>
              <td>{item.bonusValue}</td>
              <td className="d-flex flex-wrap p-2" style={{ gap: "8px" }}>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-dark"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </button>
                <button
                  style={{ width: "-webkit-fill-available" }}
                  className="btn-wide btn btn-danger"
                  onClick={() => handleDelete(item)}
                >
                  Delete
                </button>
              </td>
              {/* <td>
                <button
                  className="mb-2 mr-2 btn btn-warning"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </button>
                <button
                  className="mb-2 mr-2 btn btn-danger"
                  onClick={() => handleDelete(item)}
                >
                  Delete
                </button>
              </td> */}
            </tr>
          </>
        );
      });
    }
  };

  return (
    <>
      <div className="app-page-title app-page-title-simple">
        <div className="page-title-wrapper">
          <div className="page-title-heading" style={{ marginLeft: 16 }}>
            <div>
              <div className="page-title-head center-elem">
                <span className="d-inline-block">Referral Management</span>
              </div>
              <div className="page-title-subheading opacity-10">
                <nav className aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <span>Here you can manage all your referral</span>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-xl-12">
        <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
          <div className="widget-chat-wrapper-outer">
            <div className="widget-chart-content pt-3 pl-3 pb-1">
              <div className="form-row">
                <div className="ml-auto" style={{ marginRight: 15 }}>
                  <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                    <span className="text-success pl-2">
                      <button
                        className="btn-wide mb-2 mr-2 btn btn-shadow btn-danger btn-lg"
                        onClick={handleShow}
                      >
                        Create Referral
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-card mb-3 card">
        <div className="card-body">
          <table
            style={{ width: "100%" }}
            id="examtable"
            className="table table-hover table-striped table-bordered"
          >
            <thead style={{ textAlign: "center" }}>
              <tr>
                <th>
                  <b>Minimum Amount</b>
                </th>
                <th>
                  <b>Bonus Value</b>
                </th>

                <th>
                  <b>Action</b>
                </th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {renderrow(referrals)}
            </tbody>
          </table>
        </div>
      </div>

      <EditReferralModal
        item={item}
        setNotify={setNotify}
        show={showEdit}
        handleEdit={handleEdit}
        setShow={setShowEdit}
        handleShowEdit={handleShowEdit}
        addReferralLoading={addReferralLoading}
        setAddReferralLoading={setAddReferralLoading}
      />
      <DeleteReferralModal
        item={item}
        setNotify={setNotify}
        show={showDelete}
        handleDelete={handleDelete}
        setShow={setShowDelete}
        handleShowDelete={handleShowDelete}
        addReferralLoading={addReferralLoading}
        setAddReferralLoading={setAddReferralLoading}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allreferrals: state.allreferrals.allreferrals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchreferrals: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(ShowLoading(setNotify));
      dispatch(
        GetSingleObjectAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Referralarea);
