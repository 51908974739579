import React, { useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ViewRefTransaction = (props) => {
  const { show, item, handleShowView } = props;

  useEffect(() => {}, [show]);

  return (
    <Modal
      show={show}
      onHide={handleShowView}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <div className="card-shadow-primary profile-responsive card-border card">
            <div className="dropdown-menu-header">
              <div className="dropdown-menu-header-inner bg-danger">
                <div
                  className="menu-header-image"
                  // style={{
                  //   backgroundImage:
                  //     'url("assets/images/dropdown-header/abstract1.jpg")',
                  // }}
                />
                <div className="menu-header-content btn-pane-right">
                  <div>
                    <h5 className="menu-header-title">
                      {item.customer?.firstName} {item.customer?.lastName}
                    </h5>
                    <h6 className="menu-header-subtitle">{item.userRole}</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <ul className="list-group list-group-flush col-md-6">
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Full Name</div>
                        <div className="widget-subheading">
                          {item.customer?.firstName} {item.customer?.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Sender Email</div>
                        <div className="widget-subheading">
                          {item.customer?.email}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">
                          Sender Phone Number
                        </div>
                        <div className="widget-subheading">
                          {item.customer?.phoneNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">
                          Sender Account Type
                        </div>
                        <div className="widget-subheading">
                          {item.customer?.accountType}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                {/* accountType: "Individual-Account"
address: "23 Fitila Street"
businessName: ""
businessRegNumber: ""
city: "Ibadan"
countryOfNationality: "Nigeria"
countryOfResidence: "Nigeria"
createdDate: "2021-05-01T18:18:18.718214"
customerBankAccount: null
customerIdentity: null
customerReferredTransaction: null
dateOfBirth: "2021-03-31T12:16:01.19"
email: "decloud23@gmail.com"
firstName: "Simi"
gender: "Female"
id: 10
isAMLCompliancePassed: true
isActive: true
isKYCCompliancePassed: true
isScannedForAML: true
lastName: "Ahmed"
lastUpdatedDate: "2021-05-01T18:18:18.71854"
passwordHash: "HRM3EfclcfTneB/H8ay7d6Jy/w9QAIkjLD4C3DHsS0QrCrM++1VIA2m3RVYcLUOvxcWDZY55p2kEi5s6DHB0Bg=="
passwordSalt: "BorhiL4xWyESxiiNGbDdzxsmk2O6hPyH9SUXGi0PL2I5C46V1B1jQ5f/dhqz6WzolSmeUHDU3HpN1e4Bbchj/pJboOCT2cFkxN9KYkDwJWdpdPs4a5iR3YdZuUsuAqOsn0i9XrDys9/ALbMQ5YSxwMhR6RfwWk4IuRf1DsVvTYQ="
phoneNumber: "+2349083456765"
pin: "3333"
postalCode: "234908"
receivers: null
referralDiscountCode: "JCI-DIS-56141447"
referreeID: 0
resetKey: "JCI-Reset-RUEHM"
sourceOfFunds: null
state: "Oyo"
transactions: [] */}

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">
                          Transaction Reference No
                        </div>
                        <div className="widget-subheading">
                          {item.transactionRefNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Transaction Type</div>
                        <div className="widget-subheading">
                          {item.transactionType}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Sending From</div>
                        <div className="widget-subheading">
                          {item.sendingCountry}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Receiving</div>
                        <div className="widget-subheading">
                          {item.receivingCountry}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Total Amount Sent</div>
                        <div className="widget-subheading">
                          <b>{item.sendingCurrency}</b>
                          {item.amountToSend}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul className="list-group list-group-flush col-md-6">
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">
                          Total Amount Charged
                        </div>
                        <div className="widget-subheading">
                          {item.amountToCharge}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">
                          Total Amount Received
                        </div>
                        <div className="widget-subheading">
                          <b>{item.receivingCurrency}</b>
                          {item.amountToReceive}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">
                          Receiver Account Name
                        </div>
                        <div className="widget-subheading">
                          {item.receiver?.accountName}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">
                          Receiver Account Number
                        </div>
                        <div className="widget-subheading">
                          {item.receiver?.accountNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Receiver bankName</div>
                        <div className="widget-subheading">
                          {item.receiver?.bankName}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {/* accountBSBCode: ""
accountCurrency: "NGN"
accountName: "kareem olayinka "
accountNumber: "658262656565"
accountSWiftCode: "WMY4848"
bankAddress: "no 23 agodi"
bankCity: "ibadan"
bankName: "wema bank"
bankPostalCode: "238585"
bankState: "oyo"
corresBankCountry: "NIGERIA"
corresBankIBAN: ""
corresBankName: ""
country: "NIGERIA"
customer: null
customerId: 13 */}
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Date Sent</div>
                        <div className="widget-subheading">
                          {item.dateSent?.substring(0, 10)}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Transaction Status</div>
                        <div className="widget-subheading">
                          {item.transactionStatus}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Exchange Rate</div>
                        <div className="widget-subheading">
                          {item.exchangeRate}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Payment Purpose</div>
                        <div className="widget-subheading">
                          {item.paymentPurpose}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleShowView}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewRefTransaction);
