import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ActionTypes from "../../redux/actiontype/ActionTypes";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CreateAction } from "../../redux/actions/createaction";

const EditCustomerReferralModal = (props) => {
  const {
    editreferral,
    setNotify,
    show,
    setShow,
    item,
    handleShowEdit,
    addReferralLoading,
    setAddReferralLoading,
  } = props;
  const [referraldetails, setReferraldetails] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setAddReferralLoading(true);
    editreferral(
      referraldetails,
      setNotify,
      ActionTypes.EDIT_REFERRAL_TRANSACTION_BY_ADMIN_SUCCESS,
      ActionTypes.EDIT_REFERRAL_TRANSACTION_BY_ADMIN_FAIL,
      setShow,
      setAddReferralLoading
    );
  };

  useEffect(() => {}, [show]);

  return (
    <Modal
      show={show}
      onHide={handleShowEdit}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Record</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="col-md-12 position-relative form-group">
            <div className="form-row">
              {/* <div className="col-md-6">
                <label htmlFor="payout_Balance">Payout Balance</label>
                <input
                  name="payout_Balance"
                  id="payout_Balance"
                  placeholder={item.payout_Balance}
                  type="number"
                  min="1"
                  className="form-control"
                  onChange={(event) => {
                    const payout_Balance = parseInt(event.target.value);
                    const customerId = parseInt(item.customerId);
                    const id = parseInt(item.id);
                    setReferraldetails({
                      ...referraldetails,
                      ...{ payout_Balance, customerId, id },
                    });
                  }}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="total_No_Transaction">No of Transaction</label>
                <input
                  name="total_No_Transaction"
                  id="total_No_Transaction"
                  placeholder={item.total_No_Transaction}
                  type="number"
                  min="1"
                  className="form-control"
                  onChange={(event) => {
                    const total_No_Transaction = parseInt(event.target.value);
                    const customerId = parseInt(item.customerId);
                    const id = parseInt(item.id);
                    setReferraldetails({
                      ...referraldetails,
                      ...{ total_No_Transaction, customerId, id },
                    });
                  }}
                />
              </div> */}

              <div className="col-md-12">
                <label htmlFor="exampleStatus">Status</label>
                <select
                  type="select"
                  id="status"
                  name="status"
                  className="mb-2 form-control"
                  defaultValue={item.status}
                  onChange={(event) => {
                    const status = event.target.value;
                    const customerId = parseInt(item.customerId);
                    const id = parseInt(item.id);
                    setReferraldetails({
                      ...referraldetails,
                      ...{ status, customerId, id },
                    });
                  }}
                >
                  <option> Select Status </option>
                  <option value="Paid">Paid</option>
                  <option value="unpaid">Unpaid</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShowEdit}>
          Close
        </Button>
        <Button
          variant="danger"
          disabled={addReferralLoading}
          onClick={handleSubmit}
        >
          Update Record
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loadingstate.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editreferral: (
      referraldetails,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setAddReferralLoading
    ) => {
      dispatch(
        CreateAction(
          referraldetails,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setAddReferralLoading
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCustomerReferralModal);
