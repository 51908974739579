import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import ActionTypes from "../../redux/actiontype/ActionTypes"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { CreateAction } from '../../redux/actions/createaction';

    const EditReferralModal = (props) => {
      const {
        editreferral,
        setNotify,
        show,
        setShow,
        item,
        handleShowEdit,
        addReferralLoading,
        setAddReferralLoading,
      } = props;
      const [referraldetails, setReferraldetails] = useState({});

      const handleSubmit = (e) => {
        e.preventDefault();
        setAddReferralLoading(true);
        editreferral(
          referraldetails,
          setNotify,
          ActionTypes.EDIT_REFERRAL_SUCCESS,
          ActionTypes.EDIT_REFERRAL_FAIL,
          setShow,
          setAddReferralLoading
        );
      };


      useEffect(() => {}, [show]);

      return (
        <Modal
          show={show}
          onHide={handleShowEdit}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Referral</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="col-md-12 position-relative form-group">
                <div className="form-row">
                  <div className="col-md-6">
                    <label htmlFor="minAmount">Min Amount</label>
                    <input
                      name="minAmount"
                      id="minAmount"
                      placeholder={item.minAmount}
                      defaultValue={item.minAmount}
                      type="number"
                      min="1"
                      className="form-control"
                      onChange={(event) => {
                        const minAmount = event.target.value;
                        setReferraldetails({
                          ...referraldetails,
                          ...{ minAmount },
                        });
                      }}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="bonusValue">Bonus Value</label>
                    <input
                      name="bonusValue"
                      id="bonusValue"
                      placeholder={item.bonusValue}
                      defaultValue={item.bonusValue}
                      type="number"
                      min="1"
                      className="form-control"
                      onChange={(event) => {
                        const bonusValue = event.target.value;
                        const id = item.id;
                        setReferraldetails({
                          ...referraldetails,
                          ...{ bonusValue, id },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleShowEdit}>
              Close
            </Button>
            <Button
              variant="danger"
              disabled={addReferralLoading}
              onClick={handleSubmit}
            >
              Update Referral
            </Button>
          </Modal.Footer>
        </Modal>
      );
    };

const mapStateToProps = (state) => {
    return {
        isLoading: state.loadingstate.isLoading,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
            editreferral: (referraldetails, setNotify, successactiontype, failureactiontype, setShow, setAddReferralLoading) => {
                dispatch(CreateAction(referraldetails, setNotify, successactiontype, failureactiontype, setShow, setAddReferralLoading)
            );
        },
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditReferralModal);
  