import axios from "axios";
import axios2 from "axios";

// For common config
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Accept-Language"] = "en";


const mainAxios = axios.create({
  timeout: 30000,
  // baseURL: "https://api.jciremit.com/api",
});

const countryAxios = axios2.create({
  timeout: 30000,
  // baseURL: "https://api.printful.com",
});

const downloadAxios = axios.create({
  timeout: 30000,
  // baseURL: "https://printreceipt.jciremit.com/api",
});

mainAxios.interceptors.request.use(function (config) {
  const authObject = localStorage.getItem("auth");

  if (authObject) {
    const auth = JSON.parse(authObject);
    const token = auth.authstate.token;
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

downloadAxios.interceptors.request.use(function (config) {
  const authObject = localStorage.getItem("auth");
  
  if (authObject) {
    const auth = JSON.parse(authObject);
    const token = auth.authstate.token;
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

mainAxios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      window.location.replace("/login");
    }

    // if (error.response.status === 500) {
    //   window.location.replace("/login");
    //   // Display Under Maintenance 
    // }

    return Promise.reject(error);
  }
);


export { mainAxios, countryAxios, downloadAxios };
