import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import ActionTypes from "../../redux/actiontype/ActionTypes"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { CreateAction } from '../../redux/actions/createaction';

    const DeleteReferralModal = (props) => {
    const { deletereferral, setNotify, show, setShow, item, handleShowDelete, addReferralLoading, setAddReferralLoading } = props;
    
    const handleSubmit = e => {
        e.preventDefault();
        setAddReferralLoading(true);
        deletereferral(
          item.id,
          setNotify,
          ActionTypes.DELETE_REFERRAL_SUCCESS,
          ActionTypes.DELETE_REFERRAL_FAIL,
          setShow,
          setAddReferralLoading
        );
    };

    useEffect(() => {
    }, [show])

    return (
            <Modal
            show={show}
            onHide={handleShowDelete}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header closeButton>
            <Modal.Title>Delete Referral</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form>
                    <div className="col-md-12 position-relative form-group">
                        <div className="form-row">
                            <div className="col-md-12"> 
                                <label htmlFor="exampleGender">Are you sure you want to Delete this Referral?</label>
                            </div>
                        </div>
                    </div>
                      
                    </form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleShowDelete}>
                No
            </Button>
            <Button variant="danger" disabled={addReferralLoading} onClick={handleSubmit} >Yes</Button>
            </Modal.Footer>
            </Modal>
            
    )
}

const mapStateToProps = (state) => {
    return {
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
            deletereferral: (referralid, setNotify, successactiontype, failureactiontype, setShow, setIsLoading) => {
                dispatch(CreateAction(referralid, setNotify, successactiontype, failureactiontype, setShow, setIsLoading)
            );
        },
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(DeleteReferralModal);
  
