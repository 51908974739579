import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import $, { isArray } from "jquery";
import DataTable from "datatables.net";
import { useTable, usePagination } from "react-table";
import moment from "moment";

import { GetAction } from "../redux/actions/getaction";
import ActionTypes from "../redux/actiontype/ActionTypes";
import ViewTransactionModal from "./modals/ViewTransactionModal";
import ViewRefTransaction from "./modals/ViewRefTransaction";
import EditTransactionModal from "./modals/EditTransactionModal";
import { downloadXLSFile } from "../redux/actions/downloadaction";
import { GetArrayAction } from "../redux/actions/getpatchaction";
import { CreateAction } from "../redux/actions/createaction";
import { ExportCSV } from "../utils/excelExport";
import { mainAxios } from "../utils/axios";
import { formatNaira, numSequence } from "../utils/extras";

const COLUMNS = [
  {
    Header: "Transaction Ref. No",
    accessor: "transactionRefNumber",
  },
  {
    Header: "Amount To Send",
    accessor: (d) => formatNaira(d.amountToSend),
  },
  {
    Header: "Sending Country",
    accessor: "sendingCountry",
  },
  {
    Header: "Receiving Country",
    accessor: "receivingCountry",
  },
  {
    Header: "Transaction Status",
    accessor: "transactionStatus",
  },
  {
    Header: "Transaction Type",
    accessor: "transactionType",
  },
];

const Transactionsarea = (props) => {
  const {
    setNotify,
    show,
    setShow,
    fetchalltransactions,
    fetchtypetransactions,
    fetchreftransactions,
    fetchreporttransactions,
    alltransactions,
    transactionLoading,
    reftransactions,
    typetransactions,
    setTransactionLoading,
    exporttransactions,
  } = props;
  const [transactions, setTransactions] = useState([]);
  const [transactions2, setTransactions2] = useState({});
  const [exportdetails, setExportdetails] = useState({});
  const [typeLoading, setTypeLoading] = useState(false);
  const [item, setItem] = useState({});
  const [transactitem, setTransactItem] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showTransact, setShowTransact] = useState(false);
  const [view, setView] = useState(false);
  const [view2, setView2] = useState(false);
  const [count, setCount] = useState(0);
  const [controlledPageCount, setPageCount] = useState(0);

  const {
    state: { pageIndex, pageSize },
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      data: transactions,
      columns: COLUMNS,
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  const getTransactions = useCallback(async () => {
    try {
      const res = await mainAxios.get(
        `/Transactions/getAllTransactions?pageNumber=${
          pageIndex + 1
        }&pageSize=${pageSize}`
      );

      if (res?.data?.data.length) {
        setTransactions(res.data.data);
        setCount(res.data.totalNoItems);
        setPageCount(res.data.totalPages);
      }
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);

  const filterTransaction = async (filterValue) => {
    try {
      const res = await mainAxios.get(
        `/Transactions/getAllTransactions?pageNumber=1&pageSize=10&statusFilter=${filterValue}`
      );

      if (res?.data?.data.length) {
        setTransactions(res.data.data);
        setCount(res.data.totalNoItems);
        setPageCount(res.data.totalPages);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTransactions();
  }, [getTransactions, pageIndex, pageSize, transactionLoading]);

  // useEffect(() => {
  //   fetchalltransactions(
  //     show,
  //     setNotify,
  //     ActionTypes.FETCH_ALL_TRANSACTION_SUCCESS,
  //     ActionTypes.FETCH_ALL_TRANSACTION_FAIL,
  //     setShow
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  //this is to check for changes in transactionLoading variable
  useEffect(() => {
    if (!transactionLoading) {
      fetchalltransactions(
        show,
        setNotify,
        ActionTypes.FETCH_ALL_TRANSACTION_SUCCESS,
        ActionTypes.FETCH_ALL_TRANSACTION_FAIL,
        setShow
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionLoading]);

  useEffect(() => {
    if (typetransactions !== undefined) {
      setView2(true);
      setTransactions2(typetransactions);
    }
  }, [typetransactions]);

  useEffect(() => {
    if (reftransactions !== undefined && exportdetails.ref) {
      viewTransact(reftransactions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reftransactions]);

  const exportReport = () => {
    const from = moment(exportdetails.startDate);
    const to = moment(exportdetails.endDate);
    let sfrom = from.format("DD-MM-YYYY");
    let sto = to.format("DD-MM-YYYY");
    let stateobject = {};
    stateobject.startDate = sfrom;
    stateobject.endDate = sto;
    stateobject.transType = exportdetails.transType;

    fetchreporttransactions(
      stateobject,
      setNotify,
      ActionTypes.FETCH_TRANSACTION_REPORT_SUCCESS,
      ActionTypes.FETCH_TRANSACTION_REPORT_FAIL,
      setShow
    );
    // downloadXLSFile(
    //   "https://api.jciremit.com/api/Transactions/ExportTransactionReportByDateRange/" +
    //     sfrom +
    //     "/" +
    //     sto +
    //     "/" +
    //     exportdetails.transType
    // );
  };

  useEffect(() => {
    if (exporttransactions !== undefined) {
      if (exportdetails.startDate) {
        let filename =
          "Transaction Report for " +
          exportdetails.transType +
          " between " +
          exportdetails.startDate +
          " and " +
          exportdetails.endDate;
        ExportCSV({ csvData: exporttransactions, fileName: filename });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exporttransactions]);

  const viewalltype = () => {
    fetchtypetransactions(
      exportdetails.transType2,
      setNotify,
      ActionTypes.FETCH_TYPE_TRANSACTION_SUCCESS,
      ActionTypes.FETCH_TYPE_TRANSACTION_FAIL,
      setShow,
      setTypeLoading
    );
  };

  const viewallref = () => {
    fetchreftransactions(
      exportdetails.ref,
      setNotify,
      ActionTypes.FETCH_REF_TRANSACTION_SUCCESS,
      ActionTypes.FETCH_REF_TRANSACTION_FAIL,
      setShow
    );
  };

  const handleShowEdit = () => {
    setShowEdit(!showEdit);
  };

  const handleShowView = () => {
    setShowView(!showView);
  };

  const handleShowTransact = () => {
    setShowTransact(!showTransact);
  };

  function viewUser(item) {
    setShowView(true);
    setItem(item);
  }

  function viewTransact(item) {
    setShowTransact(true);
    setTransactItem(item);
  }

  function editUser(item) {
    setShowEdit(true);
    setItem(item);
  }

  const renderrow = (items) => {
    return Array.from(items)?.map((item, i) => {
      return (
        <>
          <tr>
            <td>{item.transactionRefNumber}</td>
            <td>{formatNaira(item.amountToSend)}</td>
            <td>{item.sendingCountry}</td>
            <td>{item.receivingCountry}</td>
            <td>{item.transactionStatus}</td>
            <td>{item.transactionType}</td>
            <td className="d-flex flex-wrap p-2" style={{ gap: "8px" }}>
              <button
                style={{ width: "-webkit-fill-available" }}
                className="btn-wide btn btn-danger"
                onClick={() => {
                  viewUser(item);
                }}
              >
                View
              </button>
              <button
                style={{ width: "-webkit-fill-available" }}
                className="btn-wide btn btn-dark"
                onClick={() => {
                  editUser(item);
                }}
              >
                Edit
              </button>
            </td>
          </tr>
        </>
      );
    });
    // }
  };

  return (
    <>
      <div className="app-page-title app-page-title-simple">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div>
              <div className="page-title-head center-elem">
                <span className="d-inline-block">
                  Transaction Management & Verification
                </span>
              </div>
              <div className="page-title-subheading opacity-10">
                <nav className aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <span>
                        Here you can manage all your transactions and process
                        them
                      </span>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-xl-12">
        <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
          <div className="widget-chat-wrapper-outer">
            <div className="widget-chart-content pt-3 pl-3 pb-1">
              <div className="widget-chart-flex">
                <div className="widget-numbers">
                  <div className="widget-chart-flex">
                    <div className="fsize-4">
                      <span>Searching</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12">
                  <div className="position-relative form-group">
                    <input
                      name="refnumber"
                      id="exampleRef"
                      placeholder="Enter Ref Number"
                      type="text"
                      className="form-control"
                      onChange={(event) => {
                        const ref = event.target.value;
                        setExportdetails({ ...exportdetails, ...{ ref } });
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="ml-auto" style={{ marginRight: 15 }}>
                      <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                        <span className="text-success pl-2">
                          <button
                            className="btn-wide btn btn-danger"
                            onClick={viewallref}
                          >
                            Find Now
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
          <div className="widget-chat-wrapper-outer">
            <div className="widget-chart-content pt-3 pl-3 pb-1">
              <div className="widget-chart-flex">
                <div className="widget-numbers">
                  <div className="widget-chart-flex">
                    <div className="fsize-4">
                      <span>Reporting</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 position-relative form-group">
                  <div className="form-row">
                    <div className="col-md-4">
                      <label htmlFor="exampleGender">Transaction Type</label>
                      <select
                        type="select"
                        id="gender"
                        name="gender"
                        className="mb-2 form-control"
                        onChange={(event) => {
                          const transType = event.target.value;
                          setExportdetails({
                            ...exportdetails,
                            ...{ transType },
                          });
                        }}
                      >
                        <option>Transaction Type</option>
                        <option value="Buy">Buy</option>
                        <option value="Sell">Sell</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label htmlfor="exampleDate">From Date</label>
                      <div className="position-relative form-group">
                        <input
                          name="startDate"
                          id="exampleDate"
                          placeholder="date placeholder"
                          type="date"
                          className="form-control"
                          onChange={(event) => {
                            const startDate = event.target.value;
                            setExportdetails({
                              ...exportdetails,
                              ...{ startDate },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <label htmlfor="exampleDate">To Date</label>
                      <div className="position-relative form-group">
                        <input
                          name="endDate"
                          id="exampleDate"
                          placeholder="date placeholder"
                          type="date"
                          className="form-control"
                          onChange={(event) => {
                            const endDate = event.target.value;
                            setExportdetails({
                              ...exportdetails,
                              ...{ endDate },
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-2" style={{ marginLeft: 0 }}>
                      <label htmlfor="exampleDate"></label>
                      <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                        <span className="text-success pl-2">
                          <button
                            className="btn-wide btn btn-danger"
                            style={{ marginTop: 12 }}
                            onClick={() => {
                              exportReport();
                            }}
                          >
                            Export
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6">
                      <label htmlFor="exampleGender">
                        Filter Transaction List
                      </label>
                      <select
                        type="select"
                        id="gender"
                        name="gender"
                        className="mb-2 form-control"
                        onChange={(event) => {
                          const transType = event.target.value;
                          filterTransaction(transType);
                          // setExportdetails({
                          //   ...exportdetails,
                          //   ...{ transType },
                          // });
                        }}
                      >
                        <option>Transaction Status</option>
                        <option value="Pending">Pending</option>
                        <option value="Processing">Processing</option>
                        <option value="Completed">Completed</option>
                        <option value="OnHold">OnHold</option>
                        <option value="Failed">Failed</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-card mb-3 card">
        <div className="card-body">
          <table
            style={{ width: "100%" }}
            id="examtable"
            className="table table-hover table-striped table-bordered"
          >
            <thead>
              <tr>
                <th>Transaction Ref. No</th>
                <th>Amount To Send</th>
                <th>Sending Country</th>
                <th>Receiving Country</th>
                <th>Transaction Status</th>
                <th>Transaction Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{renderrow(transactions)}</tbody>
          </table>
          {!!page.length && (
            <div className="pagination-wrapper my-4">
              <div className="colItem">
                <div className="colItem pageSize item">
                  <span className="sup">Items per page: </span>
                  <div className="colItem selectGroup">
                    <select
                      id="pageSize"
                      name="pageSize"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                      defaultValue={pageSize}
                    >
                      <option value="" hidden>
                        {pageSize}
                      </option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                      <option value="60">60</option>
                      <option value="70">70</option>
                      <option value="80">80</option>
                      <option value="90">90</option>
                      <option value="100">100</option>
                    </select>
                    <i data-feather="chevron-down" className="dropdownIcon"></i>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="colItem item">
                  <span className="sup">
                    page <b>{pageIndex + 1}</b> of <b>{count}</b> entries
                  </span>
                </div>
                <div className="divider"></div>
              </div>
              <div className="colItem">
                <div className="divider"></div>
                <div className="colItem item">
                  <div className="colItem selectGroup">
                    <select
                      id="pageSize"
                      name="pageSize"
                      onChange={(e) => {
                        const pageNumber = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(pageNumber);
                      }}
                      value={pageIndex + 1}
                    >
                      <option value="" hidden>
                        {pageIndex + 2}
                      </option>
                      {numSequence(pageCount).map((item) => (
                        <option key={`page_${item}`} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {/* <FeatherIcon
                        iconName="chevron-down"
                        className="dropdownIcon icon"
                        color="var(--bs-gray-200)"
                      /> */}
                  </div>
                  <div className="colItem">
                    <span className="sup">
                      &nbsp; of {pageCount} page{pageCount !== 1 ? "s" : ""}
                    </span>
                  </div>
                </div>
                <div className="divider"></div>
                <button
                  className="colItem item"
                  disabled={!canPreviousPage}
                  onClick={() => previousPage()}
                >
                  Previous
                </button>
                <div className="divider"></div>
                <button
                  className="colItem item"
                  disabled={!canNextPage}
                  onClick={() => nextPage()}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <ViewTransactionModal
        item={item}
        show={showView}
        setShow={setShowView}
        handleShowView={handleShowView}
      />
      <ViewRefTransaction
        item={transactitem}
        show={showTransact}
        setShow={setShowTransact}
        handleShowView={handleShowTransact}
      />

      <EditTransactionModal
        item={item}
        show={showEdit}
        setShow={setShowEdit}
        handleShowEdit={handleShowEdit}
        setNotify={setNotify}
        editTransactionLoading={transactionLoading}
        setEditTransactionLoading={setTransactionLoading}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    alltransactions: state.alltransactions.alltransactions,
    typetransactions: state.typetransactions.typetransactions,
    reftransactions: state.reftransactions.reftransactions,
    exporttransactions: state.exporttransactions.exporttransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchalltransactions: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchreporttransactions: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
    fetchtypetransactions: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow,
      setTypeLoading
    ) => {
      dispatch(
        CreateAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow,
          setTypeLoading
        )
      );
    },
    fetchreftransactions: (
      show,
      setNotify,
      successactiontype,
      failureactiontype,
      setShow
    ) => {
      dispatch(
        GetArrayAction(
          show,
          setNotify,
          successactiontype,
          failureactiontype,
          setShow
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactionsarea);
